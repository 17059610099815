import React, { useState } from 'react';
import { downloadZip } from 'modules/api';
import { Checkbox } from 'components/check-box';
import DownloadRow from 'components/download-row';
import { SelectedCheckbox, Download } from 'components/icons';
import styles from './styles.module.scss';

export const DownloadResults = ({ results }) => {
  const [selected, setSelected] = useState([]);
  const noResults = results.length === 0;
  const allSelected = selected.length >= 20;

  const handleSelect = (name, value) => {
    const index = selected.indexOf(value);
    const isSelected = index >= 0;
    setSelected(
      isSelected
        ? selected.filter(select => select !== value)
        : [...selected, value]
    );
  };

  const handleSelectAll = () => {
    const selected = allSelected
      ? []
      : results.map(result => new URL(result.guid).pathname);
    setSelected(selected);
  };

  const handleDownloadSelected = () => {
    const downloads = selected.map(file => file.substr(1));
    downloadZip(downloads);
  };

  const handleDownloadAll = () => {
    const downloads = results.map(result =>
      new URL(result.guid).pathname.substr(1)
    );
    downloadZip(downloads);
  };

  const handleSingleDownload = url => {
    const pathname = new URL(url).pathname.substr(1);
    downloadZip([pathname]);
  };

  return (
    <>
      <div className={styles.actions}>
        <div className={styles.select}>
          <label htmlFor="select-all">
            <Checkbox
              icon={<SelectedCheckbox />}
              id="select-all"
              name="select-all"
              value="select-all"
              checked={allSelected}
              onChange={(name, value) => handleSelectAll(name, value)}
            />
            <span className={!noResults ? '' : `${styles.fade}`}>
              Select all
            </span>
          </label>
          <p
            className={
              !noResults ? styles.amount : `${styles.amount} ${styles.fade}`
            }
          >
            {selected.length} Selected
          </p>
        </div>

        <div
          className={
            !noResults ? styles.buttons : `${styles.buttons}  ${styles.fade}`
          }
        >
          <button
            className={styles['download-selected']}
            onClick={handleDownloadSelected}
            disabled={selected.length === 0}
          >
            download selected <Download className={styles['download-icon']} />
          </button>
          <button
            className={styles['download-all']}
            onClick={handleDownloadAll}
          >
            download all <Download className={styles['download-icon']} />
          </button>
        </div>
      </div>
      <div className={styles.results}>
        {results.map((result, index) => (
          <DownloadRow
            key={index}
            {...result}
            handleSingleDownload={handleSingleDownload}
            selected={selected}
            handleSelect={handleSelect}
          />
        ))}
      </div>
    </>
  );
};

DownloadResults.defaultProps = {
  results: [],
};
