import urls from 'constants/urls.json';
import { getFaqs } from 'modules/api';

export const FAQS_REQUEST = 'FAQS_REQUEST';
export const FAQS_RECEIVED = 'FAQS_RECEIVED';

export const requestFaqs = () => {
  return { type: FAQS_REQUEST };
};

export const receiveFaqs = faqs => {
  return { type: FAQS_RECEIVED, faqs };
};

export const fetchFaqs = () => {
  return async dispatch => {
    dispatch(requestFaqs());

    const faqs = await getFaqs(urls['faqs']);

    dispatch(receiveFaqs(faqs));
  };
};
