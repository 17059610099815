import React from 'react';
import { useSelector } from 'react-redux';
import { abbreviateAllVariants } from 'modules/abbreviate-all-variants';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

export const SearchView = ({ products }) => {
  const { notification } = useSelector((state) => state.notification);

  const classes = classNames({
    [`${styles['search-view']}`]: true,
    [`${styles['notification-on']}`]: notification.length > 0,
  });

  return (
    <div className={classes}>
      <div className={styles.wrapper}>
        <ul>
          {Object.values(products).map((item) => (
            <li key={item.ID}>
              <a href={item.default.url}>
                <div>
                  <h3>{item.title}</h3>
                  <p>{abbreviateAllVariants(item.allVariants).join(' / ')}</p>
                  {item.types.map((type, i) => (
                    <p key={item.title + i}>{type}</p>
                  ))}
                </div>
              </a>
            </li>
          ))}
        </ul>
        <div className={`${styles.results} flex-center`}>
          {products.length} results
        </div>
      </div>
    </div>
  );
};

SearchView.propTypes = {
  products: PropTypes.array.isRequired,
};
