import React from 'react';

export const New = props => (
  <svg viewBox="0 0 41 20" {...props}>
    <path d="M4 0h33c2.2 0 4 1.8 4 4v12c0 2.2-1.8 4-4 4H4c-2.2 0-4-1.8-4-4V4c0-2.2 1.8-4 4-4z" />
    <g fill="#fff">
      <path d="M8.1 14H6.2V6.2H8v1C8.5 6.3 9.4 6 10.3 6c1.9 0 2.8 1.4 2.8 3.1V14h-1.9V9.4c0-1-.4-1.7-1.6-1.7-1.1 0-1.6.8-1.6 1.8V14zM22.3 11.8c-.4 1.4-1.6 2.5-3.5 2.5-2.1 0-4-1.5-4-4.2 0-2.5 1.8-4.1 3.8-4.1 2.4 0 3.8 1.6 3.8 4v.6h-5.7c0 1.2 1 2 2.1 2s1.7-.6 2-1.4zm-1.8-2.6c0-.9-.6-1.7-1.9-1.7-1.2 0-1.8.9-1.9 1.7zM30.4 6.2l1.8 5.4 1.6-5.4h1.9L33.2 14h-1.9l-1.9-5.6-1.9 5.6h-1.9L23 6.2h2l1.6 5.4 1.8-5.4z" />
    </g>
  </svg>
);
