import React from 'react';
import { values } from 'lodash/fp';
import PropTypes from 'prop-types';
import { SIZE_BIG } from 'constants/sizes';
import { SECTION_COLLABORATORS } from 'constants/section-name';
import { FloatingLogo } from 'components/floating-logo';
import styles from './style.module.scss';

export const Collaborators = ({ collaborators, offsetHelper }) => {
  return (
    <section className={`${styles.collaborators} section`}>
      <div className="container">
        <h3 className={styles.title}>proud of our collaborators</h3>
        <FloatingLogo
          section={SECTION_COLLABORATORS}
          size={SIZE_BIG}
          offsetHelper={offsetHelper}
        />
        <div className={`${styles.wrapper}`}>
          {values(collaborators).map((collaborator, index) => {
            return (
              <figure key={index} className={styles['images-wrapper']}>
                <img src={collaborator.logo} alt="collaborator logo" />
              </figure>
            );
          })}
        </div>
      </div>
    </section>
  );
};

Collaborators.propTypes = {
  collaborators: PropTypes.object.isRequired,
  offsetHelper: PropTypes.number,
};