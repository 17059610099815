import React from 'react';
import PropTypes from 'prop-types';
import { HtmlBlock } from 'components/html-block';
import styles from './style.module.scss';

export const FeaturedImagesProject = ({
  color,
  year,
  image1,
  image2,
  text,
}) => {
  return (
    <>
      {image1 && image2 && (
        <section className={`${styles['featured-images']} section container`}>
          <h2>{year}</h2> {/* floating text */}
          <div className={styles.wrapper}>
            <div
              className={styles['image-big']}
              style={{
                background: `url(${image1}) no-repeat`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
              }}
            />
            <div className={styles['column-right']}>
              <div className={styles['group']}>
                <div className={`${styles['bg-color']} ${styles[color]}`} />
                <div
                  className={styles['image-small']}
                  style={{
                    background: `url(${image2}) no-repeat`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                  }}
                />
              </div>
              <div className={styles.copy}>
                <HtmlBlock>{text}</HtmlBlock>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

FeaturedImagesProject.propTypes = {
  color: PropTypes.string,
  year: PropTypes.string,
  image1: PropTypes.string.isRequired,
  image2: PropTypes.string.isRequired,
  text: PropTypes.string,
}
