import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';

export const LazyImage = ({
  src,
  thumbnailSrc,
  alt,
  lightboxOpen,
  classes,
}) => {
  const [imageSrc, setImageSrc] = useState(thumbnailSrc);
  const [imageRef, setImageRef] = useState(null);

  useEffect(() => {
    let observer;
    let didCancel = false;

    if (
      !('IntersectionObserver' in window) ||
      !('IntersectionObserverEntry' in window) ||
      !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
    ) {
      return setImageSrc(src);
    }

    if (imageRef && imageSrc === thumbnailSrc) {
      if ('IntersectionObserver' in window) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                setImageSrc(src);
              }
            });
          },
          {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
          }
        );
        observer.observe(imageRef);
      } else {
        // Old browsers fallback
        setImageSrc(src);
      }
    }
    return () => {
      didCancel = true;
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef);
      }
    };
  }, [thumbnailSrc, src, imageRef, imageSrc]);

  /* make sure the src changes
   * when the user navigates in the media gallery */
  useEffect(() => {
    if (lightboxOpen) setImageSrc(src);
  }, [lightboxOpen, src]);

  return (
    <img
      className={styles['lazy-image'] + ' ' + classes}
      ref={setImageRef}
      src={imageSrc}
      alt={alt}
    />
  );
};
