import React from 'react';

export const Download = props => (
  <svg data-name="Layer 1" viewBox="0 0 36 36" {...props}>
    <path
      d="M18 0a18 18 0 1018 18A18 18 0 0018 0zm-1 19V9h2v10h2l-3 4-3-4zm10 8H9v-2h18z"
      fill="#393e5b"
      data-name="np download 711089 000000"
    />
  </svg>
);
