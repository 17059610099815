import {
  GET_IN_TOUCH_REQUEST,
  GET_IN_TOUCH_RECEIVED,
} from 'redux/actions/get-in-touch-banner';

const initialState = {
  hasError: false,
  banner: {},
  loading: true,
};

export default function getInTouchBanner(state = initialState, action) {
  switch (action.type) {
    case GET_IN_TOUCH_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        banner: { ...state.banner },
        loading: true,
      });
    case GET_IN_TOUCH_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        banner: {
          ...state.banner,
          ...action.content,
        },
        loading: false,
      });
    default:
      return state;
  }
}
