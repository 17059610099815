/**
 * Get localized date depending on location
 * @param {string} date string e.g. "2020-01-02T10:57:50"
 * @return {string} localized date string e.g. "02/01/2020"
 */
export const getLocalizedDate = (date, locale) => {
  const _date = new Date(date);

  if ('Intl' in window) {
    /* we cannot enforce locale, because it should display accordingly to the user location
    * it was added for testing matters
    */
    return new Intl.DateTimeFormat(locale).format(_date);
  } else {
    return date;
  }
};
