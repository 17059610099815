import React, { useEffect, useState } from 'react';
import { getPage } from 'modules/api';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPeople } from 'redux/actions';
import { values } from 'lodash/fp';
import urls from 'constants/urls.json';
import { THEME_SOLID } from 'constants/color-theme';
import { Header } from 'components/header';
import { PeopleHero } from 'components/people-hero';
import { PeopleGrid } from 'components/people-grid';
import { SignUpSection } from 'components/signup-section';
import { SectionWithBackground } from 'components/section-with-background';
import { Footer } from 'components/footer';
import { Loading } from 'components/loading';
import { SlideUp } from 'components/animations';
import { ErrorBoundary } from 'components/error';

export const People = () => {
  const dispatch = useDispatch();

  const [heroDescription, setHeroDescription] = useState(null);
  const [heroImageOne, setHeroImageOne] = useState(null);
  const [heroImageTwo, setHeroImageTwo] = useState(null);
  const [calloutImage, setCalloutImage] = useState(null);
  const [metaLoading, setMetaLoading] = useState(true);

  const fetchPageMeta = async () => {
    const page = await getPage(urls['page'], 'people');
    return page.acf;
  };

  useEffect(() => {
    fetchPageMeta()
      .then(
        ({ hero_description, hero_image_1, hero_image_2, callout_image }) => {
          setHeroDescription(hero_description);
          setHeroImageOne(hero_image_1);
          setHeroImageTwo(hero_image_2);
          setCalloutImage(callout_image);
        }
      )
      .then(() => setMetaLoading(false));
  }, []);

  useEffect(() => {
    dispatch(fetchPeople());
  }, [dispatch]);

  const { people, peopleLoading } = useSelector((state) => state.people);

  if (metaLoading && peopleLoading) return <Loading />;

  return (
    <ErrorBoundary>
      <Header theme={THEME_SOLID} />
      <SlideUp>
        <PeopleHero
          description={heroDescription}
          imageOne={heroImageOne}
          imageTwo={heroImageTwo}
        />
      </SlideUp>
      {!peopleLoading && <PeopleGrid people={values(people)} />}
      <SignUpSection />
      <SlideUp>
        <SectionWithBackground sectionName="people" image={calloutImage} />
      </SlideUp>
      <Footer />
    </ErrorBoundary>
  );
};
