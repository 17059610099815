import urls from 'constants/urls.json';
import { getLitEffects } from 'modules/api';

export const LIT_EFFECTS_REQUEST = 'LIT_EFFECTS_REQUEST';
export const LIT_EFFECTS_RECEIVED = 'LIT_EFFECTS_RECEIVED';

export const requestLitEffects = () => {
  return { type: LIT_EFFECTS_REQUEST };
};

export const receiveLitEffects = litEffects => {
  return { type: LIT_EFFECTS_RECEIVED, litEffects };
};

export const fetchLitEffects = () => {
  return async dispatch => {
    dispatch(requestLitEffects());

    const litEffects = await getLitEffects(urls['lit-effects']);

    dispatch(receiveLitEffects(litEffects));
  };
};
