import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNewsPost, fetchNews } from 'redux/actions';
import { useWindowSize } from 'hooks/use-window-size';

import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { SignUpSection } from 'components/signup-section';
import { Loading } from 'components/loading';
import { ErrorBoundary } from 'components/error';
import { FourOhFour } from 'components/four-oh-four';
import { HtmlBlock } from 'components/html-block';
import { NewsHero } from 'components/news-hero';
import { Carousel } from 'components/carousel';
import { getLocalizedDate } from 'modules/localized-date';
import { Slide } from 'components/carousel/slide';
import { settingsLandscapeSmall } from 'components/carousel/settings';
import { THEME_DARK, THEME_SOLID } from 'constants/color-theme';
import { TITLE_MORE_NEWS } from 'constants/titles';
import { CAROUSEL_SMALL_LANDSCAPE } from 'constants/carousel-types';

export const NewsDetailScreen = ({ match }) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const smallScreen = width < 980;

  useEffect(() => {
    dispatch(fetchNewsPost(match.params.slug));
    dispatch(fetchNews());
  }, [dispatch, match]);

  const { loading: newsLoading, news } = useSelector((state) => state.news);
  const { loading, post } = useSelector((state) => state.newsPost);

  if (loading) return <Loading />;

  if (post.slug === undefined) return <FourOhFour />;

  return (
    <ErrorBoundary>
      <Header theme={THEME_SOLID} />
      <NewsHero image={post.hero_image} title={post.title} date={post.date} />
      <div
        className="section container"
        style={{
          paddingBottom: '22px',
          paddingLeft: !smallScreen ? '115px' : '25px',
          paddingRight: !smallScreen ? '24vw' : '29px',
        }}
      >
        <HtmlBlock content={post.description} />
      </div>
      {!newsLoading && Object.values(news).length > 2 && (
        <Carousel
          type={CAROUSEL_SMALL_LANDSCAPE}
          title={TITLE_MORE_NEWS}
          theme={THEME_DARK}
          settings={settingsLandscapeSmall}
        >
          {Object.values(news)
            .filter((post) => post.slug !== match.params.slug)
            .map((post, index) => {
              return (
                <Slide
                  key={index}
                  src={post.thumbnail}
                  type={CAROUSEL_SMALL_LANDSCAPE}
                  url={`${post.slug}`}
                  title={post.title.rendered}
                  description={getLocalizedDate(post.date)}
                  theme={THEME_DARK}
                />
              );
            })}
        </Carousel>
      )}
      <SignUpSection />
      <Footer />
    </ErrorBoundary>
  );
};
