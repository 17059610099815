import React from 'react';
import posed from 'react-pose';
import SplitText from 'react-pose-text';

import styles from './style.module.scss';

export const SignUpTitle = ({ title, entered }) => {
  const wordPoses = {
    enter: {
      opacity: 1,
      transition: ({ wordIndex }) => ({
        delay: wordIndex * 20,
        duration: 500,
        ease: 'easeInOut',
      }),
    },
    exit: { opacity: 0 },
  };

  const SideRectangle = posed.div({
    enter: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1000,
        ease: 'easeInOut',
      },
    },
    exit: { x: '-100%', opacity: 0 },
  });

  return (
    <div className={styles.title}>
      <SideRectangle
        className={styles.rectangle}
        pose={entered ? 'enter' : 'exit'}
      />
      <h2>
        <SplitText wordPoses={wordPoses} pose={entered ? 'enter' : 'exit'}>
          {title}
        </SplitText>
      </h2>
    </div>
  );
};
