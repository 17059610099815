import STATEMENTS from 'components/spec-code-new/statements';

export const applySpecCodeStatements = (
  product,
  codeGroup,
  data,
  optionsValues
) => {
  let result = data;
  STATEMENTS.forEach(({ check, execute }) => {
    if (check(product, codeGroup, optionsValues)) {
      result = execute(result);
    }
  });
  return result;
};
