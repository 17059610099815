import urls from 'constants/urls.json';
import { getDownloads } from 'modules/api';
import md5 from 'md5';

export const DOWNLOADS_REQUEST = 'DOWNLOADS_REQUEST';
export const DOWNLOADS_RECEIVED = 'DOWNLOADS_RECEIVED';

function requestDownloads(hash) {
  return { type: DOWNLOADS_REQUEST, hash };
}

function receiveDownloads(hash, data) {
  return { type: DOWNLOADS_RECEIVED, hash, data };
}

function fetchDownloads(hash, filters) {
  return async dispatch => {
    dispatch(requestDownloads(hash));

    const data = await getDownloads(urls['downloads'], filters);

    dispatch(receiveDownloads(hash, data));
  };
}

function shouldFetchDownloads(state, hash) {
  const downloads = state.downloads?.[hash];

  if (!downloads) {
    return true;
  } else if (downloads.loading) {
    return false;
  } else {
    return downloads.error;
  }
}

export default function fetchDownloadsIfNeeded(filters) {
  const hash = md5(JSON.stringify(filters));
  return (dispatch, getState) => {
    if (shouldFetchDownloads(getState(), hash)) {
      return dispatch(fetchDownloads(hash, filters));
    } else {
      return Promise.resolve();
    }
  };
}
