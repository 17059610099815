import React from 'react';
import styles from 'components/check-box/style.module.scss';

export const SurfaceAndTrack = (props) => (
  <svg
    className={styles['filter-icon']}
    {...props}
    height="56"
    viewBox="0 0 57 56"
    width="57"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd" transform="translate(1.5 1)">
      <circle cx="27" cy="27" r="27" stroke="#f4f4f4" strokeWidth="2" />
      <path
        d="m15.2720779 15.2720779c2.4809779-2.4809778 4.9950153-3.9894003 5.6152598-3.3691558.0273874.0273874.0506245.0584672.0698107.0930736l.0116467-.0116162 21.9203102 21.9203102-.0769704.0768626c.1179177.0169089.2138653.0600323.284943.13111.6202445.6202445-.888178 3.1342819-3.3691558 5.6152598-2.4809779 2.4809778-4.9950153 3.9894003-5.6152598 3.3691558-.0710777-.0710777-.1142011-.1670253-.13111-.284943l-.0768626.0769704-21.9203102-21.9203102.0116162-.0116467c-.0346064-.0191862-.0656862-.0424233-.0930736-.0698107-.6202445-.6202445.888178-3.1342819 3.3691558-5.6152598z"
        fill="#555762"
      />
    </g>
  </svg>
);
