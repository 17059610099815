import React, { useState, useEffect } from 'react';
import { head } from 'lodash/fp';
import {
  getBeamAngles,
  getPopulatedBeamAngles,
  getColourTemperatureTitles,
  getLitEffects,
} from 'modules/tech-spec';
import { HEADERS } from 'constants/tech-spec';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

export const TechSpec = ({ data }) => {
  const [state, setState] = useState({
    activeColourTemperature: false,
    activeLitEffect: false,
    beamAngles: false,
    populatedBeamAngles: false,
    colourTemperatureTitles: false,
    litEffects: false,
    initialised: false,
  });
  
  const {
    activeColourTemperature,
    activeLitEffect,
    beamAngles,
    populatedBeamAngles,
    colourTemperatureTitles,
    litEffects,
    initialised,
  } = state;

  const colourTemperatures = data['add_colour_temperature'];
  // Initial load
  useEffect(() => {
    const colourTemperatureTitles = getColourTemperatureTitles(
      colourTemperatures
    );

    const activeColourTemperature = head(colourTemperatureTitles);
    const litEffects = getLitEffects(
      activeColourTemperature,
      colourTemperatures
    );

    const activeLitEffect = head(litEffects)?.lit_effect;
    const beamAngles = getBeamAngles(activeLitEffect, litEffects);
    const populatedBeamAngles = getPopulatedBeamAngles(beamAngles);

    setState({
      colourTemperatureTitles,
      litEffects,
      activeColourTemperature,
      activeLitEffect,
      beamAngles,
      populatedBeamAngles,
      initialised: true,
    });
  }, [data, colourTemperatures]);

  // Colour temperature change
  useEffect(() => {
    if (activeColourTemperature !== false) {
      const litEffects = getLitEffects(
        activeColourTemperature,
        colourTemperatures
      );

      const activeLitEffect = head(litEffects)?.lit_effect;
      const beamAngles = getBeamAngles(activeLitEffect, litEffects);
      const populatedBeamAngles = getPopulatedBeamAngles(beamAngles);

      setState({
        ...state,
        litEffects,
        activeLitEffect,
        beamAngles,
        populatedBeamAngles,
      });
    }
    // eslint-disable-next-line
  }, [activeColourTemperature]);

  // Lit effect change
  useEffect(() => {
    if (activeLitEffect !== false) {
      const beamAngles = getBeamAngles(activeLitEffect, litEffects);
      const populatedBeamAngles = getPopulatedBeamAngles(beamAngles);

      setState({
        ...state,
        beamAngles,
        populatedBeamAngles,
      });
    }
    // eslint-disable-next-line
  }, [activeLitEffect]);

  // Dont show anything until we have initialised
  if (!initialised) return null;

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <section className={`${styles['tech-spec']} section`}>
      <div className="container">
        <div className={styles.inner}>
          <div className={styles['left-column']}>
            <p className={styles['tech-spec-title']}>beam and lumens</p>
            <p className={styles['tech-spec-description']}>
              Choose the colour temperature and beam angle to suit your scheme.
              Up to 10 precise beam angles ranging from 'Soft' to 'Spot' and
              'Speciality'.
            </p>
            <table className={styles['table-left']}>
              <tbody>
                {Object.keys(HEADERS).map((key, index) => {
                  if (populatedBeamAngles.indexOf(key) > -1) {
                    return (
                      <tr className={styles['table-row-left']} key={index}>
                        <td className={styles.headers}>{HEADERS[key]}</td>
                      </tr>
                    );
                  }

                  return false;
                })}
              </tbody>
            </table>
          </div>
          <div className={styles['right-column']}>
            <div className={styles['colour-temperature-toggle']}>
              <div className={styles['colour-temperature-container']}>
                {colourTemperatureTitles.map((title, index) => (
                  <button
                    key={index}
                    className={
                      activeColourTemperature === title ? styles.active : ''
                    }
                    onClick={() =>
                      handleChange('activeColourTemperature', title)
                    }
                  >
                    {title}
                  </button>
                ))}
              </div>
            </div>
            <div className={styles['lit-effect-toggle']}>
              <div className={styles['lit-effect-container']}>
                {litEffects.map((item, index) => (
                  <button
                    key={index}
                    className={
                      activeLitEffect === item.lit_effect ? styles.active : ''
                    }
                    onClick={() =>
                      handleChange('activeLitEffect', item.lit_effect)
                    }
                  >
                    {item.lit_effect}
                  </button>
                ))}
              </div>
            </div>
            <table className={styles['table-right']}>
              <tbody className={styles.table}>
                {Object.keys(HEADERS).map((key, index) => {
                  if (populatedBeamAngles.indexOf(key) > -1) {
                    return (
                      <tr className={styles['table-row-right']} key={index}>
                        {beamAngles.map((item, index) => (
                          <td className={styles.values} key={index}>
                            {item[key]}
                          </td>
                        ))}
                      </tr>
                    );
                  }
                  return false;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

TechSpec.propTypes = {
  data: PropTypes.shape({
    add_colour_temperature: PropTypes.array,
    add_line_drawings: PropTypes.array,
    downloads: PropTypes.object,
    engine_type: PropTypes.object,
    specification_code: PropTypes.object
  })
}