import axios from 'axios';
import { stringify } from 'query-string';
import { keys } from 'lodash/fp';

export const getDownloads = async (endpoint, page) => {
  try {
    const query = keys(page).length > 0 ? ['?', stringify(page)].join('') : '';

    const url = [
      process.env.REACT_APP_CMS_URL,
      endpoint.replace(':query', query),
    ].join('/');
    const response = await axios.get(url);
    const totalResults = await response.headers['x-wp-total'];
    const totalPages = await response.headers['x-wp-totalpages'];
    const data = await response.data;

    return {
      data,
      totalResults,
      totalPages,
    };
  } catch (error) {
    throw error;
  }
};
