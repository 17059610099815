import React from 'react';
import { useSelector } from 'react-redux';
import values from 'lodash/fp/values';
import PropTypes from 'prop-types';
import { THEME_DARK } from 'constants/color-theme';
import { LABEL_VIEW_ALL_PRODUCTS } from 'constants/labels';
import { SIZE_MEDIUM } from 'constants/sizes';
import { ROUTE_PRODUCTS, ROUTE_RANGES } from 'constants/router-links';
import { thumbExtensionCreator } from 'modules/thumb-extension-creator';
import { handleGridAssets } from 'modules/handle-grid-assets';
import { htmlToReactParser } from 'modules/html-to-react';
import classNames from 'classnames';
import styles from './style.module.scss';
import { Link } from 'react-router-dom';
import { Button } from 'components/buttons';
import { LazyImage } from 'components/lazy-image';
import { Loading } from 'components/loading';

export const RangeGrid = ({ content, ranges, loading, isProductListing }) => {
  const { rangeNavVisibility } = useSelector((state) => state.rangeNavigation);
  const {
    display_title,
    display_description,
    display_button,
    title,
    description,
    grid_size,
    range_to_exclude,
  } = content;

  const handleVideoPlay = (video) => {
    // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
    const videoPromise = video.play();

    if (videoPromise !== undefined) {
      videoPromise
        .then((_) => {
          video.play();
        })
        .catch((error) => {
          video.pause();
        });
    }
  };

  const sectionClasses = classNames({
    [`${styles['range-grid-section']}`]: true,
    [`${styles['is-product-listing']}`]: isProductListing,
    [`${styles['range-nav-hidden']}`]: !rangeNavVisibility && isProductListing,
  });

  const gridClasses = classNames({
    [`${styles['range-grid']}`]: true,
    [`${styles[`range-${grid_size}`]}`]: true,
  });

  if (loading) return <Loading />;

  const filteredRanges = values(ranges).filter((range) => {
    if (!range_to_exclude) return ranges;
    return !range_to_exclude.includes(range.ID);
  });

  return (
    <section className={sectionClasses}>
      <div className={styles.intro}>
        {display_title && <h2>{htmlToReactParser.parse(title)}</h2>}
        {display_description && <p>{htmlToReactParser.parse(description)}</p>}
      </div>
      <div className={gridClasses}>
        {filteredRanges.map((range, index) => {
          const { square, wide } = range.thumbnails.video;
          const videoAsset = handleGridAssets(index, grid_size) ? wide : square;

          return (
            <div key={range.title + index}>
              <Link to={`${ROUTE_RANGES}/${range.slug}`}>
                <video
                  src={videoAsset ? videoAsset : ''}
                  onMouseOver={(e) => handleVideoPlay(e.target)}
                  onMouseLeave={(e) => e.target.pause()}
                  poster={videoAsset ? '' : range.thumbnails.mobile_image}
                  muted
                />
                <LazyImage
                  thumbnailSrc={thumbExtensionCreator(
                    range.thumbnails?.mobile_image,
                    '-thumb'
                  )}
                  src={
                    range.thumbnails?.mobile_image
                      ? range.thumbnails.mobile_image
                      : thumbExtensionCreator(range.thumbnails.mobile_image, '')
                  }
                  alt={`${range.title} ambient motion`}
                />
                <p>
                  <span>{range.title}</span>
                </p>
              </Link>
            </div>
          );
        })}
      </div>
      {display_button && (
        <Link to={ROUTE_PRODUCTS}>
          <Button
            size={SIZE_MEDIUM}
            theme={THEME_DARK}
            label={LABEL_VIEW_ALL_PRODUCTS}
          />
        </Link>
      )}
    </section>
  );
};

RangeGrid.propTypes = {
  content: PropTypes.object,
  ranges: PropTypes.object,
  loading: PropTypes.bool,
  isProductListing: PropTypes.bool,
};
