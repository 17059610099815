import React from 'react';
import classNames from 'classnames';
import { Arrow } from 'components/icons';
import styles from './style.module.scss';

export const Pagination = ({
  handleNext,
  handlePrevious,
  currentPage,
  totalResults,
  changePage,
  perPage,
}) => {
  const pageNumbers = [];

  const totalPages = Math.ceil(totalResults / perPage);

  if (currentPage !== totalPages) {
    for (let i = 1; i <= Math.ceil(totalResults / perPage); i++) {
      pageNumbers.push(i);
    }
  }

  const getVisiblePages = (current, total) => {
    const pages = [];
    for (let i = 1; i <= total; i++) {
      pages.push(i);
    }

    if (total > 5) {
      if (total - current < 2) {
        return pages.slice(-5);
      }

      if (current < 4) {
        return pages.slice(0, 5);
      }
      return pages.slice(current - 3, current + 2);
    }
    return pages;
  };

  const pages = getVisiblePages(currentPage, totalPages);
  const showFirstPage = currentPage >= 4 && totalPages > 5;
  const showLastpage = totalPages - currentPage > 2 && totalPages > 5;

  const classes = classNames({
    [`${styles.pagination}`]: true,
    [`${styles.hidden}`]: totalPages <= 1,
  });

  return (
    <div className={classes}>
      {currentPage > 1 && (
        <button className={styles.previous} onClick={handlePrevious}>
          <Arrow />
        </button>
      )}
      <ul className={styles['page-list']}>
        {showFirstPage && (
          <>
            <li>
              <button
                onClick={e => {
                  changePage(1);
                }}
              >
                1
              </button>
            </li>
            <li>...</li>
          </>
        )}
        {pages.map((page, index) => {
          return page === currentPage ? (
            <li key={page}>
              <button disabled={true} className={styles.current}>
                {page}
              </button>
            </li>
          ) : (
            <li key={page}>
              <button onClick={e => changePage(page)}>{page}</button>
            </li>
          );
        })}
        {showLastpage && (
          <>
            <li>...</li>
            <li>
              <button
                onClick={e => {
                  changePage(totalPages);
                }}
              >
                {totalPages.toString()}
              </button>
            </li>
          </>
        )}
      </ul>
      {currentPage !== totalPages && (
        <button className={styles.next} onClick={handleNext}>
          <Arrow />
        </button>
      )}
    </div>
  );
};
