import urls from 'constants/urls.json';
import { getCollaborators } from 'modules/api';

export const COLLABORATORS_REQUEST = 'COLLABORATORS_REQUEST';
export const COLLABORATORS_RECEIVED = 'COLLABORATORS_RECEIVED';

export const requestCollaborators = () => {
  return { type: COLLABORATORS_REQUEST };
};

export const receiveCollaborators = collaborators => {
  return { type: COLLABORATORS_RECEIVED, collaborators };
};

export const fetchCollaborators = () => {
  return async dispatch => {
    dispatch(requestCollaborators());

    const collaborators = await getCollaborators(urls['collaborators']);

    dispatch(receiveCollaborators(collaborators));
  };
};
