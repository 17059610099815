import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { htmlToReactParser } from 'modules/html-to-react';
import classNames from 'classnames';
import posed from 'react-pose';
import { Callout } from 'components/callout';
import styles from './style.module.scss';

const Wrapper = posed.div({
  enter: {
    x: '0',
    delayChildren: 300,
    staggerChildren: 300,
  },
});

const ImageDiv = posed.div({
  enter: {
    opacity: 1,
    transition: {
      duration: 300,
      ease: 'easeIn',
    },
  },
  exit: { opacity: 0 },
});

export const FeaturedContent = ({ content, rangeName }) => {
  const { landscape, portrait, title, description, text } = content;
  const [isVisible, setVisibility] = useState(false);
  const [entered, setEntered] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setEntered(true);
    }
  }, [isVisible]);

  const handleOnView = (visiblity) => setVisibility(visiblity);

  const classes = classNames({
    container: true,
    [`${styles['featured-content']}`]: true,
    [`${styles['negative-margin-bottom']}`]: rangeName,
  });

  return (
    <>
      <section data-testid="featured-content" className={classes}>
        <VisibilitySensor partialVisibility onChange={handleOnView}>
          <Wrapper className={styles.wrapper} pose={entered ? 'enter' : 'exit'}>
            <div className={`${styles['text-wrapper']} container`}>
              <h3>{title}</h3>
              <p>{htmlToReactParser.parse(description)}</p>
            </div>
            <ImageDiv className={styles['landscape']}>
              <Callout
                videoDesktop={landscape.video_desktop}
                videoMobile={landscape.video_mobile}
                imageDesktop={landscape.image_desktop}
                imageMobile={landscape.image_mobile}
              />
            </ImageDiv>
            <ImageDiv className={styles['portrait']}>
              <span>{text}</span>
              <Callout
                videoDesktop={portrait.video_desktop}
                videoMobile={portrait.video_mobile}
                imageDesktop={portrait.image_desktop}
                imageMobile={portrait.image_mobile}
              />
            </ImageDiv>
          </Wrapper>
        </VisibilitySensor>
        <div className={styles['vertical-copy']}>
          <h2>{rangeName}</h2>
        </div>
      </section>
    </>
  );
};
