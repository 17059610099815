import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Media } from 'components/media-gallery/media';
import { Controls } from 'components/media-gallery/controls';
import { Grid } from 'components/media-gallery/grid';
import { DownwardArrow } from 'components/icons';
import { Loading } from 'components/loading';
import styles from './lightbox.module.scss';

export const Lightbox = ({
  medias,
  featuredAsset,
  imagesLength,
  toggleLightbox,
  lightboxOpen,
  mediaNumber,
  active,
  setMediaNumber,
  setActive,
}) => {
  const [thumbnailIsOpen, toggleThumbnails] = useState(true);
  const [localMedias, setLocalMedias] = useState(null);
  const firstMedia = mediaNumber === 0;
  const lastMedia = mediaNumber === imagesLength;

  useEffect(() => {
    setLocalMedias(medias);
    if (active === null) {
      setActive(featuredAsset);
      setMediaNumber(0);
    }
  }, [medias, active, featuredAsset, setActive, setMediaNumber]);

  const handlePrevious = useCallback(() => {
    if (firstMedia) return;
    setMediaNumber(mediaNumber - 1);
    setActive(localMedias[mediaNumber - 1]);
  }, [firstMedia, localMedias, mediaNumber, setActive, setMediaNumber]);

  const handleNext = useCallback(() => {
    if (lastMedia) return;
    setMediaNumber(mediaNumber + 1);
    setActive(localMedias[mediaNumber + 1]);
  }, [lastMedia, localMedias, mediaNumber, setActive, setMediaNumber]);

  useEffect(() => {
    const handleKeyUp = (e) => {
      if (e.keyCode === 37) {
        handlePrevious();
      }
      if (e.keyCode === 39) {
        handleNext();
      }
    };
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handlePrevious, handleNext]);

  const handleClose = () => {
    toggleLightbox(false);
  };

  const handleThumbnails = () => {
    toggleThumbnails(!thumbnailIsOpen);
  };

  if (!active) return <Loading overlay fit />;

  return (
    <div className={styles.lightbox}>
      <div className={styles['image-container']}>
        <Controls
          imageUri={active?.url}
          handleClose={handleClose}
          handleThumbnails={handleThumbnails}
        />

        <span className={styles['arrow-left']} onClick={handlePrevious}>
          {!firstMedia && <DownwardArrow />}
        </span>

        <figure className={styles.wrapper}>
          <Media activeMedia url={active.url} lightboxOpen={lightboxOpen} />
          <div className={styles.description}>
            <h3>{active.title}</h3>
            <span>
              {mediaNumber + 1}/{imagesLength + 1}
            </span>
            <figcaption>{active.caption}</figcaption>
          </div>
        </figure>

        <span className={styles['arrow-right']} onClick={handleNext}>
          {!lastMedia && <DownwardArrow />}
        </span>
      </div>

      <Grid
        grid={medias}
        imagesLength={imagesLength}
        thumbnailIsOpen={thumbnailIsOpen}
        lightboxOpen={lightboxOpen}
        active={active}
        setActive={setActive}
        toggleLightbox={toggleLightbox}
        setMediaNumber={setMediaNumber}
      />
    </div>
  );
};

Lightbox.defaultProps = {
  theme: 'pink',
  featuredAsset: {
    url: 'https://via.placeholder.com/590x536',
    title: 'Featured asset',
    caption: '',
  },
};

Lightbox.propTypes = {
  medias: PropTypes.array.isRequired,
  featuredAsset: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
    caption: PropTypes.string,
  }),
  imagesLength: PropTypes.number.isRequired,
  toggleLightbox: PropTypes.func.isRequired,
  lightboxOpen: PropTypes.bool.isRequired,
  mediaNumber: PropTypes.number.isRequired,
  active: PropTypes.object,
  setMediaNumber: PropTypes.func.isRequired,
  setActive: PropTypes.func.isRequired,
};
