import React, { useState, useEffect } from 'react';
import posed from 'react-pose';
import { thumbExtensionCreator } from 'modules/thumb-extension-creator';
import { LinkFromCms } from 'components/link-from-cms';
import { LazyImage } from 'components/lazy-image';
import placeholder from 'assets/images/floating_logo_icon.png';
import { SlideUp } from 'components/animations';

import styles from './style.module.scss';
import { htmlToReactParser } from 'modules/html-to-react';

const Title = posed.h1({
  enter: {
    delay: 1000,
    y: 0,
    opacity: 1,
    transition: {
      duration: 700,
      ease: 'easeIn',
    },
  },
  exit: { y: 20, opacity: 0 },
});

export const SimpleHero = ({
  pageName,
  theme,
  hideTitle,
  desktop,
  mobile,
  videoMobile,
  videoDesktop,
  desktopLoop,
  mobileLoop,
  tagline,
  title,
  smallScreen,
  addCta,
  heroCta,
}) => {
  const [entered, setEntered] = useState(false);

  useEffect(() => {
    setEntered(true);
  }, []);

  return (
    <div role="banner" className={`${styles.hero} ${styles[pageName]}`}>
      <LazyImage
        classes={styles.mobile}
        thumbnailSrc={thumbExtensionCreator(mobile, '-thumb')}
        src={mobile ? mobile : placeholder}
        alt={`${title} range`}
      />
      <LazyImage
        classes={styles.desktop}
        thumbnailSrc={thumbExtensionCreator(desktop, '-thumb')}
        src={desktop ? desktop : placeholder}
        alt={`${title} range`}
      />
      {videoMobile && (
        <video
          className={styles.mobile}
          src={videoMobile}
          poster={mobile ? mobile : placeholder}
          playsInline={true}
          autoPlay
          loop={mobileLoop}
          muted
        />
      )}
      {videoDesktop && (
        <video
          className={styles.desktop}
          src={videoDesktop}
          poster={desktop ? desktop : placeholder}
          playsInline={true}
          autoPlay
          loop={desktopLoop}
          muted
        />
      )}
      <div className="container">
        <div className={`${styles['content-wrapper']} container`}>
          <div>
            <SlideUp delay={1000}>
              {tagline && <img src={tagline} alt="Circular Product" />}
            </SlideUp>

            {(!hideTitle || smallScreen) && (
              <Title
                className={`${styles[theme]}`}
                pose={entered ? 'enter' : 'exit'}
              >
                {htmlToReactParser.parse(title)}
              </Title>
            )}
          </div>
        </div>
      </div>
      {addCta && !smallScreen && (
        <span className={styles.cta}>
          <LinkFromCms url={heroCta.link} title={heroCta.label} />
        </span>
      )}
    </div>
  );
};

SimpleHero.defaultProps = {
  desktopLoop: true,
  mobileLoop: true,
};
