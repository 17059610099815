import React from 'react';
import { useSelector } from 'react-redux';
import { SubFilterOptions } from 'components/filters/sub-filters/sub-filter-options';

import styles from './style.module.scss';

export const SubFilters = ({ availableTerms }) => {
  const { mainFiltersOpen } = useSelector((state) => state.products);

  const sizeOptions = [
    { value: 'small', label: 'small' },
    { value: 'medium', label: 'medium' },
    { value: 'large', label: 'large' },
  ];

  const lumensOptions = [
    { value: '300-lm', label: '< 300lm' },
    { value: '300-500-lm', label: '300 - 500lm' },
    { value: '500-800-lm', label: '500 - 800lm' },
    { value: '800-1000-lm', label: '800 - 1000lm' },
    { value: '1000-lm', label: '1000lm+' },
  ];

  const shapeOptions = [
    { value: 'round', label: 'round' },
    { value: 'square', label: 'square' },
  ];

  const cutOptions = [
    { value: '59-mm-or-less', label: '59mm or less' },
    { value: '60-69-mm', label: '60-69mm' },
    { value: '70-79-mm', label: '70-79mm' },
    { value: '80-89-mm', label: '80-89mm' },
    { value: '90-99-mm', label: '90-99mm' },
    { value: '100-mm-or-greater', label: '100mm or greater' },
  ];

  const fixingOptions = [
    { value: 'plaster-in', label: 'plaster in' },
    { value: 'springs', label: 'springs' },
    { value: 'surface', label: 'surface' },
  ];

  const fireOptions = [
    { value: 'fire-plate', label: 'fire plate' },
    { value: 'fire-hood', label: 'fire hood' },
  ];

  const ipOptions = [
    { value: 'ip-20', label: 'ip20' },
    { value: 'ip-54', label: 'ip54' },
    { value: 'ip-65', label: 'ip65' },
    { value: 'ip-67', label: 'ip67' },
  ];

  if (!mainFiltersOpen) return null;

  return (
    <div className={styles['sub-filter']}>
      <div className={styles['filter-options']}>
        <SubFilterOptions
          availableTerms={availableTerms}
          title="Size"
          name="size"
          options={sizeOptions}
        />
        <SubFilterOptions
          title="Lumens"
          name="luminaire-lumens"
          options={lumensOptions}
          availableTerms={availableTerms}
        />
        <SubFilterOptions
          availableTerms={availableTerms}
          title="Shape"
          name="shape"
          options={shapeOptions}
        />
        <SubFilterOptions
          availableTerms={availableTerms}
          title="Cut-out"
          name="cut_out"
          options={cutOptions}
        />
        <SubFilterOptions
          availableTerms={availableTerms}
          title="Fixing"
          name="fixing"
          options={fixingOptions}
        />
        <SubFilterOptions
          title="Fire rating"
          name="fire-rating"
          options={fireOptions}
          availableTerms={availableTerms}
        />
        <SubFilterOptions
          title="IP rating"
          name="ip-rating"
          options={ipOptions}
          availableTerms={availableTerms}
        />
      </div>
    </div>
  );
};
