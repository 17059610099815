import React from 'react';
import styles from './style.module.scss';
import { SelectedCheckbox, Pdf, DownwardArrow } from 'components/icons';
import { DownloadCheckbox } from 'components/download-check-box';
import {
  NO_DATA_HEADERS_LEFT,
  NO_DATA_HEADERS_RIGHT,
} from 'constants/spec-code-downloads';

export const NoData = () => (
  <>
    <div className={styles['left-column']}>
      <div className={`${styles['filter-options']} container`}>
        <ul>
          {NO_DATA_HEADERS_LEFT.map((item, index) => (
            <li key={item + index}>
              <label htmlFor={item}>
                <DownloadCheckbox
                  icon={<SelectedCheckbox />}
                  id={item}
                  disabled
                />
                <span className={styles.info}>
                  <span className={styles.icon}>
                    <Pdf className={styles.pdf} />
                  </span>
                  <span className={styles.header}>{item}</span>
                </span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>

    <div className={styles['right-column']}>
      {NO_DATA_HEADERS_RIGHT.map((item, index) => (
        <div key={index} className={`${styles['filter-header']} flex-center`}>
          <div className="container">
            <span className={styles.title}>
              <span className={styles.icon}>
                <Pdf className={styles.pdf} />
              </span>
              <span>
                <span className={styles.header}>{item}</span>
                <DownwardArrow className={styles.arrow} />
              </span>
            </span>
          </div>
        </div>
      ))}
    </div>
  </>
);
