import React from 'react';
import Select, { components } from 'react-select';
import {
  HEADERS,
  SPEC_CODE_DEFAULT_COLOUR,
} from 'constants/spec-code-downloads';
import { KEY_BAFFLE_COLOURS, KEY_BEZEL_COLOURS } from './constants';
import { customStyles } from './custom-styles';
import { TooltipSpecCode } from 'components/tooltip-spec-code';

export const SelectOption = ({
  header,
  options,
  isMulti,
  isDisabled,
  handleChange,
  value,
  tooltips,
  stainlessSteelDefault,
}) => {
  const { Option, Control } = components;

  const colorOption = (color, border) => (
    <span
      style={{
        background: color,
        border: `1px solid ${border}`,
        display: 'inline-block',
        height: 24,
        width: 24,
        marginRight: 13,
      }}
    />
  );

  const CustomOption = (props) => {
    const hasColour = props.data.colour !== undefined;
    const addColor = props.data.colour !== '';
    const addStd = stainlessSteelDefault && props.data.value === 'ss';

    return (
      <Option {...props}>
        {hasColour && addColor && colorOption(props.data.colour)}
        {/* border only  */}
        {hasColour && !addColor && colorOption('', SPEC_CODE_DEFAULT_COLOUR)}
        {props.data.label}
        {addStd ? ' (std)' : ''}
      </Option>
    );
  };

  const CustomControl = (props) => {
    const addToolTip =
      (header === HEADERS[KEY_BEZEL_COLOURS] && tooltips[header]?.display) ||
      (header === HEADERS[KEY_BAFFLE_COLOURS] && tooltips[header]?.display);

    return (
      <div>
        {<p>{header + (isMulti ? ' (multi select)' : '')}</p>}
        {addToolTip && (
          <TooltipSpecCode
            title={header}
            headings={tooltips[header].headings}
          />
        )}
        <Control {...props} />
      </div>
    );
  };

  const CustomValue = ({ data }) => {
    if (data.value === undefined) return null;
    if (Array.isArray(data.value)) {
      return data.value.map(({ value }) => value).join('');
    }
    return data.value;
  };

  return (
    <Select
      id={header}
      value={value}
      menuIsOpen={true}
      menuShouldScrollIntoView={false}
      captureMenuScroll={false}
      options={options}
      isOptionDisabled={(option) => option.disabled}
      components={{
        Control: CustomControl,
        Option: CustomOption,
        SingleValue: CustomValue,
        MultiValue: CustomValue,
      }}
      styles={customStyles}
      isMulti={isMulti}
      isDisabled={isDisabled}
      hideSelectedOptions={false}
      isSearchable={false}
      placeholder="select below"
      onChange={(selection) => handleChange(selection)}
    />
  );
};
