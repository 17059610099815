import React from 'react';
import { thumbExtensionCreator } from 'modules/thumb-extension-creator';
import { useWindowSize } from 'hooks/use-window-size';
import { bpSmallPlus } from 'constants/breakpoints';
import defaultTo from 'lodash/fp/defaultTo';
import { LazyImage } from 'components/lazy-image';
import styles from './style.module.scss';

export const Callout = ({
  videoDesktop,
  videoMobile,
  imageDesktop,
  imageMobile,
}) => {
  const { width } = useWindowSize();
  const smallScreen = width < bpSmallPlus;

  // so we don't break older products page
  if (!imageDesktop) return null;

  return (
    <div data-testid="callout" className={styles.callout}>
      {!smallScreen && (
        <>
          {imageDesktop && !videoDesktop && (
            <LazyImage
              thumbnailSrc={thumbExtensionCreator(imageDesktop, '-thumb')}
              src={imageDesktop}
              alt="Orluna lit effect"
            />
          )}
          {videoDesktop && (
            <video
              src={videoDesktop}
              poster={defaultTo(imageDesktop, '')}
              playsInline
              autoPlay
              loop
              muted
            />
          )}
        </>
      )}
      {smallScreen && (
        <>
          {imageMobile && !videoMobile && (
            <LazyImage
              thumbnailSrc={thumbExtensionCreator(imageMobile, '-thumb')}
              src={imageMobile}
              alt="Orluna lit effect"
            />
          )}
          {videoMobile && (
            <video
              src={videoMobile}
              poster={defaultTo(imageMobile, '')}
              playsInline
              autoPlay
              loop
              muted
            />
          )}
        </>
      )}
    </div>
  );
};
