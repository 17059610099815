import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fetchDownloadsIfNeeded from 'redux/actions/downloads.js';
import { useWindowSize } from 'hooks/use-window-size';
import { push } from 'connected-react-router';
import urls from 'constants/urls.json';
import queryString from 'query-string';
import { getPage } from 'modules/api';
import md5 from 'md5';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { Loading } from 'components/loading';
import { DownloadContainer } from 'components/download-container';
import { DownloadHeader } from 'components/download-header';
import { DownloadFilters } from 'components/download-filters';
import { DownloadResults } from 'components/download-results';
import { DownloadPagination } from 'components/download-pagination';
import { SectionWithBackground } from 'components/section-with-background';
import { SignUpSection } from 'components/signup-section';
import { ErrorBoundary } from 'components/error';
import DownloadLoading from 'components/download-loading';
import { bpSmall } from 'constants/breakpoints';
import { BANNER_NAME_IMAGE_ONLY } from 'constants/banners';
import { THEME_SOLID } from 'constants/color-theme';

export const DownloadsLibrary = (props) => {
  const [pageData, setPageData] = useState({});
  const [pageLoading, setPageLoading] = useState(true);
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const { search } = props.location;
  const filters = queryString.parse(search.substr(1));
  const hash = md5(JSON.stringify(filters));
  const { totalPages, totalResults, loading, data } = useSelector(
    (state) =>
      state.downloads?.[hash] || {
        loading: true,
        data: [],
        totalPages: 0,
        totalResults: 0,
      }
  );

  const handleFilterChange = (name, value) => {
    const qs = queryString.stringifyUrl(
      {
        url: '/downloads',
        query: {
          ...filters,
          [name]: value,
          page: undefined,
        },
      },
      {
        skipEmptyString: true,
      }
    );

    dispatch(push(qs));
  };

  useEffect(() => {
    dispatch(fetchDownloadsIfNeeded(filters));
    async function fetchPage() {
      const page = await getPage(urls['page'], 'downloads');
      setPageData(page.acf);
      setPageLoading(false);
    }

    fetchPage();
  }, [search, dispatch, filters]);

  if (pageLoading) return <Loading />;

  return (
    <ErrorBoundary>
      <Header theme={THEME_SOLID} />
      <DownloadContainer>
        <DownloadHeader
          title={pageData.title}
          description={pageData.description}
        />
        <DownloadFilters
          onFilterChange={(name, value) => handleFilterChange(name, value)}
          filters={filters}
          enabledProducts={data.product_names}
          enabledEngines={data.engine_names}
          enabledVariants={data.variant_names}
          enabledFieldTypes={data.field_types}
        />
        {loading && <DownloadLoading />}
        {!loading && (
          <>
            <DownloadResults results={data.results} />
            <DownloadPagination
              currentPage={parseInt(filters.page || 0)}
              totalResults={totalResults}
              filters={filters}
              totalPages={totalPages}
            />
          </>
        )}
      </DownloadContainer>
      {width > bpSmall && (
        <SectionWithBackground
          sectionName={BANNER_NAME_IMAGE_ONLY}
          image={pageData.banner}
        />
      )}
      <SignUpSection />
      <Footer />
    </ErrorBoundary>
  );
};
