import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'components/buttons';
import { THEME_DARK } from 'constants/color-theme';
import { SIZE_MEDIUM } from 'constants/sizes';
import styles from './style.module.scss';

export const CarouselButton = ({ label, link }) => {
  return (
    <div className={styles['view-all']}>
      <Link to={link}>
        <Button size={SIZE_MEDIUM} theme={THEME_DARK} label={label} />
      </Link>
    </div>
  );
};
