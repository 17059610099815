import React from 'react';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { FourOhFour } from 'components/four-oh-four';
import { ErrorBoundary } from 'components/error';
import { THEME_DARK } from 'constants/color-theme';

export const FourOhFourScreen = () => {
  return (
    <ErrorBoundary>
      <Header theme={THEME_DARK} />
      <FourOhFour />
      <Footer />
    </ErrorBoundary>
  );
};
