import {
  RANGE_NAVIGATION_REQUEST,
  RANGE_NAVIGATION_RECEIVED,
  RANGE_NAVIGATION_TOGGLE,
  RANGE_NAVIGATION_CLOSE,
} from 'redux/actions/range-navigation';

const initialState = {
  hasError: false,
  rangeNavVisibility: false,
  rangeNavIsOpen: false,
  loading: true,
};

export default function rangeNavigation(state = initialState, action) {
  switch (action.type) {
    case RANGE_NAVIGATION_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        rangeNavVisibility: state.rangeNavVisibility,
        loading: true,
      });
    case RANGE_NAVIGATION_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        rangeNavVisibility: action.visibility,
        loading: false,
      });
    case RANGE_NAVIGATION_TOGGLE:
      return Object.assign({}, state, {
        rangeNavIsOpen: !state.rangeNavIsOpen,
      });
    case RANGE_NAVIGATION_CLOSE:
      return Object.assign({}, state, {
        rangeNavIsOpen: false,
      });
    default:
      return state;
  }
}
