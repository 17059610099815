import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter, toggleFilter, resetFilters } from 'redux/actions/products';
import { useWindowSize } from 'hooks/use-window-size';
import { bpSmall } from 'constants/breakpoints';
import { Checkbox } from 'components/check-box';
import { DownwardArrow } from 'components/icons';

import styles from '../main-filter-options/style.module.scss';

export const SingleFilter = ({ name, options, title }) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const { singleFilter } = useSelector((state) => state.products);
  const [isOpen, toggleOpen] = useState(false);

  const handleMenu = () => {
    toggleOpen(!isOpen);
  };

  const handleChange = (name, value) => {
    dispatch(resetFilters());

    if (singleFilter !== value) {
      dispatch(setFilter(name, value === singleFilter ? '' : value));
      dispatch(toggleFilter(name, value));
    }
  };

  const isChecked = (value) => {
    return singleFilter === value;
  };

  const isDisabled = (value) => {};

  const labelClass = (label) => {
    return classNames({
      [`${styles['is-disabled']}`]: isDisabled(label),
    });
  };

  const isCheckedClass = (label) => {
    return classNames({
      [`${styles['is-checked']}`]: isChecked(label),
    });
  };

  const filterList = options.map((option) => (
    <li key={option.label}>
      <label htmlFor={option.value} className={labelClass(option.value)}>
        <Checkbox
          icon={option.icon}
          checked={isChecked(option.value)}
          disabled={isDisabled(option.value)}
          onChange={handleChange}
          value={option.value}
          name={name}
          id={option.value}
        />
        <span className={isCheckedClass(option.label)}>{option.label}</span>
      </label>
    </li>
  ));

  const classes = classNames({
    [`${styles['filter-option']}`]: true,
    [`${styles['is-open']}`]: isOpen,
  });

  return (
    <>
      <div className={classes}>
        <div className={styles.title} onClick={handleMenu}>
          <h3>{title}</h3>
          <DownwardArrow />
        </div>
        {(width > bpSmall || isOpen) && <ul>{filterList}</ul>}
      </div>
    </>
  );
};
