/**
 * Add disabled flag to options if the value is not in the enabled options array
 */
export default function filterDownloadOptions(options, enabledOptions) {
  return options.map((option) => {
    const isArray = Array.isArray(enabledOptions);
    const isDisabled = isArray
      ? enabledOptions.indexOf(option.value) < 0 && option.value !== ''
      : false;

    return { ...option, isDisabled };
  });
}
