import React from 'react';

export const Info = (props) => (
  <svg height="19" width="19" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="#393e5b">
      <path d="m9.5 0c-5.236 0-9.5 4.264-9.5 9.5s4.264 9.5 9.5 9.5 9.5-4.264 9.5-9.5-4.264-9.5-9.5-9.5zm0 17.674c-4.507 0-8.174-3.667-8.174-8.174s3.667-8.174 8.174-8.174 8.174 3.667 8.174 8.174-3.667 8.174-8.174 8.174z" />
      <path d="m9.5 7.512a.651.651 0 0 0 -.663.662v5.082c0 .375.287.663.663.663a.651.651 0 0 0 .663-.663v-5.082a.651.651 0 0 0 -.663-.662zm0-2.431a.886.886 0 0 0 -.884.884c0 .486.398.884.884.884a.886.886 0 0 0 .884-.884.886.886 0 0 0 -.884-.884z" />
    </g>
  </svg>
);
