import axios from 'axios';
import urls from 'constants/urls.json';

export const getEngines = async () => {
  try {
    const url = [process.env.REACT_APP_CMS_URL, urls['engines']].join('/');
    const response = await axios.get(url);
    const data = await response.data;

    return data;
  } catch (error) {
    throw error;
  }
};
