import React, { useState, useEffect } from 'react';
import { useWindowSize } from 'hooks/use-window-size';
import { getPage } from 'modules/api';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInternationalPartners } from 'redux/actions';
import urls from 'constants/urls.json';
import { bpSmall } from 'constants/breakpoints';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import Map from 'components/map';
import { Headquarters } from 'components/headquarters';
import { Dropdown } from 'components/dropdown';
import { WorldwideOffices } from 'components/worldwide-offices';
import { SectionWithBackground } from 'components/section-with-background';
import { SlideUp } from 'components/animations';
import { ErrorBoundary } from 'components/error';
import { Loading } from 'components/loading';
import { BANNER_NAME_GET_IN_TOUCH, BANNER_NAME_WIDER } from 'constants/banners';
import { THEME_DARK, THEME_LIGHT } from 'constants/color-theme';
import { ROUTE_GET_IN_TOUCH } from 'constants/router-links';
import { PAGE_CONTACT } from 'constants/page-name';
import { TITLE_INT_PARTNERS } from 'constants/titles';

export const ContactScreen = () => {
  const dispatch = useDispatch();
  const [loading, isLoading] = useState(true);
  const [pageData, setPageData] = useState({});
  const { width } = useWindowSize();
  const smallScreen = width < bpSmall;

  useEffect(() => {
    async function fetchPage() {
      const page = await getPage(urls['page'], PAGE_CONTACT);
      setPageData(page);
      isLoading(false);
    }
    fetchPage();
    dispatch(fetchInternationalPartners());
  }, [loading, dispatch]);

  const { internationalPartners, loading: partnersLoading } = useSelector(
    (state) => state.internationalPartners
  );

  if (loading || partnersLoading) return <Loading />;

  const salesLocations = pageData.acf.worldwide_offices.offices.concat(
    pageData.acf.uk_headquarters
  );

  return (
    <ErrorBoundary>
      <Header theme={THEME_LIGHT} />
      <Map
        mobileMap={pageData.acf.map_replacement}
        partnersLocations={internationalPartners}
        salesLocations={salesLocations}
      />
      <SlideUp>
        <Headquarters data={pageData.acf.uk_headquarters} />
      </SlideUp>
      <SlideUp>
        <SectionWithBackground
          sectionName={BANNER_NAME_WIDER}
          image={pageData.acf.featured_image}
        />
      </SlideUp>
      <SlideUp>
        <WorldwideOffices worldwideOffices={pageData.acf.worldwide_offices} />
      </SlideUp>
      <SlideUp>
        <SectionWithBackground
          theme={THEME_DARK}
          sectionName={BANNER_NAME_GET_IN_TOUCH}
          description={pageData.acf.get_in_touch.title}
          cta={pageData.acf.get_in_touch.button_title}
          link={ROUTE_GET_IN_TOUCH}
          image={
            smallScreen
              ? pageData.acf.get_in_touch.background_mobile
              : pageData.acf.get_in_touch.background_desktop
          }
        />
      </SlideUp>
      <SlideUp>
        <Dropdown title={TITLE_INT_PARTNERS} arr={internationalPartners} />
      </SlideUp>
      <Footer />
    </ErrorBoundary>
  );
};
