import React from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { closeRangeNavigation, toggleFilters } from 'redux/actions';
import { useWindowSize } from 'hooks/use-window-size';
import { bpLarge } from 'constants/breakpoints';
import { SEARCH } from 'constants/view';
import { RangeNavigationButton } from 'components/range-navigation-button';
import { SearchBar } from 'components/search/search-bar';
import { DownwardArrow } from 'components/icons';
import styles from './style.module.scss';

export const Search = ({ addFilters }) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const isDesktop = width > bpLarge;
  const { hasSearched } = useSelector((state) => state.search);
  const { rangeNavVisibility } = useSelector((state) => state.rangeNavigation);
  const { mainFiltersOpen, view } = useSelector((state) => state.products);
  const searchView = view === SEARCH;

  const handleOnClick = () => {
    dispatch(closeRangeNavigation());
    dispatch(toggleFilters());
  };

  const searchClasses = classNames({
    [`${styles.search}`]: true,
    [`${styles['range-nav-hidden']}`]: !rangeNavVisibility,
  });

  const containerClasses = classNames({
    [`${styles['search-container']}`]: true,
    [`${styles.mobile}`]: !isDesktop && searchView,
  });

  const buttonClasses = classNames({
    [`${styles['filter-toggle']}`]: true,
    [`${styles['is-open']}`]: mainFiltersOpen,
  });

  return (
    <div className={searchClasses}>
      <div className={containerClasses}>
        <SearchBar />
        {!hasSearched && !searchView && (
          <div className={styles['button-container']}>
            {rangeNavVisibility && <RangeNavigationButton />}
            {addFilters && (
              <button onClick={() => handleOnClick()} className={buttonClasses}>
                filters
                <DownwardArrow />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
