import urls from 'constants/urls.json';
import { getNotification } from 'modules/api';

export const NOTIFICATION_REQUEST = 'NOTIFICATION_REQUEST';
export const NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED';

export const requestNotification = () => {
  return { type: NOTIFICATION_REQUEST };
};

export const receivedNotification = (notification) => {
  return { type: NOTIFICATION_RECEIVED, notification };
};

export const fetchNotification = () => {
  return async (dispatch) => {
    dispatch(requestNotification());

    const notification = await getNotification(urls['notification']);

    if (!notification) return dispatch(receivedNotification([]));

    dispatch(receivedNotification(notification));
  };
};
