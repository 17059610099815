import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { subLinks } from 'components/menu-mobile/config';
import { Arrow } from 'components/icons';
import styles from './style.module.scss';

export const SubMenu = ({ isOpen }) => {
  const menuList = classNames({
    [`${styles['sub-menu']}`]: true,
    [`${styles['is-open']}`]: isOpen,
  });

  return (
    <ul className={menuList}>
      {subLinks.map((link, i) => (
        <li key={link.link}>
          <Link to={link.link}>
            <span>{link.title}</span>
            <Arrow className={styles.arrow} />
          </Link>
        </li>
      ))}
    </ul>
  );
};
