import React from 'react';
import { NavLink } from 'react-router-dom';
import { useScrolling } from 'hooks/use-scrolling';
import { ROUTE_RANGES } from 'constants/router-links';
import values from 'lodash/fp/values';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

export const RangeNavigationDesktop = ({ ranges, isProductDetail }) => {
  const { scrollY, isScrolling } = useScrolling(5000);

  const classes = classNames({
    [`${styles['range-navigation']}`]: true,
    [`${styles['product-detail']}`]: isProductDetail,
    [`${styles['is-visible']}`]: isScrolling && scrollY > 31,
  });

  return (
    <nav className={classes}>
      <ul className="container">
        {values(ranges).map((range, index) => {
          return (
            <li key={range.ID + index}>
              <NavLink
                exact
                to={`${ROUTE_RANGES}/${range.slug}`}
                activeClassName="nav-link-active"
              >
                {range.title}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

RangeNavigationDesktop.propTypes = {
  ranges: PropTypes.object,
  lowerOpacity: PropTypes.bool,
};
