import urls from 'constants/urls.json';
import { getProductFamilies } from 'modules/api';

export const PRODUCT_FAMILY_REQUEST = 'PRODUCT_FAMILY_REQUEST';
export const PRODUCT_FAMILY_RECEIVED = 'PRODUCT_FAMILY_RECEIVED';

export const requestProductFamily = (id) => {
  return { type: PRODUCT_FAMILY_REQUEST, id };
};

export const receiveProductFamily = (productFamily) => {
  return { type: PRODUCT_FAMILY_RECEIVED, productFamily };
};

export const fetchProductFamily = (id) => {
  return async (dispatch) => {
    dispatch(requestProductFamily());

    const productFamily = await getProductFamilies(urls['product-family'], id);

    dispatch(receiveProductFamily(productFamily));
  };
};
