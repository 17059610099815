import axios from 'axios';

export const submitForm = async (endpoint, data) => {
  try {
    const url = [process.env.REACT_APP_CMS_URL, endpoint].join('/');
    const response = await axios(url, {
      method: 'POST',
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};
