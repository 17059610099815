import posed from 'react-pose';

export const Description = posed.div({
  enter: {
    x: '0%',
    delayChildren: 200,
    staggerChildren: 150,
  },
});

export const DescriptionPart = posed.span({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 300,
      ease: 'easeIn',
    },
  },
  exit: { y: 20, opacity: 0 },
});

export const LitOptions = posed.div({
  enter: {
    opacity: 1,
    delayChildren: 200,
    staggerChildren: 150,
    transition: {
      delay: 1000,
      duration: 1000,
      ease: 'easeInOut',
    },
  },
  exit: { opacity: 0 },
});

export const LitOption = posed.li({
  enter: {
    opacity: 1,
    transition: {
      delay: 1200,
      duration: 500,
      ease: 'easeIn',
    },
  },
  exit: { opacity: 0 },
});
