import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFilter } from 'redux/actions/products';
import { useWindowSize } from 'hooks/use-window-size';
import { bpSmall } from 'constants/breakpoints';
import { Checkbox } from 'components/check-box';
import { SelectedCheckbox } from 'components/icons';
import { DownwardArrow } from 'components/icons';
import styles from './style.module.scss';

export const SubFilterOptions = ({ availableTerms, name, options, title }) => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.products);
  const values = filters[name] || [];

  const { width } = useWindowSize();
  const [isOpen, toggleOpen] = useState(false);

  const handleMenu = () => {
    toggleOpen(!isOpen);
  };

  const handleChange = (name, value) => {
    dispatch(toggleFilter(name, value));
  };

  const isChecked = (value) => {
    return values.indexOf(value) > -1;
  };

  const isDisabled = (value) => {
    if (availableTerms.length > 0) {
      return availableTerms.indexOf(value) < 0;
    }
  };

  const labelClass = (label) => {
    return classNames({
      [`${styles['is-disabled']}`]: isDisabled(label),
      [`${styles['is-checked']}`]: isChecked(label),
    });
  };

  const filterList = options.map((option) => (
    <li key={option.label}>
      <label htmlFor={option.value} className={labelClass(option.value)}>
        <Checkbox
          icon={<SelectedCheckbox />}
          id={option.value}
          checked={isChecked(option.value)}
          disabled={isDisabled(option.value)}
          onChange={handleChange}
          value={option.value}
          name={name}
        />
        <span>{option.label}</span>
      </label>
    </li>
  ));

  const classes = classNames({
    [`${styles['filter-option']}`]: true,
    [`${styles['is-open']}`]: isOpen,
  });

  return (
    <div className={classes}>
      <div className={styles.title} onClick={handleMenu}>
        <h3>{title}</h3>
        <DownwardArrow />
      </div>
      {(width > bpSmall || isOpen) && <ul>{filterList}</ul>}
    </div>
  );
};
