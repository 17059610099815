import { RANGE_REQUEST, RANGE_RECEIVED } from 'redux/actions/range';

const initialState = {
  hasError: false,
  range: {},
  loading: true,
};

export default function range(state = initialState, action) {
  switch (action.type) {
    case RANGE_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        range: {
          ...state.range,
        },
        loading: true,
      });
    case RANGE_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        range: {
          ...action.range,
        },
        loading: false,
      });
    default:
      return state;
  }
}
