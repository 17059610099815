import urls from 'constants/urls.json';
import { getRange } from 'modules/api';

export const RANGE_REQUEST = 'RANGE_REQUEST';
export const RANGE_RECEIVED = 'RANGE_RECEIVED';

export const requestRange = (slug) => {
  return { type: RANGE_REQUEST, slug };
};

export const receiveRange = (range) => {
  return { type: RANGE_RECEIVED, range };
};

export const fetchRange = slug => {
  return async dispatch => {
    dispatch(requestRange());

    const range = await getRange(urls['range'], slug);

    dispatch(receiveRange(range));
  };
};
