import { NEWS_REQUEST, NEWS_RECEIVED } from 'redux/actions/news';

const initialState = {
  hasError: false,
  news: {},
  loading: true,
};

export default function news(state = initialState, action) {
  switch (action.type) {
    case NEWS_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        news: {
          ...state.news,
        },
        loading: true,
      });
    case NEWS_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        news: {
          ...state.news,
          ...action.news,
        },
        loading: false,
      });
    default:
      return state;
  }
}
