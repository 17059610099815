import { POST_REQUEST, POST_RECEIVED } from 'redux/actions/news-post';

const initialState = {
  hasError: false,
  post: {},
  loading: true,
};

export default function newsPost(state = initialState, action) {
  switch (action.type) {
    case POST_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        post: {
          ...state.post,
        },
        loading: true,
      });
    case POST_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        post: {
          ...action.post,
        },
        loading: false,
      });
    default:
      return state;
  }
}
