export const LABEL_OK = 'ok';
export const LABEL_GO = 'go';
export const LABEL_DOWNLOAD = 'download';
export const LABEL_CONFIRM = 'confirm';
export const LABEL_CAREERS = 'careers';
export const LABEL_SUBMIT = 'submit';
export const LABEL_CALL_US = 'call us';
export const LABEL_LEARN_MORE = 'learn more';
export const LABEL_OPEN_GALLERY = 'open gallery';
export const LABEL_RETURN_HOME = 'return to homepage';
export const LABEL_VIEW_ALL_PRODUCTS = 'view all products';
export const LABEL_DISC_COLLECTION = 'discover the collection';
