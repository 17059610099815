import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import { exitFullscreen } from 'modules/exit-fullscreen';
import styles from './styles.module.scss';

export const VideoBanner = ({ video }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const {
    is_embedded,
    is_autoplay,
    embed_link,
    upload_video,
    thumbnail,
  } = video;

  const classes = classNames({
    [`${styles['video-player']}`]: true,
    [`${styles.playing}`]: isPlaying,
    [`${styles.autoplay}`]: is_autoplay,
  });

  if (!upload_video && !is_embedded) return null;

  return (
    <>
      <div className={classes} onClick={() => setIsPlaying(true)}>
        <div className={styles['video-container']}>
          <ReactPlayer
            url={is_embedded ? embed_link.trim() : upload_video}
            onPause={exitFullscreen}
            onEnded={exitFullscreen}
            config={{
              vimeo: {
                playerOptions: { autoplay: isPlaying },
                preload: true,
              },
              youtube: {
                rel: 0,
              },
            }}
            light={!is_autoplay}
            playIcon={
              !is_autoplay && (
                <span className={styles.thumbnail}>
                  <img src={thumbnail} alt="video thumbnail" />
                </span>
              )
            }
            controls={!is_autoplay}
            playsinline={is_autoplay}
            volume={0}
            muted={is_autoplay}
            loop={is_autoplay}
            playing={is_autoplay}
            height="100%"
            width="100%"
          />
        </div>
      </div>
    </>
  );
};
