import React from 'react';
import styles from 'components/check-box/style.module.scss';

export const Fixed = props => (
  <svg
    className={styles['filter-icon']}
    data-name="Layer 1"
    viewBox="0 0 57 57"
    {...props}
  >
    <g fill="none">
      <circle cx={28} cy={28} r={27} strokeWidth={2} />
      <ellipse
        cx={28}
        cy={28}
        rx={16}
        ry={7}
        stroke="#555762"
        strokeWidth={3}
      />
    </g>
  </svg>
);
