export const topLinks = [
  {
    title: 'why orluna',
    link: '/why-orluna',
  },
  {
    title: 'products',
    link: '/products',
  },
  {
    title: 'projects',
    link: '/projects',
  },
  {
    title: 'circular product',
    link: '/circular-product',
  },
];

export const bottomLinks = [
  {
    title: 'news',
    link: '/news',
  },
  {
    title: 'contact',
    link: '/contact',
  },
];

export const subLinks = [
  {
    title: 'our story',
    link: '/our-story',
  },
  {
    title: 'people',
    link: '/people',
  },
  {
    title: 'work with us',
    link: '/work-with-us',
  },
  {
    title: 'FAQs',
    link: '/faqs',
  },
];
