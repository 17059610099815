import {
  BANNER_IMAGES_REQUEST,
  BANNER_IMAGES_RECEIVED,
} from 'redux/actions/book-consultation';

const initialState = {
  hasError: false,
  bannerImages: {},
  loading: false,
};

export default function bookConsultation(state = initialState, action) {
  switch (action.type) {
    case BANNER_IMAGES_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        bannerImages: { ...state.bannerImages },
        loading: true,
      });
    case BANNER_IMAGES_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        bannerImages: {
          ...state.bannerImages,
          ...action.images,
        },
        loading: false,
      });
    default:
      return state;
  }
}
