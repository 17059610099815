import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFaqs } from 'redux/actions';
import urls from 'constants/urls.json';
import { getPage } from 'modules/api';
import values from 'lodash/fp/values';
import head from 'lodash/fp/head';
import { TITLE_CAN_WE_HELP, TITLE_FAQS } from 'constants/titles';
import { BANNER_NAME_WIDER } from 'constants/banners';
import { THEME_DARK } from 'constants/color-theme';
import { PAGE_CONTACT } from 'constants/page-name';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { SimpleHero } from 'components/simple-hero';
import { ContactFormSection } from 'components/contact-form-section';
import { SectionWithBackground } from 'components/section-with-background';
import { Dropdown } from 'components/dropdown';
import { ErrorBoundary } from 'components/error';
import { Loading } from 'components/loading';

export const GetInTouchScreen = () => {
  const { faqs } = useSelector((state) => state.faqs);
  const [pageData, setPageData] = useState({});
  const [featuredCategory, setFeaturedCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFaqs());
  }, [dispatch]);

  useEffect(() => {
    if (faqs) {
      const questions = values(faqs).filter(
        (option) => option.category === 'FEATURED'
      );
      setFeaturedCategory(head(questions)?.faqs);
    }
  }, [faqs]);

  useEffect(() => {
    async function fetchPage() {
      const page = await getPage(urls['page'], 'get-in-touch');
      setPageData(page.acf);
      setLoading(false);
    }
    fetchPage();
  }, [loading]);

  if (loading) return <Loading />;

  return (
    <ErrorBoundary>
      <Header theme={THEME_DARK} />
      <SimpleHero
        pageName={PAGE_CONTACT}
        theme={THEME_DARK}
        desktop={pageData.hero.desktop}
        mobile={pageData.hero.mobile}
        title={TITLE_CAN_WE_HELP}
      />
      <ContactFormSection sideImage={pageData.side_image} />
      <SectionWithBackground
        sectionName={BANNER_NAME_WIDER}
        image={pageData.banner}
      />
      <Dropdown title={TITLE_FAQS} arr={featuredCategory} />
      <Footer />
    </ErrorBoundary>
  );
};
