import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMenu } from 'redux/actions';
import { MenuMobile } from 'components/menu-mobile';
import styles from './style.module.scss';

export const NavBurger = ({ theme }) => {
  const { menuIsOpen } = useSelector((state) => state.menu);
  const { rangeNavIsOpen } = useSelector((state) => state.rangeNavigation);

  const dispatch = useDispatch();

  useEffect(() => {
    if (menuIsOpen || rangeNavIsOpen) {
      document.body.classList.add('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [menuIsOpen, rangeNavIsOpen]);

  const handleMobileMenu = () => {
    dispatch(toggleMenu());
  };

  const burgerClasses = classNames({
    [`${styles[theme]}`]: true,
    [`${styles['nav-burger']}`]: true,
    [`${styles['is-open']}`]: menuIsOpen,
  });

  const barClasses = classNames({
    [`${styles.bar}`]: true,
    [`${styles.animate}`]: menuIsOpen,
  });

  return (
    <>
      <div className={styles['burger-wrapper']} onClick={handleMobileMenu}>
        <div className={burgerClasses}>
          <div className={barClasses} />
        </div>
      </div>
      <MenuMobile open={menuIsOpen} />
    </>
  );
};
