import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROUTE_PROJECTS, ROUTE_WHY_ORLUNA } from 'constants/router-links';
import { SIZE_MEDIUM, SIZE_SMALL } from 'constants/sizes';
import { SECTION_BEAUTY } from 'constants/section-name';
import { THEME_DARK } from 'constants/color-theme';
import { Button } from 'components/buttons';
import { FloatingLogo } from 'components/floating-logo';
import { SlideUp } from 'components/animations';
import styles from './style.module.scss';

export const DesignAndBeauty = ({
  imageOne,
  copyOne,
  buttonOne,
  imageTwo,
  copyTwo,
  buttonTwo,
}) => {
  return (
    <section className={`${styles['design-and-beauty']}`}>
      <div className={`${styles.flex} ${styles.wrapper} container`}>
        <SlideUp>
          <div className={`${styles.flex} ${styles.design}`}>
            <div
              className={`${styles['design-left-side']}`}
              style={{
                background: `url(${imageOne}) no-repeat`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
              }}
            />
            <div className={styles['design-right-side']}>
              <div className={styles['floating-written']}>
                <h2>beautifully lit</h2>
              </div>
              <div className={styles['design-right-side-content']}>
                <h3>{copyOne}</h3>
                <Link to={ROUTE_PROJECTS}>
                  <Button
                    size={SIZE_MEDIUM}
                    theme={THEME_DARK}
                    label={buttonOne}
                  />
                </Link>
              </div>
            </div>
          </div>
        </SlideUp>
        <SlideUp>
          <div className={`${styles.flex} ${styles.beauty}`}>
            <FloatingLogo
              section={SECTION_BEAUTY}
              size={SIZE_SMALL}
              offsetHelper={2900}
            />
            <div className={`${styles['beauty-left-side']}`}>
              <div className={styles['beauty-left-side-content']}>
                <h3>{copyTwo}</h3>
                <Link to={ROUTE_WHY_ORLUNA}>
                  <Button
                    size={SIZE_MEDIUM}
                    theme={THEME_DARK}
                    label={buttonTwo}
                  />
                </Link>
              </div>
            </div>
            <div
              className={styles['beauty-right-side']}
              style={{
                background: `url(${imageTwo}) no-repeat`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
              }}
            />
          </div>
        </SlideUp>
      </div>
    </section>
  );
};

DesignAndBeauty.propTypes = {
  imageOne: PropTypes.string.isRequired,
  copyOne: PropTypes.string,
  buttonOne: PropTypes.string.isRequired,
  imageTwo: PropTypes.string.isRequired,
  copyTwo: PropTypes.string,
  buttonTwo: PropTypes.string.isRequired,
};