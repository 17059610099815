import React from 'react';
import styles from './style.module.scss';

//import gsap from "gsap";

export function GenericSection(props) {
  return (
    <section className={styles['generic-section']}>{props.children}</section>
  );
}
