import React from 'react';
import PropTypes from 'prop-types';
import { closeBlack } from 'components/search/icons';

import styles from './style.module.scss';

export const CloseView = ({ closeView }) => {
  return (
    <>
      <div className={styles.close}>
        <img onClick={() => closeView()} src={closeBlack} alt="close icon" />
      </div>
    </>
  );
};

CloseView.propTypes = {
  closeView: PropTypes.func.isRequired
}
