import urls from 'constants/urls.json';
import { submitFormWorkWithUs } from 'modules/api';

export const SUBMIT_WORK_WITH_US_REQUEST = 'SUBMIT_WORK_WITH_US_REQUEST';
export const SUBMIT_WORK_WITH_US_SUCCESS = 'SUBMIT_WORK_WITH_US_SUCCESS';
export const SUBMIT_WORK_WITH_US_ERROR = 'SUBMIT_WORK_WITH_US_ERROR';

export const submitWorkWithUsRequest = () => {
  return { type: SUBMIT_WORK_WITH_US_REQUEST };
};

export const submitWorkWithUsSuccess = () => {
  return { type: SUBMIT_WORK_WITH_US_SUCCESS };
};

export const submitWorkWithUsError = () => {
  return { type: SUBMIT_WORK_WITH_US_ERROR };
};

export const submitWorkWithUs = formData => {
  return async dispatch => {
    dispatch(submitWorkWithUsRequest());

    const response = await submitFormWorkWithUs(
      urls['formWorkWithUs'],
      formData
    );

    if (response.status === 500) {
      dispatch(submitWorkWithUsError());
      return false;
    }

    dispatch(submitWorkWithUsSuccess());
  };
};
