import React from 'react';
import classNames from 'classnames';
import { getLocalizedDate } from 'modules/localized-date';
import { SECTION_PROJECTS } from 'constants/section-name';
import { SIZE_BIG } from 'constants/sizes';
import PropTypes from 'prop-types';
import { SlideUp } from 'components/animations';
import { FloatingLogo } from 'components/floating-logo';
import { Article } from 'components/listing/article';
import styles from './style.module.scss';

export const Listing = ({
  data,
  isFeatured,
  isNews,
  isProjects,
  isAtRange,
}) => {
  return (
    <div className={styles['listing-wrapper']}>
      <FloatingLogo section={SECTION_PROJECTS} size={SIZE_BIG} />

      {data.map((item, index) => {
        const url = [isProjects ? '/projects/' : '/news/', item.slug].join('');
        const description = isProjects ? item.description : item.excerpt;
        const thumbnail = isProjects ? item.thumbnail : item.hero_image;
        const sector = isProjects ? item.sector : getLocalizedDate(item.date);
        const newIndex = isProjects ? index + 1 : index;

        const classes = classNames({
          container: true,
          [`${styles.wrapper}`]: true,
          [`${styles['image-first']}`]: newIndex % 2 === 0 && !isFeatured,
          [`${styles['text-first']}`]: newIndex % 2 !== 0,
          [`${styles['featured']}`]: isFeatured,
          [`${styles['project-featured']}`]: isProjects && isFeatured,
          [`${styles['news-featured']}`]: isNews && isFeatured,
          [`${styles['range-page']}`]: isAtRange,
        });

        const sectionClasses = classNames({
          section: true,
          [`${styles.item}`]: true,
        });

        return (
          <article key={item.title + index} className={sectionClasses}>
            <SlideUp>
              <div className={classes}>
                <Article
                  title={item.title}
                  sector={sector}
                  description={description}
                  url={url}
                  thumbnail={thumbnail}
                  theme={item.theme}
                  isFeatured={isFeatured}
                  isNews={isNews}
                  isAtRange={isAtRange}
                />
              </div>
            </SlideUp>
          </article>
        );
      })}
    </div>
  );
};

Listing.propTypes = {
  data: PropTypes.array.isRequired,
  isFeatured: PropTypes.bool,
  isNews: PropTypes.bool,
  isProjects: PropTypes.bool,
};
