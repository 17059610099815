import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import posed from 'react-pose';
import { topLinks, bottomLinks } from './config';
import { logoBrandWhite } from 'components/logos/logo-brand';
import { SubMenu } from 'components/menu-mobile/sub-menu';
import { Arrow } from 'components/icons';
import { Social } from 'components/social';
import styles from './style.module.scss';

const List = posed.ul({
  enter: {
    delayChildren: 500,
    staggerChildren: 150,
  },
});

const ListItem = posed.li({
  enter: { x: 0, opacity: 1 },
  exit: { x: 10, opacity: 0 },
});

const SocialWrapper = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 10, opacity: 0 },
});

export const MenuMobile = ({ open }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmenu = e => {
    setIsOpen(!isOpen);
  };

  const wrapperClasses = classNames({
    [`${styles['mobile-menu-wrapper']}`]: true,
    [`${styles['slide-in']}`]: open,
  });

  const logoClasses = classNames({
    [`${styles.logo}`]: true,
    [`${styles['fade-in']}`]: open,
  });

  const companyClasses = classNames({
    [`${styles.company}`]: true,
    [`${styles['is-open']}`]: isOpen,
  });

  return (
    <div className={wrapperClasses}>
      <nav>
        <div className={logoClasses}>
          <Link to="/">
            <img src={logoBrandWhite} alt="Orluna logo" />
          </Link>
        </div>
        <List pose={open ? 'enter' : 'exit'}>
          {!isOpen &&
            topLinks.map((link, i) => (
              <ListItem key={link.link}>
                <Link to={link.link}>
                  <span>{link.title}</span>
                  <Arrow className={styles.arrow} />
                </Link>
              </ListItem>
            ))}

          <ListItem className={companyClasses} onClick={handleSubmenu}>
            <span>company</span>
            <Arrow className={styles.arrow} />
          </ListItem>
          {isOpen && <SubMenu isOpen={isOpen} />}

          {!isOpen &&
            bottomLinks.map((link, i) => (
              <ListItem key={link.link}>
                <Link to={link.link}>
                  <span>{link.title}</span>
                  <Arrow className={styles.arrow} />
                </Link>
              </ListItem>
            ))}
        </List>
      </nav>
      <SocialWrapper pose={open ? 'enter' : 'exit'}>
        <Social extraClass={'mobile-social'} noHeader={true} />
      </SocialWrapper>
    </div>
  );
};
