import urls from 'constants/urls.json';
import { getRangeGridProduct } from 'modules/api';

export const RANGE_GRID_REQUEST = 'RANGE_GRID_REQUEST';
export const RANGE_GRID_RECEIVED = 'RANGE_GRID_RECEIVED';

export const requestRangeGrid = () => {
  return { type: RANGE_GRID_REQUEST };
};

export const receiveRangeGrid = (grid) => {
  return { type: RANGE_GRID_RECEIVED, grid };
};

export const fetchRangeGridProduct = () => {
  return async (dispatch) => {
    dispatch(requestRangeGrid());

    const grid = await getRangeGridProduct(urls['range-grid-product']);

    dispatch(receiveRangeGrid(grid));
  };
};
