import React from 'react';

export const Instagram = props => (
  <svg data-name="Layer 1" viewBox="0 0 34.5 34.5" {...props}>
    <circle
      cx={17.25}
      cy={17.25}
      r={16.5}
      fill="none"
      stroke=""
      strokeWidth={1.5}
    />
    <path
      d="M17.25 10.3h-2.86a5.3 5.3 0 00-1.64.37 3.26 3.26 0 00-1.23.8 3.31 3.31 0 00-.85 1.28 5 5 0 00-.33 1.69v5.72a5.3 5.3 0 00.33 1.69 3.26 3.26 0 00.8 1.23 3.19 3.19 0 001.23.8 5.3 5.3 0 001.69.33h5.72a5.3 5.3 0 001.69-.33 3.26 3.26 0 001.23-.8 3.39 3.39 0 00.8-1.23 5.3 5.3 0 00.33-1.69v-5.72a5.3 5.3 0 00-.33-1.69 3.31 3.31 0 00-.8-1.23 3.26 3.26 0 00-1.23-.8 5.3 5.3 0 00-1.69-.33h-2.86zm-.62 1.25h3.43a4.27 4.27 0 011.29.24 2.3 2.3 0 011.32 1.32 4.27 4.27 0 01.24 1.29v5.61a4.2 4.2 0 01-.24 1.29 2.17 2.17 0 01-.52.8 2.09 2.09 0 01-.8.52 3.71 3.71 0 01-1.29.24h-5.62a3.76 3.76 0 01-1.29-.24 2.09 2.09 0 01-.8-.52 2.17 2.17 0 01-.52-.8 4 4 0 01-.23-1.29V14.4a4 4 0 01.23-1.29 2.26 2.26 0 01.52-.8 2.35 2.35 0 01.8-.52 4 4 0 011.29-.24h2.19zm4.33 1.2a.83.83 0 00-.83.83.83.83 0 101.66 0 .83.83 0 00-.83-.83zm-3.71 1a3.57 3.57 0 103.57 3.57 3.57 3.57 0 00-3.57-3.57zm0 1.25a2.32 2.32 0 11-2.32 2.32A2.32 2.32 0 0117.25 15z"
      fill=""
      stroke="none"
      fillRule="evenodd"
    />
  </svg>
);
