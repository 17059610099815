import React from 'react';

export const Facebook = props => (
  <svg data-name="Layer 1" viewBox="0 0 34.5 34.5" {...props}>
    <g data-name="FACEBOOK">
      <circle
        cx={17.25}
        cy={17.25}
        r={16.5}
        fill="none"
        stroke=""
        strokeWidth={1.5}
      />
      <path
        d="M18.17 23.61v-7.09h2l.26-2.44h-2.26v-1.23c0-.63.06-1 1-1h1.23V9.43h-2c-2.35 0-3.18 1.19-3.18 3.18v1.47h-1.47v2.44h1.46v7.09z"
        fill=""
        stroke="none"
        fillRule="evenodd"
      />
    </g>
  </svg>
);
