import {
  FEATURED_PRODUCT_REQUEST,
  FEATURED_PRODUCT_RECEIVED,
} from 'redux/actions/featured-product';

const initialState = {
  hasError: false,
  data: {},
  loading: true,
};

export default function featuredProduct(state = initialState, action) {
  switch (action.type) {
    case FEATURED_PRODUCT_REQUEST:
      return Object.assign({}, state, {
        ...state,
        hasError: false,
        data: {
          ...state.data,
          [action.slug]: {
            loading: true,
          },
        },
      });
    case FEATURED_PRODUCT_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        data: {
          ...state.data,
          [action.slug]: {
            loading: false,
            ...action.product,
          },
        },
      });
    default:
      return state;
  }
}
