import React from 'react';
import { Link } from 'react-router-dom';
import { htmlToReactParser } from 'modules/html-to-react';
import { Arrow } from 'components/icons';
import styles from './style.module.scss';

export const Article = ({
  title,
  sector,
  description,
  url,
  thumbnail,
  theme,
  isFeatured,
  isAtRange,
  isNews,
}) => {
  const featuredText = isNews ? 'see the post' : 'view project';
  const linkText = isFeatured ? featuredText : 'read more';

  return (
    <>
      <div className={styles['text-wrapper']}>
        <h3>{title}</h3>
        <p className={styles.sector}>{sector}</p>
        {(!isFeatured || isAtRange) && (
          <span className={styles.description}>
            {htmlToReactParser.parse(description)}
          </span>
        )}
        <a className={styles['read-more']} href={url}>
          {linkText}
          <Arrow />
        </a>
      </div>
      <Link to={url}>
        <div className={`${styles['image-wrapper']}`}>
          <div className={styles['image-container']}>
            <img src={thumbnail} alt={title} />
          </div>
          <div className={`${styles['bg-color']} ${styles[theme]}`} />
        </div>
      </Link>
    </>
  );
};
