import md5 from 'md5';
import urls from 'constants/urls.json';
import { get } from 'modules/fetch/get';

export const PRODUCTS_REQUEST = 'PRODUCTS_REQUEST';
export const PRODUCTS_RECEIVED = 'PRODUCTS_RECEIVED';
export const PRODUCTS_OPEN_MAIN_FILTERS = 'PRODUCTS_OPEN_MAIN_FILTERS';
export const PRODUCTS_CLOSE_MAIN_FILTERS = 'PRODUCTS_CLOSE_MAIN_FILTERS';
export const PRODUCTS_TOGGLE_FILTERS = 'PRODUCTS_TOGGLE_FILTERS';
export const PRODUCTS_TOGGLE_FILTER = 'PRODUCTS_TOGGLE_FILTER';
export const PRODUCTS_RESET_FILTERS = 'PRODUCTS_RESET_FILTERS';
export const PRODUCTS_SET_FILTER = 'PRODUCTS_SET_FILTER';
export const PRODUCTS_SET_SEARCHED = 'PRODUCTS_SET_SEARCHED';
export const PRODUCTS_VIEW = 'PRODUCTS_VIEW';

export const openMainFilters = () => {
  return { type: PRODUCTS_OPEN_MAIN_FILTERS };
};

export const closeMainFilters = () => {
  return { type: PRODUCTS_CLOSE_MAIN_FILTERS };
};

export const toggleFilters = () => {
  return { type: PRODUCTS_TOGGLE_FILTERS };
};

export const toggleFilter = (name, value) => {
  return { type: PRODUCTS_TOGGLE_FILTER, name, value };
};

export const setFilter = (name, value) => {
  return { type: PRODUCTS_SET_FILTER, name, value };
};

export const setSearchedProducts = (products) => {
  return { type: PRODUCTS_SET_SEARCHED, products };
};

export const resetFilters = () => {
  return { type: PRODUCTS_RESET_FILTERS };
};

export const requestProducts = (hash) => {
  return { type: PRODUCTS_REQUEST, hash };
};

export const setProductsView = (view) => {
  return { type: PRODUCTS_VIEW, view };
};

export const receiveProducts = ({
  products,
  availableTerms,
  filters,
  hash,
}) => {
  return { type: PRODUCTS_RECEIVED, products, availableTerms, filters, hash };
};

export const fetchProducts = (hash, filters) => {
  return async (dispatch) => {
    dispatch(requestProducts(hash));

    const response = await get(urls['products'], filters);
    const products = response.products;
    const availableTerms = response.available_terms;

    dispatch(receiveProducts({ products, availableTerms, filters, hash }));
  };
};

function shouldFetchProducts(state, hash) {
  const data = state.products?.[hash];

  if (!data) {
    return true;
  } else if (data.loading) {
    return false;
  } else {
    return false;
  }
}

export const fetchProductsIfNeeded = (filters) => {
  const hash = md5(JSON.stringify(filters));

  return (dispatch, getState) => {
    if (shouldFetchProducts(getState(), hash)) {
      return dispatch(fetchProducts(hash, filters));
    } else {
      Promise.resolve();
    }
  };
};
