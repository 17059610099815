import {
  PARTNERS_REQUEST,
  PARTNERS_RECEIVED,
} from 'redux/actions/international-partners';

const initialState = {
  hasError: false,
  internationalPartners: {},
  loading: true,
};

export default function internationalPartners(state = initialState, action) {
  switch (action.type) {
    case PARTNERS_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        internationalPartners: {
          ...state.internationalPartners,
        },
        loading: true,
      });
    case PARTNERS_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        internationalPartners: {
          ...state.internationalPartners,
          ...action.internationalPartners,
        },
        loading: false,
      });
    default:
      return state;
  }
}
