import React from 'react';
import { ImagePreview } from 'components/icons';
import styles from './style.module.scss';

export const PreviewLinkImage = ({ url }) => {
  return (
    <a
      className={styles['preview-icon']}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ImagePreview className={styles['pdf-small']} />
    </a>
  );
};
