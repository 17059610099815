import React, { useState, useEffect } from 'react';
import posed from 'react-pose';
import PropTypes from 'prop-types';
import { htmlToReactParser } from 'modules/html-to-react';
import { Person } from 'components/people-grid/person';
import styles from './style.module.scss';

const Grid = posed.ul({
  enter: {
    delayChildren: 450,
    staggerChildren: 200,
  },
});

const Item = posed.li({
  enter: {
    scale: 1,
    y: 0,
    opacity: 1,
    transition: {
      duration: 400,
      ease: 'easeInOut',
    },
  },
  exit: { scale: 0.95, y: 20, opacity: 0 },
});

export const PeopleGrid = ({ people }) => {
  const [entered, setEntered] = useState(false);

  useEffect(() => {
    setEntered(true);
  }, []);
  
  return (
    <>
      {people.length > 0 && (
        <div className={`${styles['people-grid']} section container`}>
          <Grid className={styles.grid} pose={entered ? 'enter' : 'exit'}>
            {people.map((person, index) => (
              <Item className={styles['grid-item']} key={index}>
                <Person
                  title={htmlToReactParser.parse(person.title)}
                  job={htmlToReactParser.parse(person.job_title)}
                  description={htmlToReactParser.parse(person.description)}
                  image={person.image}
                />
              </Item>
            ))}
          </Grid>
        </div>
      )}
    </>
  );
};

PeopleGrid.propTypes = {
  people: PropTypes.array.isRequired
}
