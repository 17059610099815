import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';
import { SEARCH } from 'constants/view';
import { THEME_SOLID } from 'constants/color-theme';
import {
  fetchProductsIfNeeded,
  hasSearched,
  setSearchedProducts,
  resetFilters,
  closeMainFilters,
  fetchRanges,
  fetchRangeGridProduct,
  fetchRangeNavigation,
} from 'redux/actions';
import { ProductGrid } from 'components/product-grid';
import { SignUpSection } from 'components/signup-section';
import { SearchView } from 'components/search-view';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { Loading } from 'components/loading';
import { ErrorBoundary } from 'components/error';
import { RangeGrid } from 'components/range-grid';

export const ProductListing = () => {
  const dispatch = useDispatch();
  const { filters, view, mainFiltersOpen, hasFiltered } = useSelector(
    (state) => state.products
  );
  const { ranges, rangesLoading } = useSelector((state) => state.ranges);
  const { grid } = useSelector((state) => state.rangeGridProduct);
  const hash = md5(JSON.stringify(filters));
  const searchView = view === SEARCH;

  useEffect(() => {
    dispatch(fetchRanges());
    dispatch(fetchRangeNavigation());
    dispatch(fetchRangeGridProduct());
    dispatch(fetchProductsIfNeeded(filters));
  }, [dispatch, filters]);

  const { loading, products } = useSelector(
    (state) =>
      state.products?.[hash] || {
        loading: true,
        products: [],
      }
  );

  useEffect(() => {
    if (searchView) {
      dispatch(hasSearched(false));
      dispatch(resetFilters());
      dispatch(closeMainFilters());
      dispatch(setSearchedProducts(products));
    }
  }, [dispatch, products, searchView]);

  const state = useSelector((state) => state);

  const { searchedProducts } = state.products;

  const Views = {
    grid: <ProductGrid products={products} />,
    search: <SearchView products={searchedProducts} />,
  };

  return (
    <ErrorBoundary>
      <Header theme={THEME_SOLID} />
      {/* false added below to hide grid, take off if Orluna request */}
      {grid.display_range_grid &&
        false &&
        !searchView &&
        !mainFiltersOpen &&
        !hasFiltered && (
          <RangeGrid
            content={grid.range_grid}
            ranges={ranges}
            loading={rangesLoading}
            isProductListing
          />
        )}
      {loading && <Loading overlay />}
      {!loading && Views[view]}
      {!loading && view !== SEARCH && (
        <>
          <SignUpSection />
          <Footer />
        </>
      )}
    </ErrorBoundary>
  );
};
