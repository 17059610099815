import React from 'react';
import { abbreviateAllVariants } from 'modules/abbreviate-all-variants';
import { SECTION_PRODUCT_INTRO } from 'constants/section-name';
import { SIZE_BIG } from 'constants/sizes';
import PropTypes from 'prop-types';
import { FloatingLogo } from 'components/floating-logo';
import { Title } from 'components/product-title';
import styles from './style.module.scss';

export const ProductIntro = ({
  title,
  allVariants,
  introduction,
  smallScreen,
}) => {
  const varOptions = abbreviateAllVariants(allVariants);
  const variants = varOptions ? varOptions.join(' / ') : null;

  return (
    <section className={`${styles.intro} section container`}>
      <FloatingLogo section={SECTION_PRODUCT_INTRO} size={SIZE_BIG} />
      <div className="container">
        {smallScreen && (
          <>
            <Title title={title} />
            <h3>{variants}</h3>
          </>
        )}
        <p>{introduction}</p>
      </div>
    </section>
  );
};

ProductIntro.propTypes = {
  title: PropTypes.string.isRequired,
  allVariants: PropTypes.array,
  introduction: PropTypes.string.isRequired,
  smallScreen: PropTypes.bool,
}