import axios from 'axios';

export const getInTouch = async (endpoint) => {
  try {
    const url = [process.env.REACT_APP_CMS_URL, endpoint].join('/');
    const response = await axios.get(url);
    const data = await response.data;
    return data;
  } catch (error) {
    throw error;
  }
};
