import React from 'react';
import {
  Downlight,
  Spot,
  Uplight,
  NarrowBeam,
  SoftBeam,
  CrispBeam,
  Elliptical,
  WallWash,
} from 'components/icons';

export const ICONS_FILTER = {
  uplights: <Uplight />,
  downlights: <Downlight />,
  spotlights: <Spot />,
};

export const ICONS_PRODUCT_HERO = {
  'narrow-beam': <NarrowBeam />,
  'soft-beam': <SoftBeam />,
  'crisp-beam': <CrispBeam />,
  elliptical: <Elliptical />,
  wallwash: <WallWash />,
};