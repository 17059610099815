import axios from 'axios';

export const getProductPreview = async (endpoint, slug) => {
  try {
    //Combine the elements together to make the URL seperated by "/"
    const url = [
      process.env.REACT_APP_CMS_URL,
      endpoint.replace(':slug', slug),
    ].join('/');

    //Fetch the data using Axios, extract the relevant data
    const response = await axios.get(url);
    const data = await response.data;

    //Return the data
    return data;
  } catch (error) {
    //Throw an error if there are issues
    throw error;
  }
};
