import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { closeRangeNavigation } from 'redux/actions';
import { useScrolling } from 'hooks/use-scrolling';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import md5 from 'md5';
import { THEME_DARK, THEME_SOLID } from 'constants/color-theme';
import {
  ROUTE_HOME,
  ROUTE_PRODUCTS,
  ROUTE_RANGES,
} from 'constants/router-links';
import { logoBrandDark, logoBrandWhite } from 'components/logos';
import { RangeNavigationMobile } from 'components/range-navigation-mobile';
import { RangeNavigationDesktop } from 'components/range-navigation-desktop';
import { RangeNavigationButton } from 'components/range-navigation-button';
import { NavBurger } from 'components/nav-burger';
import { Filters } from 'components/filters';
import { Search } from 'components/search';
import { Menu } from 'components/menu';
import styles from './style.module.scss';

const handleIsProductPage = (pathname) => {
  if (pathname === ROUTE_HOME) return false;
  // remove all slashes from pathname to avoid confusion
  const replacedPathname = pathname.replace(/\//g, '');
  return replacedPathname === 'products';
};

export const Header = ({ theme, gradient }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { scrollY } = useScrolling(5000);
  const [addSearchBar, setAddSearchBar] = useState(false);
  const [addRangeNavigation, setAddRangeNavigation] = useState(false);
  const [addFilters, setAddFilters] = useState(false);
  const [homepageScroll, setHomepageScroll] = useState(false);
  const { menuIsOpen } = useSelector((state) => state.menu);
  const { notification } = useSelector((state) => state.notification);
  const { ranges } = useSelector((state) => state.ranges);
  const { rangeNavVisibility, rangeNavIsOpen } = useSelector(
    (state) => state.rangeNavigation
  );
  const isProductsPage = handleIsProductPage(pathname);
  const isProductPage = pathname.indexOf(ROUTE_PRODUCTS) > -1;
  const isRangePage = pathname.indexOf(ROUTE_RANGES) > -1;
  const { filters, hasFiltered, mainFiltersOpen } = useSelector(
    (state) => state.products
  );
  const hash = md5(JSON.stringify(filters));

  useEffect(() => {
    setAddSearchBar(isProductsPage || isRangePage);
    setAddRangeNavigation(addSearchBar || isProductPage);

    if (!isRangePage) setAddFilters(true);

    return () => dispatch(closeRangeNavigation());
  }, [
    dispatch,
    pathname,
    addRangeNavigation,
    addSearchBar,
    isProductPage,
    isProductsPage,
    isRangePage,
  ]);

  useEffect(() => {
    if (hasFiltered || mainFiltersOpen || homepageScroll) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      if (homepageScroll) setHomepageScroll(false);
    }
  }, [menuIsOpen, theme, hasFiltered, mainFiltersOpen, homepageScroll]);

  const { products, availableTerms } = useSelector(
    (state) =>
      state.products?.[hash] || {
        products: [],
        availableTerms: [],
      }
  );

  // 40 = 2/3 of notification height
  const headerClasses = classNames({
    [`${styles.header}`]: true,
    [`${styles['notification-on']}`]:
      notification.length > 0 && scrollY < 40 && !mainFiltersOpen,
    [`${styles.relative}`]: mainFiltersOpen,
  });

  const wrapperClasses = classNames({
    [`${styles['header-wrapper']}`]: true,
    [`${styles[`${theme}`]}`]: true,
    [`${styles.fixed}`]: scrollY > 0,
    [`${styles.gradient}`]: gradient,
    [`${styles['back-to-fixed']}`]:
      scrollY > 0 && hasFiltered && mainFiltersOpen,
  });

  const innerWrapperClasses = classNames({
    container: true,
    [`${styles.wrapper}`]: true,
    [`flex-between`]: true,
  });

  return (
    <header className={headerClasses}>
      <div className={wrapperClasses} id="back-to-top">
        <div className={innerWrapperClasses}>
          <Link
            to={ROUTE_HOME}
            className={styles.logo}
            onClick={() => setHomepageScroll(true)}
          >
            <img src={logoBrandWhite} alt="White Orluna logo" />
            <img
              className={styles['black-logo']}
              src={logoBrandDark}
              alt="Black Orluna logo"
            />
          </Link>
          <Menu />
        </div>
        <NavBurger theme={!scrollY ? theme : THEME_SOLID} />
      </div>
      {addRangeNavigation && rangeNavVisibility && (
        <RangeNavigationDesktop
          ranges={ranges}
          isProductDetail={isProductPage && !isProductsPage}
        />
      )}
      {addSearchBar && (
        <>
          <Search addFilters={addFilters} />
          <Filters availableTerms={availableTerms} products={products} />
        </>
      )}
      {addRangeNavigation && rangeNavVisibility && (
        <>
          {isProductPage && !isProductsPage && (
            <RangeNavigationButton
              isProductPage={isProductPage && !isProductsPage}
            />
          )}
          {rangeNavIsOpen && <RangeNavigationMobile ranges={ranges} />}
        </>
      )}
    </header>
  );
};

Header.defaultProps = {
  theme: THEME_DARK,
  gradient: false,
};

Header.propTypes = {
  theme: PropTypes.string,
  gradient: PropTypes.bool,
};
