import urls from 'constants/urls.json';
import { getProject } from 'modules/api';

export const PROJECT_REQUEST = 'PROJECT_REQUEST';
export const PROJECT_RECEIVED = 'PROJECT_RECEIVED';

export const requestProject = () => {
  return { type: PROJECT_REQUEST };
};

export const receiveProject = project => {
  return { type: PROJECT_RECEIVED, project };
};

export const fetchProject = slug => {
  return async dispatch => {
    dispatch(requestProject());

    const project = await getProject(urls['project'], slug);

    dispatch(receiveProject(project));
  };
};
