import React from 'react';

export const DownloadArrow = () => (
  <svg
    height="22"
    viewBox="0 0 21 22"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m0 11.5 9.5 8.5581079 9.6494045-8.5581079m-9.6494045-11 .07470224 19.0799981"
      fill="none"
      stroke="#fff"
      strokeWidth="1.6"
      transform="translate(1)"
    />
  </svg>
);
