/**
 * Generate options for React Select from an array of objects
 * @param {*[]} options
 * @param {string} labelKey - Key used to find the label in the options array
 * @param {string} valueKey - Key used to find the value in the options array
 * @return {string[]}
 */
export const generateSelectOptions = (
  options = [],
  labelKey = '',
  valueKey = ''
) => {
  if (!Array.isArray(options)) return [];
  if (typeof labelKey !== 'string' || typeof valueKey !== 'string') return [];

  try {
    return [
      { label: 'All', value: '' },
      ...options.map((option) => {
        return { label: option[labelKey], value: option[valueKey] };
      }),
    ];
  } catch (error) {
    return [];
  }
};

/**
 * Generate options for React Select from an array of objects, filtered by
 * "keyed" array
 * @param {*[]} options
 * @param {string} labelKey - Key used to find the label in the options array
 * @param {array} searchTerms - an array of keys used to determine if option available
 * @return {[<ReactSelectOptions>]}
 */
export const genereateSelectOptionsBySearchTerms = (options, key, terms) =>
  options
    .map((option) => {
      const label = option[key];
      // create the value from the label
      // values are lower case, and contain no spaces
      const value = label.toLowerCase().replace(/ /g, '-');
      if (terms.indexOf(value) > -1) {
        return {
          label,
          value,
        };
      }
      return null;
    })
    .filter((value) => value !== null);
