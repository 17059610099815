import urls from 'constants/urls.json';
import { getProductPreview } from 'modules/api';
import { head } from 'lodash/fp';

export const FEATURED_PRODUCT_REQUEST = 'FEATURED_PRODUCT_REQUEST';
export const FEATURED_PRODUCT_RECEIVED = 'FEATURED_PRODUCT_RECEIVED';

export const requestFeaturedProduct = (slug) => {
  return { type: FEATURED_PRODUCT_REQUEST, slug };
};

export const receiveFeaturedProduct = (slug, product) => {
  return { type: FEATURED_PRODUCT_RECEIVED, slug, product };
};

function fetchFeaturedProduct(slug) {
  return async (dispatch) => {
    dispatch(requestFeaturedProduct(slug));

    const products = await getProductPreview(urls['product-preview'], slug);
    const product = head(products);

    dispatch(receiveFeaturedProduct(slug, product));
  };
}

function shouldFetchFeaturedProduct(state, slug) {
  const product = state.featuredProduct.data[slug];

  if (!product) {
    return true;
  } else if (product.loading) {
    return false;
  } else {
    return false;
  }
}

export const fetchFeaturedProductIfNeeded = (slug) => {
  return (dispatch, getState) => {
    if (shouldFetchFeaturedProduct(getState(), slug)) {
      return dispatch(fetchFeaturedProduct(slug));
    } else {
      return Promise.resolve();
    }
  };
};
