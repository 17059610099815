import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import AOS from 'aos';
import { chunk } from 'modules/chunk-array';
import { Product } from 'components/product-grid/product';
import styles from './style.module.scss';

export const ProductGrid = ({ products }) => {
  const { rangeNavVisibility } = useSelector((state) => state.rangeNavigation);
  const { hasFiltered, mainFiltersOpen } = useSelector(
    (state) => state.products
  );
  const features = [6, 13, 23, 32, 41, 48];
  const amount = hasFiltered ? 4 : 18;
  const groupsOfProducts = chunk(products, amount);

  useEffect(() => {
    /* makes sure there's no white screen 
       after filter is reset and closed */
    if (!mainFiltersOpen) {
      AOS.refresh();
    }
  }, [mainFiltersOpen]);

  const productClasses = (index) => {
    const featureIndex = features.indexOf(index);
    const featureClassName = `feature-${featureIndex + 1}`;
    const featureClass = styles[featureClassName];

    return classNames({
      [`${styles['grid-item']}`]: true,
      [`${featureClass}`]: !hasFiltered && featureIndex > -1,
    });
  };

  const classes = classNames({
    section: true,
    container: true,
    [`${styles['product-grid']}`]: true,
    [`${styles.filtered}`]: hasFiltered,
    [`${styles['remove-margin']}`]: mainFiltersOpen || !hasFiltered,
    [`${styles['range-nav-hidden']}`]: !rangeNavVisibility,
  });

  AOS.init({
    duration: 750,
  });

  return (
    <main className={classes}>
      {!hasFiltered && !mainFiltersOpen && <h3>all products</h3>}
      {groupsOfProducts.map((group, i) => (
        <ul key={group[0].ID} className={styles.grid}>
          {group.map((product, i) => (
            <li
              data-aos="fade-up"
              data-aos-once="true"
              style={{ transitionDelay: `0.${i + 2}s` }}
              className={productClasses(i)}
              key={product.title + i}
            >
              <Product {...product} media={product.video} />
            </li>
          ))}
        </ul>
      ))}
    </main>
  );
};
