import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCheckboxes } from 'redux/actions';
import classNames from 'classnames';
import keys from 'lodash/fp/keys';
import { downloadZip } from 'modules/api';
import { Download } from 'components/icons';
import { LeftColumn } from './left-column';
import { RightColumn } from './right-column';
import { NoData } from './no-data';
import styles from './style.module.scss';
import {
  DOWNLOAD_ADD_EPD,
  DOWNLOAD_ADD_1800_3000,
  DOWNLOAD_ADD_2700,
  DOWNLOAD_ADD_3000,
  DOWNLOAD_ADD_1800_4000,
  DOWNLOAD_ADD_2700_6500,
  DOWNLOAD_ADD_DATASHEETS,
  DOWNLOAD_ADD_DRAWINGS,
  DOWNLOAD_ADD_IMAGE,
  DOWNLOAD_ADD_INSTALLATION,
  DOWNLOAD_ADD_PHOTO_REPORTS,
  DOWNLOAD_ADD_PSU,
} from 'constants/spec-code-downloads';

export const downloadsRef = React.createRef(); // used to anchor to

export const Downloads = ({ data, disabled }) => {
  const [active, setActive] = useState(null);
  const { checkboxes } = useSelector((state) => state.downloadCheckbox);
  const productDownloads = useSelector((state) => state.productDownloads);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetCheckboxes());
  }, [dispatch]);

  const leftColumn = {
    [DOWNLOAD_ADD_EPD]: data['epd'],
    [DOWNLOAD_ADD_DATASHEETS]: data['datasheets'],
    [DOWNLOAD_ADD_1800_3000]: data['ies_ldt'],
    /* TODO Get rid of these and change 1800_3000 to ies_ldt as ies_ldt holds all of them  */
    [DOWNLOAD_ADD_2700]: data[DOWNLOAD_ADD_2700],
    [DOWNLOAD_ADD_3000]: data[DOWNLOAD_ADD_3000],
    [DOWNLOAD_ADD_1800_4000]: data[DOWNLOAD_ADD_1800_4000],
    [DOWNLOAD_ADD_2700_6500]: data[DOWNLOAD_ADD_2700_6500],
    [DOWNLOAD_ADD_PHOTO_REPORTS]: data['luminaire_photometric_reports'],
    [DOWNLOAD_ADD_INSTALLATION]: data['installer_and_contractor_information'],
    [DOWNLOAD_ADD_DRAWINGS]: data['diagrams_and_line_drawings'],
  };

  const rightColumn = {
    [DOWNLOAD_ADD_IMAGE]: data['images'],
    [DOWNLOAD_ADD_PSU]: data['psu'],
  };

  const handleClick = (item) => {
    item === active ? setActive(null) : setActive(item);
  };

  const handleDownloadSelected = () => {
    downloadZip(checkboxes);
  };

  const handleDownloadAll = () => {
    const urls = [];

    keys(leftColumn).map((item, index) => {
      return keys(leftColumn[item]).map((subItem, index) => {
        const url = leftColumn[item][subItem].url;
        const pathname = new URL(url).pathname;

        return urls.push(pathname.substr(1));
      });
    });

    keys(rightColumn).map((item, index) => {
      return keys(rightColumn[item]).map((subItem, index) => {
        const url = rightColumn[item][subItem].url;
        const pathname = new URL(url).pathname;

        return urls.push(pathname.substr(1));
      });
    });

    downloadZip(urls);
  };

  const sectionClasses = classNames({
    [`${styles.downloads}`]: true,
    [`${styles.desktop}`]: true,
    [`${styles.disabled}`]: disabled,
    [`section`]: true,
  });

  return (
    <section className={sectionClasses} ref={downloadsRef}>
      <div className="container">
        <div className={styles.inner}>
          <div className={styles.heading}>
            <h3>downloads</h3>
            <span>
              Includes all downloads based on your chosen specification.
            </span>
          </div>
          <div className={styles['download-btn-container']}>
            <button
              className={checkboxes.length === 0 ? styles.disabled : ''}
              onClick={handleDownloadSelected}
              disabled={checkboxes.length === 0}
            >
              download selected <Download className={styles['download-icon']} />
            </button>
            <button onClick={handleDownloadAll} disabled={disabled}>
              download all <Download className={styles['download-icon']} />
            </button>
          </div>
        </div>

        <div className={styles.inner}>
          {(Object.keys(data).length === 0 ||
            disabled ||
            productDownloads.loading) && <NoData />}
          {Object.keys(data).length > 0 &&
            !disabled &&
            !productDownloads.loading && (
              <>
                <LeftColumn data={leftColumn} disabled={disabled} />
                <RightColumn
                  data={rightColumn}
                  disabled={disabled}
                  handleClick={handleClick}
                  handleDownloadSelected={handleDownloadSelected}
                  checkboxes={checkboxes}
                />
              </>
            )}
        </div>
      </div>
    </section>
  );
};
