import { PROJECTS_REQUEST, PROJECTS_RECEIVED } from 'redux/actions/projects';

const initialState = {
  hasError: false,
  projects: {},
  loading: true,
};

export default function projects(state = initialState, action) {
  switch (action.type) {
    case PROJECTS_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        projects: {
          ...state.projects,
        },
        loading: true,
      });
    case PROJECTS_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        projects: {
          ...state.projects,
          ...action.projects,
        },
        loading: false,
      });
    default:
      return state;
  }
}
