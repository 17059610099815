import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import posed from 'react-pose';
import { useSelector } from 'react-redux';
import styles from './style.module.scss';

const Navbar = posed.ul({
  enter: {
    x: '0%',
    delayChildren: 200,
    staggerChildren: 150,
    staggerDirection: -1,
  },
});

const Item = posed.li({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 700,
      ease: 'easeIn',
    },
  },
  exit: { y: 10, opacity: 0 },
});
export const Menu = () => {
  const [entered, setEntered] = useState(false);

  useEffect(() => {
    setEntered(true);
  }, []);

  const headerMenu = useSelector((state) => state.navMenus.data?.header);

  try {
    if (headerMenu === (null || undefined)) {
      throw new Error('Header menu has not been defined');
    }
  } catch (error) {
    console.error(
      'Header Menu Unavailable: the header menu (header) has not been defined in the CMS'
    );
  }

  const linksArray = headerMenu;

  return (
    <nav className={`${styles['header-menu']}`}>
      <Navbar pose={entered ? 'enter' : 'exit'}>
        {linksArray.map((link, i) => (
          <Item key={link.link || link.url}>
            <NavLink
              exact
              to={link.link || link.url}
              activeClassName="nav-link-active"
            >
              {link.title}
            </NavLink>
          </Item>
        ))}
      </Navbar>
    </nav>
  );
};
