import axios from 'axios';
import { head } from 'lodash/fp';

export const getPage = async (endpoint, slug) => {
  try {
    const url = [
      process.env.REACT_APP_CMS_URL,
      endpoint.replace(':slug', slug),
    ].join('/');
    const response = await axios.get(url);
    const data = await response.data;

    return head(data);
  } catch (error) {
    throw error;
  }
};
