import React from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { push } from 'connected-react-router';
import { Pagination } from 'components/pagination';

export const DownloadPagination = ({
  currentPage,
  filters,
  totalPages,
  totalResults,
}) => {
  const dispatch = useDispatch();
  const goToPage = number => {
    const qs = queryString.stringifyUrl({
      url: '/downloads',
      query: {
        ...filters,
        page: number,
      },
    });

    dispatch(push(qs));
  };
  const handleNext = () => goToPage(currentPage + 1);
  const handlePrevious = () => goToPage(currentPage - 1);
  const handleGoToPage = number => goToPage(number);

  return (
    <Pagination
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      currentPage={currentPage}
      totalResults={totalResults}
      perPage={20}
      changePage={number => handleGoToPage(number)}
    />
  );
};

DownloadPagination.defaultProps = {
  currentPage: 0,
  filters: [],
  totalPages: 0,
  totalResults: 0,
};
