import urls from 'constants/urls.json';
import { getNavMenus } from 'modules/api';

export const NAVMENUS_REQUEST = 'NAVMENUS_REQUEST';
export const NAVMENUS_RECEIVED = 'NAVMENUS_RECEIVED';

export const requestNavMenus = () => {
  return { type: NAVMENUS_REQUEST };
};

export const receiveNavMenus = (navMenus) => {
  return { type: NAVMENUS_RECEIVED, navMenus };
};

export const fetchNavMenus = () => {
  return async (dispatch) => {
    dispatch(requestNavMenus());

    const navMenus = await getNavMenus(urls['nav-menus']);

    dispatch(receiveNavMenus(navMenus));
  };
};
