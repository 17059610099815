import {
  COLLABORATORS_REQUEST,
  COLLABORATORS_RECEIVED,
} from 'redux/actions/collaborators';

const initialState = {
  hasError: false,
  collaborators: {},
  collaboratorsLoading: true,
};

export default function collaborators(state = initialState, action) {
  switch (action.type) {
    case COLLABORATORS_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        collaborators: {
          ...state.collaborators,
        },
        collaboratorsLoading: true,
      });
    case COLLABORATORS_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        collaborators: {
          ...state.collaborators,
          ...action.collaborators,
        },
        collaboratorsLoading: false,
      });
    default:
      return state;
  }
}
