import React from 'react';
import styles from 'components/check-box/style.module.scss';

export const CrispBeam = props => (
  <svg
    className={styles['filter-icon']}
    data-name="Layer 1"
    viewBox="0 0 57 57"
    {...props}
  >
    <circle cx={28} cy={28} r={27} fill="none" strokeWidth={2} />
    <path
      d="M11 37.33l.06-.22S28 13 28.19 13s17 24.11 17 24.11l.06.22c.02-.22-34.25-.07-34.25 0z"
      fillRule="evenodd"
    />
  </svg>
);
