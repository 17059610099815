import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

export const FooterMenu = ({ navList, title }) => {
  const linkList = navList.map(link => (
    <li key={link.path}>
      <a href={link.path}>{link.name}</a>
    </li>
  ));

  return (
    <div className={styles['footer-menu']}>
      <h3>{title}</h3>
      <nav>
        <ul>{linkList}</ul>
      </nav>
    </div>
  );
};

FooterMenu.propTypes = {
  title: PropTypes.string.isRequired,
  navList: PropTypes.array.isRequired
}