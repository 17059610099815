import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'components/buttons';
import { SIZE_MEDIUM } from 'constants/sizes';
import { THEME_DARK } from 'constants/color-theme';
import { LABEL_DISC_COLLECTION } from 'constants/labels';
import { ROUTE_PRODUCTS } from 'constants/router-links';
import styles from './style.module.scss';

export const FourOhFour = () => {
  return (
    <main className={styles['four-oh-four']}>
      <div className={`${styles.content} container`}>
        <h2>
          404
          <br />
          page not found.
        </h2>
        <p>why not explore our products instead?</p>
        <Link to={ROUTE_PRODUCTS}>
          <Button
            size={SIZE_MEDIUM}
            theme={THEME_DARK}
            label={LABEL_DISC_COLLECTION}
          />
        </Link>
      </div>
    </main>
  );
};
