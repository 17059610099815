import urls from 'constants/urls.json';
import { submitForm } from 'modules/api';

export const SUBMIT_CONTACT_US_REQUEST = 'SUBMIT_CONTACT_US_REQUEST';
export const SUBMIT_CONTACT_US_SUCCESS = 'SUBMIT_CONTACT_US_SUCCESS';
export const SUBMIT_CONTACT_US_ERROR = 'SUBMIT_CONTACT_US_ERROR';

export const submitContactUsRequest = () => {
  return { type: SUBMIT_CONTACT_US_REQUEST };
};

export const submitContactUsSuccess = () => {
  return { type: SUBMIT_CONTACT_US_SUCCESS };
};

export const submitContactUsError = () => {
  return { type: SUBMIT_CONTACT_US_ERROR };
};

export const submitContactUs = formData => {
  return async dispatch => {
    dispatch(submitContactUsRequest());

    const response = await submitForm(urls['formContactUs'], formData);

    if (response.status === 500) {
      dispatch(submitContactUsError());
      return false;
    }

    dispatch(submitContactUsSuccess());
  };
};
