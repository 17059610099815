import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchCollaborators,
  fetchBookConsultation,
  fetchProducts,
  fetchRanges,
} from 'redux/actions';
import { useWindowSize } from 'hooks/use-window-size';
import { getPage } from 'modules/api';
import { bpSmall } from 'constants/breakpoints';
import urls from 'constants/urls.json';
import { ROUTE_SUSTAINABILITY, ROUTE_CONTACT } from 'constants/router-links';
import { THEME_DARK } from 'constants/color-theme';
import {
  BANNER_NAME_GET_IN_TOUCH_SUSTAINABILITY,
  BANNER_NAME_NEED_REPAIRS,
} from 'constants/banners';
import { PAGE_SUSTAINABILITY } from 'constants/page-name';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { SectionWithBackground } from 'components/section-with-background';
import {
  SECTION_CIRCULAR_REVERSE,
  SECTION_CIRCULAR,
} from 'constants/section-name';
import { Loading } from 'components/loading';
import { SlideUp } from 'components/animations';
import { ErrorBoundary } from 'components/error';
import { SimpleHero } from 'components/simple-hero';
import { LatticeBlocks } from 'components/lattice-blocks';
import PortraitImageDiv from 'components/portrait-image-div';
import { Callout } from 'components/callout';
import { CircularInteractive } from 'components/circular-interactive';
import { ImageAndText } from 'components/image-and-text';
import { FaqsDropdown } from 'components/faqs-dropdown';
import { Button } from 'components/buttons';
import { SectionWithPortrait } from 'components/section-with-portrait';
import { GenericSection } from 'components/generic-section';
import mountainImagePeachDesktop from '../assets/images/mountain-image-peach-desktop.png';
import mobilePinkMountain from '../assets/images/pink-mountain-product-combo-mobile2.png';
import infinityLoopProduct from '../assets/images/7ee1cba6-ccd4-4e36-a746-9c49c24021b5.png';
import commitmentDesktop from '../assets/images/commitment-desktop.png';
import repairsDesktop from '../assets/images/repairs-desktop.png';
import circularProductLogoBlack from '../assets/images/circular_product_WM-black.png';
import circularProductLogoWhite from '../assets/images/circular_product_WM-white.png';

export const Sustainability = () => {
  //const { bannerImages } = useSelector((state) => state.bookConsultation);
  //const { ranges, rangesLoading } = useSelector((state) => state.ranges);
  const { width } = useWindowSize();
  const isSmall = width < bpSmall;
  const dispatch = useDispatch();
  const [loading, isLoading] = useState(true);
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    dispatch(fetchRanges());
    dispatch(fetchCollaborators());
    dispatch(fetchProducts({}));
    dispatch(fetchBookConsultation());

    async function fetchPage() {
      const page = await getPage(urls['page'], 'circular-product');
      setPageData(page.acf);
      isLoading(false);
    }

    fetchPage();
  }, [dispatch]);

  if (loading) return <Loading />;

  const faqsArray = pageData.q_a.map((qa) => {
    return {
      answer: '<p>' + qa.answer + '</p>',
      hasButton: false,
      link: null,
      question: qa.question,
    };
  });

  return (
    <ErrorBoundary>
      <Header theme={THEME_DARK} gradient />
      <SimpleHero
        pageName={PAGE_SUSTAINABILITY}
        theme={THEME_DARK}
        desktop={pageData.hero.image}
        mobile={pageData.hero.image}
        tagline={circularProductLogoWhite}
        title="beautifully lit,<br> for life"
        //smallScreen={}
      />
      <LatticeBlocks
        leftSideStyle="realise-content"
        leftSideStyleInline={{
          color: 'black',
          background: `url('') no-repeat`,
          backgroundColor: 'white',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
        leftSide={
          <>
            <h3 style={{ marginTop: '100px', padding: '40px' }}>
              {pageData.intro.text_1}
            </h3>
            <img alt="" src={infinityLoopProduct} />
            <img className="mobile-only" alt="" src={mobilePinkMountain} />
          </>
        }
        rightSide={
          <>
            <img
              alt=""
              src={mountainImagePeachDesktop}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <p style={{ padding: '60px 30px' }}>
              {pageData.intro.image_caption}
            </p>
          </>
        }
        rightSideBackground=""
        rightSideStyle="right-side-content--hidesecond"
        rightHideMobile={true}
        realiseStyle="realise--reverse"
      ></LatticeBlocks>
      <GenericSection>
        <div className="container">
          <h3 style={{ maxWidth: '1190px', padding: '0 100px 0 70px' }}>
            {pageData.intro.text_2}
          </h3>
        </div>
      </GenericSection>

      <SectionWithBackground
        theme={THEME_DARK}
        sectionName="teting"
        title="testing"
        subDescription="testing"
        link={ROUTE_SUSTAINABILITY}
      />
      <section>
        <CircularInteractive
          point1={pageData.interactive_module.point_1}
          point2={pageData.interactive_module.point_2}
          point3={pageData.interactive_module.point_3}
          point4={pageData.interactive_module.point_4}
        ></CircularInteractive>
      </section>
      <section className="" style={{ paddingTop: '100px' }}>
        <SectionWithPortrait>
          <h3
            style={{
              padding: '0 100px 0 70px',
              maxWidth: '864px',
            }}
          >
            {pageData.image_and_text.description}
          </h3>
          <PortraitImageDiv>
            <span></span>
            <Callout
              imageDesktop={pageData.image_and_text.image}
              imageMobile={pageData.image_and_text.image}
            />
          </PortraitImageDiv>
        </SectionWithPortrait>
      </section>

      <section style={{ paddingTop: '100px' }}>
        <div className="container">
          <SlideUp>
            <ImageAndText
              sectionName={SECTION_CIRCULAR_REVERSE}
              title={pageData.image_and_text_1.title}
              image={pageData.image_and_text_1.image}
              mobileImage={pageData.image_and_text_1.image_mobile}
              text={pageData.image_and_text_1.description}
            />
          </SlideUp>
        </div>
      </section>

      <section>
        <div className="container">
          <SlideUp>
            <ImageAndText
              sectionName={SECTION_CIRCULAR}
              title=""
              tagline={circularProductLogoBlack}
              image={pageData.image_and_text_2.image}
              text={pageData.image_and_text_2.text}
              hideBorder={true}
            ></ImageAndText>
          </SlideUp>
        </div>
      </section>

      <SlideUp>
        <SectionWithBackground
          theme={THEME_DARK}
          sectionName={BANNER_NAME_GET_IN_TOUCH_SUSTAINABILITY}
          description={pageData.title_with_bg_image.title}
          link={ROUTE_SUSTAINABILITY}
          image={pageData.title_with_bg_image.background_image}
        />
      </SlideUp>

      <LatticeBlocks
        noMinHeight={true}
        leftSideStyle="realise-content"
        leftSideStyleInline={{
          color: 'black',
          background: `url('${commitmentDesktop}') no-repeat`,
          backgroundColor: 'white',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
        leftSide={<></>}
        rightSide={
          <div
            className="text-content"
            style={{ padding: '50px 41px 30px 33px' }}
          >
            <h2>{pageData.lattice_blocks.title}</h2>
            <p>{pageData.lattice_blocks.description}</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={pageData.lattice_blocks.button_link}
            >
              <Button
                theme={THEME_DARK}
                label={pageData.lattice_blocks.button_title}
              />
            </a>
          </div>
        }
        rightSideBackground=""
        rightSideStyle="realise-content"
        rightSideStyleInline={{
          color: 'black',
          backgroundColor: '#f2d9cf',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
        realiseStyle="realise"
      ></LatticeBlocks>

      <section style={{ marginTop: '12%' }}>
        <div className="container">
          <div
            style={{
              marginBottom: '85px',
              maxWidth: '1190px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <h2 style={{ margin: '30px' }}>{pageData.q_a_title}</h2>
          </div>
          <SlideUp>
            <FaqsDropdown
              data={[
                {
                  category: 'PRODUCT',
                  faqs: faqsArray,
                },
              ]}
            />
          </SlideUp>
        </div>
      </section>

      <section>
        <SlideUp>
          <SectionWithBackground
            image={isSmall ? repairsDesktop : repairsDesktop}
            theme={THEME_DARK}
            sectionName={BANNER_NAME_NEED_REPAIRS}
            description={pageData.cta_with_bg_image.title}
            subDescription={pageData.cta_with_bg_image.description}
            cta={pageData.cta_with_bg_image.button_title}
            link={ROUTE_CONTACT}
          />
        </SlideUp>
      </section>

      <Footer />
    </ErrorBoundary>
  );
};
