import { FAQS_REQUEST, FAQS_RECEIVED } from 'redux/actions/faqs';

const initialState = {
  hasError: false,
  faqs: {},
  loading: true,
};

export default function faqs(state = initialState, action) {
  switch (action.type) {
    case FAQS_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        faqs: {
          ...state.faqs,
        },
        loading: true,
      });
    case FAQS_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        faqs: {
          ...state.faqs,
          ...action.faqs,
        },
        loading: false,
      });
    default:
      return state;
  }
}
