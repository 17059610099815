import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

export const PeopleHero = ({ description, imageOne, imageTwo }) => {
  const classes = classNames({
    [`${styles['people-hero']}`]: true,
    [`container`]: true,
  });

  return (
    <div className={classes}>
      <h1>people</h1>
      <div className={styles.content}>
        <p>{description}</p>
        <div>
          <img src={imageOne} alt="People interacting at work" />
        </div>
        <div>
          <img src={imageTwo} alt="People interacting at work" />
        </div>
      </div>
    </div>
  );
};

PeopleHero.defaultProps = {
  imageOne: "https://via.placeholder.com/625x406",
  imageTwo: "https://via.placeholder.com/460x406/999999"
}

PeopleHero.propTypes = { 
  description: PropTypes.string,
  imageOne: PropTypes.string,
  imageTwo: PropTypes.string,
};