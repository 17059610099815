export const descriptions = {
  or: 'General use, 98 CRI',
  nt: 'Independent colour and output, 1800K to 4000K, 2700K to 6500K',
  nh:
    'Our highest quality light source, Flattering Provence light, 98 CRI / Rf98 / Rg100',
  oh: 'General use, 98 CRI',
  wd: 'Warms gently as it dims, 3000K warming to 1800K',
  lo: 'Low height for low voids',
  ho: 'Designed for maximum light output, 85 CRI',
  100: 'Designed for low level lighting, Low power consumption with high lumen impact, 90 CRI',
  nl:
    'Our highest quality light source, Flattering Provence light, 98 CRI / Rf98 / Rg100',
  ne:
    'Our highest quality light source, Flattering Provence light, 98 CRI / Rf98 / Rg100',
  oe: 'General use, 98 CRI',
  nu: 'origin natural ultra output',
  ou: 'origin ultra output',
};
