import React from 'react';
import classNames from 'classnames';
import { CodeAndLabels } from 'components/quick-spec/code-and-labels';
import styles from './style.module.scss';

export const SpecBar = ({
  data,
  toggleSpec,
  setToggleSpec,
  setCloseSpec,
  productName,
}) => {
  const barClasses = classNames({
    [`${styles['spec-bar']}`]: true,
    [`${styles.minimized}`]: !toggleSpec,
  });

  const containerClasses = classNames({
    [`${styles.flex}`]: true,
    container: true,
  });

  return (
    <div className={barClasses}>
      <div className={containerClasses}>
        <h3>
          {productName} {toggleSpec ? '| summary' : ''}
        </h3>
        {!toggleSpec && (
          <div className={styles['code-container']}>
            <CodeAndLabels data={data} toggleSpec={toggleSpec} />
          </div>
        )}
        <div className={styles.flex}>
          {!toggleSpec && (
            <span className={styles['bar-span']}>spec summary</span>
          )}
          <button onClick={() => setToggleSpec(!toggleSpec)} />
          {toggleSpec && <button onClick={() => setCloseSpec(true)} />}
        </div>
      </div>
    </div>
  );
};
