import { HAS_SEARCHED } from 'redux/actions/search';

const initialState = {
  hasSearched: false,
  searchIsOpen: false,
};

export default function search(state = initialState, action) {
  switch (action.type) {
    case HAS_SEARCHED:
      return Object.assign({}, state, {
        hasSearched: action.value,
        searchIsOpen: true,
      });
    default:
      return state;
  }
}
