export const settingsLandscapeSmall = {
  infinite: false,
  speed: 500,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  draggable: true,
  swipeToSlide: true,
  variableWidth: true,
  outerEdgeLimit: false,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 740,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const settingsFeatured = {
  infinite: false,
  speed: 500,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  draggable: false,
  variableWidth: true,
  outerEdgeLimit: false,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        draggable: true,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 670,
      settings: {
        infinite: true,
        draggable: true,
        slidesToShow: 1,
      },
    },
  ],
};

export const settingsSquareSmall = {
  infinite: false,
  speed: 800,
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  swipeToSlide: true,
  draggable: true,
  variableWidth: true,
  outerEdgeLimit: false,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
