import {
  SUBMIT_CONTACT_US_REQUEST,
  SUBMIT_CONTACT_US_SUCCESS,
  SUBMIT_CONTACT_US_ERROR,
} from 'redux/actions/contact-form';

const initialState = {
  success: false,
  loading: false,
  hasError: false,
};

export default function contactForm(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_CONTACT_US_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        hasError: false,
        success: false,
      });
    case SUBMIT_CONTACT_US_SUCCESS:
      return Object.assign({}, state, {
        success: true,
        loading: false,
        hasError: false,
      });
    case SUBMIT_CONTACT_US_ERROR:
      return Object.assign({}, state, {
        success: false,
        loading: false,
        hasError: true,
      });
    default:
      return state;
  }
}
