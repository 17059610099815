import React from 'react';

export const Ies = props => (
  <svg width="24" height="34" {...props}>
    <path
      d="M1.49 1.358a.173.173 0 00-.17.176v30.91c0 .098.077.176.17.176h21.023c.093 0 .17-.078.17-.176V8.67a.18.18 0 00-.049-.121l-6.886-7.14a.165.165 0 00-.118-.05H1.49zM22.523 34H1.49C.667 34 0 33.309 0 32.454V1.544C0 .692.667 0 1.49 0h14.137c.39.003.765.165 1.04.453l6.896 7.124c.275.287.432.674.437 1.078v23.79c.006.852-.657 1.548-1.48 1.555h-.007.01zM16.632 7.627h5.116l-5.116-5.303v5.303z"
      fill="#393E5B"
    />
    <path
      d="M7.876 30h-1.28v-5.672h1.28V30zm4.824 0H9.1v-5.672h3.592v1.192h-2.328v1.08h2.112v1.112h-2.112V28.8H12.7V30zm4.968-4.28l-1.136.32c-.04-.264-.256-.712-.944-.712-.472 0-.776.296-.776.608 0 .272.168.472.56.544l.76.144c1.08.2 1.64.896 1.64 1.704 0 .888-.744 1.792-2.096 1.792-1.568 0-2.24-1.016-2.32-1.816l1.16-.28c.048.52.4.976 1.176.976.496 0 .8-.24.8-.592 0-.288-.232-.496-.608-.568l-.776-.144c-.96-.184-1.544-.824-1.544-1.664 0-1.072.944-1.824 2.032-1.824 1.416 0 1.952.848 2.072 1.512z"
      fill="#393E5B"
      fillRule="nonzero"
    />
    <path
      d="M22.62 8.996h-6.352c-.525-.002-.95-.44-.957-.984V1.419c-.01-.547.409-1 .937-1.012.262-.006.515.1.699.293L23.3 7.294c.375.385.377 1.013.005 1.402a.941.941 0 01-.699.293l.015.007z"
      fill="#393E5B"
    />
  </svg>
);
