import React, { useEffect, useState } from 'react';
import { ROUTE_HOME } from 'constants/router-links';
import { bpSmall } from 'constants/breakpoints';
import classNames from 'classnames';
import { useWindowSize } from 'hooks/use-window-size';
import { getRandomValueFromArray } from 'modules/get-random-value-from-array';
import { LinkFromCms } from 'components/link-from-cms';
import styles from './style.module.scss';

export const Notification = ({ notification, pathname }) => {
  const [notificationContent, setNotificationContent] = useState(
    notification[0]
  );
  const { width } = useWindowSize();
  const smallScreen = width < bpSmall;

  const classes = classNames({
    [`${styles.notification}`]: true,
    [`${styles.hide}`]: pathname !== ROUTE_HOME && smallScreen,
  });

  useEffect(() => {
    if (notification) {
      setNotificationContent(getRandomValueFromArray(notification));
    } else {
      return;
    }
  }, [notification, notificationContent]);

  const { theme, description, button } = notificationContent;

  return (
    <div role="banner" className={classes}>
      <div className={styles[theme]}>
        <p>{description}</p>
        <LinkFromCms url={button.url} title={button.title} />
      </div>
    </div>
  );
};
