import urls from 'constants/urls.json';
import { getPartnerTypes, getPartner } from 'modules/api';

export const PARTNERS_REQUEST = 'PARTNERS_REQUEST';
export const PARTNERS_RECEIVED = 'PARTNERS_RECEIVED';

export const requestPartners = () => {
  return { type: PARTNERS_REQUEST };
};

export const receivePartners = (partners) => {
  return { type: PARTNERS_RECEIVED, partners };
};

export const fetchPartners = () => {
  return async (dispatch) => {
    dispatch(requestPartners());

    const partnerTypesResponse = await getPartnerTypes(urls['partner-types']);

    const partnerTypes = partnerTypesResponse.map(async (partnerType) => {
      const partnersResponse = await getPartner(
        urls['partners'],
        partnerType.id
      );
      return {
        name: partnerType.name,
        partners: partnersResponse,
      };
    });
    Promise.all(partnerTypes).then((data) => dispatch(receivePartners(data)));
  };
};
