import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Downloads } from 'components/downloads';
import { SpecCodeNew } from 'components/spec-code-new';

export const SpecCodeAndDownloads = ({ activeEngine, smallScreen }) => {
  const { data } = useSelector((state) => state.product);
  const [downloadsDisabled, setDownloadsDisabled] = useState(false);
  const productDownloads = useSelector(
    (state) => state.productDownloads.productDownloads
  );

  return (
    <>
      <SpecCodeNew
        productOptions={data.specification_code}
        disabledOptions={activeEngine?.specification_code}
        setDownloadsDisabled={setDownloadsDisabled}
        smallScreen={smallScreen}
      />
      <Downloads
        data={productDownloads}
        disabled={downloadsDisabled || isEmpty(productDownloads)}
      />
    </>
  );
};
