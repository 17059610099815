import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Tick } from 'components/icons';
import { cleanSpecCode } from 'redux/actions';
import { SelectedEngine } from 'components/selected-engine';
import { descriptions } from 'components/engine-selector/descriptions';
import { VariantAnchor } from 'components/engine-selector/variant-anchor';
import styles from './style.module.scss';

export const engineSectionRef = React.createRef();

export const Selector = ({
  options,
  variantName,
  productName,
  activeEngine,
  setActiveEngine,
  singleVariant,
}) => {
  const [scrollTo, setScrollTo] = useState(false);
  const dispatch = useDispatch();

  const sectionClasses = classNames({
    [`${styles['engine-selector']}`]: true,
    [`section`]: true,
  });

  const optionsClasses = classNames({
    [`${styles.options}`]: true,
    [`${styles.centered}`]: options.length < 3,
  });

  return (
    <>
      <section className={sectionClasses} ref={engineSectionRef}>
        <div className={`${styles['main-wrapper']} container`}>
          <VariantAnchor
            productName={productName}
            variantName={variantName}
            singleVariant={singleVariant}
          />
          <div className={styles.cta}>
            <h2>choose your engine</h2>
          </div>
          <div className={optionsClasses}>
            {options.map((option, index) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    setActiveEngine(option);
                    setScrollTo(true);
                    dispatch(cleanSpecCode());
                  }}
                  className={
                    activeEngine.engine_type.value === option.engine_type.value
                      ? `${styles.active} ${styles.engine}`
                      : `${styles.engine}`
                  }
                >
                  <div>
                    <Tick />
                    <p>{option.engine_type.label}</p>
                    <ul>
                      {descriptions[option.engine_type.value]
                        .split(',')
                        .map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                    </ul>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </section>
      <SelectedEngine
        scrollTo={scrollTo}
        selected={activeEngine.engine_type.label}
        description={descriptions[activeEngine.engine_type.value]}
      />
    </>
  );
};
