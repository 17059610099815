import React from 'react';

export const Ldt = props => (
  <svg width="24" height="34" {...props}>
    <path
      d="M1.49 1.358a.173.173 0 00-.17.176v30.91c0 .098.077.176.17.176h21.023c.093 0 .17-.078.17-.176V8.67a.18.18 0 00-.049-.121l-6.886-7.14a.165.165 0 00-.118-.05H1.49zM22.523 34H1.49C.667 34 0 33.309 0 32.454V1.544C0 .692.667 0 1.49 0h14.137c.39.003.765.165 1.04.453l6.896 7.124c.275.287.432.674.437 1.078v23.79c.006.852-.657 1.548-1.48 1.555h-.007.01zM16.632 7.627h5.116l-5.116-5.303v5.303z"
      fill="#393E5B"
    />
    <path
      d="M8.628 30H4.916v-5.672H6.18v4.464h2.448V30zm2.08-1.192h.736c.872 0 1.584-.504 1.584-1.64s-.712-1.648-1.584-1.648h-.736v3.288zM11.492 30H9.444v-5.672H11.5c1.704 0 2.848 1.088 2.848 2.84 0 1.752-1.144 2.832-2.856 2.832zm8.056-4.464h-1.752V30h-1.264v-4.464h-1.744v-1.208h4.76v1.208z"
      fill="#393E5B"
      fillRule="nonzero"
    />
    <path
      d="M22.62 8.996h-6.352c-.525-.002-.95-.44-.957-.984V1.419c-.01-.547.409-1 .937-1.012.262-.006.515.1.699.293L23.3 7.294c.375.385.377 1.013.005 1.402a.941.941 0 01-.699.293l.015.007z"
      fill="#393E5B"
    />
  </svg>
);
