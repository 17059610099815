import React from 'react';
import styles from 'components/check-box/style.module.scss';

export const Elliptical = props => (
  <svg
    className={styles['filter-icon']}
    data-name="Layer 1"
    viewBox="0 0 57 57"
    {...props}
  >
    <circle cx={28.5} cy={28.5} r={27} fill="none" strokeWidth={2} />
    <ellipse cx={28.5} cy={28.5} rx={16} ry={6} />
  </svg>
);
