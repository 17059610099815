import React from 'react';
//import { Link } from 'react-router-dom';
//import { Button } from 'components/buttons';
import { FloatingLogo } from 'components/floating-logo';
import { SlideUp } from 'components/animations';
import { SECTION_REALISE } from 'constants/section-name';
//import { THEME_DARK } from 'constants/color-theme';
import { SIZE_SMALL } from 'constants/sizes';
import styles from './style.module.scss';

//import { Callout } from 'components/callout';

export const LatticeBlocks = ({
  videoDesktop,
  videoMobile,
  videoImageDesktop,
  videoImageMobile,
  videoCaption,
  enhanceImage,
  productCollection,
  bestLitEffect,
  circularImageDesktop,
  circularImageMobile,
  circularProduct,
  leftSide,
  leftSideStyle,
  leftSideStyleInline,
  leftSideBackground,
  leftSideNegativeMargin,
  rightSide,
  rightSideStyle,
  rightSideStyleInline,
  rightSideBackground,
  noMinHeight,
  realiseStyle,
  rightSideNegativeMargin,
}) => {
  return (
    <section className={`${styles['realise-and-enhance']}`}>
      <div className={`${styles.flex} ${styles.wrapper} container`}>
        <SlideUp>
          <div className={`${styles.flex} ${styles[realiseStyle]}`}>
            <div
              className={`${styles['realise-left-side']}`}
              style={leftSideStyleInline}
            >
              <div className={styles[leftSideStyle]}>{leftSide}</div>
            </div>
            {noMinHeight && (
              <div
                className={`${styles['negative-margin']} ${styles['realise-right-side']} ${styles['no-min-height']}`}
                style={rightSideStyleInline}
              >
                <FloatingLogo section={SECTION_REALISE} size={SIZE_SMALL} />
                <div className={styles[rightSideStyle]}>{rightSide}</div>
              </div>
            )}
            {!noMinHeight && (
              <div
                className={`${styles['negative-margin']} ${styles['realise-right-side']}`}
                style={rightSideStyleInline}
              >
                <FloatingLogo section={SECTION_REALISE} size={SIZE_SMALL} />
                <div className={styles[rightSideStyle]}>{rightSide}</div>
              </div>
            )}
          </div>
        </SlideUp>

        {/*
            <SlideUp>
              <div className={`${styles.flex} ${styles.enhance}`}>
                <div
                  className={`${styles['negative-margin']} ${styles['enhance-left-side']}`}
                >
                  <div className={styles['enhance-content']}>
                    <h2>{bestLitEffect.title}</h2>
                    <p>{bestLitEffect.description}</p>
                    <Link to={ROUTE_PRODUCTS}>
                      <Button
                        theme={THEME_DARK}
                        label={bestLitEffect.button_title}
                      />
                    </Link>
                  </div>
                </div>
                <FloatingLogo
                  section={SECTION_ENHANCE}
                  size={SIZE_SMALL}
                  offsetHelper={1200}
                />
                <div
                  className={styles['enhance-right-side']}
                  style={{
                    background: `url(${enhanceImage}) no-repeat`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                  }}
                />
              </div>
            </SlideUp>
          */}
      </div>
    </section>
  );
};
