import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { submitGetInTouch } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Button } from 'components/buttons';
import { SelectedCheckbox } from 'components/icons';
import { CheckboxDefault } from 'components/check-box/checkbox-default';
import {
  REGEX_COMPANY_NAME,
  REGEX_EMAIL,
  REGEX_NAME,
  REGEX_PHONE,
  REGEX_SURNAME,
} from 'constants/form-regex';
import { SIZE_DEFAULT, SIZE_SMALL } from 'constants/sizes';
import { THEME_DARK } from 'constants/color-theme';
import { LABEL_SUBMIT, LABEL_GO } from 'constants/labels';
import styles from './style.module.scss';

export const GetInTouchForm = ({ formContext }) => {
  const { register, handleSubmit, watch, errors } = useForm();
  const watchFields = watch([
    'name',
    'surname',
    'email',
    'phone',
    'company',
    'textarea',
  ]);
  const [goToNextStep, setGoToNextStep] = useState(false);
  const [contactIsChecked, togglecontactIsChecked] = useState(false);
  const [newsletterIsChecked, toggleNewsletterIsChecked] = useState(false);
  const [showBtn, setShowBtn] = useState(true);
  const { loading } = useSelector((state) => state.signUpForm);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const context = { context: formContext };
    dispatch(submitGetInTouch({ ...data, ...context }));
  };

  const handleContactCheckbox = () => {
    togglecontactIsChecked(!contactIsChecked);
  };

  const handleNewsletterCheckbox = () => {
    toggleNewsletterIsChecked(!newsletterIsChecked);
  };

  const nextStep = () => {
    setGoToNextStep(true);
    setShowBtn(false);
  };

  const classes = classNames({
    [`${styles['get-in-touch-form']}`]: true,
    [`${styles.fullform}`]: goToNextStep,
  });

  const emailClasses = classNames({
    [`${styles.email}`]: !goToNextStep,
    [`${styles.fullform}`]: goToNextStep && !errors.email,
    [`has-errors`]: errors.email,
  });

  const newsClasses = classNames({
    [`${styles.newsletter}`]: true,
    [`${styles.checked}`]: newsletterIsChecked,
  });

  const contactClasses = classNames({
    [`${styles.contact}`]: true,
    [`${styles.checked}`]: contactIsChecked,
  });

  const nameIsValid = watchFields.name !== undefined && watchFields.name !== '';

  const phoneIsValid =
    watchFields.phone !== undefined &&
    watchFields.phone !== '' &&
    REGEX_PHONE.test(watchFields.phone);

  // A user has entered their phone number but it is invalid
  const phoneEnteredInvalid =
    watchFields.phone !== undefined &&
    watchFields.phone !== '' &&
    !REGEX_PHONE.test(watchFields.phone);

  const surnameIsValid =
    watchFields.surname !== undefined && watchFields.surname !== '';

  const emailIsValid =
    watchFields.email !== undefined &&
    watchFields.email !== '' &&
    REGEX_EMAIL.test(watchFields.email);

  const emailEnteredInvalid =
    watchFields.email !== undefined &&
    watchFields.email !== '' &&
    !REGEX_EMAIL.test(watchFields.email);

  return (
    <>
      <div className={classes}>
        {!goToNextStep && <h2>book a consultation</h2>}
        <p>Enter your details to book a 10-minute video consultation.</p>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          {goToNextStep && (
            <>
              <div className={styles.name}>
                <input
                  ref={register({ pattern: REGEX_NAME })}
                  className={errors.name ? 'has-error' : ''}
                  type="text"
                  name="name"
                  placeholder="*first name"
                />
                {errors.name && (
                  <small className="error">
                    please enter a valid first name
                  </small>
                )}
              </div>
              <div className={styles.surname}>
                <input
                  ref={register({ pattern: REGEX_SURNAME })}
                  className={errors.surname ? 'has-error' : ''}
                  type="text"
                  name="surname"
                  placeholder="*surname"
                />
                {errors.surname && (
                  <small className="error">please enter a valid surname</small>
                )}
              </div>

              <div className={styles.company}>
                <input
                  ref={register({ pattern: REGEX_COMPANY_NAME })}
                  className={errors.company ? 'has-error' : ''}
                  type="text"
                  name="company"
                  placeholder="company"
                />
                {errors.company && (
                  <small className="error">
                    please enter a valid company name
                  </small>
                )}
              </div>
            </>
          )}

          <div>
            <input
              ref={register({ required: true, pattern: REGEX_PHONE })}
              className={emailClasses}
              type="phone"
              name="phone"
              placeholder="*phone number"
            />
            {errors.phone ||
              (phoneEnteredInvalid && (
                <small className="error">
                  please enter a valid phone number (with intl. prefix), e.g
                  +441234567890
                </small>
              ))}
          </div>

          <div>
            <input
              ref={register({ required: true, pattern: REGEX_EMAIL })}
              className={emailClasses}
              type="email"
              name="email"
              placeholder={
                goToNextStep ? '*name@company.com' : '*email address'
              }
            />
            {errors.email ||
              (emailEnteredInvalid && (
                <small className="error">please enter a valid e-mail</small>
              ))}
          </div>

          {goToNextStep && (
            <>
              <div className={newsClasses}>
                <label htmlFor={'newsletter'}>
                  <CheckboxDefault
                    register={register}
                    icon={<SelectedCheckbox />}
                    id="newsletter"
                    name="newsletter"
                    checked={newsletterIsChecked}
                    value={newsletterIsChecked}
                    onChange={handleNewsletterCheckbox}
                  />
                </label>
                <small className={styles.terms}>
                  Yes I would like to receive Orluna's news, information on new
                  products, and promotions, and agree to Orluna's{' '}
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy
                  </a>
                  . You may unsubscribe at any time.
                </small>
              </div>

              <div className={contactClasses}>
                <label htmlFor={'contact'}>
                  <CheckboxDefault
                    register={register}
                    icon={<SelectedCheckbox />}
                    id="contact"
                    name="contact"
                    checked={contactIsChecked}
                    value={contactIsChecked}
                    onChange={handleContactCheckbox}
                  />
                </label>
                <small className={styles.terms}>
                  Please tick this box, if you agree for us to contact you.
                  We’ll only use the details you give us to arrange a call back
                  from our sales team. Read our{' '}
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy
                  </a>{' '}
                  for more information about how we use personal data.
                </small>
              </div>
            </>
          )}
          {showBtn && (
            <span className={`${styles['go-btn']}`}>
              <Button
                size={SIZE_SMALL}
                theme={THEME_DARK}
                label={LABEL_GO}
                disabled={emailIsValid && phoneIsValid ? false : true}
                handleClick={nextStep}
              />
            </span>
          )}
          {goToNextStep && (
            <div>
              <Button
                size={SIZE_DEFAULT}
                theme={THEME_DARK}
                label={LABEL_SUBMIT}
                type="submit"
                disabled={
                  !loading &&
                  nameIsValid &&
                  phoneIsValid &&
                  surnameIsValid &&
                  (newsletterIsChecked || contactIsChecked)
                    ? false
                    : true
                }
                handleClick={handleSubmit}
              />
              <small>
                If you’ve requested a call back, we'll be in touch as soon as we
                can. Our office hours are Monday to Friday, 9am until 5pm GMT.
              </small>
            </div>
          )}
        </form>
      </div>
    </>
  );
};
