import { magnifyingGlass, closeWhite } from 'components/search/icons';

export const customStyles = {
  option: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: isFocused ? '#e4e4e4' : '#f1f1f1',
    color: '#555762',
    transition: 'background-color .3s',
    paddingLeft: 33,
    // 1480px instead 1440px because of the side padding
    maxWidth: 1480,
    margin: '0 auto',

    '@media only screen and (min-width: 1100px)': {
      paddingLeft: 20,
    },
  }),
  container: (provided) => ({
    ...provided,

    // menu wrapper
    '> :nth-of-type(2)': {
      left: 0,
    },
  }),
  menu: () => ({
    backgroundColor: '#f1f1f1',
    marginLeft: 0,
    height: '100vh',

    '@media only screen and (min-width: 1100px)': {
      backgroundColor: '#f1f1f1',
    },
  }),
  menuList: () => ({
    height: '100%',
    overflowY: 'scroll',
  }),
  control: () => ({
    backgroundColor: '#f1f1f1',
    borderBottom: 'none',
    display: 'flex',
    flexDirection: 'column-reverse',
    height: '100%',
    padding: '8px 0 10px 0',
    maxHeight: 75,
    // 1480px instead 1440px because of the side padding
    maxWidth: 1480,
    margin: '0 auto',

    '@media only screen and (min-width: 1100px)': {
      justifyContent: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center',
    },
  }),
  placeholder: (provided, { isFocused }) => ({
    ...provided,
    fontSize: 18,
    alignItems: 'center',
    display: 'flex',

    ':before': {
      content: `url(${magnifyingGlass})`,
      display: isFocused ? 'none' : 'block',
      marginRight: 16,
      opacity: 0.6,
      height: 24,
      width: 24,

      '@media only screen and (min-width: 740px)': {
        height: 27,
        width: 27,
      },
    },
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    padding: '4px 21px 4px 9px',
    position: 'relative',
    cursor: 'pointer',

    '@media only screen and (min-width: 1100px)': {
      padding: '4px 18px 5px 27px',
    },

    svg: {
      opacity: 0,
    },

    ':before': {
      content: `url(${closeWhite})`,
      display: 'block',
      position: 'absolute',
      lineHeight: '15px',
      height: 14,
      width: 14,
      transition: 'opacity 0.3s',
    },

    ':hover': {
      ':before': {
        opacity: 0.3,
      },
    },
  }),
  multiValue: (provided) => {
    return {
      ...provided,
      color: 'white',
      backgroundColor: '#555762',
      padding: '4px 0 7px 20px',
      borderRadius: 25,
      alignItems: 'center',

      '@media only screen and (min-width: 1100px)': {
        padding: '4px 0 5px 26px',
      },
    };
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      color: 'white',
      fontSize: 18,
      lineHeight: '23px',
      textTransform: 'lowercase',
      padding: 0,
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: 'nowrap',
    padding: '2px 8px 2px 20px',

    '@media only screen and (min-width: 1100px)': {
      paddingLeft: 20,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    textAlign: 'left',
    fontSize: 17,
    lineHeight: '23px',
    paddingLeft: 33,
    paddingTop: 29,
    backgroundColor: 'white',
    width: '100%',
    height: 'calc(100vh - 159px)',

    span: {
      textDecoration: 'underline',
    },

    p: {
      maxWidth: 862,
    },

    '@media only screen and (min-width: 1100px)': {
      backgroundColor: '#f1f1f1',
      fontSize: 24,
      letterSpacing: 1.07,
      lineHeight: '30px',
      height: 'calc(100vh - 187px)',
      paddingLeft: 100,
      paddingTop: 70,

      p: {
        ':last-of-type': {
          marginTop: 30,
        },
      },
    },
  }),
};
