import { TOGGLE_MENU, CLOSE_MENU } from 'redux/actions/menu';

const initialState = {
  menuIsOpen: false,
};

export default function contactInformation(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MENU:
      return Object.assign({}, state, {
        menuIsOpen: !state.menuIsOpen,
      });
    case CLOSE_MENU:
      return Object.assign({}, state, {
        menuIsOpen: false,
      });
    default:
      return state;
  }
}
