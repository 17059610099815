import React from 'react';
import { useSelector } from 'react-redux';
import { SEARCH } from 'constants/view';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './style.module.scss';

export const PageWrapper = ({ children, pageName, view }) => {
  const { rangeNavVisibility } = useSelector((state) => state.rangeNavigation);
  const { hasSearched } = useSelector((state) => state.search);

  const classes = classNames({
    [`${styles['page-wrapper']}`]: true,
    [`${styles[`page-${pageName}`]}`]: true,
    [`${styles['range-nav-on']}`]: rangeNavVisibility,
    [`${styles['remove-margin-top']}`]: hasSearched || view === SEARCH,
  });

  return <div className={classes}>{children}</div>;
};

PageWrapper.propTypes = {
  pageName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
