import urls from 'constants/urls.json';
import { getBookConsultation } from 'modules/api';

export const BANNER_IMAGES_REQUEST = 'BANNER_IMAGES_REQUEST';
export const BANNER_IMAGES_RECEIVED = 'BANNER_IMAGES_RECEIVED';

export const requestBookConsultation = () => {
  return { type: BANNER_IMAGES_REQUEST };
};

export const receivedBookConsultation = (images) => {
  return { type: BANNER_IMAGES_RECEIVED, images };
};

export const fetchBookConsultation = () => {
  return async (dispatch) => {
    dispatch(requestBookConsultation());

    const BookConsultation = await getBookConsultation(
      urls['book-consultation']
    );

    dispatch(receivedBookConsultation(BookConsultation));
  };
};
