import {
  PRODUCT_REQUEST,
  PRODUCT_RECEIVED,
  SET_ACTIVE_ENGINE,
  SET_ACTIVE_VARIANT,
} from 'redux/actions/product';

const initialState = {
  hasError: false,
  data: {},
  loading: true,
  activeVariant: null,
  activeEngine: null,
};

export default function product(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        activeEngine: null,
        activeVariant: null,
        data: {
          loading: true,
        },
      });
    case PRODUCT_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        data: {
          ...state.data,
          loading: false,
          ...action.product,
        },
      });
    case SET_ACTIVE_ENGINE:
      return Object.assign({}, state, {
        ...state,
        activeEngine: action.engine,
      });
    case SET_ACTIVE_VARIANT:
      return Object.assign({}, state, {
        ...state,
        activeVariant: action.variant,
      });
    default:
      return state;
  }
}
