import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

export const ProjectHero = ({ image, title, sector, color }) => {
  return (
    <section className={`${styles['project-hero']} section`}>
      <div className={`${styles.wrapper} container`}>
        <div className={styles['image-wrapper']}>
          <img src={image} alt={`${title} ${sector}`} />
          <div className={styles.overlay} />
        </div>
        <div className={styles['text-container']}>
          <div className={styles['text-wrapper']}>
            <h1>{title}</h1>
            <p className={styles[color]}>{sector}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

ProjectHero.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
}