import axios from 'axios';

export const getRangeNavigation = async (endpoint) => {
  try {
    const url = [process.env.REACT_APP_CMS_URL, endpoint].join('/');
    const response = await axios.get(url);
    const data = await response.data;

    return data;
  } catch (error) {
    //Custom 404 error handling when user goes on non-existant products
    if (error.response.status === 404) {
      const data = error.response.data;
      return data;
    }
    throw error;
  }
};
