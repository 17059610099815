import urls from 'constants/urls.json';
import { getProjects } from 'modules/api';

export const PROJECTS_REQUEST = 'PROJECTS_REQUEST';
export const PROJECTS_RECEIVED = 'PROJECTS_RECEIVED';

export const requestProjects = () => {
  return { type: PROJECTS_REQUEST };
};

export const receiveProjects = projects => {
  return { type: PROJECTS_RECEIVED, projects };
};

export const fetchProjects = () => {
  return async dispatch => {
    dispatch(requestProjects());

    const projects = await getProjects(urls['projects']);

    dispatch(receiveProjects(projects));
  };
};
