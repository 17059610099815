import { find, flow, get, map } from 'lodash/fp';

/**
 * Get a list of lit effects based on active colour temperatures
 * @param {string} colorTemperature
 * @param {Object[]} colorTemperatures
 * @return {Object[]}
 */
export const getLitEffects = (colourTemperature, colourTemperatures) => {
  return flow(
    find({ colour_temperature: colourTemperature }),
    get('add_lit_effect')
  )(colourTemperatures);
};

/**
 * Get a list of beam angles based on the lit effect
 * @param {string} litEffect
 * @param {Object[]} litEffects
 * @return {Object[]}
 */
export const getBeamAngles = (litEffect, litEffects) => {
  return flow(
    find({ lit_effect: litEffect }),
    get('add_beam_angle')
  )(litEffects);
};

/**
 * Get all colour temperate titles
 * @param {Object[]} colorTemperatures
 * @return {string[]}
 */
export const getColourTemperatureTitles = (colourTemperatures) => {
  return flow(map('colour_temperature'))(colourTemperatures);
};

/**
 * Get a list of beam angles that contain a value in the CMS
 * @param {Object[]} beamAngles
 * @return {Array}
 */
export const getPopulatedBeamAngles = (beamAngles) => {
  return Object.keys(beamAngles[0]).filter(
    (angle) => beamAngles[0][angle].length > 0
  );
};
