import React from 'react';
import { keys } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFilters, resetFilters } from 'redux/actions/products';
import { MainFilterOptions } from 'components/filters/main-filters/main-filter-options';
import { SingleFilter } from 'components/filters/main-filters/single-filter';
import { SubFilters } from 'components/filters/sub-filters';

import {
  Adjustable,
  Bespoke,
  CrispBeam,
  Downlight,
  Elliptical,
  Fixed,
  FloorWasher,
  KitForm,
  Linear,
  NarrowBeam,
  SoftBeam,
  SurfaceAndTrack,
  Twin,
  Uplight,
  WallWash,
  Waterproof,
} from 'components/icons';

import styles from './style.module.scss';

export const MainFilters = ({ availableTerms }) => {
  const dispatch = useDispatch();
  const { mainFiltersOpen } = useSelector((state) => state.products);
  const { filters } = useSelector((state) => state.products);
  const hasASelection = keys(filters).length > 0;

  const handleCloseFilters = () => {
    dispatch(toggleFilters());
  };

  const handleResetFilter = () => {
    dispatch(resetFilters());
  };

  const luminaireTypes = [
    { value: 'uplight', label: 'uplights & shelf', icon: <Uplight /> },
    { value: 'linear', label: 'linear', icon: <Linear /> },
    { value: 'downlight', label: 'downlights', icon: <Downlight /> },
    { value: 'waterproof', label: 'waterproof', icon: <Waterproof /> },
    {
      value: 'surface-and-track',
      label: 'surface & track',
      icon: <SurfaceAndTrack />,
    },
    { value: 'floor-washer', label: 'floor washers', icon: <FloorWasher /> },
    { value: 'wall', label: 'wall washers', icon: <WallWash /> },
  ];

  const litEffects = [
    { value: 'narrow-beam', label: 'narrow beam', icon: <NarrowBeam /> },
    { value: 'soft-beam', label: 'soft beam', icon: <SoftBeam /> },
    { value: 'crisp-beam', label: 'crisp beam', icon: <CrispBeam /> },
    { value: 'wallwash', label: 'wall wash', icon: <WallWash /> },
    { value: 'elliptical', label: 'elliptical', icon: <Elliptical /> },
  ];

  const variants = [
    { value: 'adjustable', label: 'adjustable', icon: <Adjustable /> },
    { value: 'fixed', label: 'fixed', icon: <Fixed /> },
    { value: 'twin', label: 'twin', icon: <Twin /> },
    { value: 'bespoke', label: 'bespoke', icon: <Bespoke /> },
    { value: 'kitform', label: 'kitform', icon: <KitForm /> },
  ];

  if (!mainFiltersOpen) return null;

  return (
    <div className={`${styles['main-filter']} container`}>
      <div className={styles.header}>
        <button
          className={styles.reset}
          disabled={!hasASelection}
          onClick={() => handleResetFilter()}
        >
          reset all filters
        </button>
        <button className={styles.close} onClick={() => handleCloseFilters()} />
      </div>
      <div className={styles['filter-options']}>
        <SingleFilter
          title="Luminaire type"
          options={luminaireTypes}
          name="luminaireType"
        />
        <MainFilterOptions
          title="Lit effect"
          options={litEffects}
          name="lit_effect"
          availableTerms={availableTerms}
        />
        <MainFilterOptions
          availableTerms={availableTerms}
          title="Variant"
          options={variants}
          name="variant"
        />
      </div>
      <SubFilters availableTerms={availableTerms} />
    </div>
  );
};
