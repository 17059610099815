import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/people-grid/style.module.scss';

export const Person = ({ description, image, title, job }) => {
  return (
    <div className={styles.person}>
      <div className={styles.overlay} /> {/* rollover*/}
      <img src={image} alt={`${title}'s portrait`} />
      <div className={styles['description-container']}>
        <h3>{title}</h3>
        <p className={styles.job}>{job}</p>
        <p className={styles.description}>{description}</p>
      </div>
      <span>{title}</span>
    </div>
  );
};

Person.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
};
