import {
  NOTIFICATION_REQUEST,
  NOTIFICATION_RECEIVED,
} from 'redux/actions/notification';

const initialState = {
  hasError: false,
  notification: [],
  notificationLoading: false,
};

export default function notification(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        notification: [...state.notification],
        notificationLoading: true,
      });
    case NOTIFICATION_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        notification: [...state.notification, ...action.notification],
        notificationLoading: false,
      });
    default:
      return state;
  }
}
