import { RANGES_REQUEST, RANGES_RECEIVED } from 'redux/actions/ranges';

const initialState = {
    hasError: false,
    ranges: {},
    rangesLoading: true
};

export default function ranges(state = initialState, action) {
    switch(action.type) {
        case RANGES_REQUEST:
            return Object.assign({}, state, {
                hasError: false,
                ranges: {
                    ...state.ranges
                },
                rangesLoading: true
            });
        case RANGES_RECEIVED:
            return Object.assign({}, state, {
                hasError: false,
                ranges: {
                    ...state.ranges,
                    ...action.ranges
                },
                rangesLoading: false
            });
        default:
            return state;
    }
}