import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchRange,
  fetchRanges,
  fetchRangeNavigation,
  fetchProductsByRange,
  fetchFamiliesByRange,
  setProductsView,
} from 'redux/actions';
import { THEME_SOLID } from 'constants/color-theme';
import { PAGE_RANGE } from 'constants/page-name';
import { GRID, SEARCH } from 'constants/view';
import { ErrorBoundary } from 'components/error';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { Loading } from 'components/loading';
import { Range } from 'components/range';
import { SearchView } from 'components/search-view';
import { PageWrapper } from 'components/page-wrapper';
import { SignUpSection } from 'components/signup-section';

// to be replaced
import { range as options } from 'components/product-range/index.fixtures';

export const RangePage = ({ match }) => {
  const { searchedProducts, view } = useSelector((state) => state.products);
  const { loading, range: data } = useSelector((state) => state.range);
  const { productsByRange } = useSelector((state) => state.productsByRange);
  const dispatch = useDispatch();
  const searchView = view === SEARCH;
  const { range } = match.params;
  const {
    ID,
    title,
    range_hero,
    intro,
    featured_content,
    featured_project,
  } = data;

  useEffect(() => {
    dispatch(fetchRanges());
    dispatch(fetchRange(range));
    dispatch(fetchRangeNavigation());
    if (ID) {
      dispatch(fetchProductsByRange(ID));
      dispatch(fetchFamiliesByRange(ID));
    }

    return () => dispatch(setProductsView(GRID));
  }, [dispatch, range, ID]);

  if (ID) {
    const products = Object.entries(productsByRange);

    let newProducts = products.reduce((accumulator, currentObj) => {
      if (accumulator[currentObj[1].family] === undefined) {
        accumulator[currentObj[1].family] = [];
      }
      accumulator[currentObj[1].family].push(currentObj[1]);

      return accumulator;
    }, {});

    let rangeProducts = { ...options };

    rangeProducts.range.families = newProducts;
  }

  if (loading) return <Loading />;

  return (
    <ErrorBoundary>
      <Header theme={THEME_SOLID} />
      <PageWrapper pageName={PAGE_RANGE} view={view}>
        {!searchView && (
          <Range
            hero={range_hero}
            name={title}
            intro={intro}
            featuredContent={featured_content}
            displayProject={featured_project?.display_featured_project}
            project={featured_project?.project}
            rangeGrid={data}
          />
        )}
        {searchView && <SearchView products={searchedProducts} />}
      </PageWrapper>
      {!searchView && (
        <>
          <SignUpSection />
          <Footer />
        </>
      )}
    </ErrorBoundary>
  );
};
