import React from 'react';
import { useSelector } from 'react-redux';
import { WorkWithUsForm } from 'components/forms/work-with-us-form';
import { ScrollTo } from 'components/scroll-to';
import { FloatingLogo } from 'components/floating-logo';
import styles from './style.module.scss';
import { SIZE_BIG } from 'constants/sizes';
import { SECTION_CONTACT_FORM } from 'constants/section-name';

export const WorkWithUsFormSection = () => {
  const { success, hasError } = useSelector((state) => state.workWithUsForm);

  return (
    <section className={`${styles['work-with-us']} section`}>
      <div className={`${styles.wrapper} container`}>
        <div className={styles.title}>
          <h1>work with us</h1>
          <p>
            We need smart and flexible people to push our vision of innovative
            lighting forward. <br /> Working at Orluna means sharing the
            mission.
          </p>
          <p>Send us your CV in absolute confidence and we’ll be in touch.</p>
        </div>
        <div className={styles.content}>
          <div className={styles['form-wrapper']}></div>
        </div>
        <WorkWithUsForm />
        <FloatingLogo section={SECTION_CONTACT_FORM} size={SIZE_BIG} />
        {success && (
          <div className={styles.confirmation}>
            <p>Thanks for registering your interest in working at Orluna!</p>
            <p>
              We will review your details and get in touch if a suitable
              position is available.
            </p>
          </div>
        )}
        {success && <ScrollTo />}
        {hasError && (
          <div className={styles.error}>
            <p>
              Sorry there was an error submitting the form. Please try again
              later.
            </p>
          </div>
        )}
      </div>
    </section>
  );
};
