import { PARTNERS_REQUEST, PARTNERS_RECEIVED } from 'redux/actions/partners';

const initialState = {
  hasError: false,
  partners: {},
  partnersLoading: true,
};

export default function partners(state = initialState, action) {
  switch (action.type) {
    case PARTNERS_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        partners: {
          ...state.partners,
        },
        partnersLoading: true,
      });
    case PARTNERS_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        partners: {
          ...state.partners,
          ...action.partners,
        },
        partnersLoading: false,
      });
    default:
      return state;
  }
}
