import React from 'react';
import { getLocalizedDate } from 'modules/localized-date';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

export const NewsHero = ({ image, title, date }) => {
  return (
    <section className={`${styles['news-hero']} section container`}>
      <div className={`${styles.wrapper}`}>
        <div className={styles['image-wrapper']}>
          <img src={image} alt={`${title}`} />
          <div className={styles.overlay} />
        </div>
        <div className={styles['text-container']}>
          <div className={styles['text-wrapper']}>
            <h1 className={styles.title}>{title}</h1>
            <p className={styles.date}>{getLocalizedDate(date)}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

NewsHero.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string
}
