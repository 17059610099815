import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useWindowSize } from 'hooks/use-window-size';
import { bpXLarge } from 'constants/breakpoints';
import { variantSectionRef } from 'components/select-variant';
import { downloadsRef } from 'components/downloads';
import styles from './style.module.scss';

export const VariantAnchor = ({ productName, variantName, singleVariant }) => {
  const [pageYOffset, setPageYOffset] = useState(window.pageYOffset);
  const [startThreshold, setStartThreshold] = useState(null);
  const [endThreshold, setEndThreshold] = useState(null);
  const { width } = useWindowSize();
  const isLarge = width >= bpXLarge;

  const anchorIsVisible =
    pageYOffset > startThreshold && pageYOffset < endThreshold;

  useEffect(() => {
    if (variantSectionRef.current) {
      const variantBottom =
        variantSectionRef.current.offsetTop +
        variantSectionRef.current.offsetHeight;
      const downloadBottom =
        downloadsRef.current.offsetTop + downloadsRef.current.offsetHeight;
      const handleScroll = () => {
        setPageYOffset(window.pageYOffset);
      };

      window.addEventListener('scroll', handleScroll);
      setStartThreshold(
        isLarge ? variantBottom : variantSectionRef.current.offsetTop
      );
      setEndThreshold(
        isLarge ? downloadBottom : downloadsRef.current.offsetTop
      );
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isLarge]);

  const handleScroll = () => {
    variantSectionRef.current.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  };

  const anchorClasses = classNames({
    [`${styles['variant-anchor']}`]: true,
    [`${styles.scroll}`]: anchorIsVisible,
    [`${styles.absolute}`]: singleVariant,
  });

  return (
    <button className={anchorClasses} onClick={handleScroll}>
      <span>
        {productName} <span>{variantName}</span>
      </span>
    </button>
  );
};
