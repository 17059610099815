import urls from 'constants/urls.json';
import { getVariants } from 'modules/api';

export const VARIANTS_REQUEST = 'VARIANTS_REQUEST';
export const VARIANTS_RECEIVED = 'VARIANTS_RECEIVED';

export const requestVariants = () => {
  return { type: VARIANTS_REQUEST };
};

export const receiveVariants = variants => {
  return { type: VARIANTS_RECEIVED, variants };
};

export const fetchVariants = () => {
  return async dispatch => {
    dispatch(requestVariants());

    const variants = await getVariants(urls['variants']);

    dispatch(receiveVariants(variants));
  };
};
