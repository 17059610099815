import urls from 'constants/urls.json';
import { getInternationalPartners } from 'modules/api';

export const PARTNERS_REQUEST = 'PARTNERS_REQUEST';
export const PARTNERS_RECEIVED = 'PARTNERS_RECEIVED';

export const requestPartners = () => {
  return { type: PARTNERS_REQUEST };
};

export const receivePartners = internationalPartners => {
  return { type: PARTNERS_RECEIVED, internationalPartners };
};

export const fetchInternationalPartners = () => {
  return async dispatch => {
    dispatch(requestPartners());

    const internationalPartners = await getInternationalPartners(
      urls['international-partners']
    );

    dispatch(receivePartners(internationalPartners));
  };
};
