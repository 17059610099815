import React from 'react';

export const Png = props => (
  <svg width={24} height={34} {...props}>
    <path
      d="M1.49 1.358a.173.173 0 00-.17.176v30.91c0 .098.077.176.17.176h21.023c.093 0 .17-.078.17-.176V8.67a.18.18 0 00-.049-.121l-6.886-7.14a.165.165 0 00-.118-.05H1.49zM22.523 34H1.49C.667 34 0 33.309 0 32.454V1.544C0 .692.667 0 1.49 0h14.137c.39.003.765.165 1.04.453l6.896 7.124c.275.287.432.674.437 1.078v23.79c.006.852-.657 1.548-1.48 1.555h-.007.01zM16.632 7.627h5.116l-5.116-5.303v5.303z"
      fill="#393E5B"
    />
    <path
      d="M5.208 26.872h.736c.48 0 .808-.28.808-.72 0-.456-.328-.728-.808-.728h-.736v1.448zm.864 1.088H5.2V30H3.936v-5.672h2.136c1.152 0 1.944.76 1.944 1.816 0 1.072-.792 1.816-1.944 1.816zM13.768 30h-1.336l-2.336-3.728V30H8.84v-5.672h1.536l2.128 3.448v-3.448h1.264V30zm6.464 0h-1.024l-.08-.576c-.248.36-.784.696-1.592.696-1.576 0-2.872-1.16-2.872-2.952 0-1.784 1.352-2.96 2.952-2.96 1.624 0 2.376.96 2.624 1.752l-1.216.424c-.088-.352-.464-.992-1.408-.992-.768 0-1.672.512-1.672 1.776 0 1.184.784 1.8 1.704 1.8.936 0 1.328-.608 1.4-.928h-1.704v-1.072h2.888V30z"
      fill="#393E5B"
      fillRule="nonzero"
    />
    <path
      d="M22.62 8.996h-6.352c-.525-.002-.95-.44-.957-.984V1.419c-.01-.547.409-1 .937-1.012.262-.006.515.1.699.293L23.3 7.294c.375.385.377 1.013.005 1.402a.941.941 0 01-.699.293l.015.007z"
      fill="#393E5B"
    />
  </svg>
);
