import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProject, fetchCollaborators } from 'redux/actions';
import { PAGE_PROJECT_INFO } from 'constants/page-name';
import { THEME_DARK, THEME_SOLID } from 'constants/color-theme';
import { SIZE_BIG } from 'constants/sizes';
import { TITLE_FEATURED_PRODUCTS } from 'constants/titles';
import { CAROUSEL_SMALL_SQUARE } from 'constants/carousel-types';
import { ROUTE_PRODUCTS } from 'constants/router-links';
import { LABEL_VIEW_ALL_PRODUCTS } from 'constants/labels';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { ProjectHero } from 'components/project-hero';
import { ProjectInfo } from 'components/project-info';
import { PageStatement } from 'components/page-statement';
import { FeaturedImagesProject } from 'components/featured-images-project';
import { FeaturedProduct } from 'components/featured-product';
import { Carousel } from 'components/carousel';
import { Collaborators } from 'components/collaborators';
import { SignUpSection } from 'components/signup-section';
import { Loading } from 'components/loading';
import { ErrorBoundary } from 'components/error';
import { FourOhFour } from 'components/four-oh-four';
import { settingsFeatured } from 'components/carousel/settings';

export const ProjectDetailScreen = ({ match }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProject(match.params.slug));
    dispatch(fetchCollaborators());
  }, [dispatch, match]);

  const { loading, data } = useSelector((state) => state.project);
  const { collaborators } = useSelector((state) => state.collaborators);

  if (loading) return <Loading />;

  if (data.slug === undefined) return <FourOhFour />;

  return (
    <ErrorBoundary>
      <Header theme={THEME_SOLID} />
      <ProjectHero
        image={data.hero_image}
        title={data.title}
        sector={data.sector}
        color={data.theme}
      />
      <ProjectInfo
        color={data.theme}
        image1={data.feature_image_1}
        image2={data.feature_image_2}
        year={data.year}
        client={data.client}
        lightingDesigner={data.lighting_designer}
        photographer={data.photographer}
        location={data.location}
        description={data.intro}
      />
      <PageStatement
        pageName={PAGE_PROJECT_INFO}
        size={SIZE_BIG}
        text={data.feature_text}
      />
      <FeaturedImagesProject
        color={data.theme}
        year={data.year}
        image1={data.feature_image_3}
        image2={data.feature_image_4}
        text={data.secondary_description}
      />
      {data.featured_products && (
        <Carousel
          type={CAROUSEL_SMALL_SQUARE}
          title={TITLE_FEATURED_PRODUCTS}
          theme={THEME_DARK}
          settings={settingsFeatured}
          carouselButton={{
            label: LABEL_VIEW_ALL_PRODUCTS,
            link: ROUTE_PRODUCTS,
          }}
        >
          {data.featured_products.map((product, index) => {
            return (
              index <= 2 && (
                <FeaturedProduct
                  key={index}
                  name={product.post_name}
                  length={data.featured_products.length}
                />
              )
            );
          })}
        </Carousel>
      )}
      <Collaborators collaborators={collaborators} offsetHelper={3000} />
      <SignUpSection />
      <Footer />
    </ErrorBoundary>
  );
};
