import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import SplitText from 'react-pose-text';
import PropTypes from 'prop-types';
import { SECTION_STORY } from 'constants/section-name';
import { SIZE_BIG } from 'constants/sizes';
import { PAGE_OUR_STORY } from 'constants/page-name';
import { FloatingLogo } from 'components/floating-logo';
import styles from './style.module.scss';

export const PageStatement = ({ pageName, size, text }) => {
  const [entered, setEntered] = useState(false);
  const [isVisible, setVisibility] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setEntered(true);
    }
  }, [isVisible]);

  const handleOnView = (visiblity) => setVisibility(visiblity);

  const wordPoses = {
    enter: {
      opacity: 1,
      transition: ({ wordIndex }) => ({
        delay: wordIndex * 20,
        duration: 500,
        ease: 'easeInOut',
      }),
    },
    exit: { opacity: 0 },
  };

  return (
    <>
      {text && (
        <>
          <VisibilitySensor partialVisibility onChange={handleOnView}>
            <section
              className={`${styles['page-statement']} ${styles[pageName]} section container`}
            >
              {pageName === PAGE_OUR_STORY && (
                <FloatingLogo section={SECTION_STORY} size={SIZE_BIG} />
              )}
              <h2 className={`${styles.content} ${styles[size]}`}>
                <SplitText
                  wordPoses={wordPoses}
                  pose={entered ? 'enter' : 'exit'}
                >
                  {text}
                </SplitText>
              </h2>
            </section>
          </VisibilitySensor>
        </>
      )}
    </>
  );
};

PageStatement.propTypes ={ 
  pageName: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string.isRequired
}