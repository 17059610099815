import React from 'react';

export const Linkedin = props => (
  <svg data-name="Layer 1" viewBox="0 0 34.5 34.5" {...props}>
    <circle
      cx={17.25}
      cy={17.25}
      r={16.5}
      fill="none"
      stroke=""
      strokeWidth={1.5}
    />
    <path
      d="M23.08 22.42h-2v-3.74c0-2.24-2.66-2.07-2.66 0v3.74h-2v-7.34h2v1.18a2.53 2.53 0 014.66 1.65zm-9-8.18a1.18 1.18 0 111.17-1.18 1.17 1.17 0 01-1.17 1.18zm-1 8.18h2v-7.34h-2zm9.34-12.67h-9.34a3.33 3.33 0 00-3.33 3.33v9.34a3.33 3.33 0 003.33 3.33h9.34a3.33 3.33 0 003.33-3.33v-9.34a3.33 3.33 0 00-3.33-3.33z"
      fillRule="evenodd"
      stroke="none"
      fill=""
    />
  </svg>
);
