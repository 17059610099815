import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import urls from 'constants/urls.json';
import { getVariants, getEngines } from 'modules/api';
import {
  generateSelectOptions,
  genereateSelectOptionsBySearchTerms,
} from 'modules/generate-select-options';
import { get } from 'modules/fetch/get';
import filterDownloadOptions from 'modules/filter-download-options';
import { fieldTypes } from './field-types';
import { DownloadSelect } from 'components/download-select';
import styles from './styles.module.scss';

export const DownloadFilters = ({
  onFilterChange,
  filters,
  enabledProducts,
  enabledVariants,
  enabledEngines,
  enabledFieldTypes,
}) => {
  const [{ products, variants, engines, loading }, setState] = useState({
    products: [],
    variants: [],
    engines: [],
    loading: true,
  });

  useEffect(() => {
    const load = async () => {
      const [engines, products, variants] = await Promise.all([
        getEngines(),
        get(urls['products'] + '?order_by=name', {}),
        getVariants(),
      ]);

      setState({
        products: genereateSelectOptionsBySearchTerms(
          products.products,
          'title',
          enabledProducts
        ),
        variants: generateSelectOptions(variants, 'name', 'slug'),
        engines: generateSelectOptions(engines, 'title', 'name'),
        loading: false,
      });
    };

    load();
  }, [enabledProducts]);

  return (
    <div className={styles.filters}>
      <DownloadSelect
        name="product"
        label="product"
        options={filterDownloadOptions(products, enabledProducts)}
        onChange={onFilterChange}
        loading={loading}
        value={filters.product}
      />
      <DownloadSelect
        name="variant"
        label="variant"
        options={filterDownloadOptions(variants, enabledVariants)}
        onChange={onFilterChange}
        loading={loading}
        value={filters.variant}
      />

      <DownloadSelect
        name="engine"
        label="engine"
        options={filterDownloadOptions(engines, enabledEngines)}
        onChange={onFilterChange}
        loading={loading}
        value={filters.engine}
      />
      <DownloadSelect
        name="format"
        label="field type"
        options={filterDownloadOptions(fieldTypes, enabledFieldTypes)}
        onChange={onFilterChange}
        loading={loading}
        value={filters.format}
      />
      <Link className={styles.clear} to="/downloads">
        clear
      </Link>
    </div>
  );
};

DownloadFilters.defaultProps = {
  enabledProducts: [],
  enabledVariants: [],
  enabledEngines: [],
  enabledFieldTypes: [],
};
