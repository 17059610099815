import React from 'react';
import { CAROUSEL_SMALL_LANDSCAPE } from 'constants/carousel-types';
import { thumbExtensionCreator } from 'modules/thumb-extension-creator';
import { LazyImage } from 'components/lazy-image';
import styles from './style.module.scss';

export const Slide = ({ src, title, description, url, type, theme }) => {
  const landscapeSmall = type === CAROUSEL_SMALL_LANDSCAPE;

  return (
    <div className={`${styles.slide} ${styles[type]} ${styles[theme]}`}>
      <a href={!landscapeSmall ? url : `/news/${url}`}>
        {!landscapeSmall && <div className={styles['bottom-overlay']} />}
        <LazyImage
          thumbnailSrc={thumbExtensionCreator(src, '-thumb')}
          src={src ? src : thumbExtensionCreator(src,'')}
          alt={`${title} product`}
        />
        <div className={styles.caption}>
          <span className={styles['slide-title']}>{title}</span>
          {landscapeSmall && (
            <p className={styles.description}>{description}</p>
          )}
        </div>
      </a>
    </div>
  );
};
