export const fieldTypes = [
    { label: 'All', value: '' },
    { label: 'Datasheets', value: 'datasheets' },
    {
      label: 'Installation sheets',
      value: 'installer_and_contractor_information',
    },
    { label: 'DXF line drawings', value: 'diagrams_and_line_drawings' },
    { label: 'Images', value: 'image_pack' },
    { label: 'Power supplies', value: 'psu' },
    { label: 'Photometric reports', value: 'luminaire_photometric_reports' },
    { label: 'Photometric files', value: 'photometric_reports' },
  ];