import urls from 'constants/urls.json';
import { getPeople } from 'modules/api';

export const PEOPLE_REQUEST = 'PEOPLE_REQUEST';
export const PEOPLE_RECEIVED = 'PEOPLE_RECEIVED';

export const requestPeople = () => {
  return { type: PEOPLE_REQUEST };
};

export const receivePeople = people => {
  return { type: PEOPLE_RECEIVED, people };
};

export const fetchPeople = () => {
  return async dispatch => {
    dispatch(requestPeople());

    const people = await getPeople(urls['people']);

    dispatch(receivePeople(people));
  };
};
