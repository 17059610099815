import React from 'react';
import { useSelector } from 'react-redux';
import { ContactForm } from 'components/forms/contact-form';
import { ScrollTo } from 'components/scroll-to';
import { FloatingLogo } from 'components/floating-logo';
import { SIZE_BIG } from 'constants/sizes';
import { SECTION_CONTACT_FORM } from 'constants/section-name';
import styles from './style.module.scss';

export const ContactFormSection = ({ sideImage }) => {
  const { success, hasError } = useSelector((state) => state.contactForm);

  return (
    <section className={`${styles.contact} section`}>
      <div className={`${styles.wrapper} container`}>
        <div className={styles.title}>
          <h3>fill in the form below to get in touch</h3>
        </div>
        <div className={styles.content}>
          <div className={styles['form-wrapper']}></div>
        </div>
        <ContactForm />
        <FloatingLogo section={SECTION_CONTACT_FORM} size={SIZE_BIG} />
        <div className={styles['side-image']}>
          <img src={sideImage} alt="product detail" />
        </div>
        {success && (
          <div className={styles.confirmation}>
            <p>
              Thanks for getting in touch, we'll respond to your enquiry as soon
              as possible.
            </p>
            <p>
              Please note customer support is available during weekdays only
              (Mon-Fri, 9am-5pm GMT) .
            </p>
          </div>
        )}
        {success && <ScrollTo />}
        {hasError && (
          <div className={styles.error}>
            <p>
              Sorry there was an error submitting the form. Please try again
              later.
            </p>
          </div>
        )}
      </div>
    </section>
  );
};
