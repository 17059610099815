import React from 'react';
import { useWindowSize } from 'hooks/use-window-size';
import { bpMedium } from 'constants/breakpoints';
import { ScrollTo } from 'components/scroll-to';
import styles from './style.module.scss';

export const SelectedEngine = ({ selected, description, scrollTo }) => {
  const { width } = useWindowSize();
  const isLarge = width >= bpMedium;

  return (
    <>
      {scrollTo && isLarge && <ScrollTo />}
      <div className={styles.description}>
        <div>
          <h2>{selected}</h2>
          <ul>
            {description.split(',').map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
