import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getProduct } from 'modules/api';
import urls from 'constants/urls.json';
import { BANNER_NAME_VERTICAL_COPY } from 'constants/banners';
import { SIZE_BIG } from 'constants/sizes';
import { THEME_DARK } from 'constants/color-theme';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'components/buttons';
import { ProductFeatured } from 'components/product-featured';
import styles from './style.module.scss';

export const SectionWithBackground = ({
  theme,
  sectionName,
  title,
  description,
  subDescription,
  hasFeaturedProduct,
  cta,
  link,
  image,
  video,
  relatedProduct,
  nextCompIsVisible,
}) => {
  const [loading, isLoading] = useState(true);
  const [relatedProductData, setRelatedProductData] = useState({});
  const hasBG =
    sectionName === BANNER_NAME_VERTICAL_COPY && !hasFeaturedProduct;

  useEffect(() => {
    async function fetchProduct() {
      const product = await getProduct(
        urls['product'],
        relatedProduct.product.post_name
      );

      setRelatedProductData(product);
      isLoading(false);
    }

    hasFeaturedProduct && fetchProduct();
  }, [hasFeaturedProduct, relatedProduct]);

  const sectionClasses = classNames({
    [`${styles[sectionName]}`]: true,
    [`section`]: true,
    [`container`]: true,
    [`${styles['has-bg-color']}`]: hasBG,
    // extra margin check due to the homepage edge case, mainly on mobile
    [`${styles['extra-margin-bottom']}`]:
      !nextCompIsVisible && sectionName === BANNER_NAME_VERTICAL_COPY,
  });

  const headingClasses = classNames({
    [`${styles[theme]}`]: true,
  });

  return (
    <>
      {image && (
        <section
          className={sectionClasses}
          style={{
            backgroundImage: `url(${image})`,
          }}
        >
          {video && <video src={video} poster={image} autoPlay loop muted />}
          {hasBG && <div className={styles['bg-color']} />}

          <div className={styles.content}>
            {title && <h1 className={headingClasses}>{title}</h1>}
            {description && <h2 className={headingClasses}>{description}</h2>}
            {subDescription && <h3>{subDescription}</h3>}
            {cta && (
              <Link to={link ? link : '/'} className={styles['btn']}>
                <Button size={SIZE_BIG} theme={THEME_DARK} label={cta} />
              </Link>
            )}
          </div>

          {hasFeaturedProduct && !loading && (
            <ProductFeatured
              title={relatedProduct.title}
              description={relatedProduct.description}
              url={relatedProductData.url}
              thumbnail={relatedProductData.thumbnail}
              name={relatedProductData.title}
            />
          )}
        </section>
      )}
    </>
  );
};

SectionWithBackground.propTypes = {
  theme: PropTypes.string,
  sectionName: PropTypes.string,
  description: PropTypes.string,
  subDescription: PropTypes.string,
  hasFeaturedProduct: PropTypes.bool,
  nextCompIsVisible: PropTypes.bool,
  cta: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.bool]),
  video: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  relatedProduct: PropTypes.shape({
    product: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};
