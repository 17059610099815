import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

export const PageTitle = ({ title }) => {
  return <h1 className={`${styles['page-title']} container`}>{title}</h1>;
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
}
