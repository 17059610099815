/*
  second parameter in splice is the number of elements wanted in each chunk
*/

export const chunk = (array, size) => {
  const chunkedArr = [];
  let copiedArr = [...array];
  const numOfChild = Math.ceil(copiedArr.length / size);

  for (let i = 0; i < numOfChild; i++) {
    chunkedArr.push(copiedArr.splice(0, size));
  }
  return chunkedArr;
};
