import React from 'react';
import { DateTime } from 'luxon';
import { snakeCase } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Arrow } from 'components/icons';
import styles from './style.module.scss';

export const WorldwideOffices = ({ worldwideOffices }) => {
  const dt = DateTime.local();
  const { offices } = worldwideOffices;

  return (
    <div className={`${styles['worldwide-offices']} container`}>
      <div className={styles['cta-wrapper']}>
        <h3 className={styles.cta}>worldwide offices</h3>
      </div>

      <div className={styles.offices}>
        {offices.map((item, index) => {
          const _city =
            item.map.city === undefined ? item.map.state : item.map.city;
          let city = _city === 'England' ? 'London' : _city;
          city = item.map.country === 'Austria' ? 'Vienna' : _city;
          const continent =
            item.continent.value === 'middle-east'
              ? 'Asia'
              : item.continent.value;

          const timeZone = [continent, snakeCase(city)].join('/');
          const mapUrl = [
            'https://www.google.com/maps/place/?q=place_id:',
            item.map.place_id,
          ].join('');

          return (
            <div key={index} className={styles.office}>
              <span className={styles.timezone}>
                {dt.setZone(timeZone).toFormat('HH:mma').toLowerCase()}
              </span>
              <h4>{item.continent.label.toUpperCase()}</h4>
              <ul>
                <li>
                  <a href={`mailto:${item.email}`}>{item.general_enquiries}</a>
                </li>
                <li>{item.address}</li>
                <li>
                  {item.city} {item.postcode ? `- ${item.postcode}` : ''}
                </li>
                <li>{item.country}</li>
              </ul>
              <span className={styles['open-in-maps']}>
                <a href={mapUrl} rel="noopener noreferrer" target="_blank">
                  open in maps
                </a>
                <Arrow className={styles.arrow} />
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

WorldwideOffices.propTypes = {
  worldwideOffices: PropTypes.object.isRequired
}