import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { New } from 'components/icons';
import placeholder from 'assets/images/floating_logo_icon.png';
import styles from 'components/product-grid/style.module.scss';

export const Product = ({
  description,
  title,
  isNew,
  media,
  thumbnail,
  default: defaultProps,
}) => {
  const { url } = defaultProps;
  const isImage = !media;
  const isVideo = media;

  if (!media && !thumbnail) {
    thumbnail = placeholder;
  }

  return (
    <Link to={url}>
      <div className={styles.overlay} /> {/* rollover*/}
      <div className={styles['bottom-overlay']} /> {/* caption */}
      {isVideo && (
        <video
          className={styles.img}
          src={media}
          autoPlay
          playsInline
          muted
          loop
        />
      )}
      {isImage && (
        <img
          className={styles.img}
          src={thumbnail}
          alt={`${title} product highlight`}
        />
      )}
      <div className={styles['description-container']}>
        {isNew && <New />}
        <figcaption>{title}</figcaption>
        <p className={styles.description}>{description}</p>
      </div>
    </Link>
  );
};

Product.propTypes = {
  description: PropTypes.string.isRequired,
  default: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  media: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
