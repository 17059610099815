import React from 'react';
import {
  Facebook,
  Instagram,
  Linkedin,
  Pinterest,
  Twitter,
} from 'components/icons';

import styles from './style.module.scss';

export const Social = ({ noHeader, extraClass }) => {
  return (
    <div className={`${styles.social} ${styles[extraClass]}`}>
      {!noHeader && <h3>Connect</h3>}
      <ul className={styles.socials}>
        <li>
          <a
            href="https://www.instagram.com/orlunaled/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram />
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/orlunaled"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook />
          </a>
        </li>
        <li>
          <a
            href="https://www.pinterest.co.uk/OrlunaLED/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Pinterest />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/orlunaled"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter />
          </a>
        </li>
        <li>
          <a
            href=" https://www.linkedin.com/company/879621"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Linkedin />
          </a>
        </li>
      </ul>
    </div>
  );
};
