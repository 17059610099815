import {
  SUBMIT_SIGN_UP_VISIBILITY_REQUEST,
  SUBMIT_SIGN_UP_VISIBILITY_RECEIVED,
  SUBMIT_SIGN_UP_REQUEST,
  SUBMIT_SIGN_UP_SUCCESS,
  SUBMIT_SIGN_UP_ERROR,
  SUBMIT_SIGN_UP_RESET,
} from 'redux/actions/sign-up-form';

const initialState = {
  visibility: false,
  success: false,
  loading: false,
  hasError: false,
};

export default function signUpForm(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_SIGN_UP_VISIBILITY_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        hasError: false,
        success: false,
        visibility: false,
      });
    case SUBMIT_SIGN_UP_VISIBILITY_RECEIVED:
      return Object.assign({}, state, {
        loading: false,
        hasError: false,
        success: false,
        visibility: action.visibility,
      });
    case SUBMIT_SIGN_UP_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        hasError: false,
        success: false,
        visibility: state.signUpForm.visibility,
      });
    case SUBMIT_SIGN_UP_SUCCESS:
      return Object.assign({}, state, {
        success: true,
        loading: false,
        hasError: false,
        visibility: state.signUpForm.visibility,
      });
    case SUBMIT_SIGN_UP_ERROR:
      return Object.assign({}, state, {
        success: false,
        loading: false,
        hasError: true,
        visibility: state.signUpForm.visibility,
      });
    case SUBMIT_SIGN_UP_RESET:
      return initialState;
    default:
      return state;
  }
}
