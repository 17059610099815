import React from 'react';
import styles from 'components/check-box/style.module.scss';

export const FloorWasher = (props) => (
  <svg
    className={styles['filter-icon']}
    {...props}
    height="56"
    viewBox="0 0 57 56"
    width="57"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd" transform="translate(1.5 1)">
      <path d="m15.9130435 22h23.173913l8.9130435 9h-41z" fill="#555762" />
      <circle cx="27" cy="27" r="27" stroke="#f4f4f4" strokeWidth="2" />
    </g>
  </svg>
);
