import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import values from 'lodash/fp/values';
import classNames from 'classnames';
import { htmlToReactParser } from 'modules/html-to-react';
import { FloatingLogo } from 'components/floating-logo';
import { DownwardArrow } from 'components/icons';
import { Button } from 'components/buttons';
import { THEME_DARK } from 'constants/color-theme';
import { SECTION_PARTNERS } from 'constants/section-name';
import { TITLE_INT_PARTNERS } from 'constants/titles';
import { LABEL_LEARN_MORE } from 'constants/labels';
import { SIZE_BIG } from 'constants/sizes';
import styles from './style.module.scss';

export const Dropdown = ({ title, arr }) => {
  const [active, setActive] = useState(null);
  const faq = title !== TITLE_INT_PARTNERS;
  const col1 = [],
    col2 = [];

  const handleClick = (item) => {
    item === active ? setActive(null) : setActive(item);
  };

  const chunckedArray = () => {
    values(arr).forEach((item, i) => {
      !faq
        ? i % 2 === 0
          ? col1.push(item)
          : col2.push(item)
        : col1.push(item);
    });
  };
  chunckedArray();

  const wrapperClasses = classNames({
    [`${styles['item-wrapper']}`]: true,
  });

  const sectionClasses = classNames({
    [`section`]: true,
    [`container`]: true,
    [`${styles.faqs}`]: faq,
    [`${styles.partners}`]: !faq,
  });

  const itemClasses = (item) => {
    return classNames({
      [`${styles.item}`]: true,
      [`${styles['is-open']}`]: active === item,
    });
  };

  const arrowClasses = (item) => {
    return classNames({
      [`${styles['arrow-wrapper']}`]: true,
      [`${styles['rotate-down']}`]: active === item,
    });
  };

  const infoClasses = (item) => {
    return classNames({
      [`${styles['item-info']}`]: true,
      [`${styles['is-open']}`]: active === item,
    });
  };

  const createDropdown = (arrayPerCol) => {
    return (
      <ul className={styles.list}>
        {arrayPerCol.map((item, index) => {
          const { partners } = item;
          return (
            <div key={index} className={wrapperClasses}>
              <div
                className={itemClasses(item)}
                onClick={() => handleClick(item)}
              >
                {!faq && <li>{item.title}</li>}
                {faq && <li>{htmlToReactParser.parse(item.question)}</li>}
                <span className={arrowClasses(item)}>
                  <DownwardArrow />
                </span>
              </div>
              <div className={infoClasses(item)}>
                {!faq &&
                  partners.map((partner, index) => (
                    <div className={styles['partner-wrapper']} key={index}>
                      <span>{partner.title}</span>
                      <span
                        className={styles.address}
                        dangerouslySetInnerHTML={{
                          __html: partner.address,
                        }}
                      />
                      <span>
                        <a
                          className={styles['partner-contact']}
                          href={partner.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {partner.website}
                        </a>
                      </span>
                    </div>
                  ))}

                {faq && (
                  <>
                    <div
                      className={styles.answer}
                      dangerouslySetInnerHTML={{
                        __html: item.answer,
                      }}
                    />
                    {item.hasButton && (
                      <Link to={`/${item.link}`}>
                        <Button theme={THEME_DARK} label={LABEL_LEARN_MORE} />
                      </Link>
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })}
      </ul>
    );
  };

  return (
    <section className={sectionClasses}>
      <h3>{title}</h3>
      <div className={styles['columns-wrapper']}>
        {createDropdown(col1)}
        {createDropdown(col2)}
      </div>
      {!faq && (
        <FloatingLogo
          section={SECTION_PARTNERS}
          size={SIZE_BIG}
          offsetHelper={2300}
        />
      )}
    </section>
  );
};
