import axios from 'axios';

export const downloadZip = async (urls) => {
  try {
    const origin = process.env.REACT_APP_DOWNLOADS_URL;
    const apiUrl = [origin, 'api', 'zip'].join('/');

    const response = await axios(apiUrl, {
      method: 'POST',
      data: JSON.stringify(urls),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const { filename } = await response.data;
    const url = [origin, filename].join('/');
    window.open(url, '_self');

    return true;
  } catch (error) {
    throw error;
  }
};
