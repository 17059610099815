import urls from 'constants/urls.json';
import { getProductDownloads } from 'modules/api';

export const PRODUCT_DOWNLOADS_REQUEST = 'PRODUCT_DOWNLOADS_REQUEST';
export const PRODUCT_DOWNLOADS_RECEIVED = 'PRODUCT_DOWNLOADS_RECEIVED';

export const requestProductDownloads = () => {
  return { type: PRODUCT_DOWNLOADS_REQUEST };
};

export const receiveProductDownloads = (productDownloads) => {
  return { type: PRODUCT_DOWNLOADS_RECEIVED, productDownloads };
};

export const fetchProductDownloads = (filters) => {
  return async (dispatch) => {
    dispatch(requestProductDownloads());

    const files = await getProductDownloads(urls['product-downloads'], filters);

    dispatch(receiveProductDownloads(files));
  };
};
