import React from 'react';

export const DownwardArrow = props => (
  <svg data-name="Layer 1" viewBox="0 0 9.68 6" {...props}>
    <path
      d="M0 .95L.98 0l3.86 4.02L8.71 0l.97.95L4.84 6 0 .95z"
      data-name="np down 1137390 000000"
      fill=""
    />
  </svg>
);
