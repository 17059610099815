import urls from 'constants/urls.json';
import { getRanges } from 'modules/api';

export const RANGES_REQUEST = 'RANGES_REQUEST';
export const RANGES_RECEIVED = 'RANGES_RECEIVED';

export const requestRanges = () => {
    return { type: RANGES_REQUEST };
};

export const receiveRanges = ranges => {
    return { type: RANGES_RECEIVED, ranges };
};

export const fetchRanges = () => {
    return async dispatch => {
        dispatch(requestRanges());

        const ranges = await getRanges(urls['ranges']);

        dispatch(receiveRanges(ranges));
    };
};