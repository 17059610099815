import React, { useEffect } from 'react';
import { head, find } from 'lodash/fp';
import { bpLarge } from 'constants/breakpoints';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveEngine } from 'redux/actions/product';
import { useWindowSize } from 'hooks/use-window-size';
import { Selector } from 'components/engine-selector/selector';
import { LineDrawingAndDatasheet } from 'components/line-drawing-and-datasheet';
import { SpecCodeAndDownloads } from 'components/spec-code-and-downloads';
import { TechSpec } from 'components/tech-spec';
import { DownloadsMobile } from 'components/downloads-mobile';
import CircularProductStrip from 'components/circular-product-strip';

const data = require('components/tech-spec/tech-spec.fixtures.json');

const product = find({ id: 1 })(data);
const engines = product['acf']['add_engine_type'];

const getEngine = (engineName) => {
  return find({ engine_type: engineName })(engines);
};

export const engineOrigin = getEngine('Origin');

export const EngineSelector = ({
  options,
  product,
  fittingCodes,
  singleVariant,
  productName,
  isCircular,
  circularAttributes,
}) => {
  const dispatch = useDispatch();
  const { activeEngine } = useSelector((state) => state.product);
  const { width } = useWindowSize();
  const smallScreen = width <= bpLarge;

  // when the page first loads
  useEffect(() => {
    onSelectEngine(head(options));
    //eslint-disable-next-line
  }, [options]);

  const onSelectEngine = (engine) => {
    dispatch(setActiveEngine(engine));
  };

  if (!activeEngine) return false;

  return (
    <>
      <Selector
        activeEngine={activeEngine}
        setActiveEngine={(engine) => onSelectEngine(engine)}
        options={options}
        variantName={product.name}
        productName={productName}
        singleVariant={singleVariant}
      />
      <TechSpec data={activeEngine} />
      {isCircular && (
        <CircularProductStrip
          recycledStat={circularAttributes.recycled}
          recyclableStat={circularAttributes.recyclable}
          repairableStat={circularAttributes.repairable}
        />
      )}
      <LineDrawingAndDatasheet
        datasheet={activeEngine.downloads.add_datasheets[0]}
        lineDrawing={activeEngine.add_line_drawings[0]}
      />
      {smallScreen && <DownloadsMobile downloads={activeEngine.downloads} />}
      <SpecCodeAndDownloads
        activeEngine={activeEngine}
        smallScreen={smallScreen}
      />
    </>
  );
};
