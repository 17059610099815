export const HEADERS = {
    beam_angle: 'Beam angle',
    beam_width_fwhm: 'Beam width FWHM',
    initial_lumens: 'Initial lumens',
    engine_lumens: 'Engine lumens',
    luminaire_lumens: 'Luminaire Lumens',
    cri: 'CRI',
    r9: 'R9',
    rf_rg: 'RF/RG',
    circuit_power: 'Circuit Power',
};