import { DOWNLOADS_REQUEST, DOWNLOADS_RECEIVED } from 'redux/actions/downloads';

const initialState = {
  error: false,
  downloads: {},
  data: [],
  totalPages: 0,
  totalResults: 0,
  loading: true,
};

function results(state = initialState, action) {
  switch (action.type) {
    case DOWNLOADS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case DOWNLOADS_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        loading: false,
        ...action.data,
      });
    default:
      return state;
  }
}

export default function downloads(state = initialState, action) {
  switch (action.type) {
    case DOWNLOADS_REQUEST:
    case DOWNLOADS_RECEIVED:
      return Object.assign({}, state, {
        [action.hash]: results(state[action.hash], action),
      });
    default:
      return state;
  }
}
