import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRelatedProductIfNeeded } from 'redux/actions';
import PropTypes from 'prop-types';
import { Slide } from 'components/carousel/slide';
import { Loading } from 'components/loading';

export const RelatedProduct = ({ slug, url, title, thumbnail }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRelatedProductIfNeeded(slug));
  });

  const { data } = useSelector((state) => state.relatedProduct);
  const loading = !data.hasOwnProperty(slug) || data[slug].loading;

  if (loading) {
    return <Loading size={50} fit />;
  }

  return (
    <Slide
      src={thumbnail}
      type="square-small"
      url={url}
      title={title}
      theme="dark"
    />
  );
};

RelatedProduct.propTypes = { 
  title: PropTypes.string, 
  slug: PropTypes.string.isRequired, 
  url: PropTypes.string.isRequired, 
  thumbnail: PropTypes.string.isRequired,  
}