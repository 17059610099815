import React from 'react';
import styles from 'components/check-box/style.module.scss';

export const Downlight = (props) => (
  <svg
    className={styles['filter-icon']}
    {...props}
    height="56"
    viewBox="0 0 57 56"
    width="57"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd" transform="translate(1.5 1)">
      <path
        d="m26.9998286 17c-8.2841933 0-14.9998286 6.7159013-14.9998286 15h6.9013811c0-4.472584 3.6258125-8.0986977 8.0984475-8.0986977 4.4729778 0 8.0987903 3.6261137 8.0987903 8.0986977h6.9013811c0-8.2840987-6.7156353-15-15.0001714-15"
        fill="#555762"
      />
      <circle cx="27" cy="27" r="27" stroke="#f4f4f4" strokeWidth="2" />
    </g>
  </svg>
);
