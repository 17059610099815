import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

export const Checkbox = ({
  checked,
  icon,
  id,
  name,
  onChange,
  value,
  disabled,
}) => {
  const handleChange = (e) => {
    const { value } = e.target;
    onChange(name, value);
  };

  const checkboxClass = classNames({
    [`${styles['custom-checkbox']}`]: true,
    [`${styles['is-checked']}`]: checked,
    [`${styles['is-disabled']}`]: disabled,
  });

  return (
    <>
      <input
        className={styles['hidden-checkbox']}
        type="checkbox"
        id={id}
        name={name}
        alt={name}
        checked={checked}
        onChange={handleChange}
        value={value}
        disabled={disabled}
      />
      <div className={checkboxClass}>{icon}</div>
    </>
  );
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
};
