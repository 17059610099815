import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import App from './App';
import { Router, Route } from 'react-router-dom';
import { history } from 'redux/store';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import CheckRedirect from 'modules/check-redirect';
import { ProviderWrapper } from 'components/provider-wrapper';

const init = () => {
  // Enable Sentry
  Sentry.init({
    dsn: 'https://8580a6c8a0b343309c433ac9d2ff8ccd@sentry.io/1807264',
    blacklistUrls: ['localhost'],
    release: process.env.REACT_APP_VERSION,
  });

  ReactDOM.render(
    <ProviderWrapper>
      <ConnectedRouter history={history}>
        <Router history={history}>
          <Route path="/" component={App} />
        </Router>
      </ConnectedRouter>
    </ProviderWrapper>,
    document.getElementById('root')
  );
};

CheckRedirect(init);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
