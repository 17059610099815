import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCollaborators,
  fetchBookConsultation,
  fetchProducts,
  fetchRanges,
} from 'redux/actions';
import { useWindowSize } from 'hooks/use-window-size';
import { getPage } from 'modules/api';
import { bpSmall } from 'constants/breakpoints';
import urls from 'constants/urls.json';
import { ROUTE_OUR_STORY, ROUTE_WHY_ORLUNA } from 'constants/router-links';
import {
  SECTION_CARESSES,
  SECTION_CIRCULAR,
  SECTION_CLARITY,
} from 'constants/section-name';
import { THEME_DARK, THEME_SOLID } from 'constants/color-theme';
import { BANNER_NAME_SO_FAR } from 'constants/banners';
import { PAGE_WHY_ORLUNA } from 'constants/page-name';
import { SIZE_SMALL } from 'constants/sizes';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { WhyOrlunaHero } from 'components/why-orluna-hero';
import { PageStatement } from 'components/page-statement';
import { SectionWithBackground } from 'components/section-with-background';
import { ImageAndText } from 'components/image-and-text';
import { GetInTouch } from 'components/get-in-touch';
import { Loading } from 'components/loading';
import { SlideUp } from 'components/animations';
import { ErrorBoundary } from 'components/error';
import { VideoBanner } from 'components/video-banner';
import { FeaturedContent } from 'components/featured-content';
import { RangeGrid } from 'components/range-grid';

export const WhyOrluna = () => {
  const { bannerImages } = useSelector((state) => state.bookConsultation);
  const { ranges, rangesLoading } = useSelector((state) => state.ranges);
  const { width } = useWindowSize();
  const isSmall = width < bpSmall;
  const dispatch = useDispatch();
  const [loading, isLoading] = useState(true);
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    dispatch(fetchRanges());
    dispatch(fetchCollaborators());
    dispatch(fetchProducts({}));
    dispatch(fetchBookConsultation());

    async function fetchPage() {
      const page = await getPage(urls['page'], 'why-orluna');
      setPageData(page.acf);
      isLoading(false);
    }

    fetchPage();
  }, [dispatch]);

  if (loading) return <Loading />;

  return (
    <ErrorBoundary>
      <Header theme={THEME_SOLID} />
      <WhyOrlunaHero image={pageData.hero} />
      <PageStatement
        pageName={PAGE_WHY_ORLUNA}
        size={SIZE_SMALL}
        text={pageData.intro}
      />
      {
        //!isSmall && <VideoBanner video={pageData.video_banner_1} />
      }
      {isSmall && (
        <ImageAndText
          title={pageData.image_and_text.title}
          image={pageData.image_and_text.image}
          text={pageData.image_and_text.description}
        />
      )}
      <ImageAndText
        sectionName={SECTION_CLARITY}
        title={pageData.image_and_text_1.title}
        image={pageData.image_and_text_1.image}
        text={pageData.image_and_text_1.description}
        comparison={pageData.image_and_text_1.is_comparison_image}
        comparisonImages={pageData.image_and_text_1.comparison_images}
      />
      <ImageAndText
        sectionName={SECTION_CARESSES}
        title={pageData.image_and_text_2.title}
        image={pageData.image_and_text_2.image}
        text={pageData.image_and_text_2.description}
      />
      <VideoBanner video={pageData.video_banner_2} />
      <FeaturedContent content={pageData.featured_content} />
      <section style={{ marginTop: '150px', marginBottom: '70px' }}>
        <SlideUp>
          <ImageAndText
            sectionName={SECTION_CIRCULAR}
            tagline="https://orluna-assets.bravestaging.site/uploads/2022/02/circular_product_WM-black.png"
            image={pageData.circular_image_and_text.image}
            text={pageData.circular_image_and_text.description}
            buttonLabel="find out more"
            buttonLink="/circular-product"
            hideBorder={true}
          />
        </SlideUp>
      </section>
      {pageData.display_range_grid && (
        <RangeGrid
          content={pageData.range_grid}
          ranges={ranges}
          loading={rangesLoading}
        />
      )}
      <SlideUp>
        <SectionWithBackground
          image={
            isSmall
              ? pageData.banner_and_cta.background_image_mobile
              : pageData.banner_and_cta.background_image_desktop
          }
          theme={THEME_DARK}
          sectionName={BANNER_NAME_SO_FAR}
          title={pageData.banner_and_cta.title}
          subDescription={pageData.banner_and_cta.description}
          cta={pageData.banner_and_cta.button_title}
          link={ROUTE_OUR_STORY}
        />
      </SlideUp>
      {bannerImages.other_pages.display && (
        <SlideUp>
          <GetInTouch
            image={
              isSmall
                ? bannerImages.other_pages.mobile_image
                : bannerImages.other_pages.desktop_image
            }
            formContext={ROUTE_WHY_ORLUNA}
          />
        </SlideUp>
      )}
      <Footer />
    </ErrorBoundary>
  );
};
