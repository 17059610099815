import urls from 'constants/urls.json';
import { submitForm, getSignUpFormVisibility } from 'modules/api';

export const SUBMIT_SIGN_UP_REQUEST = 'SUBMIT_SIGN_UP_REQUEST';
export const SUBMIT_SIGN_UP_SUCCESS = 'SUBMIT_SIGN_UP_SUCCESS';
export const SUBMIT_SIGN_UP_VISIBILITY_REQUEST =
  'SUBMIT_SIGN_UP_VISIBILITY_REQUEST';
export const SUBMIT_SIGN_UP_VISIBILITY_RECEIVED =
  'SUBMIT_SIGN_UP_VISIBILITY_RECEIVED';
export const SUBMIT_SIGN_UP_RESET = 'SUBMIT_SIGN_UP_RESET';
export const SUBMIT_SIGN_UP_ERROR = 'SUBMIT_SIGN_UP_ERROR';

export const requestSignUpVisibility = () => {
  return { type: SUBMIT_SIGN_UP_VISIBILITY_REQUEST };
};

export const receivedSignUpVisibility = (visibility) => {
  return { type: SUBMIT_SIGN_UP_VISIBILITY_RECEIVED, visibility };
};

export const fetchSignUpFormVisibility = () => {
  return async (dispatch) => {
    dispatch(requestSignUpVisibility());

    const visibility = await getSignUpFormVisibility(
      urls['sign-up-visibility']
    );

    if (!visibility) return dispatch(receivedSignUpVisibility(false));

    dispatch(receivedSignUpVisibility(visibility));
  };
};

export const submitSignUpRequest = () => {
  return { type: SUBMIT_SIGN_UP_REQUEST };
};

export const submitSignUpSuccess = () => {
  return { type: SUBMIT_SIGN_UP_SUCCESS };
};

export const submitSignUpReset = () => {
  return { type: SUBMIT_SIGN_UP_RESET };
};

export const submitSignUpError = () => {
  return { type: SUBMIT_SIGN_UP_ERROR };
};

export const submitSignUp = (formData) => {
  return async (dispatch) => {
    dispatch(submitSignUpRequest());

    const response = await submitForm(urls['formSignUp'], formData);

    if (response.status === 500) {
      dispatch(submitSignUpError());
      return false;
    }

    dispatch(submitSignUpSuccess());
  };
};
