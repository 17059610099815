import { PROJECT_REQUEST, PROJECT_RECEIVED } from 'redux/actions/project';

const initialState = {
  hasError: false,
  data: {},
  loading: true,
};

export default function project(state = initialState, action) {
  switch (action.type) {
    case PROJECT_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        data: {
          ...state.data,
        },
        loading: true,
      });
    case PROJECT_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        data: {
          ...action.project,
        },
        loading: false,
      });
    default:
      return state;
  }
}
