import React from 'react';
import styles from './styles.module.scss';

export const DownloadHeader = ({ title, description }) => {
  return (
    <div className={styles.header}>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};
