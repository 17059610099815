import { arrTwo } from 'components/product-families/index.fixtures';

export const range = {
  range: {
    families: {
      accent: [...arrTwo],
      concealed: [...arrTwo],
    },
  },
};
