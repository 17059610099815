import React, { useState, useEffect, Fragment } from 'react';
import keys from 'lodash/fp/keys';
import values from 'lodash/fp/values';
import classNames from 'classnames';
import getFileExtension from 'modules/get-file-extension';
import { DOWNLOAD_HEADERS } from 'constants/spec-code-downloads';
import { DownwardArrow, Pdf, ImageIcon } from 'components/icons';
import { PreviewLinkPdf } from 'components/downloads/preview-link-pdf';
import { PreviewLinkImage } from 'components/downloads/preview-link-image';
import styles from 'components/downloads/style.module.scss';

export const DownloadsMobile = ({ downloads }) => {
  const [imagePackOpen, setImagePackOpen] = useState(true);
  const [data, setData] = useState(false);
  const [active, setActive] = useState(null);

  useEffect(() => {
    const data = {};
    data['add_image_pack'] = downloads['add_image_pack'];
    data['add_psu'] = downloads['add_psu'];
    data['add_installer_and_contractor_information'] =
      downloads['add_installer_and_contractor_information'];
    setData(data);
    setActive('add_image_pack');
  }, [downloads]);

  const handleClick = (item) => {
    setImagePackOpen(false);
    item === active ? setActive(null) : setActive(item);
  };

  const sectionClasses = classNames({
    [`${styles.downloads}`]: true,
    [`${styles.mobile}`]: true,
    [`section`]: true,
  });

  return (
    <section className={sectionClasses}>
      <div className="container">
        <div className={styles.inner}>
          <div className={styles.heading}>
            <h3>assets</h3>
          </div>
          <div className={styles['right-column']}>
            {keys(data).map((item, index) => {
              const imagePack = DOWNLOAD_HEADERS[item] === 'Image pack';
              const psu = DOWNLOAD_HEADERS[item] === 'Power supplies';
              const installation =
                DOWNLOAD_HEADERS[item] === 'Installation sheet';

              if (values(data[item]).length < 1) {
                return null;
              }

              const headerClasses = (item) => {
                return classNames({
                  [`${styles['filter-header']}`]: true,
                  [`flex-center`]: true,
                  [`${styles['is-open']}`]:
                    active === item || (imagePack && imagePackOpen),
                });
              };

              return (
                <Fragment key={index}>
                  <div key={index} className={headerClasses(item)}>
                    <div className="container">
                      <span
                        className={styles.title}
                        onClick={() => handleClick(item)}
                      >
                        <span className={styles.icon}>
                          {imagePack && (
                            <ImageIcon className={styles['image-big']} />
                          )}
                          {(psu || installation) && (
                            <Pdf className={styles.pdf} />
                          )}
                        </span>
                        <span>
                          <span className={styles.header}>
                            {DOWNLOAD_HEADERS[item]}
                          </span>
                          <DownwardArrow
                            className={styles.arrow}
                            onClick={() => handleClick(item)}
                          />
                        </span>
                      </span>
                    </div>
                  </div>

                  <div
                    className={
                      active === item || (imagePack && imagePackOpen)
                        ? `${styles['filter-options']} container`
                        : `${styles['filter-options']} ${styles.hide} container`
                    }
                  >
                    <div className={styles['subtext-wrapper']}>
                      <div className={styles['select-preview']}>
                        <span className={styles.select}>Select to preview</span>
                      </div>
                    </div>
                    <ul>
                      {values(data[item]).map((subItem, index) => {
                        const filename = subItem.filename;
                        
                        if(!filename) {
                          return null;
                        }

                        const url = subItem.url;
                        const extension = getFileExtension(url);
                        const name = filename.split('.' + extension)[0];
                        
                        return (
                          <li key={filename+index}>
                            <label>
                              <span>{name}</span>
                            </label>
                            {(extension === 'jpg' || extension === 'png') && (
                              <PreviewLinkImage url={url} />
                            )}
                            {extension === 'pdf' && (
                              <PreviewLinkPdf url={url} />
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
