import React, { Fragment } from 'react';
import styles from './style.module.scss';
import keys from 'lodash/fp/keys';
import values from 'lodash/fp/values';
import getFileExtension from 'modules/get-file-extension';
import { SelectedCheckbox, Pdf, Ies, Ldt, Dxf } from 'components/icons';
import {
  DISPLAYED_EXTENSIONS,
  DOWNLOAD_HEADERS,
} from 'constants/spec-code-downloads';
import {
  EXTENSION_IES,
  EXTENSION_LDT,
  EXTENSION_PDF,
} from 'constants/extensions';
import { DownloadCheckbox } from 'components/download-check-box';
import { PreviewLinkPdf } from './preview-link-pdf';

export const LeftColumn = ({ data, disabled }) => {
  return (
    <div className={styles['left-column']}>
      <p className={styles.preview}>preview</p>

      {keys(data).map((item, index) => {
        if (values(data[item]).length < 1) {
          return null;
        }

        const epd = DOWNLOAD_HEADERS[item] === 'epd';
        const dataSheet = DOWNLOAD_HEADERS[item] === 'Datasheet';
        const installation = DOWNLOAD_HEADERS[item] === 'Installation sheet';
        const diagrams = DOWNLOAD_HEADERS[item] === 'DXF Drawings';
        const luminaire = DOWNLOAD_HEADERS[item] === 'Photometric reports';

        return (
          <Fragment key={index}>
            <div className={`${styles['filter-options']} container`}>
              <ul>
                {values(data[item]).map((subItem, index) => {
                  const filename = subItem.filename;

                  if (!filename) {
                    return null;
                  }

                  const url = subItem.url;
                  const extension = getFileExtension(url);
                  const name = filename.split('.' + extension)[0];
                  const pathname = new URL(url).pathname;
                  const showExtension =
                    DISPLAYED_EXTENSIONS.indexOf(extension) > -1
                      ? `.${extension}`
                      : '';

                  return (
                    <li key={filename}>
                      <label htmlFor={filename}>
                        <DownloadCheckbox
                          icon={<SelectedCheckbox />}
                          id={filename}
                          url={pathname.substr(1)}
                          disabled={disabled}
                        />
                        <span className={styles.info}>
                          <span className={styles.icon}>
                            {extension === EXTENSION_IES && (
                              <Ies className={styles.pdf} />
                            )}
                            {extension === EXTENSION_LDT && (
                              <Ldt className={styles.pdf} />
                            )}
                            {diagrams && <Dxf className={styles.pdf} />}
                            {(epd ||
                              luminaire ||
                              dataSheet ||
                              installation) && <Pdf className={styles.pdf} />}
                          </span>
                          <span className={styles.header}>
                            {DOWNLOAD_HEADERS[item]}:
                          </span>
                          <span className={styles.filename}>
                            {name}
                            {showExtension}
                          </span>
                        </span>
                      </label>
                      {extension === EXTENSION_PDF && (
                        <PreviewLinkPdf url={url} />
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};
