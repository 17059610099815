import {
  PRODUCTS_BY_RANGE_REQUEST,
  PRODUCTS_BY_RANGE_RECEIVED,
} from 'redux/actions/products-by-range';

const initialState = {
  hasError: false,
  productsByRange: {},
  loading: true,
};

export default function productsByRange(state = initialState, action) {
  switch (action.type) {
    case PRODUCTS_BY_RANGE_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        productsByRange: {
          ...state.productsByRange,
        },
        loading: true,
      });
    case PRODUCTS_BY_RANGE_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        productsByRange: {
          ...action.productsByRange,
        },
        loading: false,
      });
    default:
      return state;
  }
}
