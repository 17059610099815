import axios from 'axios';

export const getPartner = async (endpoint, id) => {
  try {
    const url = [
      process.env.REACT_APP_CMS_URL,
      endpoint.replace(':id', id),
    ].join('/');
    const response = await axios.get(url);
    const data = await response.data;

    return data;
  } catch (error) {
    throw error;
  }
};

export const getPartnerTypes = async endpoint => {
  try {
    const url = [process.env.REACT_APP_CMS_URL, endpoint].join('/');
    const response = await axios.get(url);
    const data = await response.data;

    return data;
  } catch (error) {
    throw error;
  }
};
