import React, { useState, useEffect } from 'react';
import posed from 'react-pose';
import styles from './style.module.scss';

const Title = posed.h1({
  enter: {
    x: '0',
    delayChildren: 350,
    staggerChildren: 150,
  },
});

const TitlePart = posed.span({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 300,
      ease: 'easeIn',
    },
  },
  exit: { y: 20, opacity: 0 },
});

const Image = posed.img({
  enter: {
    opacity: 1,
    transition: {
      duration: 400,
      ease: 'easeIn',
    },
  },
  exit: { opacity: 0 },
});

export const WhyOrlunaHero = ({ image }) => {
  const [entered, setEntered] = useState(false);

  useEffect(() => {
    setEntered(true);
  }, []);

  return (
    <div className={`${styles['why-hero']} container`}>
      <div className={styles.title}>
        <Title pose={entered ? 'enter' : 'exit'}>
          <TitlePart>why</TitlePart> <TitlePart>orluna?</TitlePart>
        </Title>
        <span className={styles.year}>est. 1973</span>
      </div>
      <div className={styles['image-wrapper']}>
        <Image src={image} alt="" pose={entered ? 'enter' : 'exit'} />
      </div>
    </div>
  );
};
