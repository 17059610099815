import urls from 'constants/urls.json';
import { getProductsByRange } from 'modules/api';

export const PRODUCTS_BY_RANGE_REQUEST = 'PRODUCTS_BY_RANGE_REQUEST';
export const PRODUCTS_BY_RANGE_RECEIVED = 'PRODUCTS_BY_RANGE_RECEIVED';

export const requestProductsByRange = (id) => {
  return { type: PRODUCTS_BY_RANGE_REQUEST, id };
};

export const receiveProductsByRange = (productsByRange) => {
  return { type: PRODUCTS_BY_RANGE_RECEIVED, productsByRange };
};

export const fetchProductsByRange = (id) => {
  return async (dispatch) => {
    dispatch(requestProductsByRange());

    const productsByRange = await getProductsByRange(
      urls['products-by-range'],
      id
    );

    dispatch(receiveProductsByRange(productsByRange));
  };
};
