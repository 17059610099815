import React, { useEffect, useRef } from 'react';
import videoMP4 from '../../assets/lottie/Loop_16-19_OPTION-1.mp4';
import videoWEBM from '../../assets/lottie/Loop_16-19_OPTION-1.webm';
import gsap from 'gsap';
import styles from './style.module.scss';
import { Arrow } from 'components/icons';

//import gsap from "gsap";

export function CircularInteractive(props) {
  let isAnimating = false;
  let vidStarted = useRef(false);
  const vidObject = useRef(false);
  const timeline = gsap.timeline();

  // Watch for currentTime changes
  function videoTracker(video) {
    video.addEventListener('timeupdate', () => {
      if (!isAnimating) {
        if (video.currentTime > 0 && video.currentTime < 0.75) {
          isAnimating = true;
          stopFunc('point1', video);
        }
        if (video.currentTime > 2 && video.currentTime < 2.75) {
          isAnimating = true;
          stopFunc('point2', video);
        }
        if (video.currentTime > 3.36 && video.currentTime < 4.01) {
          isAnimating = true;
          stopFunc('point3', video);
        }
        if (video.currentTime > 5.12 && video.currentTime < 5.87) {
          isAnimating = true;
          stopFunc('point4', video);
        }
        if (video.currentTime > 7 && video.currentTime < 7.7) {
          isAnimating = true;
          stopFunc('point5', video);
        }
      }
      // Find the time to be triggered
    });
  }

  function stopFunc(position, video) {
    // Get the element
    const elementToFadeIn = document.getElementById(position);

    if (elementToFadeIn) {
      if (position === 'point5') {
        elementToFadeIn.style.display = 'block';
        const title = elementToFadeIn.querySelector('div img');
        const cta = elementToFadeIn.querySelector('.' + styles['play-again']);
        /*
        const ctaText = elementToFadeIn.querySelector(
          '.' + styles['play-'] + ' span'
        );
        */

        timeline.fromTo(
          title,
          { y: 30 },
          {
            y: 0,
            opacity: 1,
            ease: 'power1.inOut',
            duration: 0.35,
            onComplete: () => {},
          }
        );
        timeline.fromTo(
          '#point5 div p',
          { y: 30 },
          {
            y: 0,
            opacity: 1,
            duration: 0.35,
            ease: 'power1.inOut',
            stagger: 0.1,
            onComplete: () => {},
          },
          '>-0.2'
        );
        timeline.fromTo(
          cta,
          { x: 0 },
          {
            x: 0,
            opacity: 1,
            ease: 'power1.inOut',
            duration: 0.35,
            onComplete: () => {
              timeline.pause();
            },
          },
          '>-0.2'
        );

        setTimeout(function () {
          timeline.to(title, {
            duration: 0.35,
            y: -30,
            ease: 'power1.inOut',
            opacity: 0,
          });
          timeline.to(
            '#point5 div p',
            {
              duration: 0.35,
              y: -30,
              ease: 'power1.inOut',
              stagger: 0.1,
              opacity: 0,
            },
            '>-0.2'
          );
          timeline.to(
            cta,
            {
              duration: 0.35,
              x: 0,
              ease: 'power1.inOut',
              opacity: 0,
              onComplete: () => {
                elementToFadeIn.style.display = 'none';
                vidObject.current.currentTime = 0;
                vidObject.current.play();
              },
            },
            '>-0.2'
          );
          isAnimating = false;
        }, 3500);
      } else {
        elementToFadeIn.style.display = 'block';
        const number = elementToFadeIn.querySelector('div h2');
        const text = elementToFadeIn.querySelector('div p');
        const cta = elementToFadeIn.querySelector('.' + styles['below']);
        const ctaText = elementToFadeIn.querySelector(
          '.' + styles['below'] + ' span'
        );

        // Run animations by setting timeline

        timeline.fromTo(
          number,
          { y: 30 },
          {
            y: 0,
            opacity: 1,
            ease: 'power1.inOut',
            duration: 0.35,
            onComplete: () => {},
          }
        );
        timeline.fromTo(
          text,
          { y: 30 },
          {
            y: 0,
            opacity: 1,
            ease: 'power1.inOut',
            duration: 0.35,
            onComplete: () => {},
          },
          '>-0.2'
        );
        timeline.fromTo(
          cta,
          { x: -30 },
          {
            x: 0,
            opacity: 1,
            ease: 'power1.inOut',
            duration: 0.35,
            onComplete: () => {},
          },
          '>-0.2'
        );
        timeline.fromTo(
          ctaText,
          { x: -30 },
          {
            x: 0,
            opacity: 1,
            ease: 'power1.inOut',
            duration: 0.35,
            onComplete: () => {
              video.pause();
              timeline.pause();
            },
          },
          '>-0.2'
        );

        setTimeout(function () {
          timeline.to(number, {
            duration: 0.35,
            y: -30,
            ease: 'power1.inOut',
            opacity: 0,
          });
          timeline.to(
            text,
            { duration: 0.35, y: -30, ease: 'power1.inOut', opacity: 0 },
            '>-0.2'
          );
          timeline.to(
            cta,
            { duration: 0.35, x: 30, ease: 'power1.inOut', opacity: 0 },
            '>-0.2'
          );
          timeline.to(
            ctaText,
            {
              duration: 0.35,
              x: 30,
              ease: 'power1.inOut',
              opacity: 0,
              onComplete: () => {
                elementToFadeIn.style.display = 'none';
              },
            },
            '>-0.2'
          );
          isAnimating = false;
        }, 800);
      }
    }
  }

  useEffect(() => {
    videoTracker(vidObject.current);

    const nextStepsLinks = document.querySelectorAll('.' + styles['below']);

    [...nextStepsLinks].forEach((link) => {
      link.addEventListener('click', () => {
        if (!isAnimating) {
          vidObject.current.play();
          timeline.play();
        }
      });
    });

    const playAgainLinks = document.querySelectorAll(
      '.' + styles['play-again']
    );

    [...playAgainLinks].forEach((link) => {
      link.addEventListener('click', () => {
        timeline.play();
      });
    });

    const interactiveBlock = document.querySelector(
      '.' + styles['circular-interactive']
    );

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !vidStarted.current) {
          vidObject.current.play();
          vidStarted.current = true;
        } else {
        }
      });
    });

    observer.observe(interactiveBlock);
  });

  return (
    <section className={styles['circular-interactive']}>
      <div className="container">
        <div>
          <div className={styles['video-overlay']}></div>
          <video ref={vidObject} muted="muted">
            <source src={videoWEBM} type="video/webm" />
            <source src={videoMP4} type="video/mp4" />
          </video>
        </div>
        <div className={styles['notes-wrapper']}>
          <div id="point1" className={styles['side-note']}>
            <div>
              <h2>1</h2>
              <div>
                <p>{props.point1} </p>
                <p className={styles['below']}>
                  {' '}
                  <span>Next Step</span>
                  <Arrow />
                </p>
              </div>
            </div>
          </div>
          <div id="point2" className={styles['side-note']}>
            <div>
              <h2>2</h2>
              <div>
                <p>{props.point2} </p>
                <p className={styles['below']}>
                  {' '}
                  <span>Next Step</span>
                  <Arrow />
                </p>
              </div>
            </div>
          </div>
          <div id="point3" className={styles['side-note']}>
            <div>
              <h2>3</h2>
              <div>
                <p>{props.point3} </p>
                <p className={styles['below']}>
                  {' '}
                  <span>Next Step</span>
                  <Arrow />
                </p>
              </div>
            </div>
          </div>
          <div id="point4" className={styles['side-note']}>
            <div>
              <h2>4</h2>
              <div>
                <p>{props.point4}</p>
                <p className={styles['below']}>
                  {' '}
                  <span>Next Step</span>
                  <Arrow />
                </p>
              </div>
            </div>
          </div>
          <div id="point5" className={styles['final-side-note']}>
            <div>
              <img
                src="https://orluna-assets.bravestaging.site/uploads/2022/02/circular_product_WM-salmon.png"
                alt="Circular Product"
              />
              <p>
                <span>1.</span> {props.point1}
              </p>

              <p>
                <span>2.</span> {props.point2}
              </p>
              <p>
                <span>3.</span> {props.point3}
              </p>
              <p>
                <span>4.</span> {props.point4}{' '}
              </p>
              <p className={styles['play-again']}>
                {' '}
                <b>
                  <span>Play Again</span>
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
