import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'hooks/use-window-size';
import { htmlToReactParser } from 'modules/html-to-react';
import VisibilitySensor from 'react-visibility-sensor';
import posed from 'react-pose';
import { SlideRight, SlideUp } from 'components/animations';

import styles from './style.module.scss';

const TopGroup = posed.div({
  enter: {
    x: '0',
    delayChildren: 550,
    staggerChildren: 150,
  },
});

const TopElement = posed.span({
  enter: {
    opacity: 1,
    transition: {
      duration: 500,
      ease: 'easeIn',
    },
  },
  exit: { opacity: 0 },
});

export const StoryDescription = ({
  imageTop,
  imageRight,
  featuredText,
  singleImage,
  singleImageText,
}) => {
  const [entered, setEntered] = useState(false);
  const [isVisible, setVisibility] = useState(false);
  const { width } = useWindowSize();
  const isLarge = width > 970;

  useEffect(() => {
    if (isVisible) {
      setEntered(true);
    }
  }, [isVisible]);

  const handleOnView = visiblity => setVisibility(visiblity);

  const topImage = (
    <TopElement>
      <img className={styles['top-image']} src={imageTop} alt="" />
    </TopElement>
  );

  const topDescription = (
    <div className={styles['top-description']}>
      <TopElement>{htmlToReactParser.parse(featuredText)}</TopElement>
    </div>
  );

  return (
    <section className={`${styles['our-story']} section container`}>
      <VisibilitySensor partialVisibility onChange={handleOnView}>
        <TopGroup
          className={styles['top-group']}
          pose={entered ? 'enter' : 'exit'}
        >
          {isLarge && (
            <div className={styles['image-and-description']}>
              {topImage}
              {topDescription}
            </div>
          )}
          {!isLarge && (
            <>
              {topImage}
              {topDescription}
            </>
          )}
          <div className={styles['top-right-image']}>
            <TopElement>
              <img src={imageRight} alt="" />
            </TopElement>
          </div>
        </TopGroup>
      </VisibilitySensor>
      <div className={styles['bottom-group']}>
        <div className={styles['bg-color']} />
        <SlideRight>
          <div className={styles['bottom-image']}>
            <img src={singleImage} alt="" />
          </div>
        </SlideRight>
        <SlideUp>
          <div className={styles['bottom-description']}>
            {htmlToReactParser.parse(singleImageText)}
          </div>
        </SlideUp>
      </div>
    </section>
  );
};
