import flatten from 'lodash/fp/flatten';
import uniqBy from 'lodash/fp/uniqBy';
import head from 'lodash/fp/head';
import {
  DICTIONARY,
  SHOULD_FILTER,
  SPEC_CODE_EMERGENCY,
  SPEC_CODE_E,
} from 'constants/spec-code-downloads';

/**
 * Get a list of unique engine types from product data
 * @param {Object} data
 * @return {Array}
 */
export const getUniqueEngineTypes = (data) =>
  uniqBy(
    (item) => item.engine_type.value,
    flatten(data.variants.map(({ engines }) => engines))
  );

/**
 * Get a specific variant from product data
 * @param {Object} data
 * @param {String} _variant
 * @return {Array}
 */
export const getVariant = (data, _variant) =>
  head(
    data.variants.filter((variant) => variant.fitting_code.value === _variant)
  );

/**
 * Checks if search term is present in array
 * @param {Array} codes
 * @param {String} term
 * @return {Boolean}
 */
export const existsInArray = (codes, term) => codes.indexOf(term) > -1;

/**
 * Checks if search term is present in array or string
 * @param {String} file
 * @param {String} term
 * @return {Boolean}
 */
export const existsInString = (file, term) => {
  try {
    return file.toLowerCase().indexOf(term.toLowerCase()) > -1;
  } catch (e) {
    return false;
  }
};
/**
 * Get a specific engine from product variant
 * @param {Object} data
 * @param {String} _engine
 * @return {Array}
 */
export const getEngine = (data, _engine) =>
  head(data.engines.filter((engine) => engine.engine_type.value === _engine));

/**
 * Get a filtered download files based on spec code selection
 * @param {Object} data
 * @param {Object} specCode
 * @return {Object}
 */
export const getDownloadFiles = (data, specCode) => {
  const filtered = {};
  const engine = getEngine(
    getVariant(data, specCode.fitting_codes),
    specCode.engine_types
  );
  const isEmergency = existsInArray(specCode.options, SPEC_CODE_E);

  Object.entries(engine.downloads).map((item) => {
    const files =
      SHOULD_FILTER.indexOf(item[0]) > -1
        ? item[1].filter(
            (file) =>
              (existsInString(file.filename, specCode.colour_temperature) &&
                existsInString(
                  file.filename,
                  `_${DICTIONARY.beam_angles[specCode.beam_angles]}`
                )) ||
              existsInString(
                file.filename,
                DICTIONARY.drivers[specCode.drivers]
              )
          )
        : item[1];

    // filter out the emergency files unless specified
    const filteredFiles = isEmergency
      ? files
      : files.filter(
          (file) => !existsInString(file.filename, SPEC_CODE_EMERGENCY)
        );

    return (filtered[item[0]] = filteredFiles);
  });

  return filtered;
};
