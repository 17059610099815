import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFaqs } from 'redux/actions';
import classNames from 'classnames';
import { values } from 'lodash/fp';
import { FaqsDropdown } from 'components/faqs-dropdown';
import { Loading } from 'components/loading';
import { ScrollTo } from 'components/scroll-to';
import { FloatingLogo } from 'components/floating-logo';
import { SECTION_FAQS } from 'constants/section-name';
import { SIZE_BIG } from 'constants/sizes';
import styles from './style.module.scss';

export const Faqs = () => {
  const dispatch = useDispatch();
  const [scrollTo, setScrollTo] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeFaqs, setActiveFaqs] = useState(null);
  const { faqs, loading } = useSelector((state) => state.faqs);

  useEffect(() => {
    dispatch(fetchFaqs());
  }, [dispatch]);

  useEffect(() => {
    if (!loading) {
      setActiveCategory(faqs[0].category);
    }
  }, [faqs, loading]);

  useEffect(() => {
    setScrollTo(false);
  }, [scrollTo]);

  useEffect(() => {
    if (activeCategory) {
      setActiveFaqs(
        values(faqs).filter((option) => option.category === activeCategory)
      );
    }
  }, [activeCategory, faqs]);

  if (loading) return <Loading />;

  return (
    <section className={`${styles.faq} container`}>
      <FloatingLogo section={SECTION_FAQS} size={SIZE_BIG} />
      <h1>frequently asked questions</h1>
      <div className={styles.wrapper}>
        {values(faqs)
          .filter((option) => option.category !== 'FEATURED')
          .map((option, index) => (
            <button
              className={classNames(styles.option, {
                [styles.active]: option.category === activeCategory,
              })}
              key={`faq_options_${index}`}
              onClick={() => {
                setActiveCategory(option.category);
                setScrollTo(true);
              }}
            >
              {option.category}
            </button>
          ))}
      </div>
      {scrollTo && <ScrollTo />}
      <FaqsDropdown data={activeFaqs} />
    </section>
  );
};
