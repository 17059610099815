import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

export const DownloadContainer = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

DownloadContainer.propTypes = {
  // array of elements
  children: PropTypes.array.isRequired
}