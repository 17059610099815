import { PEOPLE_REQUEST, PEOPLE_RECEIVED } from 'redux/actions/people';

const initialState = {
  hasError: false,
  people: {},
  peopleLoading: true,
};

export default function people(state = initialState, action) {
  switch (action.type) {
    case PEOPLE_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        people: {
          ...state.people,
        },
        peopleLoading: true,
      });
    case PEOPLE_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        people: {
          ...state.people,
          ...action.people,
        },
        peopleLoading: false,
      });
    default:
      return state;
  }
}
