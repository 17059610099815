import React, { useState, useEffect } from 'react';
import urls from 'constants/urls.json';
import { getNewsPost } from 'modules/api';
import { Listing } from 'components/listing';
import { Loading } from 'components/loading';

export const NewsFeatured = ({ news }) => {
  const [loading, isLoading] = useState(true);
  const [newsData, setNewsData] = useState({});

  useEffect(() => {
    async function fetchProject() {
      const result = await getNewsPost(urls['news-post'], news.post_name);
      setNewsData(result);
      isLoading(false);
    }

    fetchProject();
  }, [news.post_name]);

  if (loading) return <Loading />;

  return <Listing data={[newsData]} isNews isFeatured />;
};
