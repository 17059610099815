import {
  PRODUCTS_OPEN_MAIN_FILTERS,
  PRODUCTS_CLOSE_MAIN_FILTERS,
  PRODUCTS_RECEIVED,
  PRODUCTS_REQUEST,
  PRODUCTS_RESET_FILTERS,
  PRODUCTS_SET_FILTER,
  PRODUCTS_TOGGLE_FILTER,
  PRODUCTS_TOGGLE_FILTERS,
  PRODUCTS_VIEW,
  PRODUCTS_SET_SEARCHED,
} from 'redux/actions/products';
import { GRID } from 'constants/view';

const initialState = {
  loading: true,
  filters: {},
  mainFiltersOpen: false,
  hasFiltered: false,
  singleFilter: '',
  searchedProducts: [],
  view: GRID,
};

const toggleFilter = (filters = [], filter) => {
  const index = filters.indexOf(filter);
  if (index > -1) {
    filters.splice(index, 1);
  } else {
    filters.push(filter);
  }
  return filters;
};

function results(
  state = { queryLoading: true, products: [], availableTerms: [] },
  action
) {
  switch (action.type) {
    case PRODUCTS_REQUEST:
      return Object.assign({}, state, {
        queryLoading: true,
      });

    case PRODUCTS_RECEIVED:
      return Object.assign({}, state, {
        queryLoading: false,
        availableTerms: action.availableTerms,
        products: action.products,
      });

    default:
      return state;
  }
}

export default function products(state = initialState, action) {
  switch (action.type) {
    case PRODUCTS_OPEN_MAIN_FILTERS:
      return Object.assign({}, state, {
        mainFiltersOpen: true,
      });

    case PRODUCTS_CLOSE_MAIN_FILTERS:
      return Object.assign({}, state, {
        mainFiltersOpen: false,
      });

    case PRODUCTS_TOGGLE_FILTERS:
      return Object.assign({}, state, {
        mainFiltersOpen: !state.mainFiltersOpen,
      });

    case PRODUCTS_TOGGLE_FILTER:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          [action.name]: toggleFilter(state.filters[action.name], action.value),
        }),
        hasFiltered: true,
      });

    case PRODUCTS_SET_FILTER:
      return Object.assign({}, state, {
        singleFilter: action.value,
        hasFiltered: true,
      });

    case PRODUCTS_RESET_FILTERS:
      return Object.assign({}, state, {
        filters: {},
        hasFiltered: false,
        singleFilter: '',
      });

    case PRODUCTS_SET_SEARCHED:
      return Object.assign({}, state, {
        searchedProducts: action.products,
      });

    case PRODUCTS_REQUEST:
    case PRODUCTS_RECEIVED:
      return Object.assign({}, state, {
        [action.hash]: results(state[action.hash], action),
      });

    case PRODUCTS_VIEW:
      return Object.assign({}, state, {
        view: action.view,
      });

    default:
      return state;
  }
}
