import React from 'react';
import Select from 'react-select';
import { find } from 'lodash/fp';
import styles from './styles.module.scss';

export const DownloadSelect = ({
  name,
  label,
  options,
  disabled,
  loading,
  onChange,
  value,
}) => {
  let selected = find({ value: value })(options);
  const handleChange = selected => onChange(name, selected.value);
  const defaultValue = { label: 'All', value: '' };

  selected = selected === undefined ? defaultValue : selected;

  return (
    <div className={styles.select}>
      <label className={styles.label} htmlFor="products">
        {label}
      </label>
      <Select
        id="products"
        name={name}
        onChange={handleChange}
        options={options}
        isDisabled={disabled}
        className="react-select-container"
        classNamePrefix="react-select"
        isLoading={loading}
        value={selected}
      />
    </div>
  );
};

DownloadSelect.defaultProps = {
  disabled: false,
  loading: true,
};
