import React from 'react';

export const ImageIcon = props => (
  <svg data-name="Layer 1" viewBox="0 0 39 30" {...props}>
    <path
      d="M29 17.18l8.25 9.18v1a.86.86 0 01-.87.89h-8.69l-4.33-4.95zm-16.45-3.52l12.81 14.58H2.6a.86.86 0 01-.87-.89v-1.86zm10-7.48A3.53 3.53 0 1026 9.71a3.51 3.51 0 00-3.47-3.53zM2.6 1.76h33.8a.86.86 0 01.87.89v21.09l-7.6-8.46A.88.88 0 0029 15a.82.82 0 00-.58.28L22.19 22l-9-10.22a.9.9 0 00-.79-.29.84.84 0 00-.5.28L1.73 22.9V2.65a.86.86 0 01.87-.89zM2.6 0A2.64 2.64 0 000 2.65v24.7A2.64 2.64 0 002.6 30h33.8a2.64 2.64 0 002.6-2.65V2.65A2.64 2.64 0 0036.4 0z"
      fill=""
      fillRule="evenodd"
    />
  </svg>
);
