import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import posed from 'react-pose';

const PoseDiv = posed.div({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 700,
      ease: 'easeInOut',
    },
  },
  exit: { x: -200, opacity: 0 },
});

export const SlideRight = ({ children }) => {
  const [isVisible, setVisibility] = useState(false);
  const [entered, setEntered] = useState(false);

  const onChange = visiblity => {
    setVisibility(visiblity);
  };

  useEffect(() => {
    if (isVisible) {
      setEntered(true);
    }
  }, [isVisible]);

  return (
    <>
      <VisibilitySensor
        partialVisibility
        offset={{ top: 100 }}
        onChange={onChange}
      >
        <PoseDiv pose={entered ? 'enter' : 'exit'}>{children}</PoseDiv>
      </VisibilitySensor>
    </>
  );
};
