import { useState, useEffect } from 'react';

export function useScrolling(timer) {
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [scrollX, setScrollX] = useState(0);

  const scrollFinished = () => {
    setIsScrolling(false);
  };

  useEffect(() => {
    let scrollTimer = -1;

    const handleScroll = () => {
      const fromTop = window.pageYOffset;
      const fromLeft = window.pageXOffset;

      setScrollY(fromTop);
      setScrollX(fromLeft);
      setIsScrolling(true);

      if (scrollTimer !== -1) {
        clearTimeout(scrollTimer);
      }
      scrollTimer = window.setTimeout(scrollFinished, timer);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimer);
    };
    //eslint-disable-next-line
  }, []);

  return { isScrolling, scrollY, scrollX };
}
