import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import { submitSignUpReset } from 'redux/actions';
import { SignUpForm } from 'components/forms/signup-form';
import { SignUpTitle } from 'components/sign-up-title';
import { Loading } from 'components/loading';
import { Spacer } from 'components/spacer';
import styles from './style.module.scss';

export const SignUpSection = () => {
  const { loading, success, hasError, visibility } = useSelector(
    (state) => state.signUpForm
  );
  const dispatch = useDispatch();
  const [entered, setEntered] = useState(false);
  const [isVisible, setVisibility] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setEntered(true);
    }
    if (success) {
      const reset = () => dispatch(submitSignUpReset());
      setTimeout(reset, 5000);
    }
  }, [isVisible, success, dispatch]);

  const handleOnView = (viewVisibility) => setVisibility(viewVisibility);

  if (visibility) {
    return (
      <VisibilitySensor onChange={handleOnView}>
        <section className={`${styles['sign-up']} section`}>
          <div className={`${styles.wrapper} container`}>
            <SignUpTitle title="be the first to know" entered={entered} />
            {!success && (
              <div className={styles.content}>
                <p>
                  Sign up to emails to hear about special offers, new products
                  and more.
                </p>
                <div className={styles['form-wrapper']}>
                  <SignUpForm formContext="/" />
                </div>
              </div>
            )}
            {loading && <Loading overlay fit />}
            {success && (
              <div className={styles.confirmation}>
                <p>
                  Great, you're now signed up to receive our newsletters. You
                  can unsubscribe at anytime.
                </p>
              </div>
            )}
            {hasError && (
              <div className={styles.error}>
                <p>
                  Sorry there was an error submitting the form. Please try again
                  later.
                </p>
              </div>
            )}
          </div>
        </section>
      </VisibilitySensor>
    );
  } else {
    return <Spacer />;
  }
};
