import urls from 'constants/urls.json';
import { getProduct } from 'modules/api';

export const PRODUCT_REQUEST = 'PRODUCT_REQUEST';
export const PRODUCT_RECEIVED = 'PRODUCT_RECEIVED';
export const SET_ACTIVE_ENGINE = 'SET_ACTIVE_ENGINE';
export const SET_ACTIVE_VARIANT = 'SET_ACTIVE_VARIANT';

export const setActiveEngine = (engine) => {
  return { type: SET_ACTIVE_ENGINE, engine };
};

export const setActiveVariant = (variant) => {
  return { type: SET_ACTIVE_VARIANT, variant };
};

export const requestProduct = (slug) => {
  return { type: PRODUCT_REQUEST, slug };
};

export const receiveProduct = (slug, product) => {
  return { type: PRODUCT_RECEIVED, slug, product };
};

export const fetchProduct = (slug) => {
  return async (dispatch) => {
    dispatch(requestProduct());

    const product = await getProduct(urls['product'], slug);

    dispatch(receiveProduct(slug, product));
  };
};

function shouldFetchProduct(state, slug) {
  const product = state.product.data[slug];

  if (!product) {
    return true;
  } else if (product.loading) {
    return false;
  } else {
    return false;
  }
}

export const fetchProductIfNeeded = (slug) => {
  return (dispatch, getState) => {
    if (shouldFetchProduct(getState(), slug)) {
      return dispatch(fetchProduct(slug));
    } else {
      Promise.resolve();
    }
  };
};
