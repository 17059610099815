import React, { Fragment } from 'react';

export const Headings = ({ headings }) => {
  return (
    <>
      {headings.map((head, index) => {
        return (
          <Fragment key={head + index}>
            <h4>{head.heading}</h4>
            <ul>
              {head.items.map((item, index) => {
                return <li key={item.item + index}>{item.item}</li>;
              })}
            </ul>
          </Fragment>
        );
      })}
    </>
  );
};
