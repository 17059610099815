import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { rootReducer } from 'redux/reducers';

const initialState = {};
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

export const configureStore = () => {
  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancer(applyMiddleware(routerMiddleware(history), thunkMiddleware))
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('redux/reducers', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
};
