import urls from 'constants/urls.json';
import { submitForm } from 'modules/api';

export const SUBMIT_GET_IN_TOUCH_REQUEST = 'SUBMIT_GET_IN_TOUCH_REQUEST';
export const SUBMIT_GET_IN_TOUCH_SUCCESS = 'SUBMIT_GET_IN_TOUCH_SUCCESS';
export const SUBMIT_GET_IN_TOUCH_RESET = 'SUBMIT_GET_IN_TOUCH_RESET';
export const SUBMIT_GET_IN_TOUCH_ERROR = 'SUBMIT_GET_IN_TOUCH_ERROR';

export const submitGetInTouchRequest = () => {
  return { type: SUBMIT_GET_IN_TOUCH_REQUEST };
};

export const submitGetInTouchSuccess = () => {
  return { type: SUBMIT_GET_IN_TOUCH_SUCCESS };
};

export const submitGetInTouchReset = () => {
  return { type: SUBMIT_GET_IN_TOUCH_RESET };
};

export const submitGetInTouchError = () => {
  return { type: SUBMIT_GET_IN_TOUCH_ERROR };
};

export const submitGetInTouch = (formData) => {
  return async (dispatch) => {
    dispatch(submitGetInTouchRequest());

    const response = await submitForm(urls['formSignUp'], formData);

    if (response.status === 500) {
      dispatch(submitGetInTouchError());
      return false;
    }

    dispatch(submitGetInTouchSuccess());
  };
};
