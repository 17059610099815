import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { CodeAndLabels } from 'components/quick-spec/code-and-labels';
import { Summary } from 'components/quick-spec/summary';
import { SpecBar } from 'components/quick-spec/spec-bar';
import styles from './style.module.scss';

export const QuickSpec = ({
  data,
  smallScreen,
  mainImage,
  productName,
  drawingOne,
  drawingTwo,
}) => {
  const [toggleSpec, setToggleSpec] = useState(false);
  const [closeSpec, setCloseSpec] = useState(false);

  useEffect(() => {
    if (toggleSpec) {
      document.body.classList.add('overflow-hidden');
    }

    if (smallScreen) {
      setToggleSpec(false);
    }

    if (closeSpec || !toggleSpec || smallScreen) {
      document.body.classList.remove('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [toggleSpec, closeSpec, smallScreen]);

  const specClasses = classNames({
    [`${styles['quick-spec']}`]: true,
    [`${styles['is-open']}`]: toggleSpec,
    [`${styles.close}`]: closeSpec,
  });

  return (
    <>
      {!toggleSpec && (
        <SpecBar
          data={data}
          toggleSpec={toggleSpec}
          setToggleSpec={setToggleSpec}
          setCloseSpec={setCloseSpec}
          productName={productName}
        />
      )}
      <div className={specClasses}>
        <div className={styles.wrapper}>
          <SpecBar
            data={data}
            toggleSpec={toggleSpec}
            setToggleSpec={setToggleSpec}
            setCloseSpec={setCloseSpec}
            productName={productName}
          />
          <div className={styles['info-wrapper']}>
            <div className={styles['code-container']}>
              <CodeAndLabels data={data} toggleSpec={toggleSpec} />
            </div>
            <Summary
              data={data}
              mainImage={mainImage}
              drawingOne={drawingOne}
              drawingTwo={drawingTwo}
            />
          </div>
        </div>
      </div>
    </>
  );
};
