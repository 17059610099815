import {
  PRODUCT_RANGE_REQUEST,
  PRODUCT_RANGE_RECEIVED,
} from 'redux/actions/product-range';

const initialState = {
  hasError: false,
  productRange: {},
  loading: true,
};

export default function productRange(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_RANGE_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        productRange: {
          ...state.productRange,
        },
        loading: true,
      });
    case PRODUCT_RANGE_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        productRange: {
          ...action.productRange,
        },
        loading: false,
      });
    default:
      return state;
  }
}
