import React from 'react';
import PropTypes from 'prop-types';
import { Info } from 'components/icons';
import { Headings } from 'components/tooltip-spec-code/headings';
import styles from './style.module.scss';

export const TooltipSpecCode = ({ title, headings }) => {
  return (
    <>
      <span className={styles.tooltip}>
        <Info />
        <div>
          <h3>{title}</h3>
          <Headings headings={headings} />
        </div>
      </span>
    </>
  );
};

TooltipSpecCode.propTypes = {
  title: PropTypes.string.isRequired,
  headings: PropTypes.array.isRequired,
};
