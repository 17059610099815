import {
  SPEC_CODE_SUBMIT,
  SPEC_CODE_CLEAN,
  SPEC_CODE_GET_OPTIONS,
} from 'redux/actions/spec-code';

const initialState = {
  loading: true,
  hasError: false,
  selected: {
    engine_type: {},
    fitting_codes: {},
    colour_temperature: {},
    drivers: {},
    beam_angles: {},
    bezel_colours: {},
    baffle_colours: {},
    options: [],
  },
  optionsAvailable: {},
};

export default function specCode(state = initialState, action) {
  switch (action.type) {
    case SPEC_CODE_CLEAN:
      return Object.assign({}, state, {
        hasError: false,
        loading: false,
        selected: {
          ...state.selected,
          colour_temperature: {},
          drivers: {},
          beam_angles: {},
          bezel_colours: {},
          baffle_colours: {},
          options: [],
        },
      });
    case SPEC_CODE_SUBMIT:
      return Object.assign({}, state, {
        hasError: false,
        loading: true,
        selected: {
          ...state.selected,
          ...action.selection,
        },
      });
    case SPEC_CODE_GET_OPTIONS:
      return Object.assign({}, state, {
        optionsAvailable: {
          ...state.optionsAvailable,
          ...action.options,
        },
      });
    default:
      return state;
  }
}
