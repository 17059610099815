import {
  PRODUCT_FAMILY_REQUEST,
  PRODUCT_FAMILY_RECEIVED,
} from 'redux/actions/product-family';

const initialState = {
  hasError: false,
  productFamily: {},
  loading: true,
};

export default function productFamily(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_FAMILY_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        productFamily: {
          ...state.productFamily,
        },
        loading: true,
      });
    case PRODUCT_FAMILY_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        productFamily: action.productFamily,
        loading: false,
      });
    default:
      return state;
  }
}
