export const DOWNLOAD_CHECKBOX_REQUEST = 'DOWNLOAD_CHECKBOX_REQUEST';
export const DOWNLOAD_CHECKBOX_RECIEVED = 'DOWNLOAD_CHECKBOX_RECIEVED';

export const DOWNLOADS_TOGGLE_CHECKBOX = 'DOWNLOADS_TOGGLE_CHECKBOX';
export const DOWNLOADS_RESET_CHECKBOXES = 'DOWNLOADS_RESET_CHECKBOXES';
export const DOWNLOADS_SELECT_ALL_CHECKBOXES =
  'DOWNLOADS_SELECT_ALL_CHECKBOXES';

export const receiveDownloadCheckbox = (items, name, current, filePath) => {
  return { type: DOWNLOAD_CHECKBOX_RECIEVED, items, name, current, filePath };
};

export const toggleCheckbox = url => {
  return { type: DOWNLOADS_TOGGLE_CHECKBOX, url };
};

export const selectAllCheckboxes = ids => {
  return { type: DOWNLOADS_SELECT_ALL_CHECKBOXES, ids };
};

export const resetCheckboxes = () => {
  return { type: DOWNLOADS_RESET_CHECKBOXES };
};
