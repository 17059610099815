import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeMainFilters, toggleRangeNavigation } from 'redux/actions';
import { useScrolling } from 'hooks/use-scrolling';
import classNames from 'classnames';
import { DownwardArrow } from 'components/icons';
import styles from './style.module.scss';

export const RangeNavigationButton = ({ isProductPage }) => {
  const { rangeNavIsOpen } = useSelector((state) => state.rangeNavigation);
  const { scrollY, isScrolling } = useScrolling(15);
  const dispatch = useDispatch();

  const classes = classNames({
    [`${styles['range-button']}`]: true,
    [`${styles['is-open']}`]: rangeNavIsOpen,
    [`${styles['is-product-page']}`]: isProductPage,
    /* is-visible check is different here because on tablet
     * we don't want it to disappear after scroll */
    [`${styles['is-visible']}`]: (isProductPage && isScrolling) || scrollY > 41,
    /* below fixes native “bounce” behaviour on safari when scrolling
     * without losing the the smooth opacity animation */
    [`${styles['display-none']}`]: scrollY < 3 && isProductPage,
    [`${styles['display-inline']}`]: scrollY > 5 && scrollY < 10,
  });

  const handleClick = () => {
    dispatch(closeMainFilters());
    dispatch(toggleRangeNavigation());
  };

  return (
    <button onClick={handleClick} className={classes}>
      range
      <DownwardArrow />
    </button>
  );
};
