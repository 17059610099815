import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  fetchProductIfNeeded,
  fetchLitEffects,
  fetchBookConsultation,
  fetchRangeGridProduct,
  fetchRangeNavigation,
  fetchRanges,
  fetchProductRange,
  fetchGetInTouch,
} from 'redux/actions';
import values from 'lodash/fp/values';
import head from 'lodash/fp/head';
import { setActiveVariant } from 'redux/actions/product';
import { useWindowSize } from 'hooks/use-window-size';
import { bpLarge } from 'constants/breakpoints';
import { THEME_DARK } from 'constants/color-theme';
import {
  ROUTE_GET_IN_TOUCH,
  ROUTE_PRODUCTS,
  ROUTE_RANGES,
} from 'constants/router-links';
import { BANNER_NAME_GET_IN_TOUCH_HOME } from 'constants/banners';
import { TITLE_RELATED_PRODUCTS } from 'constants/titles';
import { LABEL_VIEW_ALL_PRODUCTS } from 'constants/labels';
import { CAROUSEL_SMALL_SQUARE } from 'constants/carousel-types';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { ProductHero } from 'components/product-hero';
import { ProductIntro } from 'components/product-intro';
import { Carousel } from 'components/carousel';
import { RelatedProduct } from 'components/related-product';
import { Loading } from 'components/loading';
import { FourOhFour } from 'components/four-oh-four';
import { SlideUp } from 'components/animations';
import { ErrorBoundary } from 'components/error';
import { MediaGallery } from 'components/media-gallery';
import { SelectVariant } from 'components/select-variant';
import { GetInTouch } from 'components/get-in-touch';
import { settingsSquareSmall } from 'components/carousel/settings';
import { SectionWithBackground } from 'components/section-with-background';
import { Callout } from 'components/callout';
import { RangeGrid } from 'components/range-grid';
import { Spacer } from 'components/spacer';

export const ProductDetail = ({ match }) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const smallScreen = width <= bpLarge;
  const { slug, variant } = match.params;
  const [rangeSlug, setRangeSlug] = useState('');
  const [rangeTitle, setRangeTitle] = useState('');
  const [carouselTitle, setCarouselTitle] = useState(TITLE_RELATED_PRODUCTS);
  const { activeVariant, data: product } = useSelector(
    (state) => state.product
  );
  const { bannerImages } = useSelector((state) => state.bookConsultation);
  const { ranges, rangesLoading } = useSelector((state) => state.ranges);
  const { productRange } = useSelector((state) => state.productRange);
  const { grid } = useSelector((state) => state.rangeGridProduct);
  const { banner } = useSelector((state) => state.getInTouchBanner);

  useEffect(() => {
    if (product.variants) {
      const newVariant = product.variants
        .filter(({ name }) => name === variant)
        .shift();
      dispatch(setActiveVariant(newVariant));
    }
  }, [variant, dispatch, product]);

  useEffect(() => {
    dispatch(fetchRanges());
    dispatch(fetchRangeGridProduct());
    dispatch(fetchRangeNavigation());
    dispatch(fetchProductIfNeeded(slug));
    dispatch(fetchLitEffects());
    dispatch(fetchBookConsultation());
  }, [slug, dispatch]);

  /* the following 2 fetches need to be done separately because
   * of the values we need inside the dependecies, and if this was done inside
   * the useEffect above, it would exceed the maximum calls */
  useEffect(() => {
    if (product.ID) {
      dispatch(fetchProductRange(product.ID));
    }
  }, [dispatch, product]);

  useEffect(() => {
    const productRangeArray = values(productRange);

    if (productRangeArray.length) {
      const rangeTitle = head(productRangeArray).title;
      const rangeSlug = head(productRangeArray).slug;
      setCarouselTitle(`related ${rangeTitle}`);
      setRangeTitle(rangeTitle);
      setRangeSlug(rangeSlug);
    }
  }, [productRange]);

  useEffect(() => {
    dispatch(fetchGetInTouch());
  }, [dispatch]);

  // make sure the user get the correct page if URL does not contain the product variant
  if (variant === undefined && product.url)
    return <Redirect to={product.url} />;
  if (product.loading || product.slug === undefined) return <Loading />;
  if (product.slug === 'product-not-found-404') {
    return <FourOhFour />;
  }

  const {
    theme,
    heroImage,
    title,
    cutOut,
    litEffects,
    allVariants,
    productName,
    productIntro,
    relatedProducts,
    featuredMediaAsset,
    specification_code,
    mediaAssets,
    mediaTheme,
    variants,
    callout,
    isCircular,
    circularAttributes,
  } = product;

  return (
    <ErrorBoundary>
      <Header theme={theme} />
      <ProductHero
        theme={theme}
        background={heroImage}
        title={title}
        cutOut={cutOut}
        allVariants={allVariants}
        litEffects={litEffects}
        smallScreen={smallScreen}
      />
      <SlideUp>
        <ProductIntro
          title={title}
          allVariants={allVariants}
          introduction={productIntro}
          smallScreen={smallScreen}
        />
      </SlideUp>
      <MediaGallery
        featuredAsset={featuredMediaAsset}
        medias={mediaAssets}
        theme={mediaTheme}
      />
      <SelectVariant
        productName={productName}
        specificationCode={specification_code}
        variants={variants}
        activeVariant={activeVariant}
        isCircular={isCircular}
        circularAttributes={circularAttributes}
      />
      {/* https://bravemarketing.atlassian.net/browse/OR-1423 */}
      <Callout
        videoDesktop={callout?.desktop.video}
        videoMobile={callout?.mobile.video}
        imageDesktop={callout?.desktop.image}
        imageMobile={callout?.mobile.image}
      />
      {/** This data comes from the Theme Settings
       *  and it might take a little longer to arrive,
       * so we use optional chaining to avoid any error  */}
      {bannerImages.product_detail_page?.display && (
        <SlideUp>
          <GetInTouch
            image={
              smallScreen
                ? bannerImages.product_detail_page.mobile_image
                : bannerImages.product_detail_page.desktop_image
            }
            formContext={product.url}
          />
        </SlideUp>
      )}
      {banner.display_banner && (
        <>
          <Spacer />
          <SectionWithBackground
            theme={THEME_DARK}
            sectionName={BANNER_NAME_GET_IN_TOUCH_HOME}
            description={banner.content.title}
            subDescription={banner.content.description}
            cta={banner.content.button_title}
            link={ROUTE_GET_IN_TOUCH}
            image={
              smallScreen
                ? banner.content.background_mobile
                : banner.content.background_desktop
            }
          />
          {smallScreen && <Spacer />}
        </>
      )}

      {relatedProducts.length > 0 && (
        <SlideUp>
          <Carousel
            type={CAROUSEL_SMALL_SQUARE}
            title={carouselTitle}
            theme={THEME_DARK}
            settings={settingsSquareSmall}
            carouselButton={{
              label:
                carouselTitle === TITLE_RELATED_PRODUCTS
                  ? LABEL_VIEW_ALL_PRODUCTS
                  : `view all ${rangeTitle}`,
              link:
                carouselTitle === TITLE_RELATED_PRODUCTS
                  ? ROUTE_PRODUCTS
                  : `${ROUTE_RANGES}/${rangeSlug}`,
            }}
          >
            {relatedProducts.map((product, index) => (
              <RelatedProduct
                key={index}
                title={product.title}
                slug={product.slug}
                url={product.url}
                thumbnail={product.thumbnail}
              />
            ))}
          </Carousel>
        </SlideUp>
      )}
      {grid.display_range_grid && (
        <RangeGrid
          content={grid.range_grid}
          ranges={ranges}
          loading={rangesLoading}
        />
      )}
      <Footer />
    </ErrorBoundary>
  );
};
