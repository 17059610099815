import React from 'react';
import styles from 'components/check-box/style.module.scss';

export const Twin = props => (
  <svg
    className={styles['filter-icon']}
    data-name="Layer 1"
    viewBox="0 0 57 57"
    {...props}
  >
    <g fill="none">
      <circle cx={28.5} cy={28.5} r={27} strokeWidth={2} />
      <g strokeWidth={2}>
        <ellipse cx={20.5} cy={22} rx={8} ry={3.5} stroke="#555762" />
        <ellipse cx={37.5} cy={35} rx={8} ry={3.5} stroke="#555762" />
      </g>
    </g>
  </svg>
);
