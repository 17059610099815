import {
  PRODUCT_DOWNLOADS_REQUEST,
  PRODUCT_DOWNLOADS_RECEIVED,
} from 'redux/actions/product-downloads';

const initialState = {
  hasError: false,
  loading: false,
  productDownloads: {},
};

export default function productDownloads(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_DOWNLOADS_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        loading: true,
        productDownloads: {
          ...state.productDownloads,
        },
      });
    case PRODUCT_DOWNLOADS_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        loading: false,
        productDownloads: {
          ...action.productDownloads,
        },
      });
    default:
      return state;
  }
}
