import React from 'react';
import styles from 'components/check-box/style.module.scss';

export const SoftBeam = props => (
  <svg
    className={styles['filter-icon']}
    data-name="Layer 1"
    viewBox="0 0 57 57"
    {...props}
  >
    <circle cx={28} cy={28} r={27} fill="none" strokeWidth={2} />
    <path
      d="M28.13 40C18.67 40 11 38.81 11 37.33l.06-.22c.69-2.45 7-24.11 17.13-24.11s16.34 21.67 17 24.11l.06.22C45.27 38.81 37.6 40 28.13 40z"
      fillRule="evenodd"
    />
  </svg>
);
