const selectedColor = 'linear-gradient(225deg, #ffc0ac 0%, #ff8c74 100%)';

export const customStyles = {
  control: () => ({
    padding: 2,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
  }),
  container: (provided, { isDisabled }) => ({
    ...provided,
    opacity: isDisabled ? 0.3 : 1,
    pointerEvents: isDisabled ? 'none' : 'all',
    flex: 1,
    '&:first-of-type': {
      maxWidth: 94,
    },
    '&:nth-of-type(2)': {
      maxWidth: 93,
      textTransform: 'lowercase',
    },
    '&:nth-of-type(3)': {
      div: {
        //colour temp
        '&:nth-of-type(2)': {
          textTransform: 'uppercase',
        },
      },
    },
    '&:nth-of-type(6)': {
      minWidth: 155,
    },
    '&:nth-of-type(7)': {
      minWidth: 155,
    },
    '&:last-of-type': {
      minWidth: 183,
    },
    p: {
      color: '#555762',
      fontSize: 16,
      marginBottom: 0,
      display: 'inline-block',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    border: '1px solid #dfdfdf',
    color: '#1c1c20',
    textTransform: 'uppercase',
    fontSize: 16,
    lineHeight: '20px',

    '@media only screen and (min-width: 1220px)': {
      fontSize: 18,
      lineHeight: '30px',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: 14,
    lineHeight: '18px',
    display: 'flex',
    opacity: 0.4,
    textTransform: 'lowercase',

    '@media only screen and (min-width: 1220px)': {
      fontSize: 16,
      lineHeight: '20px',
    },
  }),
  menu: () => ({
    backgroundColor: 'none',
    marginLeft: 2,
    marginRight: 2,
  }),
  menuList: () => ({
    maxHeight: 'auto',

    div: {
      minHeight: '32px',
    },
  }),
  option: (provided, { isSelected, isDisabled }) => ({
    ...provided,
    background: isSelected ? selectedColor : '#f4f4f4',
    color: isSelected ? 'white' : '#555762',
    cursor: isDisabled ? 'auto' : 'pointer',
    textDecoration: isDisabled ? 'line-through' : 'none',
    borderRadius: 4,
    marginBottom: 2,
    fontSize: 13,
    lineHeight: '15px',
    display: 'flex',
    alignItems: 'center',
    padding: '3px 6px',
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
};
