import React from 'react';
import { useWindowSize } from 'hooks/use-window-size';
import { bpSmall } from 'constants/breakpoints';
import { Header } from 'components/header';
import { Faqs } from 'components/faqs';
import { SectionWithBackground } from 'components/section-with-background';
import { SignUpSection } from 'components/signup-section';
import { Footer } from 'components/footer';
import { ErrorBoundary } from 'components/error';
import { BANNER_NAME_GET_IN_TOUCH } from 'constants/banners';
import { THEME_DARK, THEME_LIGHT } from 'constants/color-theme';
import { ROUTE_GET_IN_TOUCH } from 'constants/router-links';
import { TITLE_GET_IN_TOUCH } from 'constants/titles';
import GetInTouchImage from 'assets/images/get-in-touch.jpg';

export const FaqsScreen = () => {
  const { width } = useWindowSize();
  const smallScreen = width < bpSmall;

  return (
    <ErrorBoundary>
      <Header theme={THEME_LIGHT} />
      <Faqs />
      {smallScreen && <SignUpSection />}
      {!smallScreen && (
        <SectionWithBackground
          theme={THEME_DARK}
          sectionName={BANNER_NAME_GET_IN_TOUCH}
          description="let us help you realise your vision"
          cta={TITLE_GET_IN_TOUCH}
          link={ROUTE_GET_IN_TOUCH}
          image={GetInTouchImage}
        />
      )}
      <Footer />
    </ErrorBoundary>
  );
};
