import React from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from 'components/check-box';
import {
  SelectedCheckbox,
  Download,
  Pdf,
  Arrow,
  Dxf,
  Ies,
  Ldt,
  Jpg,
  Png,
} from 'components/icons';
import getFileExtension from 'modules/get-file-extension';
import styles from './styles.module.scss';

const ICON = {
  pdf: <Pdf className={styles.icon} />,
  jpg: <Jpg className={styles.icon} />,
  png: <Png className={styles.icon} />,
  ldt: <Ldt className={styles.icon} />,
  dxf: <Dxf className={styles.icon} />,
  ies: <Ies className={styles.icon} />,
};

export default function DownloadRow({
  ID,
  selected,
  guid,
  title,
  url,
  handleSelect,
  handleSingleDownload,
}) {
  const pathname = new URL(guid).pathname;
  const extension = getFileExtension(pathname);

  return (
    <div className={styles.result}>
      <div className={styles.info}>
        <label htmlFor={ID.toString()}>
          <Checkbox
            icon={<SelectedCheckbox />}
            id={ID.toString()}
            name={ID.toString()}
            value={pathname}
            checked={selected.indexOf(pathname) >= 0}
            onChange={handleSelect}
          />
        </label>
        {ICON[extension]}
        <p>{guid.substr(guid.lastIndexOf('/') + 1)}</p>
      </div>

      <div className={styles.buttons}>
        <Link to={url}>
          go to product <Arrow />
        </Link>
        <button onClick={() => handleSingleDownload(guid)}>
          download <Download />
        </button>
      </div>
    </div>
  );
}

DownloadRow.defaultProps = {
  ID: 0,
  pathname: '',
  selected: false,
};
