import React from 'react';
import styles from 'components/check-box/style.module.scss';

export const NarrowBeam = props => (
  <svg
    className={styles['filter-icon']}
    data-name="Layer 1"
    viewBox="0 0 57 57"
    {...props}
  >
    <path
      d="M28.5 45c-1.93 0-3.5-.34-3.5-2.41v-.29c.14-3.42.88-31.24 3.5-31.24s3.35 27.79 3.5 31.19v.29c0 2.08-1.57 2.46-3.5 2.46z"
      fillRule="evenodd"
    />
    <circle cx={28} cy={28} r={27} fill="none" strokeWidth={2} />
  </svg>
);
