import React, { Fragment } from 'react';
import classNames from 'classnames';
import { HEADERS } from 'constants/spec-code-downloads';
import { getValueFromDataObject } from 'modules/get-value-from-data-object';
import styles from './style.module.scss';

export const CodeContainer = ({ selected, refToUse }) => {
  return (
    <div className={styles['code-container']} ref={refToUse}>
      {/* Guarantee the correct order to be displayed */}
      {Object.keys(HEADERS).map((item, index) => {
        const dash = [0, 1, 4, 5, 6];
        const addDash = dash.indexOf(index) > -1;
        const isArray = Array.isArray(selected[item]);
        const code = isArray
          ? getValueFromDataObject(selected[item], 'value')
          : selected[item]?.value;
        const getLength = isArray ? code.join('')?.length : code?.length;

        const classes = classNames({
          [`${styles.code}`]: true,
          [`${styles.smaller}`]: getLength > 3 && (index === 0 || index === 1),
          [`${styles['over-seven']}`]: isArray && getLength > 7,
          [`${styles['over-nine']}`]: isArray && getLength > 9,
        });

        return (
          <Fragment key={index}>
            <span className={classes}>{code}</span>
            {addDash && <span className={styles.dash}> - </span>}
          </Fragment>
        );
      })}
    </div>
  );
};
