import React from 'react';

export const FirePlate = (props) => (
  <svg
    id="prefix__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 182 190"
    xmlSpace="preserve"
    enableBackground="new 0 0 182 190"
    {...props}
  >
    <g id="prefix__fire_1_">
      <defs>
        <filter
          id="prefix__Adobe_OpacityMaskFilter"
          filterUnits="userSpaceOnUse"
          x={45.61}
          y={5.45}
          width={93.5}
          height={158.97}
        >
          <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
        </filter>
      </defs>
      <mask
        maskUnits="userSpaceOnUse"
        x={45.61}
        y={5.45}
        width={93.5}
        height={158.97}
      >
        <g filter="url(#prefix__Adobe_OpacityMaskFilter)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#fff"
            d="M44.07-13.21l104.1-.05.08 182.81-104.1.05z"
          />
        </g>
      </mask>
      <path
        d="M90.46 159.3s-16.12-12.02-16.13-33.56c-.01-21.55 17.81-34.86 17.81-34.86s17.15 16.44 17.16 34.96c.01 18.52-18.84 33.46-18.84 33.46M78.07 9s10.81 26.19-2.12 43.35c-11.71 15.55-26.8 29.13-26.79 54.63.01 26.53 11.04 40.45 41.84 53.75 0 0 44.59-8.35 44.57-54.79C135.54 59.5 78.07 9 78.07 9"
        mask="url(#prefix__mask-2_1_)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="none"
        strokeWidth={7.098}
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
    </g>
    <path
      d="M128.78 165.16c24.3 1.27 40.72 3.68 40.72 6.45 0 6.39-35.65 8.39-79.63 8.39s-79.63 0-79.63-8.39c0-2.99 20.11-5.56 47.62-6.73"
      fill="none"
      strokeWidth={6.211}
      strokeLinecap="round"
      strokeMiterlimit={10}
    />
  </svg>
);
