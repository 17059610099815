/*
 * Check if the user is viewing a legacy url and redirect to the new url
 */

const checks = {
  '/about-us/': '/our-story',
  '/luminaires/': '/products',
  '/dimming/': 404,
  '/download/': '/downloads',
  '/contact-us/': '/get-in-touch',
  '/luminaires/one/one-tilt-and-rotate/': '/products/one-adjustable',
  '/luminaires/one/one-fixed/': '/products/one-fixed',
  '/luminaires/timo/timo-tilt-and-rotate/': '/products/timo-adjustable',
  '/luminaires/true/true-tilt-and-rotate/': '/products/true-adjustable',
  '/luminaires/true/true-twin/': '/products/true-twin',
  '/luminaires/fina/fina-fixed/': '/products/fina-fixed',
  '/luminaires/fade/fade-tilt-and-rotate/': '/products/fade-adjustable',
  '/luminaires/fade/fade-twin/': '/products/fade-twin',
  '/luminaires/fade/fade-fixed/': '/products/fade-fixed',
  '/luminaires/suri/suri-tilt-rotate/': '/products/suri-adjustable',
  '/luminaires/suri/suri-fixed/': '/products/suri-fixed',
  '/luminaires/muro/muro/': '/products/muro-fixed',
  '/luminaires/clarin/clarin-fixed/': '/products/clarin-fixed',
  '/luminaires/dino/dino-tilt-and-rotate/': '/products/dino-adjustable',
  '/luminaires/dino/dino-fixed/': '/products/dino-fixed',
  '/luminaires/dino/dino-twin/': '/roducts/dino-twin',
  '/luminaires/detail/detail-tilt-and-rotate/': '/products/detail-adjustable',
  '/luminaires/detail/detail-fixed/': '/products/detail-fixed',
  '/luminaires/curve/curve-tilt/': '/products/curve-adjustable',
  '/luminaires/curve/curve-fixed/': '/products/curve-fixed',
  '/luminaires/curve/curve-recessed-fixed/': '/products/curve-recessed-fixed',
  '/luminaires/cero/cero-400/': '/products/cero-origin',
  '/luminaires/cero/cero-100/': '/products/cero-100',
  '/luminaires/mini-recessed/mini-recessed-tilt/': '/products/mina-adjustable',
  '/luminaires/mini-recessed/mini-recessed-fixed/': '/products/mina-fixed',
  '/luminaires/detail-mini-square/detail-mini-square-tilt-rotate/':
    '/products/cuadro-adjustable',
  '/luminaires/detail-mini-square/detail-mini-square-twin/':
    '/products/cuadro-fixed',
  '/luminaires/look/look-tilt-rotate/': '/products/look-adjustable',
  '/luminaires/look/look-twin/': '/products/look-twin',
  '/luminaires/shadowline/shadowline-mini-fixed/': '/products/nikko-adjustable',
  '/luminaires/shadowline/shadowline-detail-mini-fixed/':
    '/products/nikko-adjustable',
  '/luminaires/shadowline/shadowline-detail-tilt-rotate/':
    '/products/nikko-adjustable',
  '/luminaires/shadowline/shadowline-detail-twin/': '/products/nikko-twin',
  '/luminaires/roc/roc-100/': '/products/roc-100',
  '/luminaires/roc/roc-lopro-100/': '/products/roc-100-lo-pro',
  '/luminaires/cue/cue-100/': '/products/cue-100',
  '/luminaires/cue/cue-lopro/': '/products/cue-100-lo-pro',
  '/luminaires/air-adjustable/air-adjustable-butterfly/':
    '/products/air-butterfly',
  '/luminaires/air-adjustable/air-adjustable-rose/': '/products/air-rose',
  '/luminaires/air-adjustable/air-adjustable-screwplate/':
    '/products/air-screw',
  '/luminaires/air-adjustable/air-adjustable-integrated/':
    '/products/air-integrated',
  '/luminaires/air/air-fixed/': '/products/air-fixed',
  '/luminaires/air/air-track/': '/products/air-track',
  '/luminaires/air/air-pendant/': '/products/air-pendant',
};

export default unsuccessful => {
  const { pathname } = window.location;
  // enforce a slash on the end of every check
  const uri = pathname.endsWith('/') ? pathname : `${pathname}/`;
  const redirect = checks[uri];

  if (redirect) {
    // there is a redirect, this will prevent the main application from loading
    if (redirect === 404) {
      return (window.location.href = '/404');
    } else {
      return (window.location.href = redirect);
    }
  }

  unsuccessful();
};
