import React from 'react';
import classNames from 'classnames';
import { useWindowSize } from 'hooks/use-window-size';
import PropTypes from 'prop-types';
import { DescriptionPart } from 'components/product-hero/animation.style.js';
import styles from './style.module.scss';
import { bpSmallPlus } from 'constants/breakpoints';

export const Title = ({ title, theme }) => {
  const { width } = useWindowSize();
  const isLarge = width > bpSmallPlus;

  const splitTitle = title.toLowerCase().trim().split(/\s+/);
  const titleSecondPart = splitTitle.slice(1, splitTitle.length).join(' ');

  const titleClasses = classNames({
    [`${styles['hero-title']}`]: true,
    [`${styles[theme]}`]: theme,
    [`${styles['font-smaller']}`]: title.length >= 20,
  });

  return (
    <>
      {isLarge &&
        splitTitle.map((word, index) => {
          return (
            <DescriptionPart key={index}>
              <h1 className={titleClasses}>{word}</h1>
            </DescriptionPart>
          );
        })}

      {!isLarge && (
        <>
          <DescriptionPart>
            <h1 className={titleClasses}>{splitTitle[0]}</h1>
          </DescriptionPart>
          <DescriptionPart>
            <h1 className={titleClasses}>{titleSecondPart}</h1>
          </DescriptionPart>
        </>
      )}
    </>
  );
};

Title.propTypes = { 
  title: PropTypes.string.isRequired, 
  theme: PropTypes.string
}