import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import getFileExtension from 'modules/get-file-extension';
import { thumbExtensionCreator } from 'modules/thumb-extension-creator';
import { LazyImage }from 'components/lazy-image';
import styles from './style.module.scss';

const videoRef = React.createRef();

export const Media = ({ 
    url, 
    featuredAsset, 
    activeMedia, 
    lightboxOpen, 
    gridMedia 
  }) => {
  const [playVideo, setPlayVideo] = useState(false);
  const extension = getFileExtension(url);
  const isVideo = extension === 'mp4';

  useEffect(() => {
    if (playVideo) {
      videoRef.current.play();
    }
  }, [playVideo]);

  const handleVideo = () => {
    setPlayVideo(true);
  };

  const videoClasses = classNames({
    [`${styles.video}`]: true,
    [`${styles.play}`]: playVideo,
    [`${styles['hide-icon']}`]: featuredAsset,
  });

  return (
    <>
      {isVideo && featuredAsset && (
        <span className={videoClasses}>
          <video src={url} autoPlay muted playsInline loop />
        </span>
      )}
      {isVideo && !featuredAsset && (
        <div className={videoClasses} onClick={handleVideo}>
          <video
            ref={activeMedia ? videoRef : undefined}
            src={url + '#t=0.1'}
            preload="metadata"
            controls={playVideo && activeMedia && !gridMedia}
          />
        </div>
      )}
      {!isVideo && (
        <LazyImage
          lightboxOpen={lightboxOpen}
          thumbnailSrc={thumbExtensionCreator(url, '-thumb')}
          src={url ? url : thumbExtensionCreator(url, '')}
          alt="product highlights"
        />
      )}
    </>
  );
};
