import {
  SUBMIT_GET_IN_TOUCH_REQUEST,
  SUBMIT_GET_IN_TOUCH_SUCCESS,
  SUBMIT_GET_IN_TOUCH_ERROR,
  SUBMIT_GET_IN_TOUCH_RESET,
} from 'redux/actions/get-in-touch-form';

const initialState = {
  success: false,
  loading: false,
  hasError: false,
};

export default function getInTouchForm(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_GET_IN_TOUCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        hasError: false,
        success: false,
      });
    case SUBMIT_GET_IN_TOUCH_SUCCESS:
      return Object.assign({}, state, {
        success: true,
        loading: false,
        hasError: false,
      });
    case SUBMIT_GET_IN_TOUCH_ERROR:
      return Object.assign({}, state, {
        success: false,
        loading: false,
        hasError: true,
      });
    case SUBMIT_GET_IN_TOUCH_RESET:
      return initialState;
    default:
      return state;
  }
}
