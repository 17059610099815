import React from 'react';
import PropTypes from 'prop-types';
import { Arrow } from 'components/icons';
import styles from './style.module.scss';

export const Headquarters = ({ data }) => {
  const {
    phone, 
    export: exportContact,
    general_enquiries, 
    orders, 
    quotes,
    map,
    address,
  } = data;
  const phoneNumber = ['tel:', phone].join('');
  const enquiriesEmail = ['mailto:', general_enquiries].join('');
  const exportEmail = ['mailto:', exportContact].join('');
  const ordersEmail = ['mailto:', orders].join('');
  const quotesEmail = ['mailto:', quotes].join('');
  const mapUrl = [
    'https://www.google.com/maps/place/?q=place_id:',
    map.place_id,
  ].join('');

  return (
    <section className={`${styles.headquarters} container`}>
      <h3 className={styles.title}>uk headquarters</h3>
      <div className={styles['content-wrapper']}>
        <div className={styles['left-column']}>
          <p className={styles['phone-desktop']}>Phone: {phone}</p>
          <p className={styles['phone-mobile']}>
            Phone: <a href={phoneNumber}>{phone}</a>
          </p>
          <p>
            General Enquiries:{' '}
            <a href={enquiriesEmail}>{general_enquiries}</a>
          </p>
          <p>
            Export: <a href={exportEmail}>{exportContact}</a>
          </p>
          <p>
            Orders: <a href={ordersEmail}>{orders}</a>
          </p>
          <p>
            Quotes: <a href={quotesEmail}>{quotes}</a>
          </p>
        </div>
        <div className={styles['right-column']}>
          <address>{address}</address>
          <span>
            <a href={mapUrl} target="_blank" rel="noopener noreferrer">
              open in maps
            </a>
            <Arrow className={styles.arrow} />
          </span>
        </div>
      </div>
    </section>
  );
};

Headquarters.propTypes = {
  data: PropTypes.object.isRequired
}
