import {
  LIT_EFFECTS_REQUEST,
  LIT_EFFECTS_RECEIVED,
} from 'redux/actions/lit-effects';

const initialState = {
  hasError: false,
  litEffects: {},
  loading: true,
};

export default function litEffects(state = initialState, action) {
  switch (action.type) {
    case LIT_EFFECTS_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        litEffects: {
          ...state.litEffects,
        },
        loading: true,
      });
    case LIT_EFFECTS_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        litEffects: {
          ...state.litEffects,
          ...action.litEffects,
        },
        loading: false,
      });
    default:
      return state;
  }
}
