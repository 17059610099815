import axios from 'axios';

export const submitFormWorkWithUs = async (endpoint, values) => {
  const formData = new FormData();

  // eslint-disable-next-line
  for (let key in values) {
    formData.append(key, values[key]);
  }

  try {
    const url = [process.env.REACT_APP_CMS_URL, endpoint].join('/');
    const response = await axios(url, {
      method: 'POST',
      data: formData,
    });

    return response;
  } catch (error) {
    throw error;
  }
};
