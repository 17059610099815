import { VARIANTS_REQUEST, VARIANTS_RECEIVED } from 'redux/actions/variants';

const initialState = {
  hasError: false,
  variants: {},
  loading: true,
};

export default function variants(state = initialState, action) {
  switch (action.type) {
    case VARIANTS_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        variants: {
          ...state.variants,
        },
        loading: true,
      });
    case VARIANTS_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        variants: {
          ...state.variants,
          ...action.variants,
        },
        loading: false,
      });
    default:
      return state;
  }
}
