import React from 'react';
import classNames from 'classnames';

import styles from './style.module.scss';

export const CheckboxDefault = ({
  register,
  name,
  id,
  value,
  checked,
  icon,
  onChange,
}) => {
  const checkboxClass = classNames({
    [`${styles['custom-checkbox']}`]: true,
    [`${styles['is-checked']}`]: checked,
  });

  return (
    <>
      <input
        className={styles['hidden-checkbox']}
        ref={register}
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        value={value}
        onChange={onChange}
      />
      <div className={checkboxClass}>{icon}</div>
    </>
  );
};
