// replace the cms origin with the frontend origin, put in try block to cater for both relative paths and full URLs

export const cmsUrlToFrontendOrigin = (urlOrPath) => {
  let localUrl;

  try {
    //Where urlOrPath is full URL
    const remoteUrl = new URL(urlOrPath);
    localUrl = [window.location.origin, remoteUrl.pathname].join('');
  } catch (err) {
    //Where urlOrPath is relative path
    localUrl = urlOrPath;
  }

  return localUrl;
};
