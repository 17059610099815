import React, { useState, useEffect } from 'react';
import urls from 'constants/urls.json';
import { THEME_LIGHT } from 'constants/color-theme';
import { getPage } from 'modules/api';
import { Loading } from 'components/loading';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { HtmlBlock } from 'components/html-block';
import { ErrorBoundary } from 'components/error';

export const PrivacyPolicyScreen = () => {
  const [loading, isLoading] = useState(true);
  const [pageContent, setPageContent] = useState({});

  useEffect(() => {
    async function fetchPageContent() {
      const response = await getPage(urls['page'], 'privacy-policy');

      setPageContent(response);
      isLoading(false);
    }

    fetchPageContent();
  });

  if (loading) return <Loading />;

  return (
    <ErrorBoundary>
      <Header theme={THEME_LIGHT} />
      <div className="privacy-and-terms container">
        <div className="privacy-and-terms-title">
          <HtmlBlock content={pageContent.title.rendered} />{' '}
        </div>
        <div className="privacy-and-terms-content">
          <HtmlBlock content={pageContent.content.rendered} />
        </div>
      </div>
      <Footer />
    </ErrorBoundary>
  );
};
