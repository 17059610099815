import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

export const ProductFeatured = ({
  description,
  title,
  url,
  thumbnail,
  name,
}) => {
  return (
    <div className={styles['product-featured']}>
      <div className={styles.copy}>
        <div className={styles.title}>
          <h2>{title}</h2>
        </div>
        <p>{description}</p>
      </div>
      <div className={styles.product}>
        <Link to={url}>
          <div className={`${styles.wrapper}`}>
            <div className={styles.overlay} />
            <img src={thumbnail} alt="featured product" />
            <span>{name}</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

ProductFeatured.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  name: PropTypes.string,
}