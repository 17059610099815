import React, { useRef, useState } from 'react';
import { useWindowSize } from 'hooks/use-window-size';
import { bpSmall } from 'constants/breakpoints';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { DownwardArrow } from 'components/icons';
import styles from './style.module.scss';

export const SliderComparison = ({ comparisonImages }) => {
  const [fromLeft, setFromLeft] = useState(50);
  const { width } = useWindowSize();
  const isSmall = width < bpSmall;
  const divisorRef = useRef(null);
  const handleRef = useRef(null);
  const sliderRef = useRef(null);
  
  const { mobile, desktop } = comparisonImages;
  const leftImage = isSmall ? mobile.left_side : desktop.left_side;
  const rightImage = isSmall ? mobile.right_side : desktop.right_side;

  const moveDivisor = () => {
    divisorRef.current.style.width = sliderRef.current.value + '%';
    handleRef.current.style.left = sliderRef.current.value + '%';
    setFromLeft(sliderRef.current.value);
  };

  const classes = classNames({
    [`${styles.slider}`]: true,
    [`${styles['hide-left']}`]: fromLeft < 10,
    [`${styles['hide-right']}`]: fromLeft > 90,
  });

  return (
    <div className={classes}>
      <figure
        style={{
          backgroundImage: `url(${rightImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'left',
        }}
      >
        <div
          ref={divisorRef}
          style={{
            backgroundImage: `url(${leftImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        />
        <div ref={handleRef}>
          <DownwardArrow />
          <DownwardArrow />
        </div>
      </figure>
      <input
        type="range"
        min="0"
        max="100"
        value="50"
        ref={sliderRef}
        onInput={moveDivisor}
        onChange={moveDivisor}
      />
      <div className={styles['bg-color']} />
    </div>
  );
};

SliderComparison.propTypes = {
  comparisonImages: PropTypes.shape({
    desktop: PropTypes.shape({
      left_side: PropTypes.string.isRequired,
      right_side: PropTypes.string.isRequired
    }),
    mobile: PropTypes.shape({
      left_side: PropTypes.string.isRequired,
      right_side: PropTypes.string.isRequired
    })
  })
}
