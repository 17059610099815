import urls from 'constants/urls.json';
import { getProductPreview } from 'modules/api';

export const RELATED_PRODUCT_REQUEST = 'RELATED_PRODUCT_LOAD';
export const RELATED_PRODUCT_RECEIVED = 'RELATED_PRODUCT_LOADED';

export const requestRelatedProduct = (slug) => {
  return { type: RELATED_PRODUCT_REQUEST, slug };
};

export const receiveRelatedProduct = (slug, product) => {
  return { type: RELATED_PRODUCT_RECEIVED, slug, product };
};

function fetchRelatedProduct(slug) {
  return async (dispatch) => {
    dispatch(requestRelatedProduct(slug));

    const product = await getProductPreview(urls['product-preview'], slug);

    dispatch(receiveRelatedProduct(slug, product));
  };
}

function shouldFetchRelatedProduct(state, slug) {
  const product = state.relatedProduct.data[slug];

  if (!product) {
    return true;
  } else if (product.loading) {
    return false;
  } else {
    return false;
  }
}

export const fetchRelatedProductIfNeeded = (slug) => {
  return (dispatch, getState) => {
    if (shouldFetchRelatedProduct(getState(), slug)) {
      return dispatch(fetchRelatedProduct(slug));
    } else {
      return Promise.resolve();
    }
  };
};
