import React from 'react';
import styles from 'components/check-box/style.module.scss';

export const Bespoke = (props) => (
  <svg
    className={styles['filter-icon']}
    data-name="Layer 1"
    viewBox="0 0 57 57"
    {...props}
  >
    <defs>
      <style>
        {'.prefix__cls-2{fill:none;stroke:#555762;stroke-width:2.5px}'}
      </style>
    </defs>
    <g id="prefix__Bespoke">
      <circle cx={28} cy={28} r={27} strokeWidth={2} fill="none" />
      <g
        id="Group-2"
        transform="translate(20.000000, 7.571068)"
        stroke="#555762"
        stroke-width="2.5"
      >
        <polyline
          id="Rectangle"
          transform="translate(7.500000, 7.500000) rotate(-45.000000) translate(-7.500000, -7.500000) "
          points="2.19669914 2.19669914 12.5 2.5 12.8033009 12.8033009"
        ></polyline>
        <polyline
          id="Rectangle-Copy"
          transform="translate(7.500000, 30.500000) rotate(-225.000000) translate(-7.500000, -30.500000) "
          points="2.19669914 25.1966991 12.5 25.5 12.8033009 35.8033009"
        ></polyline>
      </g>
      <g
        transform="translate(9.000000, 21.000000)"
        stroke="#555762"
        stroke-width="2.5"
      >
        <polyline
          id="Rectangle-Copy-2"
          transform="translate(6.000000, 6.000000) rotate(-135.000000) translate(-6.000000, -6.000000) "
          points="1.75735931 1.75735931 10.2426407 1.75735931 10.2426407 10.2426407"
        ></polyline>
        <polyline
          id="Rectangle-Copy-3"
          transform="translate(30.879394, 6.000000) rotate(-315.000000) translate(-30.879394, -6.000000) "
          points="26.6367532 1.75735931 35.1220346 1.75735931 35.1220346 10.2426407"
        ></polyline>
      </g>
      <line
        x1="27.5"
        y1="10.5"
        x2="27.5"
        y2="43.5"
        id="Line-2"
        stroke="#555762"
        stroke-width="2.5"
        stroke-linecap="square"
      ></line>
    </g>
  </svg>
);
