import React from 'react';

import styles from './style.module.scss';

export const Button = ({
  theme,
  size,
  type,
  label,
  disabled,
  handleClick,
  stylesClass,
}) => {
  return (
    <button
      className={`${styles.btn}
        ${styles[theme]}
        ${styles[size]}
        ${styles[stylesClass]}`}
      type={type}
      onClick={handleClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};
