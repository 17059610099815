import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPage } from 'modules/api';
import urls from 'constants/urls.json';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { Loading } from 'components/loading';
import { ErrorBoundary } from 'components/error';
import { WorkWithUsFormSection } from 'components/work-with-us-form-section';
import { SectionWithBackground } from 'components/section-with-background';
import { BANNER_NAME_WIDER } from 'constants/banners';
import { THEME_SOLID } from 'constants/color-theme';

export const WorkWithUsScreen = () => {
  const { loading } = useSelector((state) => state.workWithUsForm);
  const [bannerImage, setBannerImage] = useState(null);
  const [metaLoading, setMetaLoading] = useState(true);

  const fetchPageMeta = async () => {
    const page = await getPage(urls['page'], 'work-with-us');
    return page.acf;
  };

  useEffect(() => {
    fetchPageMeta()
      .then(({ banner_image }) => {
        setBannerImage(banner_image);
      })
      .then(() => setMetaLoading(false));
  }, []);

  if (metaLoading && loading) return <Loading />;

  return (
    <ErrorBoundary>
      <Header theme={THEME_SOLID} />
      <WorkWithUsFormSection />
      <SectionWithBackground
        sectionName={BANNER_NAME_WIDER}
        image={bannerImage}
      />
      <Footer />
    </ErrorBoundary>
  );
};
