import { sales } from 'components/map/pins';

import styles from './style.module.scss';

export const createSalesMarker = (map, salesLocations) => {
  const infoWindow = new window.google.maps.InfoWindow({
    content: '',
    maxWidth: 298,
  });

  salesLocations.forEach((location, index) => {
    const mapUrl = [
      'https://www.google.com/maps/place/?q=place_id:',
      location.map.place_id,
    ].join('');

    const marker = new window.google.maps.Marker({
      map,
      position: { lat: location.map.lat, lng: location.map.lng },
      icon: {
        url: sales,
        scaledSize: new window.google.maps.Size(24, 21),
      },
    });
    marker.addListener(`click`, () => {
      infoWindow.setContent(
        `<div class=${styles['info-container']}>
          <p>Orluna LED Technologies Limited</p>
          <p>${location.address}</p>
          <p class=${styles.phone}>Phone: ${location.phone}</p>
          <p class=${styles['general-email']}>General: <a href="mailto:${
          location.general_enquiries
        }">${location.general_enquiries}</a> </p>
          <p>Export: <a href="mailto:${location.export}">${
          location.export
        }</a> </p>
          <p>Orders: <a href="mailto:${location.orders}">${
          location.orders
        }</a> </p>
          <p>Quotes: <a href="mailto:${location.quotes}">${
          location.quotes
        }</a> </p>
          <a class=${
            styles.directions
          } href='${mapUrl}' target="_target">Directions</a>
        </div>
        `
      );
      infoWindow.open(map, marker);
    });
  });
};
