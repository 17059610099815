import urls from 'constants/urls.json';
import { getInTouch } from 'modules/api';

export const GET_IN_TOUCH_REQUEST = 'GET_IN_TOUCH_REQUEST';
export const GET_IN_TOUCH_RECEIVED = 'GET_IN_TOUCH_RECEIVED';

export const requestGetInTouch = () => {
  return { type: GET_IN_TOUCH_REQUEST };
};

export const receivedGetInTouch = (content) => {
  return { type: GET_IN_TOUCH_RECEIVED, content };
};

export const fetchGetInTouch = () => {
  return async (dispatch) => {
    dispatch(requestGetInTouch());

    const content = await getInTouch(urls['get-in-touch']);

    dispatch(receivedGetInTouch(content));
  };
};
