import React from 'react';

export const PdfPreview = props => (
  <svg width={15} height={19} {...props}>
    <path
      d="M14.4 19H.6a.604.604 0 01-.6-.608V.608C0 .272.269 0 .6 0h10.256v3.463a.73.73 0 00.726.736H15v14.193a.604.604 0 01-.6.608zM2.703 12.312h.763V11.26h.58c.776 0 1.4-.422 1.4-1.234v-.01c0-.718-.5-1.214-1.327-1.214H2.703v3.511zm.763-1.74V9.498h.589c.381 0 .619.185.619.532v.01c0 .3-.223.532-.605.532h-.603zm2.544 1.74h1.352c1.089 0 1.84-.767 1.84-1.756v-.01c0-.988-.751-1.745-1.84-1.745H6.01v3.511zm1.352-2.814c.623 0 1.044.436 1.044 1.058v.01c0 .623-.421 1.049-1.044 1.049h-.59V9.498h.59zm2.52 2.814h.762v-1.36h1.653v-.702h-1.653v-.747h1.875v-.702H9.881v3.511zm1.368-9.12V0L15 3.8h-3.15a.604.604 0 01-.6-.608z"
      fill="#393E5B"
    />
  </svg>
);
