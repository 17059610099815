import React, { useState } from 'react';
import { values } from 'lodash/fp';
import { SIZE_MEDIUM } from 'constants/sizes';
import { THEME_DARK } from 'constants/color-theme';
import { htmlToReactParser } from 'modules/html-to-react';
import PropTypes from 'prop-types';
import { Button } from 'components/buttons';
import { Loading } from 'components/loading';
import styles from './style.module.scss';

export const PartnersAndClients = ({ partners, loading }) => {
  const [viewFullList, setViewFullList] = useState(false);

  const openFullList = () => {
    setViewFullList(!viewFullList);
  };

  if (loading) return <Loading />;

  return (
    <section className={`${styles['partners-and-clients']} section`}>
      <h2 className={viewFullList ? `${styles['full-list']}` : ''}>projects</h2>
      <div className="container">
        <h3>partners and clients</h3>
        <div className={styles.wrapper}>
          {values(partners).map((item, index) => {
            return (
              <div key={index} className={`${styles['list-wrapper']}`}>
                <h4>{item.name}</h4>
                <ul
                  className={
                    viewFullList
                      ? `${styles.list} ${styles['full-list']}`
                      : `${styles.list}`
                  }
                >
                  {item.partners.map((name, index) => {
                    return (
                      <li key={index}>
                        {htmlToReactParser.parse(name.title.rendered)}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
        <Button
          size={SIZE_MEDIUM}
          theme={THEME_DARK}
          label={viewFullList ? 'hide full list' : 'see full list'}
          handleClick={openFullList}
        />
      </div>
    </section>
  );
};

PartnersAndClients.propTypes = {
  partners: PropTypes.object.isRequired,
  loading: PropTypes.bool
}