import React from 'react';

export const Pinterest = props => (
  <svg data-name="Layer 1" viewBox="0 0 34.5 34.5" {...props}>
    <g data-name="PINTEREST">
      <circle
        cx={17.25}
        cy={17.25}
        r={16.5}
        fill="none"
        stroke=""
        strokeWidth={1.5}
      />
      <path
        d="M17.25 7.75a9.5 9.5 0 00-3.5 18.35 9 9 0 010-2.73c.17-.74 1.11-4.72 1.11-4.72a3.48 3.48 0 01-.28-1.41c0-1.32.77-2.31 1.72-2.31a1.19 1.19 0 011.2 1.34 19.08 19.08 0 01-.75 3.16 1.39 1.39 0 001.41 1.72c1.69 0 3-1.78 3-4.35a3.75 3.75 0 00-4-3.87 4.12 4.12 0 00-4.29 4.13 3.68 3.68 0 00.71 2.17.27.27 0 01.06.27c-.07.3-.23.94-.26 1.07s-.14.22-.32.13a4.25 4.25 0 01-1.93-3.68c0-3 2.18-5.75 6.28-5.75a5.58 5.58 0 015.91 5.48c0 3.27-2.07 5.91-4.93 5.91a2.54 2.54 0 01-2.18-1.09s-.48 1.81-.59 2.26a11.45 11.45 0 01-1.18 2.49 9.65 9.65 0 002.81.43 9.5 9.5 0 000-19"
        fill=""
        stroke="none"
        fillRule="evenodd"
      />
    </g>
  </svg>
);
