import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { abbreviateAllVariants } from 'modules/abbreviate-all-variants';
import { thumbExtensionCreator } from 'modules/thumb-extension-creator';
import { ICONS_PRODUCT_HERO } from 'constants/icons';
import { Title } from 'components/product-title';
import { Tick } from 'components/icons';
import { LazyImage } from 'components/lazy-image';

import styles from './style.module.scss';
import {
  Description,
  DescriptionPart,
  LitOptions,
  LitOption,
} from './animation.style.js';

export const ProductHero = ({
  theme,
  background,
  title,
  litEffects,
  cutOut,
  allVariants,
  smallScreen,
}) => {
  const [entered, setEntered] = useState(false);
  const varOptions = abbreviateAllVariants(allVariants);
  const variants = varOptions ? varOptions.join(' / ') : null;

  useEffect(() => {
    setEntered(true);
    // eslint-disable-next-line
  }, []);

  const iconClasses = classNames({
    [`${styles['lit-icon']}`]: true,
    [`${styles[theme]}`]: theme,
  });
  
  return (
    <div className={`${styles.hero} ${styles[theme]}`}>
      <div className={styles['background-wrapper']}>
        <div className={`${styles[theme]}`} /> {/* overlay */}
        <LazyImage 
          thumbnailSrc={thumbExtensionCreator(background,'-thumb')} 
          src={background} 
          alt={`${title} product`}
        />
      </div>
      <div className="container">
        <div className={styles['content-wrapper']}>
          <Description
            className={styles['description-wrapper']}
            pose={entered ? 'enter' : 'exit'}
          >
            {!smallScreen && <Title title={title} theme={theme} />}
            <DescriptionPart>
              <p className={styles.variants}>{variants}</p>
            </DescriptionPart>
            <DescriptionPart>
              <p className={styles.cut}>{cutOut}mm</p>
            </DescriptionPart>
          </Description>
        </div>
      </div>
      <LitOptions className={styles.flex} pose={entered ? 'enter' : 'exit'}>
        <ul>
          {litEffects.map((item, index) => {
            return (
              <LitOption key={index}>
                <div className={styles['icons-wrapper']}>
                  <span className={iconClasses}>{ICONS_PRODUCT_HERO[item.slug]}</span>
                  <Tick className={styles['tick-icon']} />
                </div>
                <span className={styles.type}>{item.name}</span>
              </LitOption>
            );
          })}
        </ul>
        <p className={`${styles['lit-effect']} ${styles[theme]}`}>lit effect</p>
      </LitOptions>
    </div>
  );
};

ProductHero.propTypes = {
  theme: PropTypes.string,
  background: PropTypes.string.isRequired,
  title: PropTypes.string,
  litEffects: PropTypes.array,
  cutOut: PropTypes.string,
  allVariants: PropTypes.array,
  smallScreen: PropTypes.bool,
}