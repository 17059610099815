import React from 'react';

export const ImagePreview = props => (
  <svg width="15" height="15" {...props}>
    <path
      d="M0 0v10.54l4.274-4.18c.06-.06.14-.1.222-.11a.389.389 0 01.321.117l3.855 4.07 2.485-2.807a.395.395 0 01.568-.013l2.88 3.04V0H0zm8.487 2.5c.872 0 1.579.746 1.579 1.667 0 .92-.707 1.666-1.58 1.666-.871 0-1.578-.746-1.578-1.666 0-.92.707-1.667 1.579-1.667zm-3.96 4.74L0 11.667V15h14.605v-3.164l-3.145-3.32-2.486 2.805a.395.395 0 01-.567.013L4.527 7.24z"
      fill="#393E5B"
      fillRule="nonzero"
    />
  </svg>
);
