import urls from 'constants/urls.json';
import { getProductRange } from 'modules/api';

export const PRODUCT_RANGE_REQUEST = 'PRODUCT_RANGE_REQUEST';
export const PRODUCT_RANGE_RECEIVED = 'PRODUCT_RANGE_RECEIVED';

export const requestProductRange = (id) => {
  return { type: PRODUCT_RANGE_REQUEST, id };
};

export const receiveProductRange = (productRange) => {
  return { type: PRODUCT_RANGE_RECEIVED, productRange };
};

export const fetchProductRange = (id) => {
  return async (dispatch) => {
    dispatch(requestProductRange());

    const productRange = await getProductRange(urls['product-range'], id);

    dispatch(receiveProductRange(productRange));
  };
};
