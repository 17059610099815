import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { submitSignUp } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/buttons';
import { SelectedCheckbox } from 'components/icons';
import { CheckboxDefault } from 'components/check-box/checkbox-default';
import styles from './style.module.scss';
import {
  REGEX_COMPANY_NAME,
  REGEX_EMAIL,
  REGEX_NAME,
  REGEX_SURNAME,
} from 'constants/form-regex';
import { SIZE_DEFAULT, SIZE_SMALL } from 'constants/sizes';
import { THEME_DARK } from 'constants/color-theme';
import { LABEL_GO, LABEL_CONFIRM } from 'constants/labels';

export const SignUpForm = ({ formContext }) => {
  const { register, handleSubmit, watch, errors } = useForm();
  const watchFields = watch([
    'name',
    'surname',
    'email',
    'company',
    'textarea',
  ]);
  const [goToNextStep, setGoToNextStep] = useState(false);
  const [privacyIsChecked, togglePrivacyIsChecked] = useState(false);
  const [showBtn, setShowBtn] = useState(true);
  const { loading } = useSelector((state) => state.signUpForm);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const context = { context: formContext };
    dispatch(submitSignUp({ ...data, ...context }));
  };

  const handlePrivacyCheckbox = () => {
    togglePrivacyIsChecked(!privacyIsChecked);
  };

  const nextStep = () => {
    setGoToNextStep(true);
    setShowBtn(false);
  };

  const nameIsValid = watchFields.name !== undefined && watchFields.name !== '';
  const surnameIsValid =
    watchFields.surname !== undefined && watchFields.surname !== '';

  const emailIsValid =
    watchFields.email !== undefined &&
    watchFields.email !== '' &&
    REGEX_EMAIL.test(watchFields.email);

  const emailEnteredInvalid =
    watchFields.email !== undefined &&
    watchFields.email !== '' &&
    !REGEX_EMAIL.test(watchFields.email);

  return (
    <div className={styles['sign-up-form']}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {goToNextStep && (
          <>
            <div className={styles.name}>
              <input
                ref={register({ pattern: REGEX_NAME })}
                className={errors.name ? 'has-error' : ''}
                type="text"
                name="name"
                placeholder="First name*"
              />
              {errors.name && (
                <small className="error">please enter a valid first name</small>
              )}
            </div>
            <div className={styles.surname}>
              <input
                ref={register({ pattern: REGEX_SURNAME })}
                className={errors.surname ? 'has-error' : ''}
                type="text"
                name="surname"
                placeholder="Surname*"
              />
              {errors.surname && (
                <small className="error">please enter a valid surname</small>
              )}
            </div>

            <div className={styles.company}>
              <input
                ref={register({ pattern: REGEX_COMPANY_NAME })}
                className={errors.company ? 'has-error' : ''}
                type="text"
                name="company"
                placeholder="Company"
              />
              {errors.company && (
                <small className="error">
                  please enter a valid company name
                </small>
              )}
            </div>
          </>
        )}

        <div>
          <input
            ref={register({ required: true, pattern: REGEX_EMAIL })}
            className={
              !goToNextStep
                ? `${styles.email}`
                : !errors.email
                ? `${styles.email} ${styles.fullform}`
                : `${styles.email} ${styles.fullform} has-error`
            }
            type="email"
            name="email"
            placeholder={goToNextStep ? 'name@company.com*' : 'Email address'}
          />
          {errors.email ||
            (emailEnteredInvalid && (
              <>
                <br />
                <small className="error">please enter a valid e-mail</small>
              </>
            ))}
        </div>

        {goToNextStep && (
          <div
            className={
              !errors.email
                ? styles['privacy']
                : `${styles['privacy']} ${styles.error}`
            }
          >
            <label htmlFor={'privacy'}>
              <CheckboxDefault
                register={register}
                icon={<SelectedCheckbox />}
                id="privacy"
                name="privacy"
                checked={privacyIsChecked}
                value={privacyIsChecked}
                onChange={handlePrivacyCheckbox}
              />
            </label>
            <small className={styles.terms}>
              *By signing up, you will receive Orluna's news, information on new
              products, and promotions. You are also agreeing to Orluna's
              privacy policy. You may unsubscribe at any time.
            </small>
          </div>
        )}
        {showBtn && (
          <span className={`${styles['go-btn']}`}>
            <Button
              size={SIZE_SMALL}
              theme={THEME_DARK}
              label={LABEL_GO}
              disabled={emailIsValid ? false : true}
              handleClick={nextStep}
            />
          </span>
        )}
        {goToNextStep && (
          <>
            <Button
              size={SIZE_DEFAULT}
              theme={THEME_DARK}
              label={LABEL_CONFIRM}
              type="submit"
              disabled={
                !loading && nameIsValid && surnameIsValid && privacyIsChecked
                  ? false
                  : true
              }
              handleClick={handleSubmit}
            />
            <small className={styles.mandatory}>
              <sup>*</sup>mandatory fields
            </small>
          </>
        )}
      </form>
    </div>
  );
};
