import React, { useState, useEffect } from 'react';
import { getProject } from 'modules/api';
import urls from 'constants/urls.json';
import { Listing } from 'components/listing';
import { Loading } from 'components/loading';

export const ProjectFeatured = ({ project, atRange }) => {
  const [loading, isLoading] = useState(true);
  const [projectData, setProjectData] = useState({});

  useEffect(() => {
    async function fetchProject() {
      const result = await getProject(urls['project'], project.post_name);
      setProjectData(result);
      isLoading(false);
    }

    fetchProject();
  }, [project.post_name]);

  if (loading) return <Loading />;

  return  <Listing 
            data={[projectData]} 
            isAtRange={atRange} 
            isProjects 
            isFeatured 
          />;
};
