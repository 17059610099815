import React from 'react';
import styles from 'components/check-box/style.module.scss';

export const Linear = (props) => (
  <svg
    className={styles['filter-icon']}
    {...props}
    height="56"
    viewBox="0 0 57 56"
    width="57"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="28.5" cy="28" r="27" stroke="#f4f4f4" strokeWidth="2" />
      <path d="m26.5 11h5v35h-5z" fill="#555762" />
    </g>
  </svg>
);
