import urls from 'constants/urls.json';
import { getNewsPost } from 'modules/api';

export const POST_REQUEST = 'POST_REQUEST';
export const POST_RECEIVED = 'POST_RECEIVED';

export const requestNewsPost = () => {
  return { type: POST_REQUEST };
};

export const receiveNewsPost = post => {
  return { type: POST_RECEIVED, post };
};

export const fetchNewsPost = slug => {
  return async dispatch => {
    dispatch(requestNewsPost());

    const post = await getNewsPost(urls['news-post'], slug);

    dispatch(receiveNewsPost(post));
  };
};
