import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useWindowSize } from 'hooks/use-window-size';
import { useScrolling } from 'hooks/use-scrolling';
import { bpXLarge } from 'constants/breakpoints';
import logoIcon from 'assets/images/floating_logo_icon.png';
import styles from './style.module.scss';

export const FloatingLogo = ({ section, size, offsetHelper = 0 }) => {
  const { scrollY } = useScrolling(5000);
  const [currentPixel, setCurrentPixel] = useState(0);
  const { width } = useWindowSize();

  useEffect(() => {
    setCurrentPixel(scrollY);
  }, [scrollY]);

  const floatingIconClasses = classNames({
    [`${styles['absolute']}`]: true,
    [`${styles[section]}`]: section,
    [`${styles[size]}`]: size,
  });

  const translateY = currentPixel - offsetHelper;
  const transform = width > bpXLarge ? `translateY(-${translateY}px)` : 'none';

  return (
    <div
      className={floatingIconClasses}
      style={{ transform, backgroundImage: `url(${logoIcon})` }}
    />
  );
};
