import React from 'react';
import PropTypes from 'prop-types';
import { htmlToReactParser } from 'modules/html-to-react';
import styles from './style.module.scss';

export const RangeIntro = ({ title, description }) => {
  if (!title && !description) return null;

  return (
    <section className={`${styles.intro} section container`}>
      <div className={styles.content}>
        <h3>{title}</h3>
        {htmlToReactParser.parse(description)}
      </div>
    </section>
  );
};

RangeIntro.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
