import React from 'react';
import { ProductFamilies } from 'components/product-families';
import styles from './style.module.scss';

export const ProductRange = ({ families }) => {
  return (
    <section className={styles.range}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <ProductFamilies families={families} />
        </div>
      </div>
    </section>
  );
};
