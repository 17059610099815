import React from 'react';
import { useWindowSize } from 'hooks/use-window-size';
import { bpMedium } from 'constants/breakpoints';
import PropTypes from 'prop-types';
import { HtmlBlock } from 'components/html-block';
import styles from './style.module.scss';

export const ProjectInfo = ({
  color,
  image1,
  image2,
  year,
  client,
  lightingDesigner,
  photographer,
  location,
  description,
}) => {
  const { width } = useWindowSize();
  const isLarge = width > bpMedium;

  const projetDetails = (
    <div className={styles['project-details']}>
      <span>
        Year: <span>{year}</span>
      </span>
      <span>
        Client: <span dangerouslySetInnerHTML={{ __html: client }} />
      </span>
      <span>
        Lighting Designer:{' '}
        <span dangerouslySetInnerHTML={{ __html: lightingDesigner }} />
      </span>
      <span>
        Photographer:{' '}
        <span dangerouslySetInnerHTML={{ __html: photographer }} />
      </span>
      <span>
        Location:
        <span>
          {' '}
          {location.city}, {location.country}
        </span>
      </span>
    </div>
  );

  const secondaryImage = image2 ? (
    <div className={styles['secondary-image']}>
      <img src={image2} alt={image2 ? `${client} project` : ''} />
    </div>
  ) : (
    ''
  );

  return (
    <section className={`${styles['project-info']} section`}>
      <div className={`${styles.wrapper} container`}>
        <div className={styles['title-wrapper']}>
          <h2>project info</h2>
        </div>
        <div className={styles['content-wrapper']}>
          <div className={styles.content}>
            {isLarge && (
              <div className={styles['column-left']}>
                {projetDetails}
                {secondaryImage}
              </div>
            )}
            {!isLarge && (
              <>
                {projetDetails}
                {secondaryImage}
              </>
            )}
            <div className={styles['column-right']}>
              <div className={styles['image-wrapper']}>
                <div className={styles['main-image']}>
                  <img src={image1} alt={`${client} project`} />
                  <div className={`${styles['bg-color']} ${styles[color]}`} />
                </div>
              </div>
              <div className={styles['description']}>
                <HtmlBlock>{description}</HtmlBlock>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ProjectInfo.propTypes = {
  color: PropTypes.string,
  image1: PropTypes.string,
  image2: PropTypes.string,
  year: PropTypes.string,
  client: PropTypes.string,
  lightingDesigner: PropTypes.string,
  photographer: PropTypes.string,
  description: PropTypes.string,
  location: PropTypes.shape({
    city: PropTypes.string, 
    country: PropTypes.string
  }),
}