import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow, concat, kebabCase, uniqBy, orderBy } from 'lodash/fp';
import { SEARCH, GRID } from 'constants/view';
import {
  hasSearched,
  setProductsView,
  setSearchedProducts,
  fetchProductsIfNeeded,
} from 'redux/actions';
import md5 from 'md5';
import Select from 'react-select';
import { useWindowSize } from 'hooks/use-window-size';
import { bpSmall } from 'constants/breakpoints';
import { customStyles } from 'components/search/custom-styles';
import { CloseView } from 'components/search/close-view';
import { NoOptionsMessage } from 'components/search/no-options-message';
import styles from './search-bar.module.scss';

export const SearchBar = () => {
  const dispatch = useDispatch();
  const { filters, view } = useSelector((state) => state.products);
  const { hasSearched: productsSearched } = useSelector(
    (state) => state.search
  );
  const [filteredOptions, setFilteredOptions] = useState({});
  const hash = md5(JSON.stringify(filters));
  const [options, setOptions] = useState({});
  const [isFocused, setIsFocused] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const searchView = view === SEARCH;
  const { width } = useWindowSize();
  const isMobile = width <= bpSmall;

  const { loading, products } = useSelector(
    (state) =>
      state.products?.[hash] || {
        loading: true,
        products: [],
      }
  );

  useEffect(() => {
    dispatch(fetchProductsIfNeeded(filters));
    if (!selectedValue) {
      setIsDisabled(true);
    }
  }, [dispatch, filters, selectedValue]);

  useEffect(() => {
    const options = products.map((product) => ({
      ...product,
      value: kebabCase(product.title),
      label: product.title,
    }));

    const termsArray = products.reduce((acc, current) => {
      const terms = current.searchTerms.map((term) => ({
        value: term,
        label: term,
      }));
      const filtered = terms.filter(({ label }) => label.indexOf('-') === -1);
      return acc.concat(filtered);
    }, []);

    const newOptions = flow(
      concat(termsArray),
      uniqBy('value'),
      orderBy('value', 'asc')
    )(options);

    setFilteredOptions(options);
    setOptions(newOptions);
  }, [loading, products]);

  useEffect(() => {
    if (productsSearched.length < 1) {
      dispatch(hasSearched(false));
      setIsDisabled(true);
    }
  }, [dispatch, productsSearched]);

  const handleInputChange = (value) => {
    if (value.length > 0) {
      setIsDisabled(false);
      dispatch(hasSearched(true));
    }
  };

  const filterList = (values) => {
    const searchedFor = values.map((value) => value.value);

    const options = [];
    filteredOptions.filter((option) => {
      return searchedFor.map((term) => {
        if (option.searchTerms.indexOf(term) !== -1) {
          options.push(option);
          return true;
        }
        return false;
      });
    });

    dispatch(setSearchedProducts(uniqBy('ID', options)));
  };

  const handleChange = (value) => {
    dispatch(setProductsView(SEARCH));

    if (value === null || value.length < 1) {
      dispatch(hasSearched(false));
      dispatch(setSearchedProducts(products));
      setSelectedValue(null);
    } else {
      setSelectedValue({ ...selectedValue, value });
      filterList(value);
      dispatch(hasSearched(true));
      setIsDisabled(false);
    }
  };

  const handleFocus = () => {
    dispatch(setProductsView(SEARCH));
    dispatch(setSearchedProducts(products));
    setIsFocused(true);
  };

  const handleReset = () => {
    setSelectedValue(null);
    setIsDisabled(true);
    dispatch(hasSearched(false));
    dispatch(setSearchedProducts(products));
  };

  const closeView = () => {
    dispatch(setProductsView(GRID));
    handleReset();
  };

  const getPlaceholderText = () => {
    const placeholderText = {
      desktopBlur: 'type to search',
      mobileBlur: '',
      desktopFocused: "e.g 'dino' or 'adjustable'",
      mobileFocused: "e.g 'dino'",
    };

    const device = isMobile ? 'mobile' : 'desktop';
    const state = isFocused ? 'Focused' : 'Blur';
    const key = [device, state].join('');

    return placeholderText[key];
  };

  return (
    <>
      {searchView && (
        <div className={styles.controls}>
          <button
            type="button"
            onClick={() => handleReset()}
            disabled={isDisabled}
          >
            reset search
          </button>
          <CloseView closeView={closeView} />
        </div>
      )}
      <div className={styles.field}>
        <Select
          isDisabled={loading}
          options={options}
          placeholder={getPlaceholderText()}
          styles={customStyles}
          components={{ NoOptionsMessage }}
          onFocus={() => handleFocus()}
          onBlur={() => setIsFocused(false)}
          isMulti
          onInputChange={(value) => handleInputChange(value)}
          onChange={(value) => handleChange(value)}
          value={selectedValue ? selectedValue.value : null}
        />
      </div>
    </>
  );
};
