import React from 'react';

export const Pdf = props => (
  <svg id="prefix__Layer_1" data-name="Layer 1" viewBox="0 0 24 34" {...props}>
    <defs>
      <style>
        {
          '.prefix__cls-3{fill-rule:evenodd}.prefix__cls-2{mask:url(#prefix__mask)}.prefix__cls-3{fill:#393e5b}'
        }
      </style>
      <mask
        id="prefix__mask"
        x={0}
        y={0}
        width={24}
        height={34}
        maskUnits="userSpaceOnUse"
      >
        <g id="prefix__mask-2">
          <path
            id="prefix__path-1"
            fillRule="evenodd"
            fill="#fff"
            d="M0 0h24v34H0V0z"
          />
        </g>
      </mask>
    </defs>
    <g id="prefix__PDF">
      <g id="prefix__PDF-icon">
        <g className="prefix__cls-2">
          <path
            id="prefix__Fill-1"
            className="prefix__cls-3"
            d="M17.06 30.19H16a.14.14 0 01-.16-.12v-5.49a.14.14 0 01.12-.16h3.46a.15.15 0 01.16.12v1.08a.14.14 0 01-.16.15H17.2v1.07h1.95a.13.13 0 01.15.1v1a.14.14 0 01-.11.16h-2V30c0 .1-.05.15-.14.15zM11 25.76v3.09h.81a1.5 1.5 0 001.52-1.54 1.54 1.54 0 00-.44-1.12 1.51 1.51 0 00-1.08-.43zm.86 4.43h-2a.13.13 0 01-.15-.12v-5.49a.14.14 0 01.11-.16h2.06a2.82 2.82 0 011.1.23 2.71 2.71 0 011.48 1.53 3.1 3.1 0 01.21 1.13 3 3 0 01-.36 1.44 2.83 2.83 0 01-1 1.05 2.68 2.68 0 01-1.43.39zM5.7 25.66v1.44h.58a.87.87 0 00.6-.18.7.7 0 00.21-.53.65.65 0 00-.21-.53.87.87 0 00-.6-.18zm-.14 4.53h-1a.12.12 0 01-.14-.13v-5.48a.14.14 0 01.11-.16h2.08a1.84 1.84 0 011 .27 1.63 1.63 0 01.63.72 2 2 0 01.21 1 2 2 0 01-.13.72 2.22 2.22 0 01-.37.63 1.76 1.76 0 01-.58.43 1.57 1.57 0 01-.73.17H5.7V30c0 .1 0 .15-.14.15zM1.49 1.36a.18.18 0 00-.17.17v30.91a.18.18 0 00.17.18h21a.17.17 0 00.17-.18V8.67a.13.13 0 00-.05-.12l-6.86-7.14a.18.18 0 00-.12 0zM22.52 34h-21A1.52 1.52 0 010 32.45V1.54A1.52 1.52 0 011.49 0h14.14a1.48 1.48 0 011 .45l6.89 7.13A1.52 1.52 0 0124 8.65v23.79A1.52 1.52 0 0122.52 34zM16.63 7.63h5.12l-5.12-5.31z"
          />
        </g>
        <g className="prefix__cls-2">
          <path
            id="prefix__Path"
            className="prefix__cls-3"
            d="M22.62 9h-6.35a1 1 0 01-1-1V1.42a1 1 0 01.94-1A.94.94 0 0117 .7l6.3 6.59a1 1 0 010 1.41 1 1 0 01-.7.29z"
          />
        </g>
      </g>
    </g>
  </svg>
);
