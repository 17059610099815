import React, { useEffect, useRef } from 'react';
import { functions, isEqual, omit } from 'lodash';
import PropTypes from 'prop-types';
import { createSalesMarker } from 'components/map/createSalesMarker';
import { CustomLegend } from 'components/map/legend';
import { partner } from 'components/map/pins';
import { values } from 'lodash/fp';

import styles from './style.module.scss';

const data = require('./custom-map.json');

function Map({
  options,
  onMount,
  className,
  salesLocations,
  partnersLocations,
  mobileMap,
}) {
  const divProps = { ref: useRef(), className };

  useEffect(() => {
    const onLoad = () => {
      const map = new window.google.maps.Map(divProps.ref.current, options);
      onMount && onMount(map);
      
      const styledMapType = new window.google.maps.StyledMapType(data, {
        name: 'Styled Map',
      });

      map.mapTypes.set('styled_map', styledMapType);
      map.setMapTypeId('styled_map');

      createSalesMarker(map, salesLocations);

      values(partnersLocations).forEach((location, index) => {
        location.partners.map(partnerLocation => {
          const marker = new window.google.maps.Marker({
            map,
            position: {
              lat: partnerLocation.map.lat,
              lng: partnerLocation.map.lng,
            },
            clickable: false,
            icon: {
              url: partner,
              scaledSize: new window.google.maps.Size(10, 10),
            },
          });
          return marker;
        });
      });
    };

    if (!window.google) {
      const script = document.createElement(`script`);
      script.type = `text/javascript`;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${
        process.env.REACT_APP_GOOGLE_API_KEY
      }`;
      const headScript = document.getElementsByTagName(`script`)[0];
      headScript.parentNode.insertBefore(script, headScript);
      script.addEventListener(`load`, onLoad);
      return () => script.removeEventListener(`load`, onLoad);
    } else onLoad();
  }, [divProps.ref, onMount, options, salesLocations, partnersLocations]);

  return (
    <>
      <div {...divProps} className={styles.map} />
      <CustomLegend />
      <div
        className={styles['mobile-map']}
        style={{
          background: `url(${mobileMap}) no-repeat`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      />
    </>
  );
}

const shouldUpdate = (prevProps, nextProps) => {
  delete prevProps.options.mapTypeId;
  const [prevFuncs, nextFuncs] = [functions(prevProps), functions(nextProps)];
  return (
    isEqual(omit(prevProps, prevFuncs), omit(nextProps, nextFuncs)) &&
    prevFuncs.every(fn => prevProps[fn].toString() === nextProps[fn].toString())
  );
};

export default React.memo(Map, shouldUpdate);

Map.defaultProps = {
  options: {
    center: { lat: 53, lng: 8 },
    zoom: 4,
    maxZoom: 5,
    minZoom: 4,
    disableDefaultUI: true,
  },
};

Map.propTypes = {
  options: PropTypes.object,
  onMount: PropTypes.func,
  className: PropTypes.string ,
  salesLocations: PropTypes.array.isRequired ,
  partnersLocations: PropTypes.object.isRequired ,
  mobileMap: PropTypes.string.isRequired ,
}