import React from 'react';

export const Tick = props => (
  <svg viewBox="0 0 36.2 36" {...props}>
    <linearGradient
      id="a"
      gradientUnits="userSpaceOnUse"
      x1="31.492"
      y1="6.572"
      x2="4.021"
      y2="29.84"
    >
      <stop offset="0" stopColor="#ffc0ac" />
      <stop offset="1" stopColor="#ff8c74" />
    </linearGradient>
    <circle cx="18" cy="18" r="18" fill="url(#a)" />
    <path
      d="M25.21 11.98L15.16 22.03l-4.44-4.44c-.56-.56-1.44-.56-2 0s-.56 1.43 0 2L14.15 25c.28.28.63.42.98.42s.73-.14.98-.42L27.1 14.01a1.4 1.4 0 000-2c-.46-.55-1.37-.55-1.89-.03z"
      fill="#fff"
    />
  </svg>
);
