import React, { Fragment, useState, useEffect } from 'react';
import keys from 'lodash/fp/keys';
import values from 'lodash/fp/values';
import classNames from 'classnames';
import getFileExtension from 'modules/get-file-extension';
import {
  SelectedCheckbox,
  DownwardArrow,
  Pdf,
  ImageIcon,
} from 'components/icons';
import {
  DISPLAYED_EXTENSIONS,
  DOWNLOAD_HEADERS,
  DOWNLOAD_ADD_IMAGE,
  DOWNLOAD_ADD_PSU,
} from 'constants/spec-code-downloads';
import {
  EXTENSION_JPG,
  EXTENSION_PDF,
  EXTENSION_PNG,
} from 'constants/extensions';
import { DownloadCheckbox } from 'components/download-check-box';
import { PreviewLinkPdf } from './preview-link-pdf';
import { PreviewLinkImage } from './preview-link-image';
import styles from './style.module.scss';

export const RightColumn = ({
  data,
  disabled,
  handleClick,
  handleDownloadSelected,
  checkboxes,
}) => {
  const [imagePackIsOpen, setImagePackIsOpen] = useState(true);
  const [imagePackBtnIsDisabled, setImagePackBtnIsDisabled] = useState(true);
  const [psuIsOpen, setPsuIsOpen] = useState(true);
  const [psuBtnIsDisabled, setPsuBtnIsDisabled] = useState(true);
  const extensions = [];

  // enable and disable the correct download button in the dropdown
  useEffect(() => {
    checkboxes.map((file) => {
      return extensions.push(getFileExtension(file));
    });

    if (
      extensions.includes(EXTENSION_JPG) ||
      extensions.includes(EXTENSION_PNG)
    ) {
      setImagePackBtnIsDisabled(false);
    } else {
      setImagePackBtnIsDisabled(true);
    }

    if (extensions.includes(EXTENSION_PDF)) {
      setPsuBtnIsDisabled(false);
    } else {
      setPsuBtnIsDisabled(true);
    }
  }, [checkboxes, extensions]);

  return (
    <div className={styles['right-column']}>
      {keys(data).map((item, index) => {
        const imagePack = DOWNLOAD_HEADERS[item] === 'Image pack';
        const psu = DOWNLOAD_HEADERS[item] === 'Power supplies';

        if (values(data[item]).length < 1) {
          return null;
        }

        const toggleAccordion = (item) => {
          handleClick(item);

          if (item === DOWNLOAD_ADD_PSU) {
            setPsuIsOpen(!psuIsOpen);
          }
          if (item === DOWNLOAD_ADD_IMAGE) {
            setImagePackIsOpen(!imagePackIsOpen);
          }
        };

        const headerClasses = classNames({
          [`${styles['is-open']}`]:
            (imagePack && imagePackIsOpen) || (psu && psuIsOpen),
          [`${styles['filter-header']}`]: true,
          [`flex-center`]: true,
        });

        const optionsClasses = classNames({
          [`${styles['filter-options']}`]: true,
          [`container`]: true,
        });

        const buttonClasses = classNames({
          [`${styles['download-zip']}`]: true,
          [`${styles.disabled}`]:
            (imagePack && imagePackBtnIsDisabled) || (psu && psuBtnIsDisabled),
        });

        return (
          <Fragment key={index}>
            <div key={index} className={headerClasses}>
              <div className="container">
                <span
                  className={styles.title}
                  onClick={() => toggleAccordion(item)}
                >
                  <span className={styles.icon}>
                    {imagePack && <ImageIcon className={styles['image-big']} />}
                    {psu && <Pdf className={styles.pdf} />}
                  </span>

                  <span>
                    <span className={styles.header}>
                      {DOWNLOAD_HEADERS[item]}
                    </span>
                    <DownwardArrow
                      className={styles.arrow}
                      onClick={() => {
                        !disabled && toggleAccordion(item);
                      }}
                    />
                  </span>
                </span>
              </div>

              <div className={optionsClasses}>
                <div className={styles['subtext-wrapper']}>
                  <p className={styles['subtext']}>Download or preview file</p>
                  <div className={styles['select-preview']}>
                    <span className={styles.select}>select</span>
                    <span className={styles.preview}>preview</span>
                  </div>
                </div>
                <ul>
                  {values(data[item]).map((subItem, index) => {
                    const filename = subItem.filename;

                    if (!filename) {
                      return null;
                    }

                    const url = subItem.url;
                    const extension = getFileExtension(url);
                    const name = filename.split('.' + extension)[0];
                    const pathname = new URL(url).pathname;
                    const showExtension =
                      DISPLAYED_EXTENSIONS.indexOf(extension) > -1
                        ? `.${extension}`
                        : '';

                    return (
                      <li key={filename + index}>
                        <label htmlFor={filename}>
                          <DownloadCheckbox
                            icon={<SelectedCheckbox />}
                            id={filename}
                            url={pathname.substr(1)}
                          />
                          <span>
                            {name}
                            {showExtension}
                          </span>
                        </label>
                        {(extension === EXTENSION_JPG ||
                          extension === EXTENSION_PNG) && (
                          <PreviewLinkImage url={url} />
                        )}
                        {extension === EXTENSION_PDF && (
                          <PreviewLinkPdf url={url} />
                        )}
                      </li>
                    );
                  })}
                </ul>
                <button
                  onClick={handleDownloadSelected}
                  className={buttonClasses}
                >
                  download zip
                </button>
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};
