export const arrOne = [];
for (let index = 1; index <= 4; index++) {
  arrOne.push({
    ID: index,
    allVariants: false,
    bezelSize: 80,
    default: { url: '/url-here', thumbnail: false, video: false },
    family: false,
    familyListingThumbnail: 'https://via.placeholder.com/196x199',
    familyOrder: false,
    hasFireplate: false,
    isNew: true,
    metaFields: ['686lm - 879lm', '80mm'],
    title: 'product title',
    types: ['downlight'],
    url: '/url-here',
    variants: [
      {
        ID: index,
        description: false,
        featureVideo: false,
        thumbnail: false,
        title: false,
        variant: 'adjustable',
      },
      {
        ID: index,
        description: false,
        featureVideo: false,
        thumbnail: false,
        title: false,
        variant: 'fixed',
      },
      {
        ID: index,
        description: false,
        featureVideo: false,
        thumbnail: false,
        title: false,
        variant: 'twin',
      },
    ],
    video: false,
  });
}

export const arrTwo = [];
for (let index = 1; index <= 8; index++) {
  arrTwo.push({
    ID: index,
    allVariants: false,
    bezelSize: 51,
    default: { url: '/url-here', thumbnail: false, video: false },
    family: false,
    familyListingThumbnail: 'https://via.placeholder.com/196x199',
    familyOrder: false,
    hasFireplate: true,
    isNew: false,
    metaFields: ['686lm - 879lm', '80mm'],
    title: 'product title',
    types: ['downlight'],
    url: '/url-here',
    variants: [
      {
        ID: index,
        description: false,
        featureVideo: false,
        thumbnail: false,
        title: false,
        variant: 'adjustable',
      },
      {
        ID: index,
        description: false,
        featureVideo: false,
        thumbnail: false,
        title: false,
        variant: 'fixed',
      },
      {
        ID: index,
        description: false,
        featureVideo: false,
        thumbnail: false,
        title: false,
        variant: 'twin',
      },
    ],
    video: false,
  });
}

export const families = {
  accent: [...arrOne],
  architectural: [...arrTwo],
  classic: [...arrTwo],
  concealed: [...arrOne],
  curved: [...arrTwo],
  specialist: [...arrOne],
  spot: [...arrTwo],
  square: [...arrOne],
};
