import urls from 'constants/urls.json';
import { getFamiliesByRange } from 'modules/api';

export const FAMILIES_BY_RANGE_REQUEST = 'FAMILIES_BY_RANGE_REQUEST';
export const FAMILIES_BY_RANGE_RECEIVED = 'FAMILIES_BY_RANGE_RECEIVED';

export const requestFamiliesByRange = (id) => {
  return { type: FAMILIES_BY_RANGE_REQUEST, id };
};

export const receiveFamiliesByRange = (familiesByRange) => {
  return { type: FAMILIES_BY_RANGE_RECEIVED, familiesByRange };
};

export const fetchFamiliesByRange = (id) => {
  return async (dispatch) => {
    dispatch(requestFamiliesByRange(id));

    const familiesByRange = await getFamiliesByRange(
      urls['families-by-range'],
      id
    );

    dispatch(receiveFamiliesByRange(familiesByRange));
  };
};
