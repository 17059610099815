import React from 'react';

export const Twitter = props => (
  <svg data-name="Layer 1" viewBox="0 0 34.5 34.5" {...props}>
    <circle
      cx={17.25}
      cy={17.25}
      r={16.5}
      fill="none"
      stroke=""
      strokeWidth={1.5}
    />
    <path
      d="M25.75 12.41a6.82 6.82 0 01-2 .55 3.51 3.51 0 001.53-2 7.07 7.07 0 01-2.21.86 3.47 3.47 0 00-2.55-1.07 3.53 3.53 0 00-3.4 4.34 9.86 9.86 0 01-7.19-3.69 3.58 3.58 0 001.08 4.72 3.41 3.41 0 01-1.58-.45 3.53 3.53 0 002.8 3.51 3.31 3.31 0 01-1.57.06 3.49 3.49 0 003.25 2.51 7 7 0 01-5.16 1.41 9.8 9.8 0 005.35 1.59 9.94 9.94 0 009.91-10.51 7.21 7.21 0 001.74-1.83"
      fillRule="evenodd"
      stroke="none"
    />
  </svg>
);
