import {
  FAMILIES_BY_RANGE_REQUEST,
  FAMILIES_BY_RANGE_RECEIVED,
} from 'redux/actions/families-by-range';

const initialState = {
  hasError: false,
  familiesByRange: {},
  loading: true,
};

export default function familiesByRange(state = initialState, action) {
  switch (action.type) {
    case FAMILIES_BY_RANGE_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        familiesByRange: {
          ...state.familiesByRange,
        },
        loading: true,
      });
    case FAMILIES_BY_RANGE_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        familiesByRange: {
          ...action.familiesByRange,
        },
        loading: false,
      });
    default:
      return state;
  }
}
