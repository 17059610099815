import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRelatedProductIfNeeded } from 'redux/actions';
import { Slide } from 'components/carousel/slide';
import { Loading } from 'components/loading';

export const FeaturedProduct = ({ name }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRelatedProductIfNeeded(name));
  });

  const { data } = useSelector((state) => state.relatedProduct);
  const loading = !data.hasOwnProperty(name) || data[name].loading;

  if (loading) {
    return <Loading size={50} fit />;
  }

  const product = data[name];

  return (
    <Slide
      src={product.thumbnail}
      type="square-small"
      url={product.url}
      title={product.title}
      theme="dark"
    />
  );
};

FeaturedProduct.propTypes = {
  name: PropTypes.string.isRequired
}