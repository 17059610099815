export const SPEC_CODE_GET_OPTIONS = 'SPEC_CODE_GET_OPTIONS';
export const SPEC_CODE_SUBMIT = 'SPEC_CODE_SUBMIT';
export const SPEC_CODE_CLEAN = 'SPEC_CODE_CLEAN';

export const cleanSpecCode = () => {
  return { type: SPEC_CODE_CLEAN };
};

export const submitSpecCodeSelection = (selection) => {
  return { type: SPEC_CODE_SUBMIT, selection };
};

export const getSpecCodeOptions = (options) => {
  return { type: SPEC_CODE_GET_OPTIONS, options };
};
