import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import styles from 'components/four-oh-four/style.module.scss';
import { Button } from 'components/buttons';
import { SIZE_MEDIUM } from 'constants/sizes';
import { THEME_DARK } from 'constants/color-theme';
import { ROUTE_HOME } from 'constants/router-links';
import { LABEL_RETURN_HOME } from 'constants/labels';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
    });
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <main className={styles['four-oh-four']}>
          <div className={`${styles.content} container`}>
            <h4>
              Oh no!
              <br />
              Unfortunately something has gone wrong, and our technical team has
              been informed of the issue.
            </h4>
            <Link to={ROUTE_HOME}>
              <Button
                size={SIZE_MEDIUM}
                theme={THEME_DARK}
                label={LABEL_RETURN_HOME}
              />
            </Link>
          </div>
        </main>
      );
    }

    return this.props.children;
  }
}
