import React from 'react';
import styles from './style.module.scss';

function CircularProductStrip(props) {
  return (
    <div className={styles['circular-product-strip']}>
      <div>
        <h2>
          <img
            src="https://orluna-assets.bravestaging.site/uploads/2022/02/circular_product_WM-white.png"
            alt="Circular Product"
          />
        </h2>
        <div>
          <div className={styles['circular-product-strip__pill']}>
            <p>
              {props.recycledStat + '%'}
              <span>recycled</span>
            </p>
          </div>
          <div className={styles['circular-product-strip__pill']}>
            <p>
              {props.recyclableStat + '%'}
              <span>recyclable</span>
            </p>
          </div>
          <div className={styles['circular-product-strip__pill']}>
            <p>
              {props.repairableStat + '%'}
              <span>repairable</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CircularProductStrip;
