import axios from 'axios';
import queryString from 'query-string';
import { kebabCase } from 'lodash';

const isObject = function(o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const isArray = function(a) {
  return Array.isArray(a);
};

const keysToCamel = o => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach(k => {
      n[kebabCase(k)] = keysToCamel(o[k]);
    });

    return n;
  } else {
    return o.map(i => {
      return kebabCase(i);
    });
  }
};

/**
 * Make a get request wiht authorization if available
 * @param {string} endpoint - Endpoint to add to the CMS URL
 * @return {object}
 */
export const get = async (endpoint, params = {}) => {
  const qs = queryString.stringify(keysToCamel(params), {
    arrayFormat: 'bracket',
  });
  const get = qs.length > 0 ? `?${qs}` : '';
  let url = `${process.env.REACT_APP_CMS_URL}/${endpoint}${get}`;

  // Fetch
  const response = await axios(url, {
    method: 'GET',
  });
  const data = await response.data;

  return data;
};
