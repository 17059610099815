import urls from 'constants/urls.json';
import { getNews } from 'modules/api';

export const NEWS_REQUEST = 'NEWS_REQUEST';
export const NEWS_RECEIVED = 'NEWS_RECEIVED';

export const requestNews = () => {
  return { type: NEWS_REQUEST };
};

export const receiveNews = news => {
  return { type: NEWS_RECEIVED, news };
};

export const fetchNews = () => {
  return async dispatch => {
    dispatch(requestNews());

    const news = await getNews(urls['news']);

    dispatch(receiveNews(news));
  };
};
