import React from 'react';
import { Loading } from 'components/loading';
import styles from './styles.module.scss';

export default function DownloadLoading() {
  return (
    <div className={styles.loading}>
      <Loading fit={true} />
    </div>
  );
}
