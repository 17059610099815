import React from 'react';
import { SIZE_BIG } from 'constants/sizes';
import { THEME_DARK } from 'constants/color-theme';
import { LABEL_DOWNLOAD } from 'constants/labels';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FloatingLogo } from 'components/floating-logo';
import { Button } from 'components/buttons';
import datasheetIcon from 'assets/images/datasheet.svg';
import linedrawingIcon from 'assets/images/line_drawing.svg';
import styles from './style.module.scss';

export const LineDrawingAndDatasheet = ({ lineDrawing, datasheet }) => {
  const generateDownloadUrl = (type) => {
    const uris = {
      datasheet: datasheet.url,
      lineDrawing: lineDrawing.line_drawing,
    };

    const query = ['download?url=', uris[type]].join('');
    return [process.env.REACT_APP_DOWNLOADS_URL, 'api', query].join('/');
  };

  const dataClasses = classNames({
    [`${styles.wrapper}`]: true,
    [`${styles.disabled}`]: !datasheet.url,
  });

  const lineClasses = classNames({
    [`${styles.wrapper}`]: true,
    [`${styles.disabled}`]: !lineDrawing.line_drawing,
  });

  return (
    <section className={styles['line-drawings']}>
      <span>
        <FloatingLogo size={SIZE_BIG} />
      </span>
      <h2>view specifications</h2>
      <div className={dataClasses}>
        <div className={styles.option}>
          <a href={datasheet.url} target="_blank" rel="noopener noreferrer">
            <img src={datasheetIcon} alt="datasheet icon" />
          </a>
          <div>
            <h3>datasheet</h3>
            <p>
              A summary sheet of <br /> product specifications.
            </p>
            <a
              className={styles['preview-icon']}
              href={generateDownloadUrl('datasheet')}
            >
              <Button theme={THEME_DARK} label={LABEL_DOWNLOAD} />
            </a>
          </div>
        </div>
        <a href={datasheet.url} target="_blank" rel="noopener noreferrer">
          view datasheet
        </a>
      </div>
      <div className={lineClasses}>
        <div className={styles.option}>
          <a
            href={lineDrawing.line_drawing ? lineDrawing.line_drawing : ''}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linedrawingIcon} alt="linedrawing icon" />
          </a>
          <div>
            <h3>line drawing</h3>
            <p>A technical drawing showing size and dimensions of product.</p>
            <a
              className={styles['preview-icon']}
              href={generateDownloadUrl('lineDrawing')}
            >
              <Button theme={THEME_DARK} label={LABEL_DOWNLOAD} />
            </a>
          </div>
        </div>
        <a
          href={lineDrawing.line_drawing ? lineDrawing.line_drawing : ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          view line drawing
        </a>
      </div>
    </section>
  );
};

LineDrawingAndDatasheet.propTypes = {
  lineDrawing: PropTypes.object,
  datasheet: PropTypes.object,
};
