import React from 'react';

export const MagnifyingGlass = (props) => (
  <svg
    {...props}
    enableBackground="new 0 0 59.71 59.71"
    viewBox="0 0 59.71 59.71"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m42.14 40.02c3.76-4.25 6.04-9.83 6.04-15.93 0-13.28-10.81-24.09-24.09-24.09s-24.09 10.81-24.09 24.09 10.81 24.09 24.09 24.09c6.1 0 11.68-2.28 15.93-6.04l17.57 17.57 2.12-2.12zm-18.05 5.16c-11.63 0-21.09-9.46-21.09-21.09s9.46-21.09 21.09-21.09 21.09 9.46 21.09 21.09-9.46 21.09-21.09 21.09z" />
  </svg>
);
