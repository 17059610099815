import React from 'react';
import styles from 'components/check-box/style.module.scss';

export const Waterproof = (props) => (
  <svg
    className={styles['filter-icon']}
    {...props}
    height="58"
    viewBox="0 0 57 58"
    width="57"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd" transform="translate(1.5 2)">
      <circle cx="27" cy="27" r="27" stroke="#f4f4f4" strokeWidth="2" />
      <path
        d="m33.2018182 24.5421687c-1.8036364-3.1325301-4.2181818-6.746988-5.7018182-10.5421687-1.4836364 3.7951807-3.8981818 7.4096386-5.7018182 10.5120482-1.2509091 2.1987952-2.2981818 3.5240964-2.2981818 6.2048193 0 4.5783132 3.5781818 8.2831325 8 8.2831325s8-3.7048193 8-8.253012c0-2.6807229-1.0472727-4.0361446-2.2981818-6.2048193z"
        fill="#555762"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
