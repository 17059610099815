import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitWorkWithUs } from 'redux/actions';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { Button } from 'components/buttons';
import { CheckboxDefault } from 'components/check-box/checkbox-default';
import { SelectedCheckbox } from 'components/icons';
import { Loading } from 'components/loading';
import {
  REGEX_EMAIL,
  REGEX_FILE_TYPE,
  REGEX_NAME,
  REGEX_SURNAME,
} from 'constants/form-regex';
import { ROUTE_COOKIES } from 'constants/router-links';
import { THEME_DARK } from 'constants/color-theme';
import { LABEL_SUBMIT } from 'constants/labels';
import { SIZE_DEFAULT } from 'constants/sizes';
import styles from './style.module.scss';

export const WorkWithUsForm = () => {
  const { register, handleSubmit, watch, errors } = useForm();
  const watchFields = watch(['given-name', 'surname', 'email', 'message']);
  const dispatch = useDispatch();
  const [privacyIsChecked, setPrivacyIsChecked] = useState(false);
  const [interestValue, setInterestValue] = useState(null);
  const [selectValues, setSelectValues] = useState({});
  const [name, setName] = useState({});
  const [cv, setCv] = useState(null);
  const { loading } = useSelector((state) => state.workWithUsForm);

  const onSubmit = (data, e) => {
    dispatch(
      submitWorkWithUs({
        ...data,
        ...selectValues,
        ...name,
        cv,
      })
    );
    //reset all fields
    e.target.reset();
    setPrivacyIsChecked(false);
    setInterestValue(null);
    setCv(null);
  };

  const handleInterestChange = (selectedOption) => {
    setInterestValue(selectedOption);
    setSelectValues({
      ...selectValues,
      interest: selectedOption.value,
    });
  };

  /* required */
  const handlePrivacyCheckbox = () => {
    setPrivacyIsChecked(!privacyIsChecked);
  };

  const handleFileChange = (event) => {
    setCv(event.target.files[0]);
  };

  const interestOptions = [
    { value: 'design', label: 'Design' },
    { value: 'sales', label: 'Sales' },
    { value: 'customer-service', label: 'Customer service' },
    { value: 'production', label: 'Production' },
    { value: 'other', label: 'Other' },
  ];

  const nameIsValid =
    watchFields['given-name'] !== undefined && watchFields['given-name'] !== '';

  const surnameIsValid =
    watchFields.surname !== undefined && watchFields.surname !== '';

  const emailIsValid =
    watchFields.email !== undefined && watchFields.email !== '';

  const emailEnteredInvalid =
    watchFields.email !== undefined &&
    watchFields.email !== '' &&
    !REGEX_EMAIL.test(watchFields.email);

  const textareaIsValid =
    watchFields.message !== undefined && watchFields.message.length > 0;

  const selectIsValid = interestValue !== null;

  const emailClass = classNames({
    [`${styles.email}`]: true,
    [`${'has-error'}`]: errors.email,
  });

  const textareaClass = classNames({
    [`${styles.textarea}`]: true,
    [`${'has-error'}`]: errors.message,
  });

  return (
    <div className={styles['work-with-us-form']}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className={styles['top-group']}>
          <div className={styles['left-side']}>
            <div className={styles.name}>
              <input
                ref={register({ pattern: REGEX_NAME })}
                className={errors.name ? 'has-error' : ''}
                type="text"
                name="given-name"
                onChange={(e) => setName({ name: e.target.value })}
                placeholder="First name*"
              />
              {errors['given-name'] && (
                <small className="error">please enter a valid first name</small>
              )}
            </div>
            <input
              ref={register({ required: true, pattern: REGEX_EMAIL })}
              className={emailClass}
              type="email"
              name="email"
              placeholder="Email address*"
            />
            {errors.email ||
              (emailEnteredInvalid && (
                <small className="error">please enter a valid e-mail</small>
              ))}
          </div>

          <div className={styles['right-side']}>
            <div className={styles.surname}>
              <input
                ref={register({ pattern: REGEX_SURNAME })}
                className={errors.surname ? 'has-error' : ''}
                type="text"
                name="surname"
                placeholder="Surname*"
              />
              {errors.surname && (
                <small className="error">please enter a valid surname</small>
              )}
            </div>
            <div className={styles['select-item-container']}>
              <label className={styles.label} htmlFor={'interest'}>
                Area of interest<sup>*</sup>
              </label>
              <Select
                ref={register}
                value={interestValue}
                options={interestOptions}
                placeholder="select..."
                id={'interest'}
                name={'interest'}
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={handleInterestChange}
              />
            </div>
          </div>
        </div>

        <div className={styles['textarea-wrapper']}>
          <label>
            Message<sup>*</sup>
          </label>
          <textarea
            ref={register({ required: true })}
            className={textareaClass}
            type="text"
            name="message"
            placeholder="type something here..."
          />
          {errors.message && <small className="error">{errors.message}</small>}
        </div>

        <div className={styles.cv}>
          <label htmlFor="file-upload">upload cv</label>
          <input
            ref={register({ pattern: REGEX_FILE_TYPE })}
            className={errors.cv ? 'has-error' : ''}
            id="file-upload"
            type="file"
            name="cv"
            onChange={handleFileChange}
          />
          <span>{cv === null || cv === undefined ? '' : cv.name}</span>
          {errors.cv && (
            <small className="error">
              please upload correct file type (.pdf, .doc, .docx)
            </small>
          )}
        </div>

        <div className={styles['privacy-wrapper']}>
          <div className={styles.privacy}>
            <label htmlFor={'privacy'}>
              <CheckboxDefault
                register={register}
                icon={<SelectedCheckbox />}
                id="privacy"
                name="privacy"
                checked={privacyIsChecked}
                value={privacyIsChecked}
                onChange={handlePrivacyCheckbox}
              />
            </label>
            <small>
              I have read and accepted Orluna's{' '}
              <a
                className={styles.terms}
                href={ROUTE_COOKIES}
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions
              </a>
              *
            </small>
          </div>
        </div>
        <Button
          size={SIZE_DEFAULT}
          theme={THEME_DARK}
          label={LABEL_SUBMIT}
          disabled={
            textareaIsValid &&
            nameIsValid &&
            surnameIsValid &&
            emailIsValid &&
            selectIsValid &&
            !loading &&
            privacyIsChecked
              ? false
              : true
          }
        />
        <small className={styles.mandatory}>
          <sup>*</sup>mandatory fields
        </small>
        {loading && <Loading overlay fit />}
      </form>
    </div>
  );
};
