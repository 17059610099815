import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getValueFromDataObject } from 'modules/get-value-from-data-object';
import { MagnifyingGlass } from 'components/icons';

import styles from './style.module.scss';
import { find, flatMap } from 'lodash';

export const Summary = ({ mainImage, drawingOne, drawingTwo, data }) => {
  const summayClasses = classNames({
    [`${styles.flex}`]: true,
    [`${styles.info}`]: true,
  });

  const drawingsClasses = classNames({
    [`${styles.drawings}`]: true,
    [`${styles['double-drawing']}`]: drawingOne && drawingTwo,
  });

  // Section to add degree number to the beam angle description
  const { activeEngine } = useSelector((state) => state.product);

  const rawBeamAngle = data.beam_angles.label;

  const words = rawBeamAngle.split(' ');

  const beamAngle = words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');

  const codes = getValueFromDataObject(data, 'value');

  const optiondescriptions = getValueFromDataObject(
    data.options,
    'description'
  );

  const litEffectsFlat = flatMap(activeEngine.add_colour_temperature, (o) => {
    return o.add_lit_effect;
  });

  const beamAnglesFlat = flatMap(litEffectsFlat, (o) => {
    return o.add_beam_angle;
  });

  const selectedBeamAngle = find(beamAnglesFlat, (o) => {
    if (
      beamAngle === 'Ultra Spot' ||
      beamAngle === 'ultraspot' ||
      beamAngle === 'ultra spot' ||
      beamAngle === 'Ultra Spot'
    ) {
      return o.beam_angle === 'Ultraspot';
    } else {
      return o.beam_angle === beamAngle;
    }
  });

  useEffect(() => {
    data.beam_angles.description =
      data.beam_angles.description +
      ', ' +
      selectedBeamAngle?.beam_width_fwhm +
      ', ' +
      selectedBeamAngle?.luminaire_lumens;
  }, [selectedBeamAngle, data]);

  const orderedDescriptions = [
    data.fitting_codes.description,
    data.bezel_colours.description,
    data.baffle_colours.description,
    data.engine_type.description,
    data.colour_temperature.description,
    data.beam_angles.description,
    ...optiondescriptions,
    data.drivers.description,
  ];

  const descList = orderedDescriptions.map((description) => (
    <p key={description}>{description}</p>
  ));

  return (
    <div className={summayClasses}>
      <div>
        <figure>
          <img src={mainImage} alt="product highlight" />
        </figure>
      </div>
      <div className={drawingsClasses}>
        {drawingOne && (
          <a href={drawingOne} target="_blank" rel="noopener noreferrer">
            <figure>
              <img src={drawingOne} alt="technical drawing" />
            </figure>
            <MagnifyingGlass />
          </a>
        )}
        {drawingTwo && (
          <a href={drawingTwo} target="_blank" rel="noopener noreferrer">
            <figure>
              <img src={drawingTwo} alt="technical drawing" />
            </figure>
            <MagnifyingGlass />
          </a>
        )}
      </div>
      <div className={styles['spec-summary']}>
        <h3>specification summary</h3>
        <p className={styles['code-summary']}>
          {codes.join(' - ').replace(/,/g, '')}
        </p>
        <span className={styles.descriptions}>{descList}</span>

        <p>
          <br />
          fully repairable and reusable <br />
          >95% recycled, >95% recyclable
        </p>
        <address>
          Ripa Subhan / info@orluna.com / 00 44 (0) 1923 889712 / Orluna, Holly
          Industrial Park, Ryan Way, Watford, <br /> WD24 4YP United Kingdom
        </address>
      </div>
    </div>
  );
};
