export const exitFullscreen = () => {
  if (document.fullscreenElement) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    if (!document.mozFullScreen) return;
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
};
