import React from 'react';
import { useScrolling } from 'hooks/use-scrolling';
import classNames from 'classnames';
import { BackToTop } from 'components/icons';
import styles from './style.module.scss';

export const BackToTopBtn = () => {
  const { scrollY, isScrolling } = useScrolling(5000);
  const fromTheTop = scrollY > 700;

  const handleBackToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const classes = classNames({
    [`${styles['back-to-top']}`]: true,
    [`${styles['scroll']}`]: fromTheTop && isScrolling,
  });

  return (
    <>
      <div onClick={(e) => handleBackToTop(e)}>
        <BackToTop className={classes} />
      </div>
    </>
  );
};
