import React, { useState, useEffect } from 'react';
import { getPage } from 'modules/api';
import { Loading } from 'components/loading';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { HtmlBlock } from 'components/html-block';
import { ErrorBoundary } from 'components/error';
import urls from 'constants/urls.json';
import { THEME_LIGHT } from 'constants/color-theme';

export const TermsCookiePolicyScreen = () => {
  const [loading, isLoading] = useState(true);
  const [pageContent, setPageContent] = useState({});

  useEffect(() => {
    let isSubscribed = true;

    async function fetchPageContent() {
      const response = await getPage(
        urls['page'],
        'website-terms-and-cookie-policy'
      );
      if (isSubscribed) {
        setPageContent(response);
        isLoading(false);
      }
    }
    fetchPageContent();
    return () => (isSubscribed = false);
  }, [pageContent]);

  if (loading) return <Loading />;

  return (
    <ErrorBoundary>
      <Header theme={THEME_LIGHT} />
      <div className="privacy-and-terms container">
        <div className="privacy-and-terms-title">
          <HtmlBlock content={pageContent.title.rendered} />
        </div>
        <div className="privacy-and-terms-content">
          <HtmlBlock content={pageContent.content.rendered} />
        </div>
      </div>
      <Footer />
    </ErrorBoundary>
  );
};
