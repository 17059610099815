import React from 'react';
import { useSelector } from 'react-redux';
import { THEME_DARK } from 'constants/color-theme';
import { PAGE_RANGE } from 'constants/page-name';
import { SimpleHero } from 'components/simple-hero';
import { ProductRange } from 'components/product-range';
import { FeaturedContent } from 'components/featured-content';
import { ProjectFeatured } from 'components/project-featured';
import { RangeIntro } from 'components/range-intro';
import { RangeGrid } from 'components/range-grid';

export const Range = ({
  hero,
  name,
  intro,
  featuredContent,
  displayProject,
  project,
  rangeGrid,
}) => {
  const { ranges, rangesLoading } = useSelector((state) => state.ranges);
  const { familiesByRange } = useSelector((state) => state.familiesByRange);

  return (
    <>
      <SimpleHero
        pageName={PAGE_RANGE}
        videoDesktop={hero.desktop.video}
        videoMobile={hero.mobile.video}
        desktopLoop={hero.desktop.loop}
        mobileLoop={hero.mobile.loop}
        desktop={hero.desktop.image}
        mobile={hero.mobile.image}
        title={name}
        theme={THEME_DARK}
      />
      <RangeIntro title={intro?.title} description={intro?.description} />
      <ProductRange families={familiesByRange} />
      <FeaturedContent content={featuredContent} rangeName={name} />
      {displayProject && (
        <ProjectFeatured project={project} atRange isFeatured />
      )}
      {rangeGrid.display_range_grid && (
        <RangeGrid
          content={rangeGrid.range_grid}
          ranges={ranges}
          loading={rangesLoading}
        />
      )}
    </>
  );
};
