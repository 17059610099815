import {
  RANGE_GRID_REQUEST,
  RANGE_GRID_RECEIVED,
} from 'redux/actions/range-grid-product';

const initialState = {
  hasError: false,
  grid: {},
  loading: true,
};

export default function rangeGridProduct(state = initialState, action) {
  switch (action.type) {
    case RANGE_GRID_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        grid: {
          ...state.grid,
        },
        loading: true,
      });
    case RANGE_GRID_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        grid: {
          ...state.grid,
          ...action.grid,
        },
        loading: false,
      });
    default:
      return state;
  }
}
