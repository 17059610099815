import React from 'react';
import Slider from 'react-slick';
import {
  CAROUSEL_SMALL_LANDSCAPE,
  CAROUSEL_SMALL_SQUARE,
} from 'constants/carousel-types';
import { CarouselButton } from 'components/carousel/carousel-button';
import styles from './style.module.scss';

export const Carousel = ({
  type,
  settings,
  children,
  title,
  description,
  carouselButton,
}) => {
  const smallSquare = type === CAROUSEL_SMALL_SQUARE;
  const landscapeSmall = type === CAROUSEL_SMALL_LANDSCAPE;

  return (
    <section className={`${styles.slider} ${styles[type]} section container`}>
      <div
        className={
          smallSquare || landscapeSmall
            ? `${styles['related-title']} ${styles.intro}`
            : styles.intro
        }
      >
        <h3>{title}</h3>
        {description && <p>{description}</p>}
      </div>

      <Slider className={`${styles.carousel} ${type}`} {...settings}>
        {children}
      </Slider>

      {smallSquare && (
        <CarouselButton
          label={carouselButton?.label}
          link={carouselButton?.link}
        />
      )}
    </section>
  );
};
