import React from 'react';
import styles from './style.module.scss';

export const SectionWithPortrait = ({ children }) => {
  return (
    <div className={`container ${styles['section-with-portrait']}`}>
      {children}
    </div>
  );
};
