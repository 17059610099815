import React from 'react';
import { sales, partner } from 'components/map/pins';

import styles from './style.module.scss';

export const CustomLegend = () => {
  const icons = [
    {
      name: 'orluna sales',
      icon: sales,
    },
    {
      name: 'international partners',
      icon: partner,
    },
  ];

  return (
    <div className={styles['custom-legend']}>
      {icons.map((item, index) => {
        return (
          <div className={styles.flex} key={index}>
            <img src={item.icon} alt={`${item.name} icon`} />
            <span>{item.name}</span>
          </div>
        );
      })}
    </div>
  );
};
