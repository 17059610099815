import React from 'react';
import { HEADERS } from 'constants/spec-code-downloads';
import { getValueFromObjectValue } from 'modules/get-value-from-object-value';
import keys from 'lodash/fp/keys';
import styles from './style.module.scss';

export const CodeAndLabels = ({ data, toggleSpec }) => {
  return (
    <>
      {keys(data).map((item, i) => {
        const addDash = i === 0 || i === 1 || i === 4 || i === 5 || i === 6;
        const codes = getValueFromObjectValue(data[item], 'value');
        const labels = getValueFromObjectValue(data[item], 'label');

        return (
          <div key={item + i}>
            <p className={styles.header}>{HEADERS[item]}</p>
            <span className={styles.code}>
              {codes}
              {addDash && <span> - </span>}
            </span>
            {toggleSpec && (
              <ul>
                {Array.isArray(labels) ? (
                  labels.map((label, index) => (
                    <li key={label + index}>{label}</li>
                  ))
                ) : (
                  <li>{labels}</li>
                )}
              </ul>
            )}
          </div>
        );
      })}
    </>
  );
};
