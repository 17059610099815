import React, { useEffect } from 'react';
import { fetchNews } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalizedDate } from 'modules/localized-date';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { PageTitle } from 'components/page-title';
import { SignUpSection } from 'components/signup-section';
import { Loading } from 'components/loading';
import { ErrorBoundary } from 'components/error';
import { Carousel } from 'components/carousel';
import { Slide } from 'components/carousel/slide';
import { Listing } from 'components/listing';
import { settingsLandscapeSmall } from 'components/carousel/settings';
import { THEME_DARK, THEME_SOLID } from 'constants/color-theme';
import { TITLE_MORE_NEWS, TITLE_WHATS_NEW } from 'constants/titles';
import { CAROUSEL_SMALL_LANDSCAPE } from 'constants/carousel-types';

export const NewsListingScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNews());
  }, [dispatch]);

  const { loading, news } = useSelector((state) => state.news);

  if (loading) return <Loading />;

  const sticky = Object.values(news).filter((post) => post.sticky);
  const nonSticky = Object.values(news).filter((post) => !post.sticky);
  const merged = sticky.concat(nonSticky);
  const featured = merged.slice(0, 5);
  const regular = merged.slice(5);

  return (
    <ErrorBoundary>
      <Header theme={THEME_SOLID} />
      <PageTitle title={TITLE_WHATS_NEW} />
      <Listing data={featured} isNews />
      {regular.length > 0 && (
        <Carousel
          type={CAROUSEL_SMALL_LANDSCAPE}
          title={TITLE_MORE_NEWS}
          settings={settingsLandscapeSmall}
        >
          {regular.map((post, index) => {
            return (
              <Slide
                key={index}
                src={post.thumbnail}
                type={CAROUSEL_SMALL_LANDSCAPE}
                url={`${post.slug}`}
                title={post.title}
                description={getLocalizedDate(post.date)}
                theme={THEME_DARK}
              />
            );
          })}
        </Carousel>
      )}
      <SignUpSection />
      <Footer />
    </ErrorBoundary>
  );
};
