import React from 'react';
import Lottie from 'react-lottie';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

import animationData from '../../assets/lottie/orluna_loader.json';

export const Loading = ({ size, overlay, fit }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const loaderClass = classNames({
    [styles.default]: true,
    [styles.overlay]: overlay,
    [styles.fit]: fit,
  });

  return (
    <div className={loaderClass}>
      <Lottie options={defaultOptions} height={size} width={size} />
    </div>
  );
};

Loading.defaultProps = {
  size: 100,
  overlay: false,
  fit: false
};

Loading.propTypes = {
  size: PropTypes.number, 
  overlay: PropTypes.bool, 
  fit: PropTypes.bool,
}