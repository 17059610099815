import React from 'react';

import styles from 'components/check-box/style.module.scss';

export const SelectedCheckbox = props => (
  <svg
    className={styles['default-checkbox']}
    data-name="Layer 1"
    viewBox="0 0 15 15"
    {...props}
  >
    <path fill="none" strokeWidth={2} d="M1 1h13v13H1z" />
    <path d="M4 4h7v7H4z" />
  </svg>
);
