import React from 'react';

export const LogoIcon = props => (
  <svg data-name="Layer 1" viewBox="0 0 260.66 260.66" {...props}>
    <defs>
      <radialGradient
        id="prefix__radial-gradient-icon"
        cx={607.37}
        cy={389.84}
        r={375.6}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
    </defs>
    <path
      d="M420.94 368a70.37 70.37 0 01-70.36-70.37h-60a130.34 130.34 0 00260.67 0h-60A70.37 70.37 0 01420.94 368z"
      transform="translate(-290.61 -167.31)"
      fill="url(#prefix__radial-gradient-icon)"
    />
    <path
      d="M420.94 167.31a130.33 130.33 0 00-130.33 130.33h60a70.37 70.37 0 11140.73 0h60a130.33 130.33 0 00-130.4-130.33z"
      transform="translate(-290.61 -167.31)"
    />
    <path
      d="M420.94 227.27a70.37 70.37 0 00-70.36 70.37h140.73a70.37 70.37 0 00-70.37-70.37z"
      transform="translate(-290.61 -167.31)"
      fill="none"
    />
    <path
      d="M420.94 368a70.37 70.37 0 0070.37-70.37H350.58A70.37 70.37 0 00420.94 368z"
      transform="translate(-290.61 -167.31)"
    />
  </svg>
);
