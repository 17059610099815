import React from 'react';
import styles from 'components/check-box/style.module.scss';

export const KitForm = (props) => (
  <svg
    className={styles['filter-icon']}
    data-name="Layer 1"
    viewBox="0 0 57 57"
    {...props}
  >
    <defs>
      <style>
        {'.prefix__cls-2{fill:#ffffff;stroke:#555762;stroke-width:2.5px}'}
      </style>
    </defs>
    <g id="prefix__KitForm">
      <circle cx={28} cy={28} r={27} strokeWidth={2} fill="none" />
      <g
        transform="translate(16.000000, 14.000000)"
        stroke="#555762"
        stroke-linejoin="bevel"
        stroke-width="1.5"
      >
        <polygon
          points="0 7.00015607 12.0013905 14 24 7.00015607 11.9986095 0"
          fill="#ffffff"
          stroke-width="1.5"
        ></polygon>
        <path
          d="M5.89147033,11.0006682 L5.941,11 L12.0013905,14.3867591 L17.936,11.069 L18.2331338,11.0710624 L24,14.2936959 L12.0013905,21 L0,14.2936959 L5.89147033,11.0006682 Z"
          id="Combined-Shape"
          fill="#ffffff"
          stroke-width="1.5"
        ></path>
        <path
          d="M5.89147033,18.0006682 L5.941,18 L12.0013905,21.3867591 L17.936,18.069 L18.2331338,18.0710624 L24,21.2936959 L12.0013905,28 L0,21.2936959 L5.89147033,18.0006682 Z"
          id="Combined-Shape-Copy"
          fill="#ffffff"
          stroke-width="1.5"
        ></path>
      </g>
    </g>
  </svg>
);
