import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCheckbox } from 'redux/actions';
import { Checkbox } from 'components/check-box';

export const DownloadCheckbox = ({ id, icon, url, disabled }) => {
  const dispatch = useDispatch();
  const { checkboxes } = useSelector((state) => state.downloadCheckbox);

  const isChecked = () => {
    return checkboxes.indexOf(url) > -1;
  };

  const handleChange = () => {
    dispatch(toggleCheckbox(url));
  };

  return (
    <Checkbox
      icon={icon}
      id={id}
      name={id}
      value={id}
      disabled={disabled}
      checked={isChecked()}
      onChange={() => handleChange()}
    />
  );
};
