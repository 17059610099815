import React from 'react';
import { components } from 'react-select';

export const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <p>sorry, no results.</p>
      <p>
        try reducing your search terms or searching by <span>downlight</span>,{' '}
        <span>uplight</span>, product name e.g <span>dino</span> or variant e.g.{' '}
        <span>adjustable</span>
      </p>
    </components.NoOptionsMessage>
  );
};
