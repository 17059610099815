import urls from 'constants/urls.json';
import { getRangeNavigation } from 'modules/api';

export const RANGE_NAVIGATION_REQUEST = 'RANGES_NAVIGATION_REQUEST';
export const RANGE_NAVIGATION_RECEIVED = 'RANGES_NAVIGATION_RECEIVED';
export const RANGE_NAVIGATION_TOGGLE = 'RANGES_NAVIGATION_TOGGLE';
export const RANGE_NAVIGATION_CLOSE = 'RANGES_NAVIGATION_CLOSE';

export const requestRangeNavigation = () => {
  return { type: RANGE_NAVIGATION_REQUEST };
};

export const receivedRangeNavigation = (visibility) => {
  return { type: RANGE_NAVIGATION_RECEIVED, visibility };
};

export const toggleRangeNavigation = () => {
  return { type: RANGE_NAVIGATION_TOGGLE };
};

export const closeRangeNavigation = () => {
  return { type: RANGE_NAVIGATION_CLOSE };
};

export const fetchRangeNavigation = () => {
  return async (dispatch) => {
    dispatch(requestRangeNavigation());

    const rangeNavigation = await getRangeNavigation(urls['range-navigation']);

    dispatch(receivedRangeNavigation(rangeNavigation));
  };
};
