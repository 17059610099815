export const TITLE_INT_PARTNERS = 'international partners';
export const TITLE_FAQS = 'frequently asked questions';
export const TITLE_GET_IN_TOUCH = 'get in touch';
export const TITLE_MORE_NEWS = 'more news';
export const TITLE_WHATS_NEW = "what's new at orluna";
export const TITLE_CAN_WE_HELP = 'how can we help?';
export const TITLE_BEAUTIFULLY_LIT = 'beautifully lit';
export const TITLE_OUR_STORY = 'our story';
export const TITLE_RELATED_PRODUCTS = 'related products';
export const TITLE_FEATURED_PRODUCTS = 'featured products';
export const TITLE_EXPLORE_PRODUCTS = 'explore our products';
export const TITLE_VISIONS_REAL = 'visions made real';
