import React from 'react';
import { DownloadArrow, ThumbnailGrid } from 'components/icons';

import styles from './controls.module.scss';

export const Controls = ({ handleThumbnails, handleClose, imageUri }) => {
  const query = ['download?url=', imageUri].join('');
  const url = [process.env.REACT_APP_DOWNLOADS_URL, 'api', query].join('/');

  return (
    <div className={styles.controls}>
      <a href={url}>
        <DownloadArrow />
      </a>
      <button onClick={handleThumbnails}>
        <ThumbnailGrid />
      </button>
      <button onClick={handleClose}>
        <span className={styles.close} />
      </button>
    </div>
  );
};
