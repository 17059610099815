import React from 'react';
import styles from 'components/check-box/style.module.scss';

export const Adjustable = props => (
  <svg
    className={styles['filter-icon']}
    data-name="Layer 1"
    viewBox="0 0 57 57"
    {...props}
  >
    <defs>
      <style>
        {'.prefix__cls-2{fill:none;stroke:#555762;stroke-width:2.5px}'}
      </style>
    </defs>
    <g id="prefix__Adjustable">
      <circle cx={28} cy={28} r={27} strokeWidth={2} fill="none" />
      <ellipse
        className="prefix__cls-2"
        cx={27}
        cy={27}
        rx={7}
        ry={16}
        transform="rotate(-45 28.709 26.288)"
      />
      <ellipse
        className="prefix__cls-2"
        cx={27}
        cy={27}
        rx={16}
        ry={7}
        transform="rotate(-45 28.709 26.288)"
      />
    </g>
  </svg>
);
