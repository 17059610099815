import React from 'react';

export const ThumbnailGrid = () => (
  <svg
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#fff" fillRule="evenodd">
      <path d="m6.767999 0h5.543999v5.543999h-5.543999z" />
      <path d="m13.535999 0h5.543999v5.543999h-5.543999z" />
      <path d="m0 6.767999h5.543999v5.543999h-5.543999z" />
      <path d="m6.767999 6.767999h5.543999v5.543999h-5.543999z" />
      <path d="m13.535999 6.767999h5.543999v5.543999h-5.543999z" />
      <path d="m0 13.535999h5.543999v5.543999h-5.543999z" />
      <path d="m6.767999 13.535999h5.543999v5.543999h-5.543999z" />
      <path d="m13.535999 13.535999h5.543999v5.543999h-5.543999z" />
      <path d="m0 0h5.543999v5.543999h-5.543999z" />
    </g>
  </svg>
);
