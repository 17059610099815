import React, { useEffect } from 'react';
import { values } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProjects,
  fetchCollaborators,
  fetchPartners,
} from 'redux/actions';
import { THEME_SOLID } from 'constants/color-theme';
import { TITLE_VISIONS_REAL } from 'constants/titles';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { PageTitle } from 'components/page-title';
import { Collaborators } from 'components/collaborators';
import { PartnersAndClients } from 'components/partners-and-clients';
import { SignUpSection } from 'components/signup-section';
import { Loading } from 'components/loading';
import { ErrorBoundary } from 'components/error';
import { Listing } from 'components/listing';

export const ProjectListingScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProjects());
    dispatch(fetchCollaborators());
    dispatch(fetchPartners());
  }, [dispatch]);

  const { loading, projects } = useSelector((state) => state.projects);
  const { collaborators, collaboratorsLoading } = useSelector(
    (state) => state.collaborators
  );
  const { partners, partnersLoading } = useSelector((state) => state.partners);
  const projectsHalfLength = Math.ceil(values(projects).length / 2);
  const projectsFirstHalf = values(projects).splice(0, projectsHalfLength);
  const projectsSecondHalf = values(projects).splice(projectsHalfLength);

  if (loading) return <Loading />;

  return (
    <ErrorBoundary>
      <Header theme={THEME_SOLID} />
      <PageTitle title={TITLE_VISIONS_REAL} />
      <Listing data={projectsFirstHalf} isProjects />
      {!collaboratorsLoading && (
        <Collaborators collaborators={collaborators} offsetHelper={800} />
      )}
      <Listing data={projectsSecondHalf} isProjects />
      <PartnersAndClients partners={partners} loading={partnersLoading} />
      <SignUpSection />
      <Footer />
    </ErrorBoundary>
  );
};
