import React, { useRef, useEffect } from 'react';

export const ScrollTo = () => {
  const ref = useRef(null);
  useEffect(() => {
    window.scrollTo({
      top: ref.current.offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  });

  return <div ref={ref} />;
};
