import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import products from 'redux/reducers/products';
import product from 'redux/reducers/product';
import relatedProduct from 'redux/reducers/related-product';
import downloadCheckbox from 'redux/reducers/download-checkbox';
import featuredProduct from 'redux/reducers/featured-product';
import projects from 'redux/reducers/projects';
import project from 'redux/reducers/project';
import collaborators from 'redux/reducers/collaborators';
import partners from 'redux/reducers/partners';
import contactForm from 'redux/reducers/contact-form';
import workWithUsForm from 'redux/reducers/work-with-us';
import signUpForm from 'redux/reducers/sign-up-form';
import getInTouchForm from 'redux/reducers/get-in-touch-form';
import getInTouchBanner from 'redux/reducers/get-in-touch-banner';
import downloads from 'redux/reducers/downloads';
import variants from 'redux/reducers/variants';
import litEffects from 'redux/reducers/lit-effects';
import menu from 'redux/reducers/menu';
import people from 'redux/reducers/people';
import navMenus from 'redux/reducers/nav-menus';
import news from 'redux/reducers/news';
import newsPost from 'redux/reducers/news-post';
import internationalPartners from 'redux/reducers/international-partners';
import bookConsultation from 'redux/reducers/book-consultation';
import faqs from 'redux/reducers/faqs';
import search from 'redux/reducers/search';
import notification from 'redux/reducers/notification';
import ranges from 'redux/reducers/ranges';
import range from 'redux/reducers/range';
import rangeNavigation from 'redux/reducers/range-navigation';
import rangeGridProduct from 'redux/reducers/range-grid-product';
import productFamily from 'redux/reducers/product-family';
import productRange from 'redux/reducers/product-range';
import productsByRange from 'redux/reducers/products-by-range';
import familiesByRange from 'redux/reducers/families-by-range';
import specCode from 'redux/reducers/spec-code';
import productDownloads from 'redux/reducers/product-downloads.js';

const reducers = {
  search,
  collaborators,
  partners,
  contactForm,
  downloadCheckbox,
  downloads,
  faqs,
  featuredProduct,
  internationalPartners,
  bookConsultation,
  litEffects,
  menu,
  navMenus,
  news,
  newsPost,
  people,
  product,
  products,
  rangeGridProduct,
  rangeNavigation,
  ranges,
  range,
  project,
  projects,
  relatedProduct,
  signUpForm,
  variants,
  workWithUsForm,
  getInTouchForm,
  getInTouchBanner,
  notification,
  productFamily,
  productRange,
  productsByRange,
  familiesByRange,
  specCode,
  productDownloads,
};

export const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    ...reducers,
  });
