import React from 'react';
import { FirePlate } from 'components/icons';
import styles from './style.module.scss';

export const TooltipFireplate = () => {
  return (
    <span className={styles.tooltip}>
      <span>fireplate available</span>
      <span>
        <FirePlate />
      </span>
    </span>
  );
};
