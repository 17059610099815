import React from 'react';
import styles from 'components/check-box/style.module.scss';

export const Uplight = (props) => (
  <svg
    className={styles['filter-icon']}
    {...props}
    height="56"
    viewBox="0 0 57 56"
    width="57"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd" transform="translate(1.5 1)">
      <circle cx="27" cy="27" r="27" stroke="#f4f4f4" strokeWidth="2" />
      <path d="m14 18h26l-10 20h-6z" fill="#555762" />
    </g>
  </svg>
);
