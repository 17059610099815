import React, { useState, useRef, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  submitSpecCodeSelection,
  cleanSpecCode,
  setActiveVariant,
  setActiveEngine,
  getSpecCodeOptions,
  resetCheckboxes,
  fetchProductDownloads,
} from 'redux/actions';
import classNames from 'classnames';
import { isEmpty, find } from 'lodash/fp';
import { HEADERS } from 'constants/spec-code-downloads';
import { COPY_TO_CLIPBOARD } from 'modules/copy-to-clipboard';
import { getValueFromDataObject } from 'modules/get-value-from-data-object';
import { applySpecCodeStatements } from 'components/spec-code-new/apply-spec-code-statements';
import { getUniqueEngineTypes } from 'components/spec-code-and-downloads/functions';
import { SelectOption } from 'components/spec-code-new/select';
import { CodeContainer } from 'components/spec-code-new/code-container';
import { QuickSpec } from 'components/quick-spec';
import styles from 'components/spec-code-new/style.module.scss';

import {
  KEY_ENGINE_TYPES,
  KEY_FITTING_CODES,
  KEY_COLOUR_TEMPERATURE,
  KEY_BEZEL_COLOURS,
  KEY_OPTIONS,
} from 'components/spec-code-new/constants';

export const SpecCodeNew = ({
  productOptions,
  disabledOptions,
  setDownloadsDisabled,
  smallScreen,
}) => {
  const dispatch = useDispatch();
  const { selected, optionsAvailable } = useSelector((state) => state.specCode);
  const product = useSelector((state) => state.product);
  const productDownloads = useSelector((state) => state.productDownloads);
  const [codeComplete, setCodeComplete] = useState(false);
  const [copied, setCopied] = useState(false);
  const [clearCode, setClearCode] = useState(false);
  const uniqueEngineTypes = getUniqueEngineTypes(product.data);
  const generatedCode = useRef(null);
  //const optionsValues = getValueFromDataObject(selected[KEY_OPTIONS], 'value');
  const productsSSDefault = [
    'cue-100-lopro',
    'cue-100',
    'roc-100',
    'roc-100-lopro',
  ];
  const stainlessSteelDefault =
    productsSSDefault.indexOf(product.data.slug) > -1;

  useEffect(() => {
    Object.assign(productOptions, {
      engine_types: productOptions['engine_type'],
    });
  }, [productOptions]);

  useEffect(() => {
    const output = {};

    Object.keys(productOptions).map((key) => {
      return (output[key] = [
        productOptions[key].map((obj, i) => {
          if (!disabledOptions[key] || isEmpty(disabledOptions[key])) {
            return { ...obj, disabled: false };
          }
          return {
            ...obj,
            disabled: find(obj, disabledOptions[key]) ? true : false,
          };
        }),
      ]);
    });

    let specCodeResult = applySpecCodeStatements(
      product.data.slug,
      selected,
      output,
      getValueFromDataObject(selected[KEY_OPTIONS], 'value')
    );

    const final = {};
    Object.keys(specCodeResult).map((key) => {
      return (final[key] = [
        productOptions[key].map((obj, i) => {
          if (!disabledOptions[key] || isEmpty(disabledOptions[key])) {
            return { ...obj, disabled: false };
          }
          return {
            ...obj,
            disabled: find(obj, disabledOptions[key]) ? true : false,
          };
        }),
      ]);
    });

    //dispatch(getSpecCodeOptions(output));

    dispatch(getSpecCodeOptions(specCodeResult));

    setCodeComplete(
      Object.values(selected).every(
        (e) => getValueFromDataObject(e, 'value').length
      )
    );
  }, [dispatch, productOptions, disabledOptions, selected, product.data.slug]);
  /*
  useEffect(() => {
    const originalData = cloneDeep(optionsAvailable); // HELPER: clonedeep comes from lodash

    const specCodeResult = applySpecCodeStatements(
      product.data.slug,
      selected,
      originalData,
      optionsValues
    );

    dispatch(getSpecCodeOptions(specCodeResult));
    // eslint-disable-next-line
  }, [dispatch, selected, product.data.slug]);
*/
  useEffect(() => {
    return () => dispatch(cleanSpecCode());
  }, [dispatch]);

  useEffect(() => {
    const isOption67Selected =
      getValueFromDataObject(selected[KEY_OPTIONS], 'value').indexOf('67') > -1;

    if (!isEmpty(selected[KEY_OPTIONS])) {
      let updatedSelectedOptions;

      // Update selected.options based on the disabled values inside optionsAvailable
      optionsAvailable[KEY_OPTIONS][0].map((val) => {
        const stainlessSteel = optionsAvailable[KEY_BEZEL_COLOURS][0].find(
          (obj) => {
            return obj.value === 'ss';
          }
        );

        if (val.disabled) {
          if (
            selected[KEY_OPTIONS].find(
              (selectVal) => selectVal.value === val.value
            )
          ) {
            updatedSelectedOptions = selected[KEY_OPTIONS].filter(
              (selectVal) => {
                return selectVal.value !== val.value;
              }
            );

            return dispatch(
              submitSpecCodeSelection({
                [KEY_OPTIONS]: updatedSelectedOptions,
              })
            );
          }
        }

        // Update selected.bezel_colours based on the values inside optionsAvailable
        if (isOption67Selected && stainlessSteelDefault) {
          return dispatch(
            submitSpecCodeSelection({
              [KEY_BEZEL_COLOURS]: stainlessSteel,
            })
          );
        }
        return updatedSelectedOptions;
      });
    }
    // eslint-disable-next-line
  }, [selected[KEY_OPTIONS]]);

  // set the default values in the dropdowns
  const getSelectionValue = (header) => {
    if (header === KEY_ENGINE_TYPES) {
      return {
        value: product.activeEngine.engine_type.value,
        label: product.activeEngine.engine_type.label,
        description: product.activeEngine.engine_type.description,
      };
    }

    if (header === KEY_FITTING_CODES) {
      return {
        value: product.activeVariant.fitting_code.value,
        label: product.activeVariant.fitting_code.label,
      };
    }

    if (
      header !== KEY_FITTING_CODES &&
      header !== KEY_ENGINE_TYPES &&
      header !== KEY_OPTIONS
    ) {
      return {
        value: selected[header].value,
        label: selected[header].label,
        description: selected[header].description,
      };
    }

    if (header === KEY_OPTIONS) {
      return [...selected[header]];
    }
  };

  // this useEffect sets the default values in the specCode state
  useEffect(() => {
    dispatch(
      submitSpecCodeSelection({
        [KEY_FITTING_CODES]: {
          value: product.activeVariant.fitting_code.value,
          label: product.activeVariant.fitting_code.label,
          description: product.activeVariant.fitting_code.description,
        },
      })
    );

    dispatch(
      submitSpecCodeSelection({
        [KEY_ENGINE_TYPES]: {
          value: product.activeEngine.engine_type.value,
          label: product.activeEngine.engine_type.label,
          description: product.activeEngine.engine_type.description,
        },
      })
    );
    // keep the default values after clearing
    if (clearCode) {
      getSelectionValue(KEY_ENGINE_TYPES);
      getSelectionValue(KEY_FITTING_CODES);
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    product.activeEngine.engine_type,
    product.activeVariant.fitting_code,
    clearCode,
  ]);

  const handleChange = (selection, option) => {
    /* "connects" to the Select Variant component
     * and cleans all selections if any change happens
     */
    if (option === KEY_FITTING_CODES) {
      const newVariant = product.data.variants
        .filter(({ fitting_code }) => fitting_code.value === selection.value)
        .shift();
      if (newVariant) {
        setDownloadsDisabled(true);
        dispatch(cleanSpecCode());
        dispatch(setActiveVariant(newVariant));
      }
    }
    /** "connects" to the Engine Selector component (Choose your Engine)
     * and cleans all selections if any change happens
     */
    if (option === KEY_ENGINE_TYPES) {
      const newEngine = uniqueEngineTypes
        .filter(({ engine_type }) => engine_type.value === selection.value)
        .shift();
      if (newEngine) {
        setDownloadsDisabled(true);
        dispatch(cleanSpecCode());
        dispatch(setActiveEngine(newEngine));
      }
    }

    if (option === KEY_COLOUR_TEMPERATURE) {
      setDownloadsDisabled(true);
    }

    setCopied(false);
    setClearCode(false);
    dispatch(resetCheckboxes());

    if (Array.isArray(selection)) {
      dispatch(
        submitSpecCodeSelection({
          [option]: selection,
        })
      );
    } else {
      dispatch(
        submitSpecCodeSelection({
          [option]: {
            value: selection.value,
            label: selection.label,
            description: selection.description,
          },
        })
      );
    }
  };

  const handleAssetPack = () => {
    // Transform the selected
    const keys = Object.keys(selected);

    let downloadCodes = {};
    keys.forEach((key) => {
      if (key !== 'options') {
        downloadCodes[key] = selected[key].value;
      } else {
        downloadCodes[key] = selected[key].map((option) => {
          return option.value;
        });
      }
    });
    setDownloadsDisabled(false);
    dispatch(fetchProductDownloads(downloadCodes));
  };

  const handleCopyToClipboard = () => {
    const text = generatedCode.current.innerText;
    COPY_TO_CLIPBOARD(text);
    setCopied(true);
  };

  const handleClearCode = () => {
    dispatch(cleanSpecCode());
    setDownloadsDisabled(true);
    setClearCode(true);
  };

  const btnClasses = classNames({
    [`${styles.btn}`]: true,
    [`${styles.ready}`]: codeComplete,
  });

  const wrapperBtnClasses = classNames({
    [`${styles['btn-wrapper']}`]: true,
    [`${styles.reverse}`]: codeComplete,
  });

  const clipboardBtnClasses = classNames({
    [`${styles['copy-btn']}`]: true,
    [`${styles.visible}`]: codeComplete,
  });

  const clearBtnClasses = classNames({
    [`${styles['copy-btn']}`]: true,
    [`${styles.visible}`]:
      getValueFromDataObject(selected[KEY_COLOUR_TEMPERATURE], 'value').length >
      0,
  });

  return (
    <>
      <section className={styles['spec-code']}>
        <h3>specification code</h3>
        <p>
          Click the options below to create your bespoke specification code and
          then download your asset pack.
        </p>
        <div className={wrapperBtnClasses}>
          <button className={clearBtnClasses} onClick={() => handleClearCode()}>
            clear code
          </button>
          <button
            className={clipboardBtnClasses}
            onClick={() => handleCopyToClipboard()}
          >
            {copied ? 'copied ' : 'copy '}to clipboard
          </button>
        </div>
        <CodeContainer selected={selected} refToUse={generatedCode} />
        <div className={styles.wrapper}>
          {Object.keys(HEADERS).map((option, index) => {
            const values = optionsAvailable[option];
            const options = values ? optionsAvailable[option][0] : [];
            const previousHeader = Object.keys(HEADERS)[index - 1];
            const isDisabledColumn = !getValueFromDataObject(
              selected[previousHeader],
              'value'
            ).length;

            return (
              <Fragment key={HEADERS[option]}>
                <SelectOption
                  key={HEADERS[option] + index}
                  header={HEADERS[option]}
                  options={options}
                  isMulti={HEADERS[option] === KEY_OPTIONS}
                  value={getSelectionValue(option)}
                  isDisabled={
                    index !== 0 &&
                    index !== 1 &&
                    index !== 2 &&
                    isDisabledColumn
                  }
                  handleChange={(value) => handleChange(value, option)}
                  tooltips={{
                    bezel: product?.data?.tooltip_bezel,
                    baffle: product?.data?.tooltip_baffle,
                  }}
                  stainlessSteelDefault={stainlessSteelDefault}
                />
              </Fragment>
            );
          })}
        </div>
        <button
          className={btnClasses}
          onClick={() => {
            handleAssetPack();
          }}
        >
          {codeComplete
            ? productDownloads.loading
              ? 'generating your asset pack...'
              : 'create asset pack'
            : 'complete spec code for asset pack'}
        </button>
      </section>
      {codeComplete && (
        <QuickSpec
          data={selected}
          smallScreen={smallScreen}
          mainImage={product.data.thumbnail}
          productName={product.data.title}
          drawingOne={product.activeEngine.add_line_drawings[0]?.line_drawing}
          drawingTwo={product.activeEngine.add_line_drawings[1]?.line_drawing}
        />
      )}
    </>
  );
};
