import React, { useState, useEffect } from 'react';
import { useWindowSize } from 'hooks/use-window-size';
import { bpMedium } from 'constants/breakpoints';
import { THEME_DARK } from 'constants/color-theme';
import { LABEL_OPEN_GALLERY } from 'constants/labels';
import { SIZE_DEFAULT } from 'constants/sizes';
import { chunk } from 'modules/chunk-array';
import PropTypes from 'prop-types';
import { Lightbox } from 'components/media-gallery/lightbox';
import { Media } from 'components/media-gallery/media';
import { Grid } from 'components/media-gallery/grid';
import { Button } from 'components/buttons';
import { Arrow } from 'components/icons';
import styles from './style.module.scss';

export const MediaGallery = ({ theme, medias, featuredAsset }) => {
  const [lightboxOpen, toggleLightbox] = useState(false);
  const [mediaNumber, setMediaNumber] = useState(0);
  const [active, setActive] = useState(null);
  const imagesLength = medias.length;
  const gridSize = chunk(medias, 4);
  const { width } = useWindowSize();
  const isMobile = width <= bpMedium;

  useEffect(() => {
    if (!lightboxOpen) setActive(null);
    if (lightboxOpen) {
      document.body.classList.add('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [lightboxOpen, active, featuredAsset, medias]);

  return (
    <div className={styles.gallery}>
      <div className={styles['left-side']}>
        <Media url={featuredAsset.url} featuredAsset />
        <div className={styles[theme] + ' ' + styles['gradient']} />
      </div>
      <div className={styles['right-side']}>
        {!lightboxOpen && !isMobile && (
          <button onClick={() => toggleLightbox(!lightboxOpen)}>
            open gallery <Arrow />
          </button>
        )}
        {!lightboxOpen && isMobile && (
          <Button
            size={SIZE_DEFAULT}
            theme={THEME_DARK}
            handleClick={() => toggleLightbox(!lightboxOpen)}
            label={LABEL_OPEN_GALLERY}
          />
        )}
        <Grid
          grid={gridSize[0]}
          active={active}
          imagesLength={imagesLength}
          setActive={setActive}
          toggleLightbox={toggleLightbox}
          setMediaNumber={setMediaNumber}
        />
      </div>
      {lightboxOpen && (
        <Lightbox
          medias={[featuredAsset, ...medias]}
          lightboxOpen={lightboxOpen}
          imagesLength={imagesLength}
          mediaNumber={mediaNumber}
          featuredAsset={featuredAsset}
          active={active}
          setActive={setActive}
          setMediaNumber={setMediaNumber}
          toggleLightbox={toggleLightbox}
        />
      )}
    </div>
  );
};

MediaGallery.defaultProps = {
  theme: 'pink',
  featuredAsset: {
    url: 'https://via.placeholder.com/590x536',
    title: 'Featured asset',
    caption: ''
  },
};

MediaGallery.propTypes = {
  theme: PropTypes.string,
  medias: PropTypes.array.isRequired,
  featuredAsset: PropTypes.object.isRequired
}