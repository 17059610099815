import React from 'react';
import styles from 'components/check-box/style.module.scss';

export const WallWash = props => (
  <svg
    className={styles['filter-icon']}
    data-name="Layer 1"
    viewBox="0 0 57 57"
    {...props}
  >
    <circle cx={28} cy={28} r={27} fill="none" strokeWidth={2} />
    <path d="M15 18h26v20H15z" />
  </svg>
);
