import React from 'react';
import { Link } from 'react-router-dom';
import values from 'lodash/fp/values';
import { ROUTE_RANGES } from 'constants/router-links';
import { DownwardArrow } from 'components/icons';
import styles from './style.module.scss';

export const RangeNavigationMobile = ({ ranges }) => {
  return (
    <nav className={styles['range-navigation-mobile']}>
      <ul className={styles.options}>
        {values(ranges).map((range, index) => {
          return (
            <li key={range.ID} className={styles.links}>
              <Link to={`${ROUTE_RANGES}/${range.slug}`}>{range.title}</Link>
              <DownwardArrow />
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
