import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import values from 'lodash/fp/values';
import classNames from 'classnames';
import { THEME_DARK } from 'constants/color-theme';
import { LABEL_LEARN_MORE } from 'constants/labels';
import { DownwardArrow } from 'components/icons';
import { Button } from 'components/buttons';
import { HtmlBlock } from 'components/html-block';
import styles from 'components/dropdown/style.module.scss';

export const FaqsDropdown = ({ data }) => {
  const [active, setActive] = useState(null);

  const handleClick = (item) => {
    item === active ? setActive(null) : setActive(item);
  };

  const wrapperClasses = classNames({
    [`${styles['item-wrapper']}`]: true,
    [`${styles.faq}`]: true,
  });

  const sectionClasses = classNames({
    [`section`]: true,
    [`container`]: true,
    [`${styles.faqs}`]: true,
  });

  const itemClasses = (item) => {
    return classNames({
      [`${styles.item}`]: true,
      [`${styles['is-open']}`]: active === item,
    });
  };

  const arrowClasses = (item) => {
    return classNames({
      [`${styles['arrow-wrapper']}`]: true,
      [`${styles['rotate-down']}`]: active === item,
    });
  };

  const infoClasses = (item) => {
    return classNames({
      [`${styles['item-info']}`]: true,
      [`${styles['is-open']}`]: active === item,
    });
  };

  const createDropdown = () => {
    return (
      <ul className={styles.list}>
        {values(data).map((item, index) => {
          const { faqs } = item;
          return faqs.map((faqItem, index) => {
            return (
              <div key={index} className={wrapperClasses}>
                <div
                  className={itemClasses(faqItem)}
                  onClick={() => handleClick(faqItem)}
                >
                  <li>{faqItem.question}</li>
                  <span className={arrowClasses(faqItem)}>
                    <DownwardArrow />
                  </span>
                </div>
                <div className={infoClasses(faqItem)}>
                  <Fragment key={index}>
                    <div className={styles.answer}>
                      <HtmlBlock>{faqItem.answer}</HtmlBlock>
                    </div>
                    {faqItem.hasButton && (
                      <Link to={`/${faqItem.link}`}>
                        <Button theme={THEME_DARK} label={LABEL_LEARN_MORE} />
                      </Link>
                    )}
                  </Fragment>
                </div>
              </div>
            );
          });
        })}
      </ul>
    );
  };

  return (
    <section className={sectionClasses}>
      <div className={styles['columns-wrapper']}>{createDropdown()}</div>
    </section>
  );
};
