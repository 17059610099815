export const abbreviateAllVariants = (allVariants) => {
  if (allVariants) {
    return allVariants.map((item) => {
      if (item.length <= 4) {
        return item;
      } else {
        return item.substring(0, 3);
      }
    });
  } else {
    return null;
  }
};
