import {
  DOWNLOAD_CHECKBOX_REQUEST,
  DOWNLOAD_CHECKBOX_RECIEVED,
  DOWNLOADS_TOGGLE_CHECKBOX,
  DOWNLOADS_RESET_CHECKBOXES,
  DOWNLOADS_SELECT_ALL_CHECKBOXES,
} from 'redux/actions/download-checkbox';

const initialState = {
  checkboxes: [],
};

const toggleCheckbox = (checkboxes = [], checkbox) => {
  const index = checkboxes.indexOf(checkbox);
  if (index > -1) {
    checkboxes.splice(index, 1);
  } else {
    checkboxes.push(checkbox);
  }
  return checkboxes;
};

export default function downloadCheckbox(state = initialState, action) {
  let newState = action.items;
  let dataArr = [];
  if (newState) {
    dataArr = Array.from(newState);
  }

  if (action.current === true) {
    if (dataArr.includes(action.name)) {
      //Its already in the state
    } else {
      dataArr.push(action.filePath);
    }
  } else if (action.current === false) {
    if (dataArr.includes(action.name)) {
      var index = dataArr.indexOf(action.filePath);
      if (index !== -1) {
        dataArr.splice(index, 1);
      }
    }
  }

  switch (action.type) {
    case DOWNLOAD_CHECKBOX_REQUEST:
      return Object.assign({}, state, {
        checkboxes: action.data,
      });
    case DOWNLOAD_CHECKBOX_RECIEVED:
      return Object.assign({}, state, {
        checkboxes: dataArr,
      });
    case DOWNLOADS_TOGGLE_CHECKBOX:
      return Object.assign({}, state, {
        checkboxes: toggleCheckbox(state.checkboxes, action.url),
      });
    case DOWNLOADS_SELECT_ALL_CHECKBOXES:
      return Object.assign({}, state, {
        checkboxes: action.ids,
      });
    case DOWNLOADS_RESET_CHECKBOXES:
      return Object.assign({}, state, {
        checkboxes: [],
      });

    default:
      return state;
  }
}
