import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Media } from 'components/media-gallery/media';
import { Arrow } from 'components/icons';
import styles from './style.module.scss';

export const Grid = ({
  thumbnailIsOpen,
  lightboxOpen,
  grid,
  imagesLength,
  active,
  setActive,
  toggleLightbox,
  setMediaNumber,
}) => {
  const classes = classNames({
    [`${styles.grid}`]: true,
    [`${styles.thumbnails}`]: lightboxOpen,
    [`${styles.hide}`]: !thumbnailIsOpen,
  });

  const imgClasses = (item) => {
    return classNames({
      [`${styles.selected}`]: active === item,
    });
  };

  const handleGrid = (item, i) => {
    const num = lightboxOpen === undefined ? i + 1 : i;
    setActive(item);
    setMediaNumber(num);
    if (!lightboxOpen) {
      toggleLightbox(true);
    }
  };

  return (
    <div className={classes}>
      {grid.map((item, i) => {
        return (
          <div
            key={item + i}
            className={imgClasses(item)}
            onClick={() => handleGrid(item, i)}
          >
            <Media url={item.url} gridMedia />
          </div>
        );
      })}
      <p>
        view all {''}
        {imagesLength + 1}
        <Arrow />
      </p>
    </div>
  );
};

Grid.propTypes = {
  thumbnailIsOpen: PropTypes.bool,
  lightboxOpen: PropTypes.bool,
  grid: PropTypes.array.isRequired,
  imagesLength: PropTypes.number,
  active: PropTypes.object,
  setActive: PropTypes.func,
  toggleLightbox: PropTypes.func,
  setMediaNumber: PropTypes.func,
}
