import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeMainFilters, resetFilters } from 'redux/actions';
import md5 from 'md5';
import { MainFilters } from 'components/filters/main-filters';
import { LogoIcon } from 'components/logos';
import styles from './style.module.scss';

export const Filters = ({ availableTerms, products }) => {
  const dispatch = useDispatch();
  const { hasFiltered, filters } = useSelector((state) => state.products);
  const hash = md5(JSON.stringify(filters));
  const productRequest = useSelector((state) => state.products[hash]);

  useEffect(() => {
    return () => {
      dispatch(closeMainFilters());
      dispatch(resetFilters());
    };
  }, [dispatch]);

  const totalResults = () => {
    const total = products.length;
    return productRequest?.queryLoading ? (
      <LogoIcon />
    ) : (
      `${total} ${total > 1 ? 'results' : 'result'}`
    );
  };

  return (
    <div className={styles.filters}>
      <MainFilters availableTerms={availableTerms} />
      {hasFiltered && (
        <div className={`${styles.results} flex-center`}>{totalResults()}</div>
      )}
    </div>
  );
};
